import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AppStatus,
    EntityPermissionType,
    HeatMapEntry,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import {
    operationsActions,
    operationsSelectors,
} from '@wdx/clmi/api-services/state';
import { of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OperationsSetup } from '../../../models/operations.model';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    entityIdsCache: string[] = [];

    constructor(private store$: Store) {}

    /**
     * Determines 'Edit' permissions of a user for a given entity record
     * @param entityType
     * @param entityId
     */
    canEdit$(entityType: SystemEntity, entityId: string) {
        if (!entityType || !entityId) {
            return of(false);
        }
        return this.hasPermission$(
            entityType,
            entityId,
            EntityPermissionType.Edit,
        );
    }

    /**
     * Determines if user has 'Edit' permission to a given app.
     * @param app
     * @returns
     */
    canEditApp$(app: AppStatus | HeatMapEntry) {
        if (app.case?.id) {
            return this.canEdit$(SystemEntity.Case, app.case.id);
        }
        if (app.party?.id) {
            return this.canEdit$(
                SystemEntity[app.party?.partyType]
                    ? (app.party?.partyType as unknown as SystemEntity)
                    : SystemEntity.Party,
                app.party.id,
            );
        }
        if (app.client?.id) {
            return this.canEdit$(SystemEntity.Client, app.client.id);
        }
        if (app.partyRole) {
            return this.canEdit$(SystemEntity.PartyRole, app.partyRole.id);
        }
        return of(false);
    }

    loadEntityPermissions(entityType: SystemEntity, entityId: string) {
        this.store$.dispatch(
            operationsActions.getOperationsForId({
                entityType,
                entityId,
            }),
        );
    }

    private getPermissions$({ entityType, entityId }: OperationsSetup) {
        return this.store$
            .select(operationsSelectors.getPermissionsForId, {
                entityType,
                entityId,
            })
            .pipe(
                tap(() => {
                    const cacheKey = entityType + '-' + entityId;
                    if (!this.entityIdsCache.includes(cacheKey)) {
                        this.entityIdsCache.push(cacheKey);
                        this.loadEntityPermissions(entityType, entityId);
                    }
                }),
            );
    }

    private hasPermission$(
        entityType: SystemEntity,
        entityId: string,
        permission: EntityPermissionType,
    ) {
        return this.getPermissions$({ entityType, entityId }).pipe(
            filter((permissions) => Boolean(permissions)),
            map((permissions) => permissions.includes(permission)),
        );
    }
}
