export enum DisplayableFileType {
    audio = 'audio',
    code = 'code',
    csv = 'csv',
    excel = 'excel',
    htm = 'htm',
    html = 'html',
    image = 'image',
    json = 'json',
    pdf = 'pdf',
    powerpoint = 'powerpoint',
    txt = 'txt',
    video = 'video',
    word = 'word',
    xml = 'xml',
}
