import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessVisibility,
    ActivityTask,
    PartyRole,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class PartyRoleService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService,
    ) {}

    getForId(id: string): Observable<PartyRole> {
        return this.http.get<PartyRole>(
            `${this.config.getConfiguration().API_BASE}/partyrole/${id}`,
        );
    }

    getVisibilityForId(id: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${id}/visibility`,
        );
    }

    getAllTasksForPartyRole(
        paginationOptions: PaginationOptions,
        partyRoleId: string,
    ): Observable<PaginatedApiResponse<ActivityTask>> {
        return this.http.get<PaginatedApiResponse<ActivityTask>>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/task`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions,
                    ),
            },
        );
    }
}
