import {
    AccessVisibility,
    Opportunity,
    OpportunityTeamMember,
    PipelineSummary,
} from '@wdx/clmi/clmi-swagger-gen';
import { OpportunityContextualTypes } from '../../models/opportunities.model';
import { createAction, props } from '@ngrx/store';

export const moveToStage = createAction(
    '[Opportunities] Move To Stage',
    props<{
        opportunity: Opportunity;
        fromStage: string;
        toStage: string;
    }>(),
);

export const moveToStageSuccess = createAction(
    '[Opportunities] Move To Stage Success',
    props<{
        opportunity: Opportunity;
        fromStage: string;
        toStage: string;
    }>(),
);

export const moveToStageFailure = createAction(
    '[Opportunities] Move To Stage Failure',
    props<{
        error: Error;
    }>(),
);

export const deleteOpportunity = createAction(
    '[Opportunity] Delete',
    props<{
        stageCode?: string;
        opportunity: Opportunity;
    }>(),
);

export const deleteOpportunitySuccess = createAction(
    '[Opportunity] Delete Success',
    props<{
        stageCode: string;
        opportunity: Opportunity;
    }>(),
);

export const deleteOpportunityFailure = createAction(
    '[Opportunity] Delete Failure',
    props<{
        error: Error;
    }>(),
);

export const getForId = createAction(
    '[Opportunities] Get For ID',
    props<{
        opportunityId: string;
    }>(),
);

export const getForIdSuccess = createAction(
    '[Opportunities] Get For ID Success',
    props<{
        opportunityId: string;
        opportunity: Opportunity;
    }>(),
);

export const getForIdFailure = createAction(
    '[Opportunities] Get For ID Failure',
    props<{
        opportunityId: string;
        error: Error;
    }>(),
);

export const getPipelineSummary = createAction(
    '[Opportunity] Get Pipeline Summary',
    props<{
        filters: any;
    }>(),
);

export const getPipelineSummarySuccess = createAction(
    '[Opportunity] Get Pipeline Summary Success',
    props<{
        pipelineSummary: PipelineSummary;
    }>(),
);

export const getPipelineSummaryFailure = createAction(
    '[Opportunity] Get Pipeline Summary Failure',
    props<{
        error: Error;
    }>(),
);

export const resetOpportunities = createAction(
    '[Opportunities] Reset',
    props<{
        id: string;
    }>(),
);

export const setActiveKanbanStage = createAction(
    '[Opportunities] Set Active Kanban Stage',
    props<{
        stageCode: string;
    }>(),
);

/** Opportunity Team Member */
export const deleteTeamMember = createAction(
    '[Opportunities] Delete Team Member',
    props<{
        opportunityId: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberSuccess = createAction(
    '[Opportunities] Delete Team Member Success',
    props<{
        opportunityId: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberFailure = createAction(
    '[Opportunities] Delete Team Member Failure',
    props<{
        opportunityId: string;
        memberId: string;
        error: Error;
    }>(),
);

export const getAllMembers = createAction(
    '[Opportunities] Get All Members',
    props<{
        opportunityId: string;
    }>(),
);

export const getAllMembersSuccess = createAction(
    '[Opportunities] Get All Members Success',
    props<{
        opportunityId: string;
        teamMembers: OpportunityTeamMember[];
    }>(),
);

export const getAllMembersFailure = createAction(
    '[Opportunities] Get All Members Failure',
    props<{
        opportunityId: string;
        error: Error;
    }>(),
);

export const getVisibilityForId = createAction(
    '[Opportunities] Get Visibility For Id',
    props<{
        id: string;
    }>(),
);

export const getVisibilityForIdSuccess = createAction(
    '[Opportunities] Get Visibility For Id Success',
    props<{
        id: string;
        visibility: AccessVisibility[];
    }>(),
);

export const getVisibilityForIdFailure = createAction(
    '[Opportunities] Get Visibility For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getContextualOpportunity = createAction(
    '[Opportunities] Get Contextual',
    props<{
        contextualId: string;
        contextualType?: OpportunityContextualTypes;
    }>(),
);

export const getContextualOpportunitySuccess = createAction(
    '[Opportunities] Get Contextual Success',
    props<{
        contextualId: string;
        contextualType?: OpportunityContextualTypes;
        opportunities: Opportunity[];
    }>(),
);

export const getContextualOpportunityFailure = createAction(
    '[Opportunities] Get Contextual Failure',
    props<{
        contextualId: string;
        contextualType?: OpportunityContextualTypes;
        error: Error;
    }>(),
);
