import { FileIndex } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

export const extractPdfFile = createAction(
    '[Investor Risk Profile] Extract PDF File',
    props<{
        entityId: string;
        file: FileIndex[];
        formName: string;
    }>(),
);

export const extractPdfFileSuccess = createAction(
    '[Investor Risk Profile] Extract PDF File Success',
);

export const extractPdfFileError = createAction(
    '[Investor Risk Profile] Extract PDF File Error',
    props<{
        error: Error;
    }>(),
);
