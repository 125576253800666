import { Injectable } from '@angular/core';
import {
    Activity,
    Article,
    Case,
    Opportunity,
} from '@wdx/clmi/clmi-swagger-gen';
import { SystemEntityActionProps } from '../../../models/system-entity-action-props.model';
import * as relatedRecordsActions from '../../../state/related-records/related-records.actions';
import * as relatedRecordsSelectors from '../../../state/related-records/related-records.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RelatedRecordsFacadeService {
    childCaseIds = [];
    relatedActivityIds = [];
    relatedCaseIds = [];
    relatedArticleIds = [];
    relatedOpportunityIds = [];
    suggestedArticleIds = [];
    suggestedCaseIds = [];
    suggestedOpportunityIds = [];

    constructor(private store$: Store) {}

    /**
     * Child Cases
     */
    getChildCases$(props: SystemEntityActionProps): Observable<Case[]> {
        return this.store$
            .select(relatedRecordsSelectors.getChildCases, props)
            .pipe(
                tap(() => {
                    if (!this.childCaseIds.includes(props.entityId)) {
                        this.loadChildCases(props);
                        this.childCaseIds.push(props.entityId);
                    }
                }),
            );
    }

    getChildCasesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getChildCasesIsLoadingList,
            props,
        );
    }

    getChildCasesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getChildCasesHasLoadingListError,
            props,
        );
    }

    loadChildCases(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getChildCasesForEntity(props),
        );
    }

    /**
     * Related Cases
     */
    getRelatedCases$(props: SystemEntityActionProps): Observable<Case[]> {
        return this.store$.select(relatedRecordsSelectors.getCases, props).pipe(
            tap(() => {
                if (!this.relatedCaseIds.includes(props.entityId)) {
                    this.loadRelatedCases(props);
                    this.relatedCaseIds.push(props.entityId);
                }
            }),
        );
    }

    getRelatedCasesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getCasesIsLoadingList,
            props,
        );
    }

    getRelatedCasesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getCasesHasLoadingListError,
            props,
        );
    }

    loadRelatedCases(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getRelatedCasesForEntity(props),
        );
    }

    /**
     * Suggested Cases
     */
    getSuggestedCases$(props: SystemEntityActionProps): Observable<Case[]> {
        return this.store$
            .select(relatedRecordsSelectors.getSuggestedActivityCases, props)
            .pipe(
                tap(() => {
                    if (!this.suggestedCaseIds.includes(props.entityId)) {
                        this.loadSuggestedCases(props);
                        this.suggestedCaseIds.push(props.entityId);
                    }
                }),
            );
    }

    getSuggestedCasesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityCasesIsLoadingList,
            props,
        );
    }

    getSuggestedCasesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityCasesHasLoadingListError,
            props,
        );
    }

    loadSuggestedCases(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getSuggestedRelatedCasesForEntity(props),
        );
    }

    /**
     * Related Opportunities
     */
    getRelatedOpportunities$(
        props: SystemEntityActionProps,
    ): Observable<Opportunity[]> {
        return this.store$
            .select(relatedRecordsSelectors.getOpportunities, props)
            .pipe(
                tap(() => {
                    if (!this.relatedOpportunityIds.includes(props.entityId)) {
                        this.loadRelatedOpportunities(props);
                        this.relatedOpportunityIds.push(props.entityId);
                    }
                }),
            );
    }

    getRelatedOpportunitiesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getOpportunitiesIsLoadingList,
            props,
        );
    }

    getRelatedOpportunitiesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getOpportunitiesHasLoadingListError,
            props,
        );
    }

    loadRelatedOpportunities(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getRelatedOpportunitiesForEntity(props),
        );
    }

    /**
     * Suggested Opportunities
     */
    getSuggestedOpportunities$(
        props: SystemEntityActionProps,
    ): Observable<Opportunity[]> {
        return this.store$
            .select(
                relatedRecordsSelectors.getSuggestedActivityOpportunities,
                props,
            )
            .pipe(
                tap(() => {
                    if (
                        !this.suggestedOpportunityIds.includes(props.entityId)
                    ) {
                        this.loadSuggestedOpportunities(props);
                        this.suggestedOpportunityIds.push(props.entityId);
                    }
                }),
            );
    }

    getSuggestedOpportunitiesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityOpportunitiesIsLoadingList,
            props,
        );
    }

    getSuggestedOpportunitiesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityOpportunitiesHasLoadingListError,
            props,
        );
    }

    loadSuggestedOpportunities(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntity(
                props,
            ),
        );
    }

    /**
     * Related Articles
     */
    getRelatedArticles$(props: SystemEntityActionProps): Observable<Article[]> {
        return this.store$
            .select(relatedRecordsSelectors.getArticles, props)
            .pipe(
                tap(() => {
                    if (!this.relatedArticleIds.includes(props.entityId)) {
                        this.loadRelatedArticles(props);
                        this.relatedArticleIds.push(props.entityId);
                    }
                }),
            );
    }

    getRelatedArticlesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getArticlesIsLoadingList,
            props,
        );
    }

    getRelatedArticlesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getArticlesHasLoadingListError,
            props,
        );
    }

    loadRelatedArticles(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getRelatedArticlesForEntity(props),
        );
    }

    /**
     * Suggested Articles
     */
    getSuggestedArticles$(
        props: SystemEntityActionProps,
    ): Observable<Article[]> {
        return this.store$
            .select(relatedRecordsSelectors.getSuggestedActivityArticles, props)
            .pipe(
                tap(() => {
                    if (!this.suggestedArticleIds.includes(props.entityId)) {
                        this.loadSuggestedArticles(props);
                        this.suggestedArticleIds.push(props.entityId);
                    }
                }),
            );
    }

    getSuggestedArticlesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityArticlesIsLoadingList,
            props,
        );
    }

    getSuggestedArticlesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getSuggestedActivityArticlesHasLoadingListError,
            props,
        );
    }

    loadSuggestedArticles(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getSuggestedRelatedArticlesForEntity(props),
        );
    }

    /**
     * Related Activites
     */
    getRelatedActivities$(
        props: SystemEntityActionProps,
    ): Observable<Activity[]> {
        return this.store$
            .select(relatedRecordsSelectors.getActivities, props)
            .pipe(
                tap(() => {
                    if (!this.relatedActivityIds.includes(props.entityId)) {
                        this.loadRelatedActivities(props);
                        this.relatedActivityIds.push(props.entityId);
                    }
                }),
            );
    }

    getRelatedActivitesIsLoading$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getActivitiesIsLoadingList,
            props,
        );
    }

    getRelatedActivitiesHasError$(
        props: SystemEntityActionProps,
    ): Observable<boolean> {
        return this.store$.select(
            relatedRecordsSelectors.getActivitiesHasLoadingListError,
            props,
        );
    }

    loadRelatedActivities(props: SystemEntityActionProps): void {
        this.store$.dispatch(
            relatedRecordsActions.getRelatedActivitiesForEntity(props),
        );
    }
}
