import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@wdx/clmi/clmi-swagger-gen';
import { Privilege } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as userActions from './user.actions';
import * as userReducer from './user.reducer';
import * as userSelectors from './user.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserFacadeService {
    store$ = inject(Store<userReducer.State>);

    me: User | undefined;
    isAdmin = false;

    getMe$(): Observable<User | undefined> {
        return this.store$.select(userSelectors.getMeSelector).pipe(
            tap((me) => {
                if (me) {
                    this.me = me;

                    this.isAdmin =
                        me.privileges?.includes(Privilege.Administrator) ||
                        false;
                }
            }),
        );
    }

    isAdminUser$(): Observable<boolean | undefined> {
        return this.store$.select(userSelectors.isSystemAdminSelector);
    }

    getUserPrivilege$(): Observable<string[] | undefined | null> {
        return this.store$.select(userSelectors.getPrivilegesSelector);
    }

    getMeLocale$(): Observable<User | undefined> {
        return this.store$.select(userSelectors.getMeLocaleSelector);
    }

    onChangeAppLanguage(language: string) {
        this.store$.dispatch(
            userActions.changeAppLanguageAction({ languageCode: language }),
        );
    }
}
