/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ExternalTaskStatusType {
  Inactive = 'Inactive',
  NotStarted = 'NotStarted',
  Submitted = 'Submitted',
  Confirmed = 'Confirmed',
  ReadyForSubmission = 'ReadyForSubmission',
  InProgress = 'InProgress',
  CannotStartYet = 'CannotStartYet'
}
