import { Pipe, PipeTransform } from '@angular/core';
import { CompletedStatusType } from '@wdx/clmi/clmi-swagger-gen';

@Pipe({ name: 'completedStatusIsCompleted' })
export class CompletedStatusIsCompletedPipe implements PipeTransform {
    transform(completedStatus: CompletedStatusType): boolean {
        return [
            CompletedStatusType.CompletedOnTime,
            CompletedStatusType.CompletedOverdue,
        ].includes(completedStatus);
    }
}
