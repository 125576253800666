import { Action, createReducer, on } from '@ngrx/store';
import { FormDataResult } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as investorRiskProfileActions from './investor-risk-profile.actions';

export interface State {
    investorRiskProfile: CrudStateObject<FormDataResult>;
}

export const initialState: State = {
    investorRiskProfile: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        investorRiskProfileActions.extractPdfFile,
        (state): State => ({
            ...state,
            investorRiskProfile: {
                ...state.investorRiskProfile,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.investorRiskProfile[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<FormDataResult>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        }),
    ),

    on(
        investorRiskProfileActions.extractPdfFileSuccess,
        (state): State => ({
            ...state,
            investorRiskProfile: {
                ...state.investorRiskProfile,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.investorRiskProfile[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                },
            },
        }),
    ),

    on(
        investorRiskProfileActions.extractPdfFileError,
        (state): State => ({
            ...state,
            investorRiskProfile: {
                ...state.investorRiskProfile,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.investorRiskProfile[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
