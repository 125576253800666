import {
    Component,
    HostBinding,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ModalManagementService } from '../../../../services/modal-management.service';

import { BehaviorSubject, Subject } from 'rxjs';

import { PowerBiReport } from '@wdx/clmi/clmi-swagger-gen';
import { PowerBiRequestsService } from '../../../../shared/services';

@Component({
    selector: 'clmi-powerbi-portfolio',
    templateUrl: './powerbi-portfolio.component.html',
})
export class PowerbiPortfolioComponent implements OnInit {
    @HostBinding('class') classes = 'd-block w-100 h-100 bg-body';

    @Input() powerbiReportId: string;
    @Input() filterPrefix: string;
    @Input() entityId: string;
    @Input() iconButtonClass = '';

    // Track the when the powerBI chart data is loaded
    hasLoadedChart$ = new BehaviorSubject<boolean>(false);

    // This would be the list of PowerBIReport for the ng-select
    powerBiList$ = new Subject<PowerBiReport[]>();

    // Track Report embedding status
    isEmbedded = false;

    // CSS Class to be passed to the wrapper
    // Hide the report container initially
    reportClass = 'report-container h-100';

    // Flag which specify the type of embedding
    phasedEmbeddingFlag = false;

    // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
    // Values for properties like embedUrl, accessToken and settings will be set on click of button
    reportConfig$ = new BehaviorSubject({
        type: 'report',
        embedUrl: null,
        tokenType: 1,
        accessToken: null,
        settings: null,
        id: null,
    });

    @ViewChild('powerBiModal', { read: TemplateRef })
    powerBiModal: TemplateRef<any>;

    constructor(
        private pbirService: PowerBiRequestsService,
        private modalManagementService: ModalManagementService,
    ) {}

    ngOnInit(): void {
        this.pbirService
            .getDashboardConfig(this.powerbiReportId)
            .subscribe((powerBiToken) => {
                this.reportConfig$.next({
                    type: 'report',
                    embedUrl: `${powerBiToken.embedUrl}&filter=${this.filterPrefix}(${this.entityId})`,
                    tokenType: 1,
                    accessToken: powerBiToken.embedToken.token,
                    settings: null,
                    id: powerBiToken.id,
                });
                this.hasLoadedChart$.next(true);
            });
    }

    openModalFullScreen(): void {
        this.modalManagementService.openModalWithId('powerBiModal');
    }
}
