import { Component, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PartyAvatarInfo, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import {
    avatarInfoActions,
    avatarInfoSelectors,
} from '@wdx/clmi/api-services/state';
import { Avatar } from '@wdx/clmi/utils/models';
import { Observable } from 'rxjs';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
import * as rootReducer from '../../../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar-info',
    templateUrl: './molecule-avatar-info.component.html',
})
export class MoleculeAvatarInfoComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() avatar: Avatar;
    @Input() editModalId: string;

    partyAvatarInfo$: Observable<PartyAvatarInfo>;
    partyAvatarInfoIsLoading$: Observable<boolean>;
    partyAvatarInfoHasLoadingError$: Observable<boolean>;

    constructor(actions$: Actions, store$: Store<rootReducer.State>) {
        super();

        this.patchInjectedItems({
            actions$,
            store$,
        });
    }

    ngOnInit() {
        let entityType: SystemEntity;
        let entityId: string;
        switch (true) {
            case Boolean(this.avatar.entityType && this.avatar.entityId):
                entityType = this.avatar.entityType;
                entityId = this.avatar.entityId;
                break;
            case Boolean(this.avatar.partyType):
                entityType = SystemEntity.Party;
                entityId = this.avatar.partyId;
                break;
            case Boolean(!this.avatar.partyType && this.avatar.lookupType):
                entityType = SystemEntity[this.avatar.lookupType];
                entityId = this.avatar.partyId;
                break;
            default:
                entityType = SystemEntity.Party;
                entityId = this.avatar.partyId || this.avatar.entityId;
        }
        if (entityType && entityId) {
            this.store$.dispatch(
                avatarInfoActions.getForEntityId({ entityType, entityId }),
            );

            this.partyAvatarInfo$ = this.store$.select(
                avatarInfoSelectors.getSingle(entityType, entityId),
            );
            this.partyAvatarInfoIsLoading$ = this.store$.select(
                avatarInfoSelectors.getIsLoadingSingle(entityType, entityId),
            );
            this.partyAvatarInfoHasLoadingError$ = this.store$.select(
                avatarInfoSelectors.getHasLoadingSingleError(
                    entityType,
                    entityId,
                ),
            );
        }
    }
}
