import { Pipe, PipeTransform } from '@angular/core';
import { Document } from '@wdx/clmi/clmi-swagger-gen';
import { DisplayDatePipe, FileSizePipe } from '@wdx/shared/utils';
import { DocumentData } from '@wdx/shared/infrastructure/file-io';

@Pipe({ name: 'documentsToDocumentData' })
export class DocumentsToDocumentDataPipe implements PipeTransform {
    transform(documents: Document[]): DocumentData[] {
        return documents
            ? documents.map((document) => ({
                  primaryInfo: document.fileIndex.name,
                  secondaryInfo: `${
                      document.documentType.name
                  } - ${new FileSizePipe().transform(
                      document.fileIndex.sizeInBytes,
                  )}`,
                  tertiaryInfo: [
                      `Uploaded ${new DisplayDatePipe().transform(
                          document.dateUploaded,
                          {
                              relative: true,
                              relativeStyle: 'long',
                          },
                      )}`,
                  ],
                  fileType: document.fileIndex.contentType,
              }))
            : [];
    }
}
