/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PendingChangeStatusType {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Accepted = 'Accepted',
  Applied = 'Applied',
  Rejected = 'Rejected',
  Superceded = 'Superceded'
}
