import { Injectable } from '@angular/core';
import { DataChangeActionType } from '@wdx/clmi/clmi-swagger-gen';
import {
    IFormDataResult,
    IFormLayout,
    IFormPendingChange,
    IFormPendingChangeSubForm,
    PENDING_CHANGE_LOCK_ALL,
    PENDING_CHANGE_REG,
    PENDING_CHANGE_SPLIT,
    UPDATE_PENDING_CHANGE_COUNTER,
} from '@wdx/shared/infrastructure/form-framework';

@Injectable({
    providedIn: 'any',
})
export class FormSummaryPendingChangesService {
    subFormPendingChanges = {};
    pendingChangesSubFormCounter: IFormPendingChangeSubForm = {};
    coc: IFormPendingChange[] = [];

    resetRequiredInfo(pendingChanges: IFormPendingChange[]): void {
        this.coc = pendingChanges?.length ? [...pendingChanges] : [];
        this.subFormPendingChanges = {};
        this.pendingChangesSubFormCounter = {};
    }

    updatePendingData(
        layoutAndDefinition: IFormLayout,
        formDetails: IFormDataResult,
    ): void {
        this.resetRequiredInfo(formDetails?.pendingChanges);
        this.updateSubFormPendingChanges();

        if (formDetails?.pendingChanges?.length) {
            layoutAndDefinition.sectionLayoutDefinitions?.forEach(
                (sectionLayoutDefinition) => {
                    sectionLayoutDefinition.elementLayoutDefinitions.forEach(
                        (elementLayoutDefinition) => {
                            const HAS_SUB_FORM_GROUPING =
                                this.subFormPendingChanges[
                                    elementLayoutDefinition.name
                                ];

                            if (HAS_SUB_FORM_GROUPING) {
                                this.updatePendingChangeCounter(
                                    elementLayoutDefinition.name,
                                    null,
                                    null,
                                    elementLayoutDefinition.label,
                                );
                            }

                            formDetails.pendingChanges.forEach(
                                (pendingChange) => {
                                    if (
                                        pendingChange.field ===
                                        elementLayoutDefinition?.name
                                    ) {
                                        pendingChange.name =
                                            elementLayoutDefinition.label;
                                        elementLayoutDefinition.pendingChange =
                                            pendingChange;
                                    }
                                },
                            );
                        },
                    );
                },
            );
        }

        this.coc = this.coc.filter((change) => change.name);
    }

    updateSubFormPendingChanges(): void {
        const removeItems: any[] = [];
        const PENDING_CHANGES = this.coc;

        PENDING_CHANGES?.forEach((pendingChange, index) => {
            // Check if sub form field is been updated
            if (pendingChange.field?.match(PENDING_CHANGE_REG)) {
                removeItems.push(index);
                const PENDING = PENDING_CHANGE_SPLIT(pendingChange.field);

                this.addDataToSubFormPendingChanges(
                    PENDING[0],
                    parseInt(PENDING[1], 10),
                    {
                        ...pendingChange,
                        field: PENDING[2],
                        name: PENDING[2],
                        ...(PENDING?.[3] ? { subItems: PENDING?.[3] } : {}),
                    },
                );
            }
        });

        removeItems.reverse().forEach((index) => {
            this.coc?.splice(index, 1);
        });
    }

    addDataToSubFormPendingChanges(
        arrayName: string,
        index: number,
        field?: any,
    ): void {
        const CHANGE_ENTIRE_ROW = [
            DataChangeActionType.Add,
            DataChangeActionType.Remove,
        ].includes(field.action);

        // For these the entire row is been changed
        if (CHANGE_ENTIRE_ROW) {
            this.subFormPendingChanges = {
                ...this.subFormPendingChanges,
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: field,
                },
            };
            this.updatePendingChangeCounter(arrayName, field.action, index);
        }

        // These are fields that are been updated in the row
        if (field.name) {
            this.subFormPendingChanges = {
                ...this.subFormPendingChanges,
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: field,
                },
            };

            this.updatePendingChangeCounter(
                arrayName,
                DataChangeActionType.Update,
                index,
            );
        }
    }

    findSubFormPendingChangesInFormData(data): void {
        Object.keys(data).forEach((key) => {
            const FIELD_DATA = data[key];

            if (Array.isArray(FIELD_DATA)) {
                FIELD_DATA.forEach((row, index) => {
                    if (PENDING_CHANGE_LOCK_ALL.includes(row.action)) {
                        this.updatePendingChangeCounter(key, row.action, index);

                        this.subFormPendingChanges = {
                            ...this.subFormPendingChanges,
                            [key]: {
                                ...this.subFormPendingChanges[key],
                                [index]: row,
                            },
                        };
                    }
                });
            }
        });
    }

    updatePendingChangeCounter(
        name: string,
        type: DataChangeActionType,
        index: number,
        label?: string,
    ): void {
        this.pendingChangesSubFormCounter = UPDATE_PENDING_CHANGE_COUNTER(
            this.pendingChangesSubFormCounter,
            name,
            type,
            label,
            index,
        );
    }
}
