import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
import { Activity, Case, PartyStub } from '@wdx/clmi/clmi-swagger-gen';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as articlesActions from '../../../../../state/articles/articles.actions';
import * as articlesSelectors from '../../../../../state/articles/articles.selectors';
import * as rootReducer from '../../../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-engagements',
    templateUrl: './molecule-engagements.component.html',
})
export class MoleculeEngagementsComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() links = 0;
    @Input() likes = 0;
    @Input() showText: boolean;
    @Input() articleId: string;
    @Input() hasInfoModal = true;
    @Input() infoModalTitle = 'Engagements Info';

    linkedActivities$: Observable<Activity[]>;
    linkedActivitiesIsLoading$: Observable<boolean>;
    linkedActivitiesHasError$: Observable<boolean>;

    linkedCases$: Observable<Case[]>;
    linkedCasesIsLoading$: Observable<boolean>;
    linkedCasesHasError$: Observable<boolean>;

    likedBy$: Observable<PartyStub[]>;
    likedByIsLoading$: Observable<boolean>;
    likedByHasError$: Observable<boolean>;

    constructor(
        actions$: Actions,
        store$: Store<rootReducer.State>,
        route: ActivatedRoute,
    ) {
        super();

        this.patchInjectedItems({
            actions$,
            store$,
            route,
        });
    }

    ngOnInit() {
        if (!this.hasInfoModal || !this.articleId) {
            return;
        }

        this.linkedActivities$ = this.store$.select(
            articlesSelectors.getLinkedActivities,
            { id: this.articleId },
        );
        this.linkedActivitiesIsLoading$ = this.store$.select(
            articlesSelectors.getIsLoadingLinkedActivities,
            { id: this.articleId },
        );
        this.linkedActivitiesHasError$ = this.store$.select(
            articlesSelectors.getHasLoadingLinkedActivitiesError,
            { id: this.articleId },
        );

        this.linkedCases$ = this.store$.select(
            articlesSelectors.getLinkedCases,
            { id: this.articleId },
        );
        this.linkedCasesIsLoading$ = this.store$.select(
            articlesSelectors.getIsLoadingLinkedCases,
            { id: this.articleId },
        );
        this.linkedCasesHasError$ = this.store$.select(
            articlesSelectors.getHasLoadingLinkedCasesError,
            { id: this.articleId },
        );

        this.likedBy$ = this.store$.select(
            articlesSelectors.getFavouritedInfinityCombinedList,
            { id: this.articleId },
        );
        this.likedByIsLoading$ = this.store$.select(
            articlesSelectors.getFavouritedIsLoadingPage,
            { id: this.articleId },
        );
        this.likedByHasError$ = this.store$.select(
            articlesSelectors.getFavouritedHasLoadingPageError,
            { id: this.articleId },
        );
    }

    onGetLinksAndLikes(): void {
        this.getLinkedRecords();
        this.getLikedBy();
    }

    getLinkedRecords(): void {
        this.store$.dispatch(
            articlesActions.linkedActivities({
                articleId: this.articleId,
            }),
        );
        this.store$.dispatch(
            articlesActions.linkedCases({
                articleId: this.articleId,
            }),
        );
    }

    getLikedBy(): void {
        this.store$.dispatch(
            articlesActions.favourited({
                articleId: this.articleId,
                reset: true,
            }),
        );
    }
}
