import { Action, createReducer, on } from '@ngrx/store';
import { License } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as licenseActions from './license.actions';

export const LicenseStore = 'license';

export interface State {
    license?: CrudState<License>;
}

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        licenseActions.getLicense,
        (state): State => ({
            ...state,
            license: {
                ...(state.license || ({} as CrudState<License>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        }),
    ),

    on(
        licenseActions.getLicenseSuccess,
        (state, props): State => ({
            ...state,
            license: {
                ...(state.license || ({} as CrudState<License>)),
                isLoadingList: false,
                hasLoadingListError: false,
                single: props.license,
            },
        }),
    ),
    on(
        licenseActions.getLicenseFailure,
        (state): State => ({
            ...state,
            license: {
                ...(state.license || ({} as CrudState<License>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
