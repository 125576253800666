import { createAction, props } from '@ngrx/store';
import { PartyRoleRelationship } from '@wdx/clmi/clmi-swagger-gen';

export const getForPartyRole = createAction(
    '[Connections] Get For PartyRole',
    props<{
        partyRoleId: string;
    }>(),
);

export const getForPartyRoleSuccess = createAction(
    '[Connections] Get For PartyRole Success',
    props<{
        partyRoleId: string;
        relationships: PartyRoleRelationship[];
    }>(),
);

export const getForPartyRoleFailure = createAction(
    '[Connections] Get For PartyRole Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>(),
);
