/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ActivityType {
  Unknown = 'Unknown',
  PhoneCall = 'PhoneCall',
  Email = 'Email',
  Appointment = 'Appointment',
  Task = 'Task',
  WorkItem = 'WorkItem',
  Note = 'Note',
  TimeBooking = 'TimeBooking',
  Glia = 'Glia',
  Qwil = 'Qwil',
  Sms = 'Sms',
  Push = 'Push',
  SecureMessage = 'SecureMessage',
  Unblu = 'Unblu',
  Letter = 'Letter',
  ExternalTask = 'ExternalTask'
}
