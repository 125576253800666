import { inject, Pipe, PipeTransform } from '@angular/core';
import { View, ViewType } from '@wdx/clmi/clmi-swagger-gen';
import { NavItem, TranslationsService } from '@wdx/shared/utils';
import { ICON_BROWSER, ICON_USER_COG } from '../constants/icons.constants';

@Pipe({ name: 'viewsAsMenuItems' })
export class ViewsAsMenuItemsPipe implements PipeTransform {
    private translationsService = inject(TranslationsService);

    transform(views: View[]): NavItem[] {
        if (!views?.length) {
            return;
        }

        const systemViews: NavItem[] = views
            ?.filter((view) => view.type === ViewType.System)
            .map((view) => ({
                label: this.translationsService.translateTokenisedString(
                    view.name,
                ),
                value: view.id,
                data: view,
            }));

        const personalViews: NavItem[] = views
            ?.filter((view) => view.type === ViewType.Personal)
            .map((view) => ({
                label: view.name,
                value: view.id,
                data: view,
            }));

        const systemPersonalGroupedViews: NavItem[] = [
            ...(systemViews?.length
                ? [
                      {
                          label: this.translationsService.getTranslationByKey(
                              'SYSTEM_VIEWS',
                          ),
                          icon: ICON_BROWSER.icon,
                          subMenu: systemViews,
                      },
                  ]
                : []),
            ...(personalViews?.length
                ? [
                      {
                          label: this.translationsService.getTranslationByKey(
                              'PERSONAL_VIEWS',
                          ),
                          icon: ICON_USER_COG.icon,
                          subMenu: personalViews,
                      },
                  ]
                : []),
        ];

        return systemPersonalGroupedViews;
    }
}
