import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormDataHistory, Team } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import * as rootReducer from '../../../../state/_setup/reducers';
import * as teamActions from '../../../../state/team/team.actions';
import * as teamSelectors from '../../../../state/team/team.selectors';

@Injectable({
    providedIn: 'root',
})
export class TeamFacade {
    _teamId: string;

    teamInfo$: Observable<Team>;
    teamInfoIsLoading$: Observable<boolean>;
    teamInfoHasError$: Observable<boolean>;

    teamHistory$: Observable<FormDataHistory[]>;
    teamHistoryIsLoading$: Observable<boolean>;
    teamHistoryHasError$: Observable<boolean>;

    constructor(private store$: Store<rootReducer.State>) {}

    set teamId(id: string) {
        this._teamId = id;
    }

    get teamId(): string {
        return this._teamId;
    }

    loadTeamInfo(teamId: string): void {
        this.store$.dispatch(
            teamActions.getForId({
                teamId: teamId,
            }),
        );
    }

    setTeamInfo(teamId: string): void {
        this.teamInfo$ = this.store$.select(teamSelectors.getForId(teamId));
        this.teamInfoIsLoading$ = this.store$.select(
            teamSelectors.getForIdIsLoading(teamId),
        );
        this.teamInfoHasError$ = this.store$.select(
            teamSelectors.getForIdHasLoadingError(teamId),
        );
    }

    setHistory(teamId: string): void {
        this.teamHistory$ = this.store$.select(
            teamSelectors.getHistoryForId(teamId),
        );
        this.teamHistoryIsLoading$ = this.store$.select(
            teamSelectors.getHistoryForIdIsLoading(teamId),
        );
        this.teamHistoryHasError$ = this.store$.select(
            teamSelectors.getHistoryForIdHasLoadingError(teamId),
        );
    }

    loadHistory(teamId: string): void {
        this.store$.dispatch(teamActions.getHistoryForId({ teamId: teamId }));
    }

    addTeamMember(teamId: string, userId: string): void {
        this.store$.dispatch(teamActions.addTeamMember({ teamId, userId }));
    }
}
