import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import {
    ADVISER_FORM_ID,
    TRANSLATION_TABLE_HEADER_COMPLETED_ACTIVITIES,
    TRANSLATION_TABLE_HEADER_LAST_CONTACTED,
    TRANSLATION_TABLE_HEADER_NAME,
    TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
    TRANSLATION_TABLE_HEADER_PIPELINE,
    TRANSLATION_TABLE_HEADER_TOTAL_VALUE_INTRODUCED,
    TRANSLATION_TABLE_HEADER_TOTAL_VALUE_MANAGED,
} from '@wdx/shared/utils';
import { ICON_ADD } from '../../../constants/icons.constants';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { Privilege } from '../../../models/privilege.model';
import { SortMenuSortBy } from '../../../models/sort-menu.model';
import { TableHeader } from '../../../models/table-header.model';

export const ADVISERS_TABLE_HEADER: TableHeader[] = [
    {
        value: 'Name',
        translationKey: TRANSLATION_TABLE_HEADER_NAME,
        sortByFieldName: 'party.name',
    },
    {
        value: 'Intermediary',
        translationKey: 'AREA_CONTACTS_INTERMEDIARY',
    },
    {
        value: 'Last Contacted',
        translationKey: TRANSLATION_TABLE_HEADER_LAST_CONTACTED,
        sortByFieldName: SortMenuSortBy.LastContacted,
    },
    {
        value: 'Next Activity',
        translationKey: TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
        sortByFieldName: SortMenuSortBy.NextMeeting,
    },
    {
        value: 'Completed Activities',
        translationKey: TRANSLATION_TABLE_HEADER_COMPLETED_ACTIVITIES,
    },
    {
        value: 'Total Value Introduced',
        translationKey: TRANSLATION_TABLE_HEADER_TOTAL_VALUE_INTRODUCED,
    },
    {
        value: 'Total Value Managed',
        translationKey: TRANSLATION_TABLE_HEADER_TOTAL_VALUE_MANAGED,
    },
    {
        value: 'Pipeline (#)',
        translationKey: TRANSLATION_TABLE_HEADER_PIPELINE,
        sortByFieldName: SortMenuSortBy.Pipeline,
    },
    {
        value: null,
    },
];

export const ADVISERS_ACTION_BTN = [
    {
        mode: ActionButtonMode.FavouriteButton,
        cySelector: 'btn-advisers-favourite',
    },
    {
        mode: ActionButtonMode.IconButton,
        cySelector: 'btn-advisers-add',
        icon: ICON_ADD.icon,
        privilege: Privilege.CreateAdviser,
        formSetup: {
            formId: ADVISER_FORM_ID,
        },
    },
];

export const ADVISERS_ROUTE_DATA = {
    viewType: SystemEntity.Adviser,
    label: 'Advisers',
    translationKey: 'AREA_CONTACTS_ADVISERS',
    dropdownMenuPrivileges: {
        export: Privilege.ExportAdviser,
        import: Privilege.ImportAdviser,
    },
    hasLegacyChildren: true,
};
