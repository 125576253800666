/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum UserErrorType {
  AccessDenied = 'AccessDenied',
  MissingValue = 'MissingValue',
  DuplicateValue = 'DuplicateValue',
  FeatureNotAvailable = 'FeatureNotAvailable',
  InvalidState = 'InvalidState',
  InvalidRequest = 'InvalidRequest',
  InconsistentValue = 'InconsistentValue',
  IncompleteApps = 'IncompleteApps',
  UnresolvedIssues = 'UnresolvedIssues',
  AppNotConfirmable = 'AppNotConfirmable',
  CannotCompleteActivitiesScheduledInFuture = 'CannotCompleteActivitiesScheduledInFuture',
  InvalidData = 'InvalidData',
  CannotDeleteAllAccess = 'CannotDeleteAllAccess',
  DocumentAlreadyExists = 'DocumentAlreadyExists',
  PotentialDuplicates = 'PotentialDuplicates',
  DatabaseConstraintViolation = 'DatabaseConstraintViolation',
  MessageOnly = 'MessageOnly',
  RequiresComment = 'RequiresComment',
  RecordLocked = 'RecordLocked',
  RecordInUse = 'RecordInUse',
  TooManyFound = 'TooManyFound',
  Process = 'Process',
  Concurrency = 'Concurrency',
  Configuration = 'Configuration',
  RecordAlreadyPrimary = 'RecordAlreadyPrimary',
  Credits = 'Credits',
  DownstreamError = 'DownstreamError',
  Custom = 'Custom'
}
