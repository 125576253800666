import { createAction, props } from '@ngrx/store';
import { Party } from '@wdx/clmi/clmi-swagger-gen';
import { EngagementState } from '../../models/engagement-state.model';

export const setVisibility = createAction(
    '[Engagement Bar] Set Visibility',
    props<{
        isVisible: boolean;
    }>(),
);

export const setActivityId = createAction(
    '[Engagement Bar] Set Activity ID',
    props<{
        activityId: string;
    }>(),
);

export const setParty = createAction(
    '[Engagement Bar] Set Party',
    props<{
        party: Party;
    }>(),
);

export const setEngagementState = createAction(
    '[Engagement Bar] Set Engagement State',
    props<{
        engagementState: EngagementState;
    }>(),
);
