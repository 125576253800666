import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { PersonCreate } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class ProspectsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    create(prospectCreateData: PersonCreate): Observable<PersonCreate> {
        return this.http.post<PersonCreate>(
            `${this.config.getConfiguration().API_BASE}/person`,
            prospectCreateData,
        );
    }
}
