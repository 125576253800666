import { createSelector } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import * as avatarInfoReducer from './avatar-info.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    avatarInfo: avatarInfoReducer.State;
}): avatarInfoReducer.State => state.avatarInfo;

/**
 * Reducer property selectors
 */
export const getIsLoadingSingle = (
    entityType: SystemEntity,
    entityId: string,
) =>
    createSelector(
        getState,
        (state: avatarInfoReducer.State) =>
            state?.avatarInfo &&
            state?.avatarInfo[entityType] &&
            state.avatarInfo[entityType][entityId]?.isLoadingSingle,
    );
export const getHasLoadingSingleError = (
    entityType: SystemEntity,
    entityId: string,
) =>
    createSelector(
        getState,
        (state: avatarInfoReducer.State) =>
            state?.avatarInfo &&
            state?.avatarInfo[entityType] &&
            state.avatarInfo[entityType][entityId]?.hasLoadingSingleError,
    );
export const getSingle = (entityType: SystemEntity, entityId: string) =>
    createSelector(
        getState,
        (state: avatarInfoReducer.State) =>
            state?.avatarInfo &&
            state?.avatarInfo[entityType] &&
            state.avatarInfo[entityType][entityId]?.single,
    );
