import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebHook } from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class WebhooksService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService,
    ) {}

    loadAllWebHooks(
        paginationOptions: PaginationOptions,
    ): Observable<PaginatedApiResponse<WebHook>> {
        return this.http.get<PaginatedApiResponse<WebHook>>(
            `${this.config.getConfiguration().API_BASE}/webhook`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions,
                    ),
            },
        );
    }
}
