import { Pipe, PipeTransform } from '@angular/core';
import { ContactPointStub, Reference } from '@wdx/clmi/clmi-swagger-gen';
import { Avatar } from '@wdx/clmi/utils/models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Pipe({ name: 'referenceToAvatar' })
export class ReferenceToAvatarPipe implements PipeTransform {
    transform(value: Reference | ContactPointStub): Avatar {
        if (!value) {
            return undefined;
        }

        return new PartyToAvatarPipe().transform(
            {
                id: value.id,
                name: value.name,
            },
            true,
        );
    }
}
