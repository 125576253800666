import { createAction, props } from '@ngrx/store';
import { SuggestedParty } from '@wdx/clmi/clmi-swagger-gen';

export const getForParty = createAction(
    '[Suggested Parties] Get For Party',
    props<{
        partyId: string;
    }>(),
);

export const getForPartySuccess = createAction(
    '[Suggested Parties] Get For Party Success',
    props<{
        partyId: string;
        suggestedParties: SuggestedParty[];
    }>(),
);

export const getForPartyFailure = createAction(
    '[Suggested Parties] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);
