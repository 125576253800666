/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum LookupSourceType {
  None = 'None',
  Any = 'Any',
  Party = 'Party',
  Person = 'Person',
  ExternalUser = 'ExternalUser',
  Organisation = 'Organisation',
  User = 'User',
  Team = 'Team',
  RootTeam = 'RootTeam',
  BookingCentre = 'BookingCentre',
  ServicingCentre = 'ServicingCentre',
  Construct = 'Construct',
  ClientProduct = 'ClientProduct',
  ClientProductLive = 'ClientProductLive',
  ClientProductLiveNotClosing = 'ClientProductLiveNotClosing',
  Portfolio = 'Portfolio',
  InvestmentAccount = 'InvestmentAccount',
  BankingAccount = 'BankingAccount',
  InsuranceAccount = 'InsuranceAccount',
  LendingAccount = 'LendingAccount',
  PensionAccount = 'PensionAccount',
  WealthManagementAccount = 'WealthManagementAccount',
  ExternalBankAccount = 'ExternalBankAccount',
  ProductGroup = 'ProductGroup',
  Adviser = 'Adviser',
  Intermediary = 'Intermediary',
  ProductProvider = 'ProductProvider',
  Company = 'Company',
  Charity = 'Charity',
  Trust = 'Trust',
  Collective = 'Collective',
  Corporate = 'Corporate',
  Client = 'Client',
  Case = 'Case',
  Process = 'Process',
  PartyRole = 'PartyRole',
  PartyRoleParty = 'PartyRoleParty',
  PersonPartyRole = 'PersonPartyRole',
  OrganisationPartyRole = 'OrganisationPartyRole',
  Opportunity = 'Opportunity',
  Activity = 'Activity',
  List = 'List',
  Campaign = 'Campaign',
  Event = 'Event',
  ContactPoint = 'ContactPoint',
  ElectronicAddress = 'ElectronicAddress',
  PhoneAddress = 'PhoneAddress',
  MobileAddress = 'MobileAddress',
  PostalAddress = 'PostalAddress',
  SocialAddress = 'SocialAddress',
  ElectronicBankingAddress = 'ElectronicBankingAddress',
  Assignee = 'Assignee',
  Document = 'Document',
  DocumentTemplate = 'DocumentTemplate',
  DocumentRequirement = 'DocumentRequirement',
  EdsMessageBatch = 'EdsMessageBatch',
  Import = 'Import',
  Envelope = 'Envelope',
  Signatory = 'Signatory',
  AccountHolders = 'AccountHolders',
  AccountHolderEmails = 'AccountHolderEmails',
  AccountHolderAddresses = 'AccountHolderAddresses',
  EligibleClient = 'EligibleClient',
  ClientRelationship = 'ClientRelationship',
  ClientRelationshipChild = 'ClientRelationshipChild',
  Association = 'Association',
  Foundation = 'Foundation',
  LifeInsuranceWrapper = 'LifeInsuranceWrapper',
  AmlReport = 'AmlReport',
  Block = 'Block',
  PersonalRelatedPeople = 'PersonalRelatedPeople'
}
