import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import {
    Highlight,
    UTILS_ICON_MISSING,
    UTILS_ICON_QUESTION,
    UTILS_ICON_USER,
    WdxThemeColor,
    fadeInOutAnimation,
} from '@wdx/shared/utils';

import { Avatar, WdxAvatarSize } from '../models';

@Component({
    selector: 'wdx-avatar',
    templateUrl: './wdx-avatar.component.html',
    animations: [fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAvatarComponent implements OnInit {
    @Input() avatar?: Avatar | null;
    @Input() size?: WdxAvatarSize;
    @Input() set borderColor(value: WdxThemeColor | undefined) {
        this.borderClasses = value ? `border border-1 border-${value}` : '';
    }
    public borderClasses?: string;

    @HostBinding('class') class = 'position-relative';

    public initials = '';
    public altIcon = '';

    ngOnInit(): void {
        this.initials = this.avatar?.text
            ? this.getInitials(this.avatar.text)
            : '';

        this.altIcon = this.getAvatarIcon(this.avatar);
    }

    getInitials(text: string): string {
        const words = text
            .split(' ')
            .map((word) => word.replace(/\W/g, ''))
            .filter((word, index, array) => {
                return (
                    Boolean(word) && (index === 0 || index === array.length - 1)
                );
            });

        const initials = words
            .map((name) => name.substring(0, 1).toUpperCase())
            .join('');

        return initials;
    }

    getAvatarIcon(avatar?: Avatar | null): string {
        if (!avatar) {
            return UTILS_ICON_MISSING;
        }
        if (avatar.isUnknown) {
            return UTILS_ICON_QUESTION;
        }
        if (avatar.icon) {
            return avatar.icon as string;
        }

        return UTILS_ICON_USER;
    }
}
