/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PhoneType {
  Unknown = 'Unknown',
  Home = 'Home',
  Work = 'Work',
  Other = 'Other',
  MobilePersonal = 'MobilePersonal',
  MobileWork = 'MobileWork',
  MobileOther = 'MobileOther',
  FaxHome = 'FaxHome',
  FaxWork = 'FaxWork',
  FaxOther = 'FaxOther'
}
