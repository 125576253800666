/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum DocumentRequirementStatusType {
  Pending = 'Pending',
  Generated = 'Generated',
  Provided = 'Provided',
  Received = 'Received',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  ApprovedWithConditions = 'ApprovedWithConditions',
  Cancelled = 'Cancelled'
}
