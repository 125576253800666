import { Feed } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

export const getForClientProduct = createAction(
    '[Feeds] Get For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getForClientProductSuccess = createAction(
    '[Feeds] Get For Client Product Success',
    props<{
        feeds: Feed[];
        clientProductId: string;
    }>(),
);

export const getForClientProductFailure = createAction(
    '[Feeds] Get For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getFeedsForClient = createAction(
    '[Feeds] Get Feeds For Client',
    props<{
        clientId: string;
    }>(),
);

export const getFeedsForClientSuccess = createAction(
    '[Feeds] Get Feeds For Client Success',
    props<{
        clientFeeds: Feed[];
        clientId: string;
    }>(),
);

export const getFeedsForClientFailure = createAction(
    '[Feeds] Get Feeds For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>(),
);
