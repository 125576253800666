import { createAction, props } from '@ngrx/store';
import { PartyRoleRelationship } from '@wdx/clmi/clmi-swagger-gen';

export const getForParty = createAction(
    '[Personal Connections] Get For Party',
    props<{
        partyId: string;
    }>(),
);

export const getForPartySuccess = createAction(
    '[Personal Connections] Get For Party Success',
    props<{
        partyId: string;
        relationships: PartyRoleRelationship[];
    }>(),
);

export const getForPartyFailure = createAction(
    '[Personal Connections] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);
