import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { AppStatus } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class AppsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getAppStatus(appId: string): Observable<AppStatus> {
        return this.http.get<AppStatus>(
            `${this.config.getConfiguration().API_BASE}/appstatus/${appId}`,
        );
    }

    confirm(appId: string): Observable<any> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/appstatus/${appId}/confirm`,
            {},
        );
    }
}
