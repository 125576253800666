/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum UserAction {
  View = 'View',
  ViewSensitive = 'ViewSensitive',
  DownloadFile = 'DownloadFile',
  Favourite = 'Favourite',
  UnFavourite = 'UnFavourite'
}
