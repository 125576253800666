import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Adviser, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { FilterQueryFacadeService } from '@wdx/clmi/api-services/state';
import { ACTIVITY_ICON_MAP } from '@wdx/clmi/utils/constants';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import {
    DisplayDatePipe,
    ShortCurrencyPipe,
    TranslationsService,
} from '@wdx/shared/utils';
import { ContactsService } from '../../../../features/contacts/shared/services/contacts.service';
import { ActionButton } from '../../../../models/action-button.model';
import { ContactType } from '../../../../models/contact-type.model';
import { RouteDataService } from '../../../../shared/services';
import { EntityOperationsService } from '../../../../shared/services/entity-operations';
import * as rootReducer from '../../../../state/_setup/reducers';
import { AccessVisibilityService } from '../../access-visibility/shared/services/access-visibility.service';
import { FilterQueryService } from '../../form-filters';
import { FilterTableComponentClass } from '../filter-table-component.class';
import { ADVISERS_ACTION_BTN, ADVISERS_TABLE_HEADERS } from './advisers.static';

@Component({
    selector: 'clmi-filter-table-advisers',
    templateUrl: '../filter-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableAdvisersComponent
    extends FilterTableComponentClass
    implements AfterViewInit, OnInit
{
    actionButtons: ActionButton[] = ADVISERS_ACTION_BTN;

    @ViewChild('infoCardCell', { read: TemplateRef })
    infoCardCell: TemplateRef<any>;

    @ViewChild('actionButtonCell', { read: TemplateRef })
    actionButtonCell: TemplateRef<any>;

    @ViewChild('responsiveCardTemplate', { read: TemplateRef })
    responsiveCardTemplate: TemplateRef<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected accessVisibilityService: AccessVisibilityService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
        protected entityRouteService: EntityRouteService,
        private contactsService: ContactsService,
    ) {
        super(
            router,
            route,
            location,
            accessVisibilityService,
            store$,
            filterQueryService,
            filterQueryFacadeService,
            entityOperationsService,
            cd,
            routeDataService,
            translationsService,
            entityRouteService,
        );

        this.headers = ADVISERS_TABLE_HEADERS;
        this.table.headers = this.headers;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(
            this.filterQueryService.queryType,
            this.getAdviserAsTableRow,
            this.infoCardCell,
            this.actionButtonCell,
            this.responsiveCardTemplate,
            this.contactsService,
        );

        this.cd.detectChanges();
    }

    resetTable(): void {
        super.resetTable(ADVISERS_TABLE_HEADERS);
    }

    getAdviserAsTableRow = (
        adviser: Adviser,
        infoCardCell,
        actionButtonCell,
        responsiveCardTemplate,
    ) => {
        const infoCardData = {
            avatars: [new PartyToAvatarPipe().transform(adviser.party)],
            primaryInfo: adviser.party.name,
            routerLinkData: this.entityRouteService.routeForEntity(
                SystemEntity.Adviser,
                adviser.id,
            ),
        };

        const actionButtonData = {
            isMine: adviser.isMine,
            isFavourite: adviser.isFavourite,
            id: adviser.id,
            partyId: adviser.party.id,
            contactType: ContactType.Adviser,
            entityType: SystemEntity.Adviser,
            party: adviser.party,
            contextMenu: this.contactsService.getContextMenuActionButton({
                entityId: adviser.party.id,
                entityType: SystemEntity.Party,
            }),
        };

        return {
            data: adviser,
            routerLink: this.entityRouteService.routeForEntity(
                SystemEntity.Adviser,
                adviser.id,
            ),
            responsiveCard: {
                templateRef: responsiveCardTemplate,
                data: {
                    infoCard: infoCardData,
                    actionButton: actionButtonData,
                },
            },
            values: [
                {
                    templateRef: infoCardCell,
                    data: infoCardData,
                },
                {
                    value: adviser?.intermediary?.name,
                },
                ...(adviser.lastContacted?.date
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  adviser.lastContacted?.date,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  },
                              ),
                              icon: ACTIVITY_ICON_MAP[
                                  adviser.lastContacted?.type
                              ],
                          },
                      ]
                    : [{ value: '' }]),
                ...(adviser.nextMeeting?.date
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  adviser.nextMeeting?.date,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  },
                              ),
                              icon: ACTIVITY_ICON_MAP[
                                  adviser.nextMeeting?.type
                              ],
                          },
                      ]
                    : [{ value: '' }]),
                { value: '' }, // TODO: api does not return completed activities yet
                {
                    value: new ShortCurrencyPipe().transform(
                        adviser.totalValueIntroduced.value,
                        adviser.totalValueIntroduced.isoCode,
                    ),
                },
                {
                    value: new ShortCurrencyPipe().transform(
                        adviser.totalValueManaged.value,
                        adviser.totalValueManaged.isoCode,
                    ),
                },
                ...(adviser.pipeline?.value
                    ? [
                          {
                              value: `${new ShortCurrencyPipe().transform(
                                  adviser.pipeline?.value?.value,
                                  adviser.pipeline?.value?.isoCode,
                              )}
                                (${adviser.pipeline?.count})`,
                          },
                      ]
                    : [{ value: '' }]),
                {
                    templateRef: actionButtonCell,
                    data: actionButtonData,
                },
            ],
        };
    };
}
