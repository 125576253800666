/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PerformancePeriod {
  OneMonth = 'OneMonth',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths',
  YearToDate = 'YearToDate',
  OneYear = 'OneYear',
  ThreeYear = 'ThreeYear',
  FiveYear = 'FiveYear',
  SinceInception = 'SinceInception'
}
