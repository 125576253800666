import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable } from 'rxjs';

import {
    Comment,
    ActivityCommentCreate,
    ActivityType,
    SystemEntity,
    User,
    ActivityComment,
} from '@wdx/clmi/clmi-swagger-gen';

import { Paging } from '@wdx/clmi/api-services/models';
import { COMMENTS_PAGE_SIZE } from '../../../../../constants/comments.constants';
import * as commentsActions from '../../../../../state/comments/comments.actions';
import * as commentsSelectors from '../../../../../state/comments/comments.selectors';
import { IAppMention } from '../constants/mention.constant';
import { ChatFacade, Chat } from '@wdx/clmi/features/chat';

@Injectable({
    providedIn: 'root',
})
export class CommentsFacade {
    id: string;
    entityType: SystemEntity;
    activityType: ActivityType;
    comments$: Observable<Comment[]>;
    paging$: Observable<Paging>;
    isLoading$: Observable<boolean>;
    hasError$: Observable<boolean>;
    isCreating$: Observable<boolean>;
    hasCreatingError$: Observable<boolean>;
    addCommentTrigger$ = new BehaviorSubject<boolean>(null);
    appMention$ = new BehaviorSubject<IAppMention>(null);
    invertedStyling = false;
    mode: 'activity' | 'entity';

    readonly PAGE_SIZE = COMMENTS_PAGE_SIZE;

    constructor(
        private store$: Store,
        private chatFacade: ChatFacade,
    ) {}

    getChat(entityId: string, entityType: SystemEntity): Observable<Chat> {
        this.mode = 'entity';
        this.entityType = entityType;
        this.id = entityId;
        return this.chatFacade.getChat$(entityType, entityId);
    }

    getActivityComments(id: string, reset?: boolean): void {
        this.mode = 'activity';
        this.id = id;
        this.comments$ = this.store$
            .select(commentsSelectors.getInfinityCombinedList, { id })
            .pipe(map((comments) => this.convertToNewCommentFormat(comments)));
        this.paging$ = this.store$.select(commentsSelectors.getInfinityPaging, {
            id,
        });
        this.isLoading$ = this.store$.select(
            commentsSelectors.getIsLoadingPage,
            { id },
        );
        this.hasError$ = this.store$.select(
            commentsSelectors.getHasLoadingPageError,
            { id },
        );
        this.isCreating$ = this.store$.select(commentsSelectors.getIsCreating, {
            id,
        });
        this.hasCreatingError$ = this.store$.select(
            commentsSelectors.getHasCreatingError,
            { id },
        );

        this.store$.dispatch(
            commentsActions.getPage({
                activityId: id,
                reset,
                page: 1,
                pageSize: this.PAGE_SIZE,
            }),
        );
    }

    dispatchCommentData(
        commentCreateData: ActivityCommentCreate,
        config?: {
            mode?: 'activity' | 'entity';
            entityId?: string;
            entityType?: SystemEntity;
        },
    ): void {
        const mode = config?.mode || this.mode;
        const id = config?.entityId || this.id;
        const entityType = config?.entityType || this.entityType;
        if (mode === 'activity') {
            this.store$.dispatch(
                commentsActions.create({
                    activityId: id,
                    commentCreateData,
                }),
            );
        } else {
            this.chatFacade.createComment(entityType, id, {
                ...commentCreateData,
                resolvesId: commentCreateData.resolveIssueId,
            });
        }
    }

    dispatchResolveIssue(me: User): void {
        this.store$.dispatch(
            commentsActions.updateCommentData({
                activityId: this.id,
                me,
            }),
        );
    }

    convertToNewCommentFormat(comments: ActivityComment[]): Comment[] {
        return (
            comments?.map((comment) => ({
                ...comment,
                resolution: (comment as ActivityComment)?.resolvingChat,
                replyTo: (comment as ActivityComment)?.replyToChat,
                timestamp: (comment as ActivityComment)?.dateOccurred,
            })) || []
        );
    }
}
