import { Pipe, PipeTransform } from '@angular/core';
import * as showdown from 'showdown';
import {
    MARKDOWN_TRANSFORM_PATTERN,
    TARGET_LINK,
} from './markdown-to-html.pipe.static';

@Pipe({ name: 'markdownToHtml', standalone: true })
export class WdxMarkdownToHtmlPipe implements PipeTransform {
    transform(markdown?: string | null): string {
        showdown.extension(TARGET_LINK, () => {
            return MARKDOWN_TRANSFORM_PATTERN;
        });
        const Converter = new showdown.Converter({
            extensions: [TARGET_LINK],
        });
        return Converter.makeHtml((markdown && String(markdown)) || '');
    }
}
