import { Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SortDirection } from '@wdx/clmi/clmi-swagger-gen';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentClass } from './base-component.class';
import { IFilterHandler } from '../interfaces/filter-handler.interface';

@Directive()
export class FilterHandler
    extends BaseComponentClass
    implements IFilterHandler
{
    private filters: any;
    private sortType: any;
    private sortBy: string;
    private sortDirection = SortDirection.Ascending;
    private searchText: string;
    private isFavourite: boolean;

    private form: UntypedFormGroup;

    valuesChanged = new Subject();
    resetValues = new Subject<void>();

    fieldDefinitions: any;

    isDirty = false;

    setForm(form: UntypedFormGroup) {
        this.form = form;
        this.form.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => this.setFilters(value));
    }

    get compiledPayload(): any {
        const finalFilters = this.filters || {};

        const definedFilters = Object.keys(finalFilters)
            .filter((key) => ![null, undefined].includes(finalFilters[key]))
            .reduce(
                (accumulator, key) => ({
                    ...accumulator,
                    [key]: finalFilters[key],
                }),
                {},
            );

        return {
            ...definedFilters,
            ...(this.sortType ? { sortBy: this.sortType } : {}),
            ...(this.sortBy ? { sortBy: this.sortBy } : {}),
            ...(this.sortDirection
                ? { sortDirection: this.sortDirection }
                : {}),
            ...(this.searchText ? { search: this.searchText } : {}),
            isFavourite: Boolean(this.isFavourite),
        };
    }

    getFilters(): any {
        return this.filters;
    }

    setFilters(filters: any): void {
        const parsedFilters = Object.keys(filters || {}).reduce(
            (accumulator, key) => {
                if (key === 'isFavourite') {
                    this.isFavourite = filters[key] === true;
                }
                return filters[key]
                    ? {
                          ...accumulator,
                          [key]: filters[key],
                      }
                    : accumulator;
            },
            {},
        );

        this.filters = parsedFilters;
    }

    setSortBy(sortBy: string, valueChanges = true): void {
        this.sortBy = sortBy;
        if (valueChanges) {
            this.valuesChanged.next(this.compiledPayload);
        }
    }

    getSortBy(): string {
        return this.sortBy;
    }

    setSortDirection(sortDirection: SortDirection, valueChanges = true): void {
        this.sortDirection = sortDirection;
        if (valueChanges) {
            this.valuesChanged.next(this.compiledPayload);
        }
    }

    toggleSortDirection(): void {
        this.sortDirection =
            this.sortDirection === SortDirection.Descending
                ? SortDirection.Ascending
                : SortDirection.Descending;
        this.valuesChanged.next(this.compiledPayload);
    }

    getSortDirection(): SortDirection {
        return this.sortDirection;
    }

    setSort(sortType: any, isInitialValue: boolean): void {
        this.sortType = sortType;
        if (!isInitialValue) {
            this.valuesChanged.next(this.compiledPayload);
        }
    }

    setSearchText(searchText: string): void {
        this.searchText = searchText;
        this.apply();
    }

    setSearchTextWithoutEmitting(searchText: string): void {
        this.searchText = searchText;
    }

    getSearchText(): string {
        return this.searchText;
    }

    setIsFavourite(isFavourite: boolean, valueChanges = true): void {
        this.isFavourite = isFavourite;
        if (valueChanges) {
            this.apply();
        }
    }

    toggleIsFavourite(isFavourite: boolean): void {
        this.isFavourite = isFavourite;
        this.apply();
    }

    apply() {
        this.isDirty = Object.values(this.filters || {}).some((value) =>
            Boolean(value),
        );
        this.valuesChanged.next(this.compiledPayload);
    }

    reset() {
        if (this.form) {
            this.form.reset();
        }

        this.filters = {};
        this.searchText = '';
        this.sortType = undefined;
        this.sortBy = '';
        this.sortDirection = SortDirection.Ascending;
        this.isFavourite = undefined;

        this.setFilters(this.form?.value);

        this.resetValues.next();
        this.valuesChanged.next(this.compiledPayload);

        this.isDirty = false;
    }
}
