import { createAction, props } from '@ngrx/store';
import { Nudge, NudgeCreate } from '@wdx/clmi/clmi-swagger-gen';

export const create = createAction(
    '[Nudges] Create',
    props<{
        nudgeCreateData: NudgeCreate;
    }>(),
);

export const createSuccess = createAction(
    '[Nudges] Create Success',
    props<{
        nudge: NudgeCreate;
    }>(),
);

export const createFailure = createAction(
    '[Nudges] Create Failure',
    props<{
        error: Error;
    }>(),
);

export const deleteNudge = createAction(
    '[Nudges] Delete',
    props<{
        nudgeId: string;
    }>(),
);

export const deleteSuccess = createAction(
    '[Nudges] Delete Success',
    props<{
        nudgeId: string;
    }>(),
);

export const deleteFailure = createAction(
    '[Nudges] Delete Failure',
    props<{
        error: Error;
    }>(),
);

export const dismissNudge = createAction(
    '[Nudges] Dismiss',
    props<{
        nudgeId: string;
    }>(),
);

export const dismissSuccess = createAction(
    '[Nudges] Dismiss Success',
    props<{
        nudge: Nudge;
    }>(),
);

export const dismissFailure = createAction(
    '[Nudges] Dismiss Failure',
    props<{
        error: Error;
    }>(),
);
