/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormElementType {
  Field = 'Field',
  Label = 'Label',
  Button = 'Button',
  Link = 'Link',
  Array = 'Array'
}
