import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessVisibility,
    Case,
    ClientProduct,
    EntityOperations,
    KeyStatResult,
    PartyRoleTeamMember,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ClientProductsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForPartyRole(partyRoleId: string): Observable<ClientProduct[]> {
        return this.http.get<ClientProduct[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/products`,
        );
    }

    getForClient(clientId: string): Observable<ClientProduct[]> {
        return this.http.get<ClientProduct[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/clientproduct`,
        );
    }

    getForParty(partyId: string): Observable<ClientProduct[]> {
        return this.http.get<ClientProduct[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/products`,
        );
    }

    getForId(clientProductId: string): Observable<ClientProduct> {
        return this.http.get<ClientProduct>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}`,
        );
    }

    getCasesForId(clientProductId: string): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/cases`,
        );
    }

    getVisibilityForId(id: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${id}/visibility`,
        );
    }

    getMemberForId(id: string): Observable<PartyRoleTeamMember[]> {
        return this.http.get<PartyRoleTeamMember[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${id}/member`,
        );
    }

    getKeyStatsForId(id: string): Observable<KeyStatResult[]> {
        return this.http.get<KeyStatResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${id}/keystats`,
        );
    }

    deleteForId(id: string): Observable<ClientProduct> {
        return this.http.delete<ClientProduct>(
            `${this.config.getConfiguration().API_BASE}/clientproduct/${id}`,
        );
    }

    deleteTeamMember(
        clientProductId: string,
        memberId: string,
    ): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/member/${memberId}`,
        );
    }

    getOperationForId(clientProductId: string): Observable<EntityOperations> {
        return this.http.get<EntityOperations>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/operation`,
        );
    }
}
