import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FilesFacadeService } from '@wdx/shared/infrastructure/file-io';
import { FileIndex, SystemEntity, UserAction } from '@wdx/shared/utils';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';
import { FormFrameworkAuditUserActionEvent } from '../../../models';

@Component({
    selector: 'wdx-ff-file-upload-control',
    templateUrl: './form-file-upload-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFileUploadControlComponent
    extends BaseWdxFormControlClass
    implements OnInit, OnDestroy
{
    @Output() formEvent = new EventEmitter<FormFrameworkAuditUserActionEvent>();

    public fileIndexes!: FileIndex[] | null;
    public fileIndexesToDelete: string[] = [];
    public initialContainerValue!: FileIndex;
    public resetUpload?: number;

    get containerField() {
        return this.controlContainer.control?.get(
            this.formElement.name as string,
        );
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private filesFacadeService: FilesFacadeService,
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        if (this.formControl?.value?.length) {
            this.fileIndexes = this.formControl.value;
        }

        this.setInitialContainerValue();
    }

    setInitialContainerValue() {
        this.initialContainerValue = this.containerField?.value
            ? this.containerField.value[0]
            : null;
    }

    onFileUploaded(fileIndex: FileIndex) {
        this.fileIndexes = [...(this.fileIndexes || []), fileIndex];
        this.formControl?.setValue(this.fileIndexes);
        this.resetUpload = Date.now();
    }

    onDeleteFile(fileIndex: FileIndex): void {
        this.fileIndexesToDelete.push(fileIndex.fileIndex as string);
        this.fileIndexes =
            this.fileIndexes?.filter(
                (fi) => fi.fileIndex !== fileIndex.fileIndex,
            ) || [];
        this.formControl?.setValue(this.fileIndexes);
    }

    /**
     * Perform file cleanup on files not saved to parent control
     */
    fileCleanup() {
        // if container is disabled don't attempt to cleanup
        if (this.containerField?.disabled) {
            return;
        }

        // delete all files removed from control using delete button if doesn't match container value
        if (this.fileIndexesToDelete.length) {
            this.fileIndexesToDelete
                .filter(
                    (fileIndex) =>
                        fileIndex !== this.initialContainerValue?.fileIndex,
                )
                .forEach((fileIndex) => {
                    this.filesFacadeService.deleteFile(fileIndex);
                });
        }
    }

    override ngOnDestroy(): void {
        this.fileCleanup();
        super.ngOnDestroy();
    }

    public downloadClicked() {
        this.formEvent.emit(
            new FormFrameworkAuditUserActionEvent({
                userAction: {
                    action: UserAction.DownloadFile,
                    entity: SystemEntity.Document,
                    id: this.formStaticService.formData.entityId as string,
                },
            }),
        );
    }
}
