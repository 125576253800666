import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

export const assignTo = createAction(
    '[Assign] Assign To',
    props<{
        entityType: SystemEntity;
        entityId: string;
        partyId: string;
    }>(),
);

export const assignToSuccess = createAction(
    '[Assign] Assign To Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        partyId: string;
    }>(),
);

export const assignToFailure = createAction(
    '[Assign] Assign To Failure',
    props<{
        entityId: string;
        partyId: string;
        error: Error;
    }>(),
);

export const bulkAssignTo = createAction(
    '[Assign] Bulk Assign To',
    props<{
        entityType: SystemEntity;
        entityIds: string[];
        partyId: string;
    }>(),
);

export const bulkAssignToSuccess = createAction(
    '[Assign] Bulk Assign To Success',
    props<{
        entityType: SystemEntity;
        entityIds: string[];
        partyId: string;
    }>(),
);

export const bulkAssignToFailure = createAction(
    '[Assign] Bulk Assign To Failure',
    props<{
        entityIds: string[];
        partyId: string;
        error: Error;
    }>(),
);
