/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ListType {
  Standard = 'Standard',
  Family = 'Family',
  Business = 'Business',
  Compliance = 'Compliance',
  Dynamic = 'Dynamic'
}
