import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { forkJoin, Observable } from 'rxjs';
import {
    SystemEntity,
    Tag,
    TagCategoryDetails,
} from '@wdx/clmi/clmi-swagger-gen';
import { SimpleTag, TagEntity } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class TagsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForRecord(entity: TagEntity, recordId: string): Observable<Tag[]> {
        return this.http.get<Tag[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/tag/${entity}/${recordId}`,
        );
    }

    getCategoriesForSystemEntity(
        systemEntity: SystemEntity,
    ): Observable<TagCategoryDetails[]> {
        return this.http.get<TagCategoryDetails[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/tag/category?entityType=${systemEntity}`,
        );
    }

    getForCategory(category: TagCategoryDetails): Observable<Tag[]> {
        return this.http.get<Tag[]>(
            `${this.config.getConfiguration().API_BASE}/tag/category/${
                category.type
            }`,
        );
    }

    createForRecord(
        entity: string,
        entityId: string,
        category: string,
        tagNames: string[],
    ): Observable<Tag[]> {
        return forkJoin(
            tagNames.map((tagName) =>
                this.http.post<Tag>(
                    `${
                        this.config.getConfiguration().API_BASE
                    }/tag/${entity}/${entityId}/category/${category}?tag=${tagName}`,
                    {},
                ),
            ),
        );
    }

    deleteForRecord(
        entity: string,
        entityId: string,
        category: string,
        tagNames: string[],
    ): Observable<any> {
        return forkJoin(
            tagNames.map((tagName) =>
                this.http.delete(
                    `${
                        this.config.getConfiguration().API_BASE
                    }/tag/${entity}/${entityId}/category/${category}?tag=${tagName}`,
                    {},
                ),
            ),
        );
    }

    delete(tagName: string): Observable<any> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/tag/${tagName}`,
            {},
        );
    }

    getForCategoryAndRecord(
        categoryId: string,
        entity: SystemEntity,
        entityId: string,
    ): Observable<SimpleTag[]> {
        return this.http.get<SimpleTag[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/tag/${entity}/${entityId}/category/${categoryId}`,
        );
    }

    getAllTags(): Observable<TagCategoryDetails[]> {
        return this.http.get<TagCategoryDetails[]>(
            `${this.config.getConfiguration().API_BASE}/tag/category`,
        );
    }

    disableTag(tagId: string, isDisabled: boolean): Observable<Tag> {
        return this.http.patch<Tag>(
            `${
                this.config.getConfiguration().API_BASE
            }/tag/${tagId}/disabled/${isDisabled}`,
            {},
        );
    }
}
