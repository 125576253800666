import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as relatedClientsActions from './related-clients.actions';
import { RelatedClientsService } from './related-clients.service';
import { Client } from '@wdx/shared/swagger-gen';

@Injectable()
export class RelatedClientsEffects {
    private actions$ = inject(Actions);
    private relatedClientsService = inject(RelatedClientsService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedClientsActions.getForParty),
            mergeMap((action) =>
                this.relatedClientsService.getForParty(action.partyId).pipe(
                    map((relatedClients) =>
                        relatedClientsActions.getForPartySuccess({
                            partyId: action.partyId,
                            relatedClients: relatedClients as Client[],
                        }),
                    ),
                    catchError((error) =>
                        of(
                            relatedClientsActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
