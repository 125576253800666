import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { LookupFieldResult } from '@wdx/clmi/clmi-swagger-gen';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import {
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { UTILS_ICON_DELETE, UTILS_ICON_SEARCH } from '@wdx/shared/utils';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { InfoCardData } from '../../../../../models/info-card-data.model';
import { InfoCardSearchMode } from '../../../../../models/info-card-search-mode.model';
import { RandomStringPipe } from '../../../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../../../services/modal-management.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-lookup-dumb',
    templateUrl: './molecule-input-lookup-dumb.component.html',
    styleUrls: ['./molecule-input-lookup-dumb.component.scss'],
})
export class MoleculeInputLookupDumbComponent
    extends BaseInputClass
    implements OnInit
{
    @Input() isMulti: boolean;
    @Input() showReadout: boolean;

    @Input() lookupIsLoading: boolean;
    @Input() lookupHasError: boolean;
    @Input() lookupResults: LookupFieldResult[];
    @Input() lookupMode: string;
    @Input() set showSuggestion(val: boolean) {
        this.bannerInfo = '';
        if (val) {
            this.bannerInfo = 'Showing suggested records.';
        }
    }
    @Input() placeholder: string;
    @Input() showNoResultsMessage: boolean;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() open = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter();
    @Output() search = new EventEmitter<string>();
    @Output() modalRegistered = new EventEmitter<string>();
    @Output() generateDocument = new EventEmitter<string>();
    @Output() previewAvailableDocumentData =
        new EventEmitter<LookupFieldResult>();
    @Output() deleteItem = new EventEmitter<any>();

    modalId: string;
    inputName: string;
    bannerInfo: string;
    keyword: string;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;
    readonly ICON_SEARCH = UTILS_ICON_SEARCH;
    readonly ICON_DELETE = UTILS_ICON_DELETE;
    readonly WDX_BUTTON_STYLE = WdxButtonStyle;
    readonly WDX_BUTTON_SIZE = WdxButtonSize;

    constructor(
        elementRef: ElementRef,
        modalManagementService: ModalManagementService,
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            modalManagementService,
        });

        this.keyword = null;
    }

    ngOnInit() {
        this.modalId = new RandomStringPipe().transform();
        this.modalRegistered.emit(this.modalId);

        this.inputName = new RandomStringPipe().transform();
    }

    lookupResultsAsInfoCardData(
        lookupResults: LookupFieldResult[],
    ): InfoCardData[] {
        return lookupResults?.map((lookupResult) => ({
            primaryInfo: lookupResult.name,
            tertiaryInfo: [
                ...(lookupResult.partyType
                    ? [{ label: lookupResult.partyType }]
                    : []),
                ...(lookupResult.description
                    ? [{ label: lookupResult.description }]
                    : []),
            ],
            avatars: [new PartyToAvatarPipe().transform(lookupResult)],
            id: lookupResult.id,
            data: lookupResult,
        }));
    }

    valueAsArray(value: any): any[] {
        return (Array.isArray(value) ? value : [value]).filter((arrayValue) =>
            Boolean(arrayValue),
        );
    }

    valueAsString(value: any): string {
        let returnValue: string;

        if (!value) {
            return null;
        }

        if (value[0]) {
            returnValue = value.map((val) => val.name).join('; ');
        } else {
            returnValue = value.name;
        }
        return returnValue;
    }

    onOpen(): void {
        this.open.emit();
    }

    onClose(): void {
        this.close.emit();
    }

    onChange(value: any): void {
        if (Array.isArray(value) && value.length > 1) {
            value = value.filter(
                (lookups, i, lookup) =>
                    lookup.findIndex(
                        (currentLookup) => currentLookup.id === lookups.id,
                    ) === i,
            );
        }
        this.updateValue(value);
    }

    onConfirmClicked(): void {
        this.modalManagementService.closeActiveModal();
        this.onClose();
    }

    onSearch(searchText: string): void {
        this.search.emit(searchText);
        this.keyword = searchText;
    }

    onGenerateAndDownloadDocument(entityId: string): void {
        this.generateDocument.emit(entityId);
    }

    onPreviewAvailableDocumentData(lookup: LookupFieldResult): void {
        this.previewAvailableDocumentData.emit(lookup);
    }

    clearField($event: Event): void {
        $event.stopPropagation();
        super.onClear();
    }

    deleteSingle($event: Event, item: any): void {
        $event.stopPropagation();
        this.deleteItem.emit(item);
    }
}
