import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { UserActionEntity } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class FavouritesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    favourite(id: string, entity: UserActionEntity): Observable<object> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/useraction/favourite/entity/${entity}/${id}`,
            {},
        );
    }

    unfavourite(id: string, entity: UserActionEntity): Observable<object> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/useraction/unfavourite/entity/${entity}/${id}`,
            {},
        );
    }
}
