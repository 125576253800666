import { Action, createReducer, on } from '@ngrx/store';
import {
    ClientProductRelationship,
    RoleType,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rolesActions from './roles.actions';

export interface State {
    roles?: CrudStateObject<RoleType>;
    clientProductRoles?: CrudStateObject<ClientProductRelationship>;
}

export const initialState: State = {
    roles: {},
    clientProductRoles: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        rolesActions.getAll,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.roles[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    list: props.roles,
                },
            },
        }),
    ),

    on(
        rolesActions.getAllFailure,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.roles[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        rolesActions.getByRoleType,
        (state, props): State => ({
            ...state,
            roles: {
                ...state.roles,
                [props.roleType]: {
                    ...(state.roles[props.roleType] ||
                        ({} as CrudState<RoleType>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.getByRoleTypeSuccess,
        (state, props): State => ({
            ...state,
            roles: {
                ...state.roles,
                [props.roleType]: {
                    ...state.roles[props.roleType],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.role,
                },
            },
        }),
    ),

    on(
        rolesActions.getByRoleTypeFailure,
        (state, props): State => ({
            ...state,
            roles: {
                ...state.roles,
                [props.roleType]: {
                    ...state.roles[props.roleType],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        rolesActions.getForClientProduct,
        (state, props): State => ({
            ...state,
            clientProductRoles: {
                ...state.clientProductRoles,
                [props.clientProductId]: {
                    ...(state.roles[props.clientProductId] ||
                        ({} as CrudState<RoleType>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.getForClientProductSuccess,
        (state, props): State => ({
            ...state,
            clientProductRoles: {
                ...state.clientProductRoles,
                [props.clientProductId]: {
                    ...state.roles[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.roles,
                },
            },
        }),
    ),

    on(
        rolesActions.getForClientProductFailure,
        (state, props): State => ({
            ...state,
            clientProductRoles: {
                ...state.clientProductRoles,
                [props.clientProductId]: {
                    ...state.roles[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
    on(
        rolesActions.enableRoleStatus,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.enableRoleStatusSuccess,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.enableRoleStatusFailure,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        rolesActions.availableRoleStatus,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.availableRoleStatusSuccess,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        rolesActions.availableRoleStatusFailure,
        (state): State => ({
            ...state,
            roles: {
                ...state.roles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.roles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RoleType>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
