import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IGetConfig,
    SystemEntity,
    UserAction,
    UserActionEntity,
} from '@wdx/shared/utils';

@Injectable()
export class UserActionHttpService {
    constructor(
        private http: HttpClient,
        private config: IGetConfig,
    ) {}

    sendAction(
        action: UserAction | UserActionEntity,
        entity: SystemEntity,
        id: string,
    ): void {
        if (!UserActionEntity[entity as unknown as UserActionEntity]) {
            return;
        }
        this.http
            .patch<void>(
                `${
                    this.config.getConfiguration().API_BASE
                }/useraction/${action.toLowerCase()}/entity/${entity.toLowerCase()}/${id}`,
                {},
            )
            .subscribe();
    }
}
