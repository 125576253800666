/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FilterFieldType {
  String = 'String',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Date = 'Date',
  DateTime = 'DateTime',
  Boolean = 'Boolean',
  Enum = 'Enum',
  Select = 'Select',
  Lookup = 'Lookup',
  Answer = 'Answer',
  Currency = 'Currency',
  Search = 'Search'
}
