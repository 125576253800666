import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import {
    AmlHitUpdate,
    AmlReport,
    InvitationType,
} from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class AmlReportsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getAmlReportForAppStatus(appStatusId: string): Observable<AmlReport> {
        return this.http.get<AmlReport>(
            `${
                this.config.getConfiguration().API_BASE
            }/appstatus/${appStatusId}/aml`,
        );
    }

    runAmlReportForAppStatus(
        appStatusId: string,
        invitationType?: InvitationType,
    ): Observable<AmlReport> {
        return this.http.patch<AmlReport>(
            // eslint-disable-next-line max-len
            `${
                this.config.getConfiguration().API_BASE
            }/appstatus/${appStatusId}/aml${
                invitationType ? `?invitationType=${invitationType}` : ''
            }`,
            {},
        );
    }

    updateAmlHitForAppStatus(amlHit: AmlHitUpdate): Observable<AmlHitUpdate> {
        return this.http.post<AmlHitUpdate>(
            `${this.config.getConfiguration().API_BASE}/appstatus/amlhit`,
            amlHit,
        );
    }
}
