import { SimpleTag, TagEntity } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';
import {
    SystemEntity,
    Tag,
    TagCategoryDetails,
} from '@wdx/clmi/clmi-swagger-gen';

export const getForRecord = createAction(
    '[Tags] Get For Record',
    props<{
        recordId: string;
        entity: TagEntity;
    }>(),
);

export const getForRecordSuccess = createAction(
    '[Tags] Get For Record Success',
    props<{
        recordId: string;
        entity: TagEntity;
        tags: Tag[];
    }>(),
);

export const getForRecordFailure = createAction(
    '[Tags] Get For Record Failure',
    props<{
        recordId: string;
        entity: TagEntity;
        error: Error;
    }>(),
);

export const getCategoriesForSystemEntity = createAction(
    '[Tags] Get Categories For System Entity',
    props<{
        systemEntity: SystemEntity;
    }>(),
);

export const getCategoriesForSystemEntitySuccess = createAction(
    '[Tags] Get Categories For System Entity Success',
    props<{
        systemEntity: SystemEntity;
        categories: TagCategoryDetails[];
    }>(),
);

export const getCategoriesForSystemEntityFailure = createAction(
    '[Tags] Get Categories For System Entity Failure',
    props<{
        systemEntity: SystemEntity;
        error: Error;
    }>(),
);

export const getForCategory = createAction(
    '[Tags] Get For Category',
    props<{
        category: TagCategoryDetails;
    }>(),
);

export const getForCategorySuccess = createAction(
    '[Tags] Get For Category Success',
    props<{
        category: TagCategoryDetails;
        tags: Tag[];
    }>(),
);

export const getForCategoryFailure = createAction(
    '[Tags] Get For Category Failure',
    props<{
        category: TagCategoryDetails;
        error: Error;
    }>(),
);

export const createForRecord = createAction(
    '[Tags] Create For Record',
    props<{
        entity: SystemEntity;
        recordId: string;
        category: string;
        tagNames: string[];
    }>(),
);

export const createForRecordSuccess = createAction(
    '[Tags] Create For Record Success',
    props<{
        entity: SystemEntity;
        recordId: string;
        tags: Tag[];
    }>(),
);

export const createForRecordFailure = createAction(
    '[Tags] Create For Record Failure',
    props<{
        entity: SystemEntity;
        recordId: string;
        error: Error;
    }>(),
);

export const deleteForRecord = createAction(
    '[Tags] Delete For Record',
    props<{
        entity: SystemEntity;
        recordId: string;
        category: string;
        tagNames: string[];
    }>(),
);

export const deleteForRecordSuccess = createAction(
    '[Tags] Delete For Record Success',
    props<{
        entity: SystemEntity;
        recordId: string;
        tags: Tag[];
    }>(),
);

export const deleteForRecordFailure = createAction(
    '[Tags] Delete For Record Failure',
    props<{
        entity: SystemEntity;
        recordId: string;
        error: Error;
    }>(),
);

export const deleteTag = createAction(
    '[Tags] Delete',
    props<{
        tagName: string;
    }>(),
);

export const deleteSuccess = createAction(
    '[Tags] Delete Success',
    props<{
        tagName: string;
    }>(),
);

export const deleteFailure = createAction(
    '[Tags] Delete Failure',
    props<{
        tagName: string;
        error: Error;
    }>(),
);

export const getForCategoryAndRecord = createAction(
    '[Tags] Get For Category And Record',
    props<{
        categoryId: string;
        entity: SystemEntity;
        entityId: string;
    }>(),
);

export const getForCategoryAndRecordSuccess = createAction(
    '[Tags] Get For Category And Record Success',
    props<{
        tags: SimpleTag[];
        categoryId: string;
        entity: SystemEntity;
        entityId: string;
    }>(),
);

export const getForCategoryAndRecordFailure = createAction(
    '[Tags] Get For Category And Record Failure',
    props<{
        categoryId: string;
        entity: SystemEntity;
        entityId: string;
        error: Error;
    }>(),
);

export const getAllTags = createAction('[Tags] Get All Tags');

export const getAllTagsSuccess = createAction(
    '[Tags] Get All Tags Success',
    props<{
        tagCategories: TagCategoryDetails[];
    }>(),
);

export const getAllTagsFailure = createAction(
    '[Tags] Get All TagsFailure',
    props<{
        error: Error;
    }>(),
);

export const addTag = createAction(
    '[Tags] Add Tag',
    props<{
        tag: Tag;
        categoryId: string;
    }>(),
);

export const updateTag = createAction(
    '[Tags] Update Tag',
    props<{
        tagId: string;
        categoryId: string;
        name: string;
    }>(),
);

export const disableTag = createAction(
    '[Tags] Disable Tag',
    props<{
        tagId: string;
        isDisabled: boolean;
    }>(),
);

export const disableTagSuccess = createAction(
    '[Tags] Disable Tag Success',
    props<{
        tagId: string;
        isDisabled: boolean;
    }>(),
);

export const disableTagFailure = createAction(
    '[Tags] Disable Tag Failure',
    props<{
        error: Error;
    }>(),
);

export const filterAdmin = createAction(
    '[Tags] Filter Admin',
    props<{
        categoryId: string;
        searchText?: string;
    }>(),
);
