import { createAction, props } from '@ngrx/store';
import { Process } from '@wdx/clmi/clmi-swagger-gen';

export const getProcessesForCase = createAction(
    '[Processes] Get Processes For Case',
    props<{
        caseId: string;
    }>(),
);

export const getProcessesForCaseSuccess = createAction(
    '[Processes] Get Processes For Case Success',
    props<{
        caseId: string;
        processes: Process[];
    }>(),
);

export const getProcessesForCaseFailure = createAction(
    '[Processes] Get Processes For Case Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getProcess = createAction(
    '[Processes] Get Process',
    props<{
        processId: string;
    }>(),
);

export const getProcessSuccess = createAction(
    '[Processes] Get Process Success',
    props<{
        process: Process;
    }>(),
);

export const getProcessFailure = createAction(
    '[Processes] Get Process Failure',
    props<{
        processId: string;
        error: Error;
    }>(),
);

export const createProcess = createAction(
    '[Processes] Create Process',
    props<{
        process: Process;
    }>(),
);

export const createProcessSuccess = createAction(
    '[Processes] Create Process Success',
    props<{
        process: Process;
    }>(),
);

export const createProcessFailure = createAction(
    '[Processes] Create Process Failure',
    props<{
        error: Error;
    }>(),
);
