<ul
    class="list-group list-group-flush h-100 overflow-hidden d-flex flex-column"
    [formGroup]="form"
>
    <li
        class="list-group-item d-flex flex-shrink-0 justify-content-between align-items-center"
    >
        <h5>{{ FILTER_LABEL | translate }}</h5>

        <section class="d-flex">
            <button
                class="btn btn-outline-primary ms-2"
                data-cy="query-builder-cancel-btn"
                (click)="clearAndReturnToViews()"
            >
                {{ CANCEL_BTN | translate }}
            </button>
            <button
                class="btn btn-outline-primary ms-2"
                [disabled]="expressions.invalid"
                data-cy="btn-apply-filter"
                (click)="applyFilter(true)"
            >
                {{ APPLY_BTN | translate }}
            </button>

            <button
                class="btn btn-primary ms-2"
                [disabled]="form.invalid"
                data-cy="btn-save-filter"
                (click)="saveView()"
            >
                {{ SAVE_BTN | translate }}
            </button>
        </section>
    </li>
    <li class="list-group-item bg-muted d-flex flex-column flex-shrink-0">
        <input
            type="text"
            [placeholder]="PLACEHOLDER_NEW_FILTER | translate"
            class="mb-2"
            wdxInputText
            formControlName="name"
            data-cy="input-name-new-filter"
        />
        <section
            class="d-flex justify-content-between align-items-center"
            [class.d-none]="viewBuilderResults$ | ngrxPush"
        >
            <wdx-dropdown ngbDropdown container="body">
                <atom-add-button
                    type="button"
                    data-cy="dropdown-add-condition"
                    ngbDropdownToggle
                >
                    {{ ADD_CONDITION | translate }}
                </atom-add-button>

                <wdx-dropdown-menu ngbDropdownMenu>
                    @for (
                        definition of definitions$ | ngrxPush;
                        track definition.name
                    ) {
                        <a
                            class="pointer"
                            wdxDropdownItem
                            (click)="addNewQuery(definition)"
                        >
                            {{ definition?.displayName?.value }}
                        </a>
                    }
                </wdx-dropdown-menu>
            </wdx-dropdown>
        </section>
    </li>
    <li
        class="flex-grow-1 overflow-hidden"
        formArrayName="expressions"
        [class.d-none]="viewBuilderResults$ | ngrxPush"
    >
        <section class="h-100 overflow-auto">
            @for (
                expressionForm of expressions.controls;
                track expressionForm;
                let i = $index
            ) {
                <ng-container
                    *ngIf="!expressionViewData[i]?.contextual"
                    [formGroup]="expressionForm"
                >
                    <li class="list-group-item">
                        <section
                            class="d-flex justify-content-between align-items-center"
                        >
                            <section>
                                <span class="small text-muted mb-1">
                                    {{
                                        i === 0
                                            ? (FILTER_WHERE | translate)
                                            : (FILTER_AND | translate)
                                    }}
                                </span>

                                <div class="mb-2 d-flex align-items-center">
                                    <span class="me-2">
                                        {{
                                            expressionViewData[i]?.displayName
                                                ?.value
                                        }}
                                    </span>

                                    <wdx-dropdown ngbDropdown container="body">
                                        <button
                                            ngbDropdownToggle
                                            background
                                            class="btn btn-outline-primary"
                                            data-cy="query-builder-dropdown"
                                        >
                                            {{
                                                FILTER_OPERATOR_PREFIX +
                                                    expressionForm.controls
                                                        ?.operator?.value
                                                    | translate
                                            }}
                                        </button>
                                        <wdx-dropdown-menu ngbDropdownMenu>
                                            @for (
                                                opt of expressionViewData[i]
                                                    ?.operators;
                                                track opt
                                            ) {
                                                <label
                                                    class="d-inline-flex justify-content-between align-items-center pointer"
                                                    wdxDropdownItem
                                                    [class.active]="
                                                        expressionForm.controls
                                                            ?.operator
                                                            ?.value === opt
                                                    "
                                                >
                                                    <span
                                                        class="flex-grow-1 text-truncate"
                                                    >
                                                        {{
                                                            FILTER_OPERATOR_PREFIX +
                                                                opt | translate
                                                        }}
                                                    </span>

                                                    <input
                                                        hidden
                                                        type="radio"
                                                        [value]="opt"
                                                        formControlName="operator"
                                                    />

                                                    <wdx-icon
                                                        *ngIf="
                                                            expressionForm
                                                                .controls
                                                                ?.operator
                                                                ?.value === opt
                                                        "
                                                        size="sm"
                                                        class="flex-shrink-0 ms-2"
                                                        icon="check"
                                                    ></wdx-icon>
                                                </label>
                                            }
                                        </wdx-dropdown-menu>
                                    </wdx-dropdown>
                                </div>
                            </section>

                            <section class="align-self-start">
                                <atom-icon-button
                                    btnClass="btn"
                                    size="sm"
                                    icon="trash-alt"
                                    (click)="deleteExpression(i)"
                                ></atom-icon-button>
                            </section>
                        </section>

                        <section class="d-flex flex-wrap">
                            <ng-container
                                formArrayName="values"
                                *ngIf="
                                    expressionViewData[i]?.type !==
                                        FilterFieldType.Lookup &&
                                    !(
                                        NO_VALUE_OPERATORS
                                        | arrayIncludes
                                            : expressionForm.controls?.operator
                                                  ?.value
                                    )
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let valuesForm of getValues(i).controls;
                                        let j = index
                                    "
                                >
                                    <clmi-filter-select
                                        class="w-100"
                                        data-cy="query-builder-filter-select"
                                        [definition]="expressionViewData[i]"
                                        [formControlNameOrIndex]="j"
                                        [options]="
                                            expressionViewData[i].possibleValues
                                        "
                                        [multiple]="
                                            expressionViewData[i]
                                                .multiOperator !== false
                                        "
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Select ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Answer ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Enum
                                        "
                                    ></clmi-filter-select>

                                    <input
                                        wdxInputText
                                        type="text"
                                        data-cy="query-builder-text-input"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.String ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Search
                                        "
                                        [formControlName]="j"
                                    />

                                    <input
                                        wdxInputText
                                        type="number"
                                        data-cy="query-builder-number-input"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Integer
                                        "
                                        [formControlName]="j"
                                    />

                                    <input
                                        wdxInputText
                                        type="number"
                                        data-cy="query-builder-number-input"
                                        step="any"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Decimal
                                        "
                                        [formControlName]="j"
                                    />

                                    <clmi-filter-currency
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Currency
                                        "
                                        [cySelector]="
                                            'query-builder-filter-currency'
                                        "
                                        [formControlName]="j"
                                    ></clmi-filter-currency>

                                    <clmi-filter-date
                                        class="w-100"
                                        type="date"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Date
                                        "
                                        [operator]="
                                            expressionForm.controls?.operator
                                                ?.value
                                        "
                                        [formControlObj]="j"
                                        [cySelector]="
                                            'query-builder-filter-date'
                                        "
                                    ></clmi-filter-date>

                                    <clmi-filter-date
                                        class="w-100"
                                        type="datetime-local"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.DateTime
                                        "
                                        [operator]="
                                            expressionForm.controls?.operator
                                                ?.value
                                        "
                                        [formControlObj]="j"
                                        [cySelector]="
                                            'query-builder-filter-date-local'
                                        "
                                    ></clmi-filter-date>

                                    <wdx-form-field
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Boolean
                                        "
                                        labelnomarginbottom
                                    >
                                        <wdx-radio
                                            label="True"
                                            value="true"
                                            [labelForId]="
                                                expressionViewData[i]?.name +
                                                '-true'
                                            "
                                            labelnomarginbottom
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                data-cy="query-builder-radio-true"
                                                [value]="true"
                                                [formControlName]="j"
                                            />
                                        </wdx-radio>

                                        <wdx-radio
                                            label="False"
                                            value="false"
                                            [labelForId]="
                                                expressionViewData[i]?.name +
                                                '-false'
                                            "
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                data-cy="query-builder-radio-false"
                                                [value]="false"
                                                [formControlName]="j"
                                            />
                                        </wdx-radio>
                                    </wdx-form-field>
                                </ng-container>
                            </ng-container>

                            <ng-container
                                formArrayName="values"
                                *ngIf="
                                    (expressionViewData[i]?.type ===
                                        FilterFieldType.Enum ||
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.LookupMultiple ||
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.Lookup) &&
                                    !(
                                        NO_VALUE_OPERATORS
                                        | arrayIncludes
                                            : expressionForm.controls?.operator
                                                  ?.value
                                    )
                                "
                            >
                                @if (
                                    expressionViewData[i]?.type ===
                                        FilterFieldType.LookupMultiple ||
                                    expressionViewData[i]?.type ===
                                        FilterFieldType.Lookup
                                ) {
                                    @for (
                                        val of arrayMultiSelectData[i]?.data;
                                        track val;
                                        let childIndex = $index
                                    ) {
                                        <wdx-info-card
                                            background
                                            class="me-1 mb-1"
                                            [flush]="true"
                                        >
                                            <div wdxInfoCardLabel>
                                                {{ val?.name }}
                                            </div>
                                            <div wdxInfoCardTrailing>
                                                <atom-icon-button
                                                    btnClass="btn"
                                                    size="sm"
                                                    icon="times"
                                                    (click)="
                                                        deleteExpressionValue(
                                                            i,
                                                            childIndex,
                                                            val,
                                                            'id'
                                                        )
                                                    "
                                                ></atom-icon-button>
                                            </div>
                                        </wdx-info-card>
                                    }

                                    <button
                                        data-cy="btn-ellipsis"
                                        background
                                        class="btn m-1"
                                        (click)="
                                            addDynamicComponent(
                                                i,
                                                expressionViewData[i]
                                            )
                                        "
                                    >
                                        <wdx-icon
                                            icon="ellipsis-h"
                                            iconStyle="fas"
                                        ></wdx-icon>
                                    </button>
                                }
                            </ng-container>
                        </section>
                    </li>
                </ng-container>
            }
        </section>
    </li>
</ul>

<ng-container #viewContainerRef></ng-container>
