import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    EntityAction,
    EntityPermissionType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';

import { BaseApiService } from '../base-api.service';
import { SystemEntityUrlSegment } from './system-entity-url-segment';

@Injectable({ providedIn: 'root' })
export class SystemEntityApiService extends BaseApiService {
    /**
     * This gets the permissions for a system entity type
     *
     * @param { SystemEntity } systemEntity - This needs to be the system entity
     * @param { string } entityId - This is the ID of the system entity
     *
     * @returns { EntityPermissionType }
     */
    getPermission(
        systemEntity: SystemEntity,
        entityId: string,
    ): Observable<EntityPermissionType[]> {
        return this.get<EntityPermissionType[]>(
            `${systemEntity.toLocaleLowerCase()}/${entityId}/${
                SystemEntityUrlSegment.Permission
            }`,
        );
    }

    /**
     * This gets the actions for a system entity type
     *
     * @param { SystemEntity } systemEntity - This needs to be the system entity
     * @param { string } entityId - This is the ID of the system entity
     *
     * @returns { EntityAction }
     */
    getActions(
        systemEntity: SystemEntity,
        entityId: string,
    ): Observable<EntityAction[]> {
        return this.get<EntityAction[]>(
            `${systemEntity.toLocaleLowerCase()}/${entityId}/${
                SystemEntityUrlSegment.Action
            }`,
        );
    }
}
