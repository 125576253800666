import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessVisibility,
    ActivityTask,
    HeatMap,
    KeyStatResult,
    List,
    Party,
    PartyRoleRelationship,
    PartyRoleTeamMember,
    PartyTeamMember,
    StatusType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class PartiesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService,
    ) {}

    getForId(partyId: string): Observable<Party> {
        return this.http.get<Party>(
            `${this.config.getConfiguration().API_BASE}/party/${partyId}`,
        );
    }

    getKeyStatsForParty(partyId: string): Observable<KeyStatResult[]> {
        return this.http.get<KeyStatResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/keystats`,
        );
    }

    updateAvatar(partyId: string, image: File): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append(image.name, image);

        return this.http.request(
            new HttpRequest(
                'POST',
                `${
                    this.config.getConfiguration().API_BASE
                }/party/${partyId}/avatar`,
                formData,
                {
                    reportProgress: true,
                },
            ),
        );
    }

    getHeatMapForParty(partyId: string): Observable<HeatMap> {
        return this.http.get<HeatMap>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/heatmap`,
        );
    }

    getTeamMembersForParty(partyId: string): Observable<PartyTeamMember[]> {
        return this.http.get<PartyTeamMember[]>(
            `${this.config.getConfiguration().API_BASE}/party/${partyId}/member`,
        );
    }

    getTeamMembersForPartyRole(
        partyRoleId: string,
    ): Observable<PartyRoleTeamMember[]> {
        return this.http.get<PartyTeamMember[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/member`,
        );
    }

    deleteTeamMemberForParty(
        partyId: string,
        memberId: string,
    ): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/member/${memberId}`,
        );
    }

    promotePartyToLead(partyId: string): Observable<Party> {
        return this.http.patch<Party>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/promote/lead`,
            {},
        );
    }

    deleteTeamMemberForPartyRole(
        partyRoleId: string,
        memberId: string,
    ): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/member/${memberId}`,
        );
    }

    setPartyStatus(partyId: string, status: StatusType): Observable<Party> {
        return this.http.post(
            `${
                this.config.getConfiguration().API_BASE
            }/Party/${partyId}/Status/${status}`,
            {},
        );
    }

    resendInvite(partId: string): Observable<Party> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/user/${partId}/password/reset`,
            {},
        );
    }

    setPrimaryTeamMemberForParty(
        partyId: string,
        teamMemberId: string,
    ): Observable<Party> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/member/${teamMemberId}/primary`,
            {},
        );
    }

    setPrimaryTeamMemberForPartyRole(
        partyRoleId: string,
        teamMemberId: string,
    ): Observable<Party> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/member/${teamMemberId}/primary`,
            {},
        );
    }

    getVisibilityForParty(partyId: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/visibility`,
        );
    }

    getAllTasksForParty(
        paginationOptions: PaginationOptions,
        partyId: string,
    ): Observable<PaginatedApiResponse<ActivityTask>> {
        return this.http.get<PaginatedApiResponse<ActivityTask>>(
            `${this.config.getConfiguration().API_BASE}/party/${partyId}/task`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions,
                    ),
            },
        );
    }
    getForPartyOrganisationConnections(
        partyId: string,
    ): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/organisationconnections`,
        );
    }

    getMembershipListsForEntity(
        entityId: string,
        entityType: SystemEntity,
    ): Observable<List[]> {
        return this.http.get<List[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType}/${entityId}/lists`,
        );
    }
}
