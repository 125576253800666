import { SortDirection } from '@wdx/clmi/clmi-swagger-gen';

export interface SortMenuValue {
    sortBy: string;
    sortDirection?: SortDirection;
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum SortMenuSortBy {
    AUM = 'AUM',
    ClientLifecycleStage = 'ClientLifecycleStage',
    Completed = 'completed',
    Created = 'Created',
    CreatedOn = 'CreatedOn',
    Date = 'date',
    DateDue = 'DateDue',
    DateLastReview = 'dateLastReview',
    DateNextReview = 'dateNextReview',
    DatePublished = 'datePublished',
    DateStarted = 'dateStarted',
    Due = 'Due',
    DueDate = 'dateDue',
    EstimatedCloseDate = 'EstimatedCloseDate',
    EstimatedValue = 'Pipeline',
    LastContacted = 'LastContacted',
    Name = 'name',
    NetWealth = 'NetWealth',
    NextActionDue = 'nextDueDate',
    NextMeeting = 'NextMeeting',
    Pipeline = 'Pipeline',
    PipelineValue = 'PipelineValue',
    PublishedOn = 'PublishedOn',
    Start = 'start',
    Status = 'Status',
    Subject = 'subject',
    Started = 'started',
    Type = 'Type',
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */
