import { Action, createReducer, on } from '@ngrx/store';
import { KanbanStage } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as kanbanStagesActions from './kanban-stages.actions';

export interface State {
    kanbanStages: CrudStateObject<KanbanStage>;
}

export const initialState: State = {
    kanbanStages: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        kanbanStagesActions.getForKanbanType,
        (state, props): State => ({
            ...state,
            kanbanStages: {
                ...state.kanbanStages,
                [props.kanbanTypeId]: {
                    ...(state.kanbanStages[props.kanbanTypeId] ||
                        ({} as CrudState<KanbanStage>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        kanbanStagesActions.getForKanbanTypeSuccess,
        (state, props): State => ({
            ...state,
            kanbanStages: {
                ...state.kanbanStages,
                [props.kanbanTypeId]: {
                    ...state.kanbanStages[props.kanbanTypeId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.kanbanStages,
                },
            },
        }),
    ),

    on(
        kanbanStagesActions.getForKanbanTypeFailure,
        (state, props): State => ({
            ...state,
            kanbanStages: {
                ...state.kanbanStages,
                [props.kanbanTypeId]: {
                    ...state.kanbanStages[props.kanbanTypeId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
