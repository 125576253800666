import { ClientProductType, ProductStatus } from '@wdx/clmi/clmi-swagger-gen';

export const PORTFOLIO_360_PRODUCTS = [
    ClientProductType.Portfolio,
    ClientProductType.Investment,
];

export const PORTFOLIO_360_PRODUCTS_STATUS = [
    ProductStatus.Active,
    ProductStatus.Cancelled,
    ProductStatus.Closing,
    ProductStatus.Inactive,
];
