import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { createSelector } from '@ngrx/store';
import * as filterReducer from './../reducer/filter.reducer';

/**
 * Reducer state selector
 */
export const getFilterState = (state: {
    filters: filterReducer.FilterState;
}): filterReducer.FilterState => state.filters;

/**
 * Reducer property selectors
 */
export const selectFilterResultsSuccess = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.results,
);

export const selectFilterLoading = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.isLoading,
);

export const selectFilterError = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.hasLoadingError,
);

export const getFilterPaging = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.paging,
);

export const selectFirstResults = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.firstResults,
);

export const selectFirstPaging = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.firstPaging,
);

export const getFilterDefinition = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.definition?.data?.fieldDefinitions,
);

export const selectTotalRecords = createSelector(
    getFilterState,
    (state: filterReducer.FilterState, filterType: SystemEntity) =>
        state[filterType]?.paging?.totalRecords,
);
