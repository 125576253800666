/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ProductStatus {
  Inactive = 'Inactive',
  Draft = 'Draft',
  Proposed = 'Proposed',
  Active = 'Active',
  Blocked = 'Blocked',
  Cancelled = 'Cancelled',
  Closing = 'Closing'
}
