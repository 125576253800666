import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityAction, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { QueryType } from '@wdx/clmi/api-services/models';
import {
    FilterQueryFacadeService,
    operationsActions,
} from '@wdx/clmi/api-services/state';
import { PagingType, TranslationsService } from '@wdx/shared/utils';
import { BehaviorSubject, Observable, from, pipe } from 'rxjs';
import {
    filter,
    finalize,
    skip,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ActionButton } from '../../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../../models/action-buttons-mode.model';
import { MultiselectPayload } from '../../../../../../models/multiselect-payload.model';
import { TableHeader } from '../../../../../../models/table-header.model';
import { Table } from '../../../../../../models/table.model';
import * as rootReducer from '../../../../../../state/_setup/reducers';
import { EntityOperationsService } from '../../../../../services/entity-operations';
import { RouteDataService } from '../../../../../services/route-data/route-data.service';
import { FilterCoreAbstractClass } from '../../abstract-classes/filter-core/filter-core.service';
import { FilterQueryService } from '../../services/filter-query';

@Directive({
    selector: '[filterTable]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FilterTableClass
    extends FilterCoreAbstractClass
    implements OnInit, AfterViewInit
{
    selectFirstResults$: Observable<any>;
    selectFilterResultsSuccess$: Observable<any>;

    actionsButtons$ = new BehaviorSubject<ActionButton[]>(null);

    headers = [];
    table: Table = {
        headers: [...this.headers],
        rows: [],
        pagingType: PagingType.InfiniteScroll,
    };

    constructor(
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected filterQueryService: FilterQueryService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected store$: Store<rootReducer.State>,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
    ) {
        super(
            filterQueryService,
            filterQueryFacadeService,
            entityOperationsService,
            routeDataService,
            store$,
            translationsService,
        );

        this.filterQueryFacadeService.resetResults$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.resetTable());
    }

    getRouteView(): void {
        this.routeDataService.view$
            .pipe(
                filter((res) => Boolean(res)),
                take(1),
            )
            .subscribe((view) => {
                this.filterQueryService.setView(view);
                this.filterQueryService.updateTableHeadersSorting(view);
            });
    }

    ngOnInit() {
        this.coreOnInit();
    }

    ngAfterViewInit(
        queryType?: QueryType,
        updateTableRows?: (...args: any[]) => any,
        ...args
    ): void {
        this.coreAfterViewInit();
        this.selectFirstResults$ =
            this.filterQueryFacadeService.selectFirstResults$(queryType);

        this.selectFilterResultsSuccess$ =
            this.filterQueryFacadeService.selectFilterResultsSuccess$(
                queryType,
            );

        this.selectFirstResults$
            .pipe(
                take(1),
                this.updateTable(),
                this.takeUntil(),
                this.updateTableRow(updateTableRows, ...args),
            )
            .subscribe((_) => ({}));

        this.selectFilterResultsSuccess$
            .pipe(
                skip(1),
                this.resetTableIfPaginated(),
                this.updateTable(),
                this.takeUntil(),
                this.updateTableRow(updateTableRows, ...args),
            )
            .subscribe((_) => ({}));

        this.entityActions$
            .pipe(
                takeUntil(this.destroyed$),
                filter((actions) => Boolean(actions?.length)),
            )
            .subscribe((actions) => this.updateActionsButtons(actions));
    }

    resetTable(headers?: TableHeader[]): void {
        this.headers = headers;
        this.table = {
            ...this.table,
            headers: headers || this.headers,
            rows: [],
        };
    }

    updateTable(): any {
        return pipe(
            filter((res) => Boolean(res)),
            switchMap((res: any[]) =>
                from(res).pipe(
                    finalize(() => {
                        this.table = {
                            ...this.table,
                        };
                        this.cd.detectChanges();
                    }),
                ),
            ),
        );
    }

    updateTableRow(updateTableRows: (...args: any[]) => any, ...args): any {
        return pipe(
            tap((data) => {
                this.table.rows.push(updateTableRows(data, ...args));
                this.cd.detectChanges();
            }),
        );
    }

    resetTableIfPaginated(): any {
        return pipe(
            tap(() => {
                if (
                    this.table.pagingType === PagingType.ClientPagination ||
                    this.table.pagingType === PagingType.ServerPagination
                ) {
                    this.resetTable();
                }
            }),
        );
    }

    updateActionsButtons(actions: EntityAction[]): void {
        this.actionsButtons$.next(
            actions?.length &&
                actions
                    .filter((action) => action.isEnabled)
                    .map((action) => ({
                        mode: ActionButtonMode.IconButton,
                        icon: action.icon,
                        altText: action.displayName?.value,
                        value: action.url,
                    })),
        );
    }

    onMultiselect(payload: MultiselectPayload): void {
        payload.selectedRows.forEach((row) => {
            this.store$.dispatch(
                operationsActions.patchActionWithURL({
                    entityType: SystemEntity.Party,
                    entityId: row.data?.party?.id || row.data?.id,
                    url: payload.multiselectBannerButton.value,
                }),
            );
        });
    }
}
