import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CommentCreate, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TriggerChar } from '../shared/constants/mention.constant';
import { AppMentionsService } from '../shared/services/app-mention/app-mention.service';
import { CommentsFacade } from '../shared/services/comments.facade';
import { MentionService } from '../shared/services/mention/mention.service';

@Component({
    selector: 'clmi-comment-input-box',
    templateUrl: './comment-input-box.component.html',
    styleUrls: ['./comment-input-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentInputBoxComponent
    extends WdxDestroyClass
    implements OnInit
{
    private mentionService = inject(MentionService);
    private appMentionsService = inject(AppMentionsService);
    private facade = inject(CommentsFacade);

    @Input() set commentOverride(override: string) {
        if (override !== this.commentsText) {
            this.commentsText = override;
        }
    }
    @Input() dynamicHeight = false;
    @Input() rows = 4;
    @Input() placeholder = 'Comment';
    @Input() enableAppMentions = false;

    @Output() commentUpdated = new EventEmitter<CommentCreate>();

    public mentionConfig$ = new BehaviorSubject({
        mentions: [
            this.mentionService.mentionsConfig,
            this.appMentionsService.appMentionConfig,
        ],
    });

    public commentsText = '';

    ngOnInit(): void {
        if (this.dynamicHeight) {
            this.rows = 1;
        }

        this.setMentionDependencies();
        this.appMentionsService.workItemAppsResults$.subscribe(
            (workItemAppsResults) => {
                this.updateMentionItems(workItemAppsResults, TriggerChar.Hash);
            },
        );
    }

    public onClose(): void {
        this.mentionService.closeMention();
    }

    onSearch(searchText: string): void {
        const TRIGGER_CHAR = searchText.substring(0, 1);
        const SEARCH_TEXT = searchText.substring(1);

        if (TRIGGER_CHAR === TriggerChar.At) {
            this.mentionService.mentionTriggerChar(
                SEARCH_TEXT,
                this.facade.id,
                SystemEntity.Party,
            );
        }

        if (this.enableAppMentions && TRIGGER_CHAR === TriggerChar.Hash) {
            this.appMentionsService.mentionTriggerChar(SEARCH_TEXT);
        }
    }

    onOpened(): void {
        // Currently only implemented for WorkItems as we need a case id to the the case work items
        if (this.enableAppMentions) {
            this.appMentionsService.onOpen();
        }
    }

    updateMentionItems(items, triggerChar: TriggerChar): void {
        const MENTION_CONFIG = this.mentionConfig$.value;
        let mentionItems = [];
        let appMentionItems = [];

        if (triggerChar === TriggerChar.At) {
            mentionItems = items;
        }

        if (triggerChar === TriggerChar.Hash) {
            appMentionItems = items;
        }

        this.mentionConfig$.next({
            ...MENTION_CONFIG,
            mentions: [
                { ...MENTION_CONFIG.mentions[0], items: mentionItems },
                { ...MENTION_CONFIG.mentions[1], items: appMentionItems },
            ],
        });
    }

    setMentionDependencies(): void {
        this.mentionService.destroyed$ = this.destroyed$;
        this.mentionService.setUpLookupResults();
        this.mentionService.lookupResults$
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (result) => {
                    this.updateMentionItems(result, TriggerChar.At);
                },
            });
    }

    commentTextUpdated(text: string): void {
        this.commentsText = text;
        let commentCreateData: CommentCreate = {
            mentions: [],
            content: text,
        };

        commentCreateData =
            this.appMentionsService.updateHashMentionsStringDataOnPost(
                commentCreateData,
            );

        commentCreateData =
            this.mentionService.updateMentionsStringDataOnPost(
                commentCreateData,
            );

        this.commentUpdated.emit(commentCreateData);
    }
}
