import { Action, createReducer, on } from '@ngrx/store';
import { Portfolio } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import * as portfolioActions from './portfolio.actions';

export interface State {
    portfolio: CrudStateObject<Portfolio>;
}

export const initialState: State = {
    portfolio: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        portfolioActions.getPortfolio,
        (state, props): State => ({
            ...state,
            portfolio: {
                ...state.portfolio,
                [props.portfolioId]: {
                    single: null,
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        portfolioActions.getPortfolioSuccess,
        (state, props): State => ({
            ...state,
            portfolio: {
                ...state.portfolio,
                [props.portfolioId]: {
                    ...state.portfolio[props.portfolioId],
                    single: props.portfolio,
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        portfolioActions.getPortfolioError,
        (state, props): State => ({
            ...state,
            portfolio: {
                ...state.portfolio,
                [props.portfolioId]: {
                    ...state.portfolio[props.portfolioId],
                    single: null,
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
