import { Action, createReducer, on } from '@ngrx/store';
import { View } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import { FilterTypeTypes } from '../../models/filter-type-types.model';
import * as viewsActions from './views.actions';
import { SystemEntity } from '@wdx/shared/utils';

export const VIEWS_FEATURE_KEY = 'views';

export interface State {
    views?: CrudStateObject<View>;
    defaultView?: CrudStateObject<View>;
    currentView?: View;
    search?: CrudStateObject<FilterTypeTypes>;
}

export const initialState: State = {
    views: {},
    defaultView: {},
    currentView: {},
    search: {},
};

export const createViewKey = (
    entityType: SystemEntity,
    typeCode?: string,
    isContextual?: boolean,
) => {
    const baseKey = typeCode ? `${entityType}.${typeCode}` : `${entityType}`;
    let contextualSuffix = '';

    if (isContextual) {
        contextualSuffix = `.isContextual=${isContextual}`;
    }
    return `${baseKey}${contextualSuffix}`;
};

const reducerSetup = createReducer(
    initialState,

    on(
        viewsActions.getAll,
        (state): State => ({
            ...state,
            views: {
                ...state.views,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.views?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<View>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                ...props.views.reduce((accumulator, view) => {
                    accumulator = {
                        ...accumulator,
                        [view.entity]: {
                            ...(accumulator[view.entity] ||
                                ({} as CrudState<View>)),
                            list: [
                                ...(accumulator[view.entity]?.list || []),
                                view,
                            ],
                        },
                    };
                    return accumulator;
                }, {} as CrudStateObject<View>),
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.views?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<View>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.getAllFailure,
        (state): State => ({
            ...state,
            views: {
                ...state.views,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.views?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<View>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        viewsActions.getForEntityType,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [createViewKey(
                    props.entityType,
                    props.typeCode,
                    props.isContextual,
                )]: {
                    ...({} as CrudState<View>),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.getForEntityTypeSuccess,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [createViewKey(
                    props.entityType,
                    props.typeCode,
                    props.isContextual,
                )]: {
                    ...(state.views[
                        createViewKey(
                            props.entityType,
                            props.typeCode,
                            props.isContextual,
                        )
                    ] || ({} as CrudState<View>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.views,
                },
            },
        }),
    ),
    on(
        viewsActions.getForEntityTypeFailure,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [createViewKey(
                    props.entityType,
                    props.typeCode,
                    props.isContextual,
                )]: {
                    ...(state.views[
                        createViewKey(
                            props.entityType,
                            props.typeCode,
                            props.isContextual,
                        )
                    ] || ({} as CrudState<View>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        viewsActions.getSingleForEntityType,
        (state, props): State => ({
            ...state,
            defaultView: {
                ...state.defaultView,
                [props.entityType]: {
                    ...({} as CrudState<View>),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.getSingleForEntityTypeSuccess,
        (state, props): State => ({
            ...state,
            currentView: props.view,
            defaultView: {
                ...state.defaultView,
                [props.entityType]: {
                    ...(state.defaultView[props.entityType] ||
                        ({} as CrudState<View>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.view,
                },
            },
        }),
    ),

    on(
        viewsActions.getSingleForEntityTypeFailure,
        (state, props): State => ({
            ...state,
            currentView: initialState.currentView,
            defaultView: {
                ...state.defaultView,
                [props.entityType]: {
                    ...(state.defaultView[props.entityType] ||
                        ({} as CrudState<View>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        viewsActions.createByEntityType,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(viewsActions.createByEntityTypeSuccess, (state, props): State => {
        return {
            ...state,
            currentView: props.view,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    list: [
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state?.views[props.entityType]?.list,
                        ...[props.view],
                    ],
                    isCreating: false,
                    hasCreatingError: false,
                },
            },
        };
    }),

    on(
        viewsActions.createByEntityTypeFailure,
        (state, props): State => ({
            ...state,
            currentView: initialState.currentView,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        }),
    ),

    // update
    on(
        viewsActions.updateByEntityType,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(viewsActions.updateByEntityTypeSuccess, (state, props): State => {
        const INDEX = state.views[props.entityType]?.list?.findIndex(
            (view) => view?.id === props?.view?.id,
        );

        return {
            ...state,
            currentView: props.view,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    list: [
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state?.views[props.entityType]?.list?.slice(
                            0,
                            INDEX,
                        ),
                        {
                            ...props.view,
                        },
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state?.views[props.entityType]?.list?.slice(
                            INDEX + 1,
                        ),
                    ],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        };
    }),

    on(
        viewsActions.updateByEntityTypeFailure,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        viewsActions.deleteByEntityType,
        (state, props): State => ({
            ...state,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.deleteByEntityTypeSuccess,
        (state, props): State => ({
            ...state,
            currentView: initialState.currentView,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    list: [
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state?.views[props.entityType]?.list?.filter(
                            (view) => view.id !== props.viewId,
                        ),
                    ],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        viewsActions.deleteByEntityTypeFailure,
        (state, props): State => ({
            ...state,
            currentView: initialState.currentView,
            views: {
                ...state.views,
                [props.entityType]: {
                    ...(state.views[props.entityType] ||
                        ({} as CrudState<View>)),
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),

    on(
        viewsActions.getViewFilterSearch,
        (state, props): State => ({
            ...state,
            search: {
                ...state.search,
                [props.viewId]: {
                    ...(state.views[props.viewId] ||
                        ({} as CrudState<FilterTypeTypes>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.search[props.viewId]?.infinity,
                },
            },
        }),
    ),

    on(
        viewsActions.getViewFilterSearchSuccess,
        (state, props): State => ({
            ...state,
            search: {
                ...state.search,
                [props.viewId]: {
                    ...(state.views[props.viewId] ||
                        ({} as CrudState<FilterTypeTypes>)),
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.search.paging,
                        combinedList: [
                            ...(state.search[props.viewId].infinity
                                ?.combinedList || []),
                            ...props.search.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        viewsActions.getViewFilterSearchFailure,
        (state, props): State => ({
            ...state,
            search: {
                ...state.search,
                [props.viewId]: {
                    ...(state.views[props.viewId] ||
                        ({} as CrudState<FilterTypeTypes>)),
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
