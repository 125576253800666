import { Component, Input } from '@angular/core';
import { Article } from '@wdx/clmi/clmi-swagger-gen';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-article-preview',
    templateUrl: './molecule-article-preview.component.html',
})
export class MoleculeArticlePreviewComponent {
    @Input() article: Article;
}
