import { createAction, props } from '@ngrx/store';
import { Product, ProductType } from '@wdx/clmi/clmi-swagger-gen';

export const getProductTypes = createAction(
    '[Product Types] Get Product Types ',
    (availableOnly: boolean = null) => ({ availableOnly }),
);

export const getProductTypesSuccess = createAction(
    '[Product Types] Get Product Types Success',
    props<{
        productTypes: ProductType[];
    }>(),
);

export const getProductTypesFailure = createAction(
    '[Product Types] Get Product Types Failure',
    props<{
        error: Error;
    }>(),
);

export const filterProductTypes = createAction(
    '[Answers] Filter Product Types',
    props<{
        searchText: string;
    }>(),
);

export const getProducts = createAction(
    '[Product Types] Get Products',
    props<{
        code: string;
    }>(),
);

export const getProductsSuccess = createAction(
    '[Product Types] Get Products Success',
    props<{
        code: string;
        products: Product[];
    }>(),
);

export const getProductsFailure = createAction(
    '[Product Types] Get Products Failure',
    props<{
        code: string;
    }>(),
);

export const disableProduct = createAction(
    '[Product Types] Disable Product',
    props<{
        id: string;
        code: string;
        isDisabled: boolean;
    }>(),
);

export const disableProductSuccess = createAction(
    '[Product Types] Disable Product Success',
    props<{
        id: string;
        code: string;
        isDisabled: boolean;
    }>(),
);

export const disableProductFailure = createAction(
    '[Product Types] Disable Product Failure',
    props<{
        id: string;
        code: string;
    }>(),
);

export const updateProductSuccess = createAction(
    '[Product Types] Update Product Success',
    props<{
        id: string;
        code: string;
        product: Product;
    }>(),
);

export const createProductSuccess = createAction(
    '[Product Types] Create Product Success',
    props<{
        code: string;
        product: Product;
    }>(),
);
