import { createAction, props } from '@ngrx/store';
import { AppLanguage, Version } from '@wdx/clmi/clmi-swagger-gen';
import { Build } from '@wdx/clmi/api-services/services';

export const getVersion = createAction('[System] Get Version');

export const getVersionSuccess = createAction(
    '[System] Get Version Success',
    props<{
        version: Version;
    }>(),
);

export const getVersionFailure = createAction(
    '[System] Get Version Failure',
    props<{
        error: Error;
    }>(),
);

export const getBuild = createAction('[System] Get Build');

export const getBuildSuccess = createAction(
    '[System] Get Build Success',
    props<{
        build: Build;
    }>(),
);

export const getBuildFailure = createAction(
    '[System] Get Build Failure',
    props<{
        error: Error;
    }>(),
);

export const getAppLanguages = createAction(
    '[System] Get Application Languages',
);

export const getAppLanguagesSuccess = createAction(
    '[System] Get Application Languages success',
    props<{
        applicationLanguages: AppLanguage[];
    }>(),
);

export const getAppLanguagesFailure = createAction(
    '[System] Get Application Languages Failure',
    props<{
        error: Error;
    }>(),
);
