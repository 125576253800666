import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wdx/clmi/clmi-swagger-gen';
import { ToDoService } from '../../services/to-do/to-do.service';

/**
 * Returns activity date
 */
@Pipe({
    name: 'activityDateValue',
})
export class ActivityDateValuePipe implements PipeTransform {
    constructor(private toDoService: ToDoService) {}
    transform(activity: Activity): string {
        return this.toDoService.getFormattedActivityStatusDate(activity);
    }
}
