import { Rag } from '@wdx/clmi/clmi-swagger-gen';
import { WdxThemeColor } from '@wdx/shared/utils';

import { KeyValueObject, Severity } from '@wdx/shared/utils';
import { NULL, UNDEFINED } from './null.constants';

export const RAG_STATUS_SEVERITY_MAP = {
    [NULL]: Severity.None,
    [UNDEFINED]: Severity.None,
    [Rag.Red]: Severity.Danger,
    [Rag.Amber]: Severity.Warning,
    [Rag.Green]: Severity.Success,
};

export const SEVERITY_RAG_STATUS_MAP = {
    [Severity.Danger]: Rag.Red,
    [Severity.Warning]: Rag.Amber,
    [Severity.Success]: Rag.Green,
};

export const RAG_THEME_COLOR_MAP: KeyValueObject<WdxThemeColor> = {
    [Rag.Red]: 'danger',
    [Rag.Amber]: 'warning',
    [Rag.Green]: 'success',
};
