import { Action, createReducer, on } from '@ngrx/store';
import { RecentRecord } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as recentRecordsActions from './recent-records.actions';

export interface State {
    recentRecords: CrudStateObject<RecentRecord>;
}

export const initialState: State = {
    recentRecords: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        recentRecordsActions.getUserRecentRecords,
        (state): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recentRecords[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RecentRecord>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        recentRecordsActions.getUserRecentRecordsSuccess,
        (state, props): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.recentRecords[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.recentRecords,
                },
            },
        }),
    ),

    on(
        recentRecordsActions.getUserRecentRecordsFailure,
        (state): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.recentRecords[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        recentRecordsActions.updateRecentlyViewedRecord,
        (state): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recentRecords[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RecentRecord>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        recentRecordsActions.updateRecentlyViewedRecordSuccess,
        (state): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recentRecords[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RecentRecord>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        recentRecordsActions.updateRecentlyViewedRecordFailure,
        (state): State => ({
            ...state,
            recentRecords: {
                ...state.recentRecords,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recentRecords[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<RecentRecord>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
