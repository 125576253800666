import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WdxBaseInputLabelIdClass } from '@wdx/shared/components/abstract-classes';

@Component({
    selector: 'wdx-radio',
    templateUrl: './wdx-radio.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxRadioComponent extends WdxBaseInputLabelIdClass {
    @Input() label?: string | null;
    @Input() set value(val: string) {
        this._value = val;
    }

    get value(): string {
        return this._value as string;
    }

    override prefix = 'radio';
}
