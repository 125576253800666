/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormSaveMode {
  ReadOnly = 'ReadOnly',
  Save = 'Save',
  Draft = 'Draft',
  Complete = 'Complete'
}
