import { createAction, props } from '@ngrx/store';
import {
    AccessVisibility,
    HistoryEntry,
    Case,
    CaseCycle,
    CaseTeamMember,
    SystemEntity,
    WorkItemCategorySummary,
    HeatMap,
} from '@wdx/clmi/clmi-swagger-gen';
import { CaseWork } from '../../models/case-work.model';

export const getCases = createAction(
    '[Cases] Get Cases',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);

export const getCasesSuccess = createAction(
    '[Cases] Get Cases Success',
    props<{
        entityId: string;
        cases: Case[];
    }>(),
);

export const getCasesFailure = createAction(
    '[Cases] Get Cases Failure',
    props<{
        entityId: string;
        error: Error;
    }>(),
);

export const getCase = createAction(
    '[Cases] Get Case',
    props<{
        caseId: string;
    }>(),
);

export const getCaseSuccess = createAction(
    '[Cases] Get Case Success',
    props<{
        caseId: string;
        case: Case;
    }>(),
);

export const getCaseFailure = createAction(
    '[Cases] Get Case Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getCaseWork = createAction(
    '[Cases] Get Case Work',
    props<{
        caseId: string;
    }>(),
);

export const getCaseWorkSuccess = createAction(
    '[Cases] Get Case Work Success',
    props<{
        caseId: string;
        caseWork: CaseWork[];
    }>(),
);

export const getCaseWorkFailure = createAction(
    '[Cases] Get Case Work Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getCaseHistory = createAction(
    '[Cases] Get Case History',
    props<{
        caseId: string;
    }>(),
);

export const getCaseHistorySuccess = createAction(
    '[Cases] Get Case History Success',
    props<{
        caseId: string;
        caseHistory: HistoryEntry[];
    }>(),
);

export const getCaseHistoryFailure = createAction(
    '[Cases] Get Case History Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getCasesForClient = createAction(
    '[Cases] Get Cases For Client',
    props<{
        clientId: string;
    }>(),
);

export const getCasesForClientSuccess = createAction(
    '[Cases] Get Cases For Client Success',
    props<{
        clientId: string;
        clientCases: Case[];
    }>(),
);

export const getCasesForClientFailure = createAction(
    '[Cases] Get Cases For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>(),
);

export const caseReady = createAction(
    '[Cases] Case Ready',
    props<{
        caseId: string;
    }>(),
);

export const caseUpdated = createAction(
    '[Cases] Case Updated',
    props<{
        caseId: string;
    }>(),
);

export const getFavourites = createAction('[Cases] Get Favourites');

export const getFavouritesSuccess = createAction(
    '[Cases] Get Favourites Success',
    props<{
        favourites: Case[];
    }>(),
);

export const getFavouritesFailure = createAction(
    '[Cases] Get Favourites Failure',
    props<{
        error: Error;
    }>(),
);

// Case Team members

export const deleteTeamMember = createAction(
    '[Cases] Delete Team Member',
    props<{
        caseId: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberSuccess = createAction(
    '[Cases] Delete Team Member Success',
    props<{
        caseId: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberFailure = createAction(
    '[Cases] Delete Team Member Failure',
    props<{
        caseId: string;
        memberId: string;
        error: Error;
    }>(),
);

export const getAllMembers = createAction(
    '[Cases] Get All Members',
    props<{
        caseId: string;
    }>(),
);

export const getAllMembersSuccess = createAction(
    '[Cases] Get All Members Success',
    props<{
        caseId: string;
        teamMembers: CaseTeamMember[];
    }>(),
);

export const getAllMembersFailure = createAction(
    '[Cases] Get All Members Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const completeCase = createAction(
    '[Cases] Complete Case',
    props<{
        caseId: string;
    }>(),
);

export const completeCaseSuccess = createAction(
    '[Cases] Complete Case Success',
    props<{
        caseId: string;
        case: Case;
    }>(),
);

export const completeCaseFailure = createAction(
    '[Cases] Complete Case Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const cancelCase = createAction(
    '[Cases] Cancel Case',
    props<{
        caseId: string;
    }>(),
);

export const cancelCaseSuccess = createAction(
    '[Cases] Cancel Case Success',
    props<{
        caseId: string;
        case: Case;
    }>(),
);

export const cancelCaseFailure = createAction(
    '[Cases] Cancel Case Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getCaseCategories = createAction(
    '[Cases] Get Case Categories',
    props<{
        caseId: string;
    }>(),
);
export const getCaseCategoriesSuccess = createAction(
    '[Cases] Get Case Categories Success',
    props<{
        caseId: string;
        categories: WorkItemCategorySummary[];
    }>(),
);
export const getCaseCategoriesFailure = createAction(
    '[Cases] Get Case Categories Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getVisibilityForId = createAction(
    '[Cases] Get Visibility For Id',
    props<{
        id: string;
    }>(),
);

export const getVisibilityForIdSuccess = createAction(
    '[Cases] Get Visibility For Id Success',
    props<{
        id: string;
        visibility: AccessVisibility[];
    }>(),
);

export const getVisibilityForIdFailure = createAction(
    '[Cases] Get Visibility For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getReviewsForId = createAction(
    '[Cases] Get Reviews For Id',
    props<{
        id: string;
    }>(),
);

export const getReviewsForIdSuccess = createAction(
    '[Cases] Get Reviews For Id Success',
    props<{
        id: string;
        reviews: CaseCycle[];
    }>(),
);

export const getReviewsForIdFailure = createAction(
    '[Cases] Get Reviews For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getKycHeatmap = createAction(
    '[Cases] Get KYC Heatmap',
    props<{
        id: string;
    }>(),
);

export const getKycHeatmapSuccess = createAction(
    '[Cases] Get KYC Heatmap Success',
    props<{
        id: string;
        heatMap: HeatMap;
    }>(),
);

export const getKycHeatmapFailure = createAction(
    '[Cases] Get KYC Heatmap Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getCaseHeatmap = createAction(
    '[Cases] Get Case Heatmap',
    props<{
        id: string;
    }>(),
);

export const getCaseHeatmapSuccess = createAction(
    '[Cases] Get Case Heatmap Success',
    props<{
        id: string;
        heatMap: HeatMap;
    }>(),
);

export const getCaseHeatmapFailure = createAction(
    '[Cases] Get Case Heatmap Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);
