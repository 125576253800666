import { Pipe, PipeTransform } from '@angular/core';
import {
    ACTIVITY_STATUS_CANCELLED,
    ACTIVITY_STATUS_COMPLETED,
    ACTIVITY_STATUS_OPEN,
} from '../constants/activity.constants';
import { Activity, ActivityStatusType } from '@wdx/clmi/clmi-swagger-gen';

@Pipe({ name: 'activityStatusLabel' })
export class ActivityStatusLabelPipe implements PipeTransform {
    transform(activity: Activity): string {
        if (activity.status === ActivityStatusType.Complete) {
            return ACTIVITY_STATUS_COMPLETED;
        }
        if (activity.status === ActivityStatusType.Cancelled) {
            return ACTIVITY_STATUS_CANCELLED;
        }
        return ACTIVITY_STATUS_OPEN;
    }
}
