import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TranslateTokenisedStringPipe } from '@wdx/shared/utils';

export interface EnumRendererValue {
    value: string;
    type: string;
}

@Component({
    selector: 'wdx-ag-grid-enum-cell-renderer',
    standalone: true,
    imports: [CommonModule, TranslateTokenisedStringPipe],
    template: '<div>{{ translationKey | translateTokenisedString }}</div>',
})
export class EnumRendererComponent implements ICellRendererAngularComp {
    public translationKey?: string;

    public agInit(params: ICellRendererParams<any, EnumRendererValue>): void {
        const { value, type } = params?.value || {};
        if (
            type &&
            value &&
            typeof type === 'string' &&
            typeof value === 'string'
        ) {
            this.translationKey = `ENUM_${type.toUpperCase()}_${value.toUpperCase()}`;
        }
    }

    public refresh(
        params: ICellRendererParams<any, EnumRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }
}
