import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import * as lookupsActions from '../../../../../../state/lookups/lookups.actions';
import * as lookupsSelectors from '../../../../../../state/lookups/lookups.selectors';

import { stringHighlightHelper } from '../../../../../../shared/helpers/string/highlight/highlight.helper';
import {
    escapeParenthesis,
    replaceString,
} from '../../../../../../shared/helpers/string/replace/replace.helper';

import {
    ActivityCommentCreate,
    LookupFieldResult,
    LookupSourceType,
    PartyStub,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { TriggerChar } from '../../constants/mention.constant';

@Injectable({
    providedIn: 'any',
})
export class MentionService {
    mentions: PartyStub[] = [];
    lookupResults$: Observable<LookupFieldResult[]>;
    mentionSearchText: string;
    mentionsConfig = {
        items: [],
        triggerChar: TriggerChar.At,
        mentionSelect: this.mentionSelect.bind(this),
        labelKey: 'name',
        disableSearch: true,
        dropUp: false,
        returnTrigger: true,
    };
    destroyed$: Observable<boolean>;

    readonly LOOKUP_ID = 'comments-lookup';

    constructor(private store$: Store) {}

    setUpLookupResults(): void {
        this.lookupResults$ = this.store$
            .select(lookupsSelectors.getList, {
                lookupId: this.LOOKUP_ID,
            })
            .pipe(
                takeUntil(this.destroyed$),
                filter((result) => Boolean(result)),
                map((result) =>
                    result.map((result) => {
                        return {
                            ...result,
                            id: result.id,
                            displayName: stringHighlightHelper(
                                result.name,
                                this.mentionSearchText,
                            ),
                            name: result.name,
                        };
                    }),
                ),
            );
    }

    closeMention(): void {
        this.dispatchClearLookupResults();
    }

    dispatchClearLookupResults(): void {
        this.store$.dispatch(
            lookupsActions.clearLookup({
                lookupId: this.LOOKUP_ID,
            }),
        );
    }

    replaceString(mention: PartyStub): RegExp {
        return new RegExp(`@${escapeParenthesis(mention.name)}`, 'g');
    }

    updateMentionReplaceForSaving(mention: PartyStub): string {
        return `#${TriggerChar.At}${mention.name}:${mention.partyType}:${mention.id}#`;
    }

    mentionTriggerChar(
        searchText: string,
        activityId: string,
        entityType: SystemEntity,
    ): void {
        this.mentionSearchText = searchText;

        this.store$.dispatch(
            lookupsActions.getLookup({
                lookupId: this.LOOKUP_ID,
                lookupSource: LookupSourceType.Assignee,
                searchText,
                entityId: activityId,
                entityType,
            }),
        );
    }

    updateMentionsStringDataOnPost(
        commentCreateData: ActivityCommentCreate,
    ): ActivityCommentCreate {
        const COMMENT_CREATE_DATA = { ...commentCreateData };

        if (this.mentions.length > 0) {
            const dedupedMentions = this.mentions.reduce((prev, curr) => {
                if ((prev as PartyStub[]).find((a) => a.id === curr.id)) {
                    return prev;
                }
                return [...prev, curr];
            }, []);
            dedupedMentions.forEach((mention) => {
                const regex = replaceString(mention.name, TriggerChar.At);

                if (
                    escapeParenthesis(COMMENT_CREATE_DATA.content).match(regex)
                ) {
                    COMMENT_CREATE_DATA.mentions.push(mention);
                    COMMENT_CREATE_DATA.content =
                        COMMENT_CREATE_DATA.content.replace(
                            regex,
                            this.updateMentionReplaceForSaving(mention),
                        );
                }
            });
        }

        return COMMENT_CREATE_DATA;
    }

    mentionSelect(lookupResult: LookupFieldResult): string {
        const name = `${lookupResult.name.replace(/<[^>]*>?/gm, '')}`;
        this.mentions.push({
            name,
            id: lookupResult.id,
            partyType: lookupResult.partyType,
        });
        return `@${name}`;
    }
}
