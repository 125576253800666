/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AppGroupType {
  Client = 'Client',
  Products = 'Products',
  AccountHolders = 'AccountHolders',
  Authorised3rdParties = 'Authorised3rdParties',
  OtherParties = 'OtherParties',
  InactiveParties = 'InactiveParties',
  Person = 'Person',
  Organisation = 'Organisation',
  Case = 'Case'
}
