import { Pipe, PipeTransform } from '@angular/core';
import { LIST_TYPE_MAP } from '../constants/list-types.constants';
import { ListType } from '@wdx/clmi/clmi-swagger-gen';

@Pipe({ name: 'listType' })
export class ListTypePipe implements PipeTransform {
    transform(listType: ListType): string {
        return LIST_TYPE_MAP[listType];
    }
}
