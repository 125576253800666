import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    Activity,
    ActivityParty,
    ActivityStatusType,
    Party,
    PartyType,
    ProcessOutcome,
    WorkItem,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { EngagementState } from '../../models/engagement-state.model';
import * as rootReducer from '../_setup/reducers';
import * as activitiesActions from '../activities/activities.actions';
import * as outcomesActions from '../outcomes/outcomes.actions';
import * as workItemsSelectors from '../work-items/work-items.selectors';
import * as engagementBarActions from './engagement-bar.actions';
import * as engagementBarSelectors from './engagement-bar.selectors';

@Injectable({ providedIn: 'root' })
export class EngagementBarFacade {
    public get isVisible$(): Observable<boolean> {
        return this.store$.pipe(select(engagementBarSelectors.getIsVisible));
    }

    public get activityId$(): Observable<string> {
        return this.store$.pipe(select(engagementBarSelectors.getActivityId));
    }

    public get party$(): Observable<Party> {
        return this.store$.pipe(select(engagementBarSelectors.getParty));
    }

    public get engagementState$(): Observable<EngagementState> {
        return this.store$.pipe(
            select(engagementBarSelectors.getEngagementState),
        );
    }

    constructor(private store$: Store<rootReducer.State>) {}

    public getParty(activityParties: ActivityParty[]): Party {
        return (
            activityParties.find(
                (activityParty: ActivityParty) =>
                    activityParty.party.partyType === PartyType.Person ||
                    activityParty.party.partyType === PartyType.Organisation,
            )?.party || activityParties[0]?.party
        );
    }

    public openEngagementBar(activity: Activity): void {
        this.store$.dispatch(
            engagementBarActions.setActivityId({ activityId: activity.id }),
        );
        this.store$.dispatch(
            engagementBarActions.setParty({
                party: this.getParty(activity.activityParties),
            }),
        );
        this.store$.dispatch(
            engagementBarActions.setVisibility({ isVisible: true }),
        );
    }

    public setVisibility(isVisible: boolean): void {
        this.store$.dispatch(engagementBarActions.setVisibility({ isVisible }));
    }

    public getWorkItem$(workItemId): Observable<WorkItem> {
        return this.store$.select(workItemsSelectors.getWorkItem, {
            id: workItemId,
        });
    }

    public dispatchCompleteActivity(activityId): void {
        this.store$.dispatch(
            activitiesActions.setStatusForId({
                activityId: activityId,
                status: ActivityStatusType.Complete,
            }),
        );
    }

    public dispatchOutcomeWorkItem(
        workItemOutcome: ProcessOutcome,
        workItemId: string,
    ): void {
        if (workItemOutcome && workItemId) {
            this.store$.dispatch(
                outcomesActions.setOutcomeForWorkItem({
                    outcomeId: workItemOutcome?.code,
                    workItemId,
                }),
            );
        }
    }
}
