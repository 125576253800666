import { Injectable, inject } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { EntityAction } from '@wdx/clmi/clmi-swagger-gen';
import { BehaviorSubject, take } from 'rxjs';
import { ModalSize } from '../../../../../models/modal.model';
import { OperationsSetup } from '../../../../../models/operations.model';
import { ModalManagementService } from '../../../../../services/modal-management.service';

import { operationsActions } from '@wdx/clmi/api-services/state';
import { ActionsModalComponent } from '../actions-modal/actions-modal.component';

@Injectable({ providedIn: 'root' })
export class MoleculeActionButtonService {
    private store$ = inject(Store);
    private modalManagementService = inject(ModalManagementService);
    private actionsSubject$ = inject(ActionsSubject);

    actions$ = new BehaviorSubject<{
        action?: EntityAction;
        operationsSetup?: OperationsSetup;
    }>({});
    isLoading$ = new BehaviorSubject(false);

    operationActionCallback(
        operationsSetup: OperationsSetup,
        action: EntityAction,
        actionCallback?: () => any,
    ): void {
        if (action.url && !action?.options?.length) {
            this.dispatchActionWithUrl(
                operationsSetup,
                action,
                undefined,
                actionCallback,
            );
        }

        if (action.url && action?.options?.length) {
            this.openActionOptionsModal(operationsSetup, action);
        }
    }

    openActionOptionsModal(
        operationsSetup: OperationsSetup,
        action: EntityAction,
    ): void {
        this.actions$.next({ operationsSetup, action });

        this.modalManagementService.openModalWithComponent(
            ActionsModalComponent,
            {
                size: ModalSize.Md,
            },
        );
    }

    dispatchActionWithUrl(
        operationsSetup: OperationsSetup,
        action,
        param?: string,
        actionCallback?: () => any,
    ): void {
        const QUERY_PARAM = param ? `?code=${param}` : '';
        this.isLoading$.next(true);

        this.actionsSubject$
            .pipe(
                ofType(
                    operationsActions.patchActionWithURLSuccess,
                    operationsActions.patchWithURLFailure,
                ),
                take(1),
            )
            .subscribe({
                next: () => {
                    this.isLoading$.next(false);
                    this.modalManagementService.closeActiveModal();
                    if (actionCallback) {
                        actionCallback();
                    }
                },
            });

        this.store$.dispatch(
            operationsActions.patchActionWithURL({
                entityType: operationsSetup.entityType,
                entityId: operationsSetup.entityId,
                url: action.url + QUERY_PARAM,
            }),
        );
    }
}
