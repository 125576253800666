import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
import { FormTemplate, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import {
    dynamicFormsActions,
    dynamicFormsSelectors,
} from '@wdx/shared/infrastructure/form-framework';
import * as rootReducer from '../../../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-activity-template-search',
    templateUrl: './organism-activity-template-search.component.html',
})
export class OrganismActivityTemplateSearchComponent
    extends BaseComponentClass
    implements OnChanges
{
    @Input() entityId: string;
    @Input() entityType: SystemEntity;

    formTemplates$: Observable<FormTemplate[]>;
    formTemplatesIsLoading$: Observable<boolean>;
    formTemplatesHasError$: Observable<boolean>;

    constructor(store$: Store<rootReducer.State>) {
        super();

        this.patchInjectedItems({ store$ });
    }

    ngOnChanges(): void {
        if (this.entityId && this.entityId) {
            this.formTemplates$ = this.store$.select(
                dynamicFormsSelectors.getFormTemplates,
                { id: this.entityId },
            );

            this.formTemplatesIsLoading$ = this.store$.select(
                dynamicFormsSelectors.getFormTemplatesIsLoadingList,
                { id: this.entityId },
            );

            this.formTemplatesHasError$ = this.store$.select(
                dynamicFormsSelectors.getFormTemplatesHasLoadingListError,
                { id: this.entityId },
            );

            this.store$.dispatch(
                dynamicFormsActions.getFormTemplates({
                    entityType: this.entityType,
                    entityId: this.entityId,
                }),
            );
        }
    }
}
