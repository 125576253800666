import { createAction, props } from '@ngrx/store';
import {
    AccessVisibility,
    ActivityTask,
    PartyRole,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getForId = createAction(
    '[Party Role] Get For ID',
    props<{
        partyRoleId: string;
    }>(),
);

export const getForIdSuccess = createAction(
    '[Party Role] Get For ID Success',
    props<{
        partyRoleId: string;
        partyRole: PartyRole;
    }>(),
);

export const getForIdFailure = createAction(
    '[Parties] Get For ID Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>(),
);

export const getVisibilityForId = createAction(
    '[Party Role] Get Visibility For Id',
    props<{
        id: string;
    }>(),
);

export const getVisibilityForIdSuccess = createAction(
    '[Party Role] Get Visibility For Id Success',
    props<{
        id: string;
        visibility: AccessVisibility[];
    }>(),
);

export const getVisibilityForIdFailure = createAction(
    '[Party Role] Get Visibility For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const partyRoleUpdatedSuccess = createAction(
    '[Party Role] Party Role Updated Success',
    props<{
        partyRoleId: string;
    }>(),
);

export const partyRoleCasesUpdatedSuccess = createAction(
    '[Party Role] Party Role Updated Success',
    props<{
        partyRoleId: string;
    }>(),
);

export const getAllTasksForPartyRole = createAction(
    '[Parties] Get All Tasks For A Party Role',
    props<{
        partyRoleId: string;
    }>(),
);

export const getAllTasksForPartyRoleSuccess = createAction(
    '[Parties] Get All Tasks For A Party Role Success',
    props<{
        partyRoleId: string;
        tasks: PaginatedApiResponse<ActivityTask>;
    }>(),
);

export const getAllTasksForPartyRoleFailure = createAction(
    '[Parties] Get All Tasks For A Party Role Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>(),
);
