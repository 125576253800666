/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ChatType {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
  Event = 'Event',
  Reference = 'Reference',
  Unknown = 'Unknown'
}
