import { Pipe, PipeTransform } from '@angular/core';
import {
    ActivityPartyType,
    LookupFieldResult,
    Organisation,
    Party,
    PartyStub,
    Person,
    User,
} from '@wdx/clmi/clmi-swagger-gen';
import { Avatar } from '@wdx/clmi/utils/models';

@Pipe({ name: 'partyToAvatar', standalone: true })
export class PartyToAvatarPipe implements PipeTransform {
    transform(
        value:
            | Party
            | PartyStub
            | Person
            | Organisation
            | User
            | LookupFieldResult
            | string,
        isUnknown?: boolean,
        isAvatarInfoEnabled = true,
        formPartyId?: string,
        fileUnderId?: string,
        activityPartyType?: ActivityPartyType,
    ): Avatar | null {
        if (!value) {
            return null;
        }

        if (typeof value === 'string') {
            return {
                text: value,
            };
        } else {
            return {
                avatarUrl: value.avatarUrl,
                partyType: value.partyType,
                ...((value as LookupFieldResult)?.lookupType
                    ? { lookupType: (value as LookupFieldResult).lookupType }
                    : {}),
                partyId: value.id,
                formPartyId: formPartyId ? formPartyId : value.id,
                fileUnderId: fileUnderId,
                text: value.name,
                isUnknown,
                isAvatarInfoEnabled,
                activityPartyType: activityPartyType,
            };
        }
    }
}
