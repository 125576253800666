import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import {
    FilterFieldDefinition,
    PossibleValue,
} from '@wdx/clmi/clmi-swagger-gen';
import { TranslationsService } from '@wdx/shared/utils';
import { map, Observable } from 'rxjs';
import { ExtendedFieldDefinitionOption } from '../../../../../../models/extended-field-definition-option.model';
import { FilterDataItem } from '../../interfaces';
import { FilterDataProviderService } from '../../services/filter-data/filter-data-provider.service';

@Component({
    selector: 'clmi-filter-select',
    templateUrl: './filter-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSelectComponent implements OnInit {
    @Input() definition: FilterFieldDefinition;
    @Input() formControlNameOrIndex: string | number;
    @Input() multiple = false;
    @Input() options: PossibleValue[];
    options$: Observable<FilterDataItem[] | ExtendedFieldDefinitionOption[]>;

    private translationsService = inject(TranslationsService);

    constructor(
        private readonly filterDataProviderService: FilterDataProviderService,
    ) {}

    ngOnInit(): void {
        this.options$ = this.filterDataProviderService
            .getData$(this.definition, this.options)
            .pipe(
                map((options: FilterDataItem[]) =>
                    options.map((option) => ({
                        ...option,
                        label: this.translationsService.translateTokenisedString(
                            option.label,
                        ),
                    })),
                ),
            );
    }
}
