import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import {
    ViewTile,
    ViewTileCreate,
    ViewTileUpdate,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';

@Injectable()
export class ViewTileService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getAll(): Observable<ViewTile[]> {
        return this.http.get<ViewTile[]>(
            `${this.config.getConfiguration().API_BASE}/viewtile`,
        );
    }

    getForId(id: string): Observable<ViewTile> {
        return this.http.get<ViewTile>(
            `${this.config.getConfiguration().API_BASE}/viewtile/${id}`,
        );
    }

    create(create: ViewTileCreate): Observable<ViewTile> {
        return this.http.post<ViewTile>(
            `${this.config.getConfiguration().API_BASE}/viewtile`,
            create,
        );
    }

    update(id: string, update: ViewTileUpdate): Observable<ViewTile> {
        return this.http.put<ViewTile>(
            `${this.config.getConfiguration().API_BASE}/viewtile/${id}`,
            update,
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(
            `${this.config.getConfiguration().API_BASE}/viewtile/${id}`,
        );
    }
}
