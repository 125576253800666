/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SummaryLevel {
  InfoCard = 'InfoCard',
  Title = 'Title',
  SubTitle1 = 'SubTitle1',
  SubTitle2 = 'SubTitle2',
  SubTitle3 = 'SubTitle3',
  IsPrimary = 'IsPrimary'
}
