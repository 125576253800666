import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StatusType } from '@wdx/clmi/clmi-swagger-gen';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from './../../state/_setup/reducers';
import * as documentRequirementTypesActions from './document-requirement-types.actions';
import * as documentRequirementTypesSelectors from './document-requirement-types.selectors';
import { DocumentRequirementTypesService } from './document-requirement-types.service';

@Injectable()
export class DocumentRequirementTypesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private documentRequirementTypesService = inject(
        DocumentRequirementTypesService,
    );

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentRequirementTypesActions.getAll),
            switchMap(() =>
                this.documentRequirementTypesService.getAll().pipe(
                    map((documentRequirementTypes) =>
                        documentRequirementTypesActions.getAllSuccess({
                            documentRequirementTypes,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            documentRequirementTypesActions.getAllFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    searchDocumentRequirementTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                documentRequirementTypesActions.searchDocumentRequirementTypes,
            ),
            withLatestFrom(
                this.store$.select(
                    documentRequirementTypesSelectors.getDocumentRequirementTypesInfinityPaging(
                        { id: GLOBAL_STATE_INDEX_ID },
                    ),
                ),
            ),
            switchMap(([action, paging]) =>
                this.documentRequirementTypesService
                    .searchDocumentRequirementTypesClient(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.filter,
                    )
                    .pipe(
                        map((documentRequirementTypes) => {
                            return documentRequirementTypesActions.searchDocumentRequirementTypesSuccess(
                                { documentRequirementTypes },
                            );
                        }),
                        catchError((error) =>
                            of(
                                documentRequirementTypesActions.searchDocumentRequirementTypesFailure(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    toggleStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentRequirementTypesActions.toggleStatus),
            map((action) =>
                action.newValue
                    ? documentRequirementTypesActions.activateDocumentRequirementType(
                          {
                              id: action.id,
                          },
                      )
                    : documentRequirementTypesActions.inactivateDocumentRequirementType(
                          {
                              id: action.id,
                          },
                      ),
            ),
        ),
    );

    activate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                documentRequirementTypesActions.activateDocumentRequirementType,
            ),
            mergeMap((action) =>
                this.documentRequirementTypesService
                    .setDocumentStatus(action.id, StatusType.Active)
                    .pipe(
                        map(() =>
                            documentRequirementTypesActions.activateDocumentRequirementTypeSuccess(
                                {
                                    id: action.id,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentRequirementTypesActions.activateDocumentRequirementTypeFailure(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    inactivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                documentRequirementTypesActions.inactivateDocumentRequirementType,
            ),
            mergeMap((action) =>
                this.documentRequirementTypesService
                    .setDocumentStatus(action.id, StatusType.Inactive)
                    .pipe(
                        map(() =>
                            documentRequirementTypesActions.inactivateDocumentRequirementTypeSuccess(
                                {
                                    id: action.id,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentRequirementTypesActions.inactivateDocumentRequirementTypeFailure(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                documentRequirementTypesActions.deleteDocumentRequirementType,
            ),
            mergeMap((action) =>
                this.documentRequirementTypesService
                    .deleteDocumentRequirementTypes(action.id)
                    .pipe(
                        map(() =>
                            documentRequirementTypesActions.deleteDocumentRequirementTypeSuccess(
                                {
                                    id: action.id,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                documentRequirementTypesActions.deleteDocumentRequirementTypeFailure(
                                    {
                                        id: action.id,
                                        error: error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
