import { Person } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

export const getForParty = createAction(
    '[People] Get For Party',
    props<{
        partyId: string;
    }>(),
);

export const getForPartySuccess = createAction(
    '[People] Get For Party Success',
    props<{
        partyId: string;
        person: Person;
    }>(),
);

export const getForPartyFailure = createAction(
    '[People] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);

export const getFavourites = createAction('[People] Get Favourites');

export const getFavouritesSuccess = createAction(
    '[People] Get Favourites Success',
    props<{
        favourites: Person[];
    }>(),
);

export const getFavouritesFailure = createAction(
    '[People] Get Favourites Failure',
    props<{
        error: Error;
    }>(),
);
