import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@wdx/clmi/clmi-swagger-gen';
import {
    UserApiService,
    userActions,
    userSelectors,
} from '@wdx/clmi/api-services/services';
import { TenantsService } from '@wdx/shared/utils';
import { Observable, filter, switchMap, tap, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GuardUserInfoService {
    private store$ = inject(Store);
    private userApiService = inject(UserApiService);
    private tenantsService = inject(TenantsService);

    setTenantAndGetUser(tenant: string | null): Observable<User | undefined> {
        return this.userApiService.getTenants().pipe(
            switchMap((tenants) => {
                this.tenantsService.set(tenants);

                const userDefaultTenant = tenants.find(
                    (t) => t.isDefault,
                )?.tenantCode;
                if (
                    this.tenantsService.updateTenant(userDefaultTenant, tenant)
                ) {
                    return this.store$.select(userSelectors.getMeSelector).pipe(
                        tap((res) => {
                            if (!res) {
                                this.store$.dispatch(userActions.getMeAction());
                            }
                        }),
                        filter(Boolean),
                    );
                } else {
                    return of();
                }
            }),
        );
    }
}
