import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseType, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { CLMI_APPLY_TRANSLATIONS } from '../../constants/header.constants';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class CaseTypesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getAll(
        isActive: boolean,
        entityType?: SystemEntity,
    ): Observable<CaseType[]> {
        const baseUrl = `${this.config.getConfiguration().API_BASE}/casetype`;
        const queryParams = [];

        if (isActive) {
            queryParams.push('isActive=true');
        }
        if (entityType) {
            queryParams.push(`entityType=${entityType}`);
        }
        const queryString = queryParams.length
            ? `?${queryParams.join('&')}`
            : '';
        const url = `${baseUrl}${queryString}`;

        return this.http.get<CaseType[]>(url, {
            headers: {
                [CLMI_APPLY_TRANSLATIONS]: 'true',
            },
        });
    }
}
