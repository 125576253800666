/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormElementStyle {
  None = 'None',
  Dropdown = 'Dropdown',
  Checkboxes = 'Checkboxes',
  CheckTiles = 'CheckTiles',
  Radios = 'Radios',
  RadioTiles = 'RadioTiles',
  ButtonGroup = 'ButtonGroup',
  StartAndEnd = 'StartAndEnd',
  StartAndDuration = 'StartAndDuration',
  StartOnly = 'StartOnly',
  DayOfYear = 'DayOfYear',
  MonthAndYearOnly = 'MonthAndYearOnly',
  YearOnly = 'YearOnly'
}
