import { Action, createReducer, on } from '@ngrx/store';
import { PartyRoleAccess } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as accessActions from './access.actions';

export interface State {
    access: CrudStateObject<PartyRoleAccess>;
}

export const initialState: State = {
    access: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        accessActions.getForParty,
        (state, props): State => ({
            ...state,
            access: {
                ...state.access,
                [props.partyId]: {
                    ...(state.access[props.partyId] ||
                        ({} as CrudState<PartyRoleAccess>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        accessActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            access: {
                ...state.access,
                [props.partyId]: {
                    ...state.access[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.access,
                },
            },
        }),
    ),

    on(
        accessActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            access: {
                ...state.access,
                [props.partyId]: {
                    ...state.access[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
