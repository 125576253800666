import {
    FilterFieldDefinition,
    FilterFieldType,
    FilterOperator,
    QueryExpression,
} from '@wdx/clmi/clmi-swagger-gen';

export const IS_FAVOURITE_DEF: FilterFieldDefinition = {
    name: 'IsFavourite',
    displayName: {
        value: 'Is Favourite',
    },
    type: FilterFieldType.Boolean,
    operators: [FilterOperator.Equals],
    possibleValues: null,
};

export const IS_FAVOURITE_QUERY: QueryExpression = {
    field: 'IsFavourite',
    operator: FilterOperator.Equals,
};

export const IS_SEARCH_DEF = {
    name: 'Search',
    displayName: {
        value: 'Search',
    },
    type: FilterFieldType.Search,
    operators: [FilterOperator.Matches],
    possibleValues: null,
};

export const IS_SEARCH_QUERY = {
    field: 'Search',
};

export const ADD_FILTER_TYPE_SINGLE = [
    FilterFieldType.Boolean,
    FilterFieldType.Date,
    FilterFieldType.DateTime,
    FilterFieldType.Decimal,
    FilterFieldType.Integer,
    FilterFieldType.Search,
    FilterFieldType.String,
    FilterFieldType.Currency,
];

export const ADD_FILTER_TYPE_MULTI = [
    FilterFieldType.Answer,
    FilterFieldType.Enum,
    FilterFieldType.Select,
];

export const ADD_FILTER_TYPE_LOOKUP = [FilterFieldType.Lookup];

export const AT_LEAST_ONE_TRUTHY = [FilterFieldType.Enum];

export const MULTIPLE_OPTIONS_OPERATOR = [
    FilterOperator.ContainsAny,
    FilterOperator.ContainsAll,
    FilterOperator.NotContainsAny,
    FilterOperator.NotContainsAll,
    FilterOperator.In,
    FilterOperator.NotIn,
];

export const NO_VALUE_OPERATORS = [
    FilterOperator.ContainsData,
    FilterOperator.DoesNotContainData,
    FilterOperator.ThisMonth,
    FilterOperator.ThisYear,
    FilterOperator.ThisWeek,
];
