import { Action, createReducer, on } from '@ngrx/store';
import { CsvFile } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import { Batch } from '../../models/batch.model';
import * as batchListTypeAction from './batch-list.actions';

export interface State {
    batches: CrudStateObject<Batch>;
    csvFile: CrudStateObject<CsvFile>;
    batchId: string;
    batchName: string;
}

export const initialState: State = {
    batches: {},
    csvFile: {},
    batchId: '',
    batchName: '',
};

const reducerSetup = createReducer(
    initialState,
    on(
        batchListTypeAction.getBatchFileById,
        (state, props): State => ({
            ...state,
            csvFile: {
                ...state.csvFile,
                [props.batchId]: {
                    ...(state.csvFile[props.batchId] ||
                        ({} as CrudStateObject<CsvFile>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(batchListTypeAction.getBatchFileByIdSuccess, (state, props): State => {
        return {
            ...state,
            csvFile: {
                ...state.csvFile,
                [props.batchId]: {
                    ...state.csvFile[props.batchId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.batch,
                },
            },
        };
    }),

    on(
        batchListTypeAction.getBatchFileByIdFailure,
        (state, props): State => ({
            ...state,
            csvFile: {
                ...state.csvFile,
                [props.batchId]: {
                    ...state.csvFile[props.batchId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),
    on(
        batchListTypeAction.emitBatchId,
        (state, props): State => ({
            ...state,
            batchId: props.id,
        }),
    ),

    on(
        batchListTypeAction.emitBatchName,
        (state, props): State => ({
            ...state,
            batchName: props.name,
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
