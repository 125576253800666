import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { Process, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class ProcessesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getProcessesForSystemEntity(
        systemEntity: SystemEntity,
        systemEntityId: string,
    ): Observable<Process[]> {
        return this.http.get<Process[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${systemEntity.toLowerCase()}/${systemEntityId}/process`,
        );
    }

    getProcess(processId: string): Observable<Process> {
        return this.http.get<Process>(
            `${this.config.getConfiguration().API_BASE}/process/${processId}`,
        );
    }

    createProcess(process: Process): Observable<Process> {
        return this.http.post<Process>(
            `${this.config.getConfiguration().API_BASE}/process`,
            process,
        );
    }
}
