import { createAction, props } from '@ngrx/store';
import {
    MailingInstruction,
    MailingInstructionStatusType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';

export const getForEntityId = createAction(
    '[Mailing Instructions] Get For Entity Id',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);
export const getForEntityIdSuccess = createAction(
    '[Mailing Instructions] Get For Entity Id Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        mailingInstructions: MailingInstruction[];
    }>(),
);
export const getForEntityIdFailure = createAction(
    '[Mailing Instructions] Get For Entity Id Failure',
    props<{
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>(),
);

export const generateForClientId = createAction(
    '[Mailing Instructions] Generate For Client Id',
    props<{
        clientId: string;
    }>(),
);

export const generateForClientIdSuccess = createAction(
    '[Mailing Instructions] Generate For Client Id Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);

export const generateForClientIdFailure = createAction(
    '[Mailing Instructions] Generate For Client Id Failure',
    props<{
        error: Error;
    }>(),
);

export const updateMailingInstructionsStatus = createAction(
    '[Mailing Instructions] Update Mailing Instructions Status',
    props<{
        ids: string[];
        status: MailingInstructionStatusType;
    }>(),
);

export const updateMailingInstructionsStatusSuccess = createAction(
    '[Mailing Instructions] Update Mailing Instructions Status Success',
    props<{
        mailingInstructions: MailingInstruction[];
    }>(),
);

export const updateMailingInstructionsStatusFailure = createAction(
    '[Mailing Instructions] Delete Mailing Instruction Status Failure',
    props<{
        error: Error;
    }>(),
);

export const duplicateMailingInstructions = createAction(
    '[Mailing Instructions] Duplicate Mailing Instructions',
    props<{
        ids: string[];
    }>(),
);

export const duplicateMailingInstructionSuccess = createAction(
    '[Mailing Instructions] Duplicate Mailing Instructions Success',
    props<{
        mailingInstructions: MailingInstruction[];
    }>(),
);

export const duplicateMailingInstructionFailure = createAction(
    '[Mailing Instructions] Duplicate Mailing Instruction Failure',
    props<{
        error: Error;
    }>(),
);

export const resetMailingInstructions = createAction(
    '[Mailing Instructions] Reset Mailing Instructions',
);
