import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feed } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class FeedsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForClientProduct(clientProductId: string): Observable<Feed[]> {
        return this.http.get<Feed[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/feed`,
        );
    }

    getFeedsForClient(clientId: string): Observable<Feed[]> {
        return this.http.get<Feed[]>(
            `${this.config.getConfiguration().API_BASE}/client/${clientId}/feed`,
        );
    }
}
