import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import * as duplicationCheckActions from '../state/duplication-check/duplication-check.actions';
import * as rootReducer from '../state/_setup/reducers';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DuplicateDetectionInterceptor implements HttpInterceptor {
    constructor(private store$: Store<rootReducer.State>) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 409) {
                    const formData = req.body;
                    const payload = err.error;
                    const urlSegments: string[] = err.url.split('/');
                    const formId: string =
                        urlSegments[urlSegments.length - 1].split('?')[0];
                    const entityId: string = err.error[0]?.entityId;
                    const entityType: SystemEntity =
                        SystemEntity[err.error[0]?.entityType];

                    this.store$.dispatch(
                        duplicationCheckActions.openDuplicatesModal({
                            formData,
                            payload,
                            formId,
                            entityId,
                            entityType,
                        }),
                    );
                } else {
                    return throwError(err);
                }
            }),
        );
    }
}
