import { createAction, props } from '@ngrx/store';
import {
    AccessVisibility,
    Case,
    ClientProduct,
    EntityOperations,
    KeyStatResult,
    PartyRoleTeamMember,
} from '@wdx/clmi/clmi-swagger-gen';

export const getForPartyRole = createAction(
    '[Client Products] Get For Party Role',
    props<{
        partyRoleId: string;
    }>(),
);

export const getForPartyRoleSuccess = createAction(
    '[Client Products] Get For Party Role Success',
    props<{
        partyRoleId: string;
        clientProducts: ClientProduct[];
    }>(),
);

export const getForPartyRoleFailure = createAction(
    '[Client Products] Get For Party Role Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>(),
);

export const getForParty = createAction(
    '[Client Products] Get For Party ',
    props<{
        partyId: string;
    }>(),
);

export const getForPartySuccess = createAction(
    '[Client Products] Get For Party  Success',
    props<{
        partyId: string;
        clientProducts: ClientProduct[];
    }>(),
);

export const getForPartyFailure = createAction(
    '[Client Products] Get For Party  Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);

export const getForClient = createAction(
    '[Client Products] Get For Client',
    props<{
        clientId: string;
    }>(),
);

export const getForClientSuccess = createAction(
    '[Client Products] Get For Client Success',
    props<{
        clientProducts: ClientProduct[];
        clientId: string;
    }>(),
);

export const getForClientFailure = createAction(
    '[Client Products] Get For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>(),
);

export const getForId = createAction(
    '[Client Products] Get For Id',
    props<{
        clientProductId: string;
    }>(),
);

export const getForIdSuccess = createAction(
    '[Client Products] Get For Id Success',
    props<{
        clientProductId: string;
        clientProduct: ClientProduct;
    }>(),
);

export const getForIdFailure = createAction(
    '[Client Products] Get For Id Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getCasesForId = createAction(
    '[Client Products] Get Cases For Id',
    props<{
        clientProductId: string;
    }>(),
);

export const getCasesForIdSuccess = createAction(
    '[Client Products] Get Cases For Id Success',
    props<{
        clientProductId: string;
        cases: Case[];
    }>(),
);

export const getCasesForIdFailure = createAction(
    '[Client Products] Get Cases For Id Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getVisibilityForId = createAction(
    '[Client Products] Get Visibility For Id',
    props<{
        id: string;
    }>(),
);

export const getVisibilityForIdSuccess = createAction(
    '[Client Products] Get Visibility For Id Success',
    props<{
        id: string;
        visibility: AccessVisibility[];
    }>(),
);

export const getVisibilityForIdFailure = createAction(
    '[Client Products] Get Visibility For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getMembersForId = createAction(
    '[Client Products] Get members For Id',
    props<{
        id: string;
    }>(),
);

export const getMembersForIdSuccess = createAction(
    '[Client Products] Get members For Id Success',
    props<{
        id: string;
        members: PartyRoleTeamMember[];
    }>(),
);

export const getMembersIdFailure = createAction(
    '[Client Products] Get members For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getKeyStatsForId = createAction(
    '[Client Products] Get keys stats For Id',
    props<{
        id: string;
    }>(),
);

export const getKeyStatsForIdSuccess = createAction(
    '[Client Products] Get keys stats Id Success',
    props<{
        id: string;
        keyStats: KeyStatResult[];
    }>(),
);

export const getKeyStatsForIdFailure = createAction(
    '[Client Products] Get keys stats Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const deleteForId = createAction(
    '[Client Products] Delete For Id',
    props<{
        clientId: string;
        productId: string;
    }>(),
);

export const deleteForIdSuccess = createAction(
    '[Client Products] Delete For Id Success',
    props<{
        clientId: string;
        productId: string;
    }>(),
);

export const deleteForIdFailure = createAction(
    '[Client Products] Delete For Id Failure',
    props<{
        clientId: string;
        productId: string;
        error: Error;
    }>(),
);

export const deleteTeamMember = createAction(
    '[Client Products] Delete Team Member',
    props<{
        id: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberSuccess = createAction(
    '[Client Products] Delete Team Member Success',
    props<{
        id: string;
        memberId: string;
    }>(),
);

export const deleteTeamMemberFailure = createAction(
    '[Client Products] Delete Team Member Failure',
    props<{
        error: Error;
        id: string;
    }>(),
);

export const getOperationForId = createAction(
    '[Client Products] Get Operations For Id',
    props<{
        id: string;
    }>(),
);

export const getOperationForIdSuccess = createAction(
    '[Client Products] Get Operations For Id Success',
    props<{
        id: string;
        operations: EntityOperations;
    }>(),
);

export const getOperationForIdFailure = createAction(
    '[Client Products] Get Operations For Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);
