import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { KanbanStage, KanbanType } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class KanbanStagesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}
    getForKanbanType(kanbanTypeId: KanbanType): Observable<KanbanStage[]> {
        return this.http.get<KanbanStage[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/kanbanstage/type/${kanbanTypeId}`,
        );
    }
}
