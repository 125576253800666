/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FeatureStatus {
  Available = 'Available',
  Enabled = 'Enabled',
  NotAvailable = 'NotAvailable',
  NotConfigurable = 'NotConfigurable'
}
