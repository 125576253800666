import { Action, createReducer, on } from '@ngrx/store';
import { Reminder } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as remindersActions from './reminders.actions';

export type State = CrudState<Reminder>;

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        remindersActions.create,
        (state): State => ({
            ...state,
            isCreating: true,
            hasCreatingError: false,
        }),
    ),

    on(
        remindersActions.createSuccess,
        (state): State => ({
            ...state,
            isCreating: false,
        }),
    ),

    on(
        remindersActions.createFailure,
        (state): State => ({
            ...state,
            isCreating: false,
            hasCreatingError: true,
        }),
    ),

    on(
        remindersActions.deleteReminder,
        (state): State => ({
            ...state,
            isDeleting: true,
            hasDeletingError: false,
        }),
    ),

    on(
        remindersActions.deleteSuccess,
        (state): State => ({
            ...state,
            isDeleting: false,
        }),
    ),

    on(
        remindersActions.deleteFailure,
        (state): State => ({
            ...state,
            isDeleting: false,
            hasDeletingError: true,
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
