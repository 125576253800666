import { Injectable } from '@angular/core';
import { PartyAvatarInfo, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class AvatarInfoService extends BaseApiService {
    getForEntityId(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<PartyAvatarInfo> {
        return this.get<PartyAvatarInfo>(
            `${entityType}/${entityId}/avatarinfo`,
        );
    }
}
