import { createAction, props } from '@ngrx/store';
import { AmlReport } from '@wdx/clmi/clmi-swagger-gen';

export const getAllForId = createAction(
    '[Aml] Get All for Id',
    props<{
        id: string;
    }>(),
);
export const getAllForIdSuccess = createAction(
    '[Aml] Get All for Id Success',
    props<{
        id: string;
        reports: AmlReport[];
    }>(),
);
export const getAllForIdFailure = createAction(
    '[Aml] Get All for Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const getLatestForId = createAction(
    '[Aml] Get Latest for Id',
    props<{
        id: string;
    }>(),
);
export const getLatestForIdSuccess = createAction(
    '[Aml] Get Latest for Id Success',
    props<{
        id: string;
        report: AmlReport;
    }>(),
);
export const getLatestForIdFailure = createAction(
    '[Aml] Get Latest for Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const patchNewCheckForId = createAction(
    '[Aml] Patch New Check for Id',
    props<{
        id: string;
    }>(),
);
export const patchNewCheckForIdSuccess = createAction(
    '[Aml] Patch New Check for Id Success',
    props<{
        id: string;
    }>(),
);
export const patchNewCheckForIdFailure = createAction(
    '[Aml] Patch New Check for Id Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);
