import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortDirection } from '@wdx/clmi/clmi-swagger-gen';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';
import { FormDisplayMode, NavItem } from '@wdx/shared/utils';
import { BaseSearchBarClass } from '../../../classes/base-search-bar.class';
import { FilterHandler } from '../../../classes/filter-handler.class';
import {
    ICON_FILTER_BASE,
    ICON_FILTER_LIGHT,
    ICON_SEARCH_BASE,
    ICON_SEARCH_SOLID,
    ICON_SORT_ARROWS,
    ICON_SORT_ARROW_DOWN,
    ICON_SORT_ARROW_UP,
    ICON_SORT_DOWN_BASE,
    ICON_SORT_DOWN_SOLID,
} from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-search-bar',
    templateUrl: './molecule-search-bar.component.html',
    styleUrls: ['./molecule-search-bar.component.scss'],
})
export class MoleculeSearchBarComponent
    extends BaseSearchBarClass
    implements OnInit
{
    @Input() filterModalId: string;

    @Output() sort = new EventEmitter<NavItem>();

    searchFormInputData: FormInputData;
    instanceId: string;
    showMobileSearch: boolean;

    readonly ICON_FILTER = ICON_FILTER_LIGHT.icon;
    readonly ICON_FILTER_ACTIVE = ICON_FILTER_BASE.icon;
    readonly ICON_SEARCH = ICON_SEARCH_BASE.icon;
    readonly ICON_SEARCH_ACTIVE = ICON_SEARCH_SOLID.icon;
    readonly ICON_SORT = ICON_SORT_DOWN_BASE.icon;
    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly ACTION_BUTTON_DROPDOWN_PLACEMENT = [
        'bottom-left',
        'bottom-right',
        'top-left',
        'top-right',
    ];

    ngOnInit() {
        this.instanceId =
            this.filterFormId || new RandomStringPipe().transform();
        this.searchFormInputData = {
            id: this.instanceId,
            placeholder: this.placeholder,
            cySelector: 'input-search-bar',
        };
    }

    toggleMobileSearch(): void {
        this.showMobileSearch = !this.showMobileSearch;
    }

    getSortButton(
        filterHandler: FilterHandler,
        sortMenu: NavItem[],
    ): ActionButton {
        if (sortMenu?.length) {
            return {
                mode: ActionButtonMode.DropdownButtonIcon,
                icon: filterHandler.getSortBy()
                    ? ICON_SORT_DOWN_SOLID.icon
                    : ICON_SORT_DOWN_BASE.icon,
                dropdownMenu: sortMenu.map((menuItem) => ({
                    ...menuItem,
                    icon:
                        filterHandler.getSortBy() === menuItem.id
                            ? filterHandler.getSortDirection() ===
                              SortDirection.Ascending
                                ? ICON_SORT_ARROW_UP.icon
                                : ICON_SORT_ARROW_DOWN.icon
                            : ICON_SORT_ARROWS.icon,
                })),
            } as ActionButton;
        } else {
            return {
                mode: ActionButtonMode.IconButton,
                icon: ICON_SORT_DOWN_BASE.icon,
            } as ActionButton;
        }
    }

    searchCountLabel(totalRecordCount: number, isLoading: boolean) {
        return isLoading && !totalRecordCount ? '--' : totalRecordCount;
    }

    onSort(sortMenuItem: NavItem): void {
        this.sort.emit(sortMenuItem);
    }
}
