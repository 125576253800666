import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import {
    List,
    ListType,
    Party,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import {
    FilterQueryFacadeService,
    operationsActions,
} from '@wdx/clmi/api-services/state';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import {
    CONTACT_FORM_IDS,
    FeaturesService,
    NavItem,
    PagingType,
    TranslationsService,
} from '@wdx/shared/utils';
import { BehaviorSubject, from } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ENTITY_ICON_MAP } from '../../../../../constants/entity-icons.constants';
import { ICON_COVERAGE } from '../../../../../constants/icons.constants';
import {
    ADD_TO_LIST_MENU_ITEM,
    VISIBILITY_MENU_ITEM,
} from '../../../../../constants/menu.constants';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { ContactType } from '../../../../../models/contact-type.model';
import { InfoCardData } from '../../../../../models/info-card-data.model';
import { InfoCardSearchMode } from '../../../../../models/info-card-search-mode.model';
import { LegendItem } from '../../../../../models/legend-item.model';
import { TableDisplayMode } from '../../../../../models/table-display-mode.model';
import { EntityRouteOpenNewTabPipe } from '../../../../../pipes/entity-route-open-new-tab.pipe';
import { ModalManagementService } from '../../../../../services/modal-management.service';
import { AccessVisibilityService } from '../../../../../shared/features/access-visibility/shared/services/access-visibility.service';
import { FilterTableClass } from '../../../../../shared/features/form-filters/shared/abstract-classes/filter-table/filter-table.class';
import { QueryBuilderFilterHandlerService } from '../../../../../shared/features/form-filters/shared/services/filter-handler/filter-handler.service';
import { FilterQueryService } from '../../../../../shared/features/form-filters/shared/services/filter-query/filter-query.service';
import { RouteDataService } from '../../../../../shared/services';
import { EntityOperationsService } from '../../../../../shared/services/entity-operations';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as favouritesActions from '../../../../../state/favourites/favourites.actions';
import * as listsActions from '../../../../../state/lists/lists.actions';
import * as listsSelectors from '../../../../../state/lists/lists.selectors';
import {
    CHAT_MENU_ITEM,
    LEGEND,
    OPEN_NEW_TAB_MENU_ITEM,
} from './contacts-view.static';

@Component({
    selector: 'clmi-contacts-view',
    templateUrl: './contacts-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FilterQueryService, QueryBuilderFilterHandlerService],
})
export class ContactsViewComponent
    extends FilterTableClass
    implements AfterViewInit, OnInit, OnDestroy
{
    @Input() set updateRows(parentFunction: (...args: any[]) => any) {
        this.updateTableRows = parentFunction;
    }

    @Input() set actionButtons(actionButtons: ActionButton[]) {
        this.actionBtns = actionButtons;
        this.actionButtons$.next(actionButtons);
    }

    @Input() set updateHeaders(tableHeaderData) {
        this.headers = tableHeaderData;
        this.table.headers = tableHeaderData;
    }

    @ViewChild('infoCardCell', { read: TemplateRef })
    infoCardCell: TemplateRef<any>;

    @ViewChild('lifecycleStageCell', { read: TemplateRef })
    lifecycleStageCell: TemplateRef<any>;

    @ViewChild('badgeCell', { read: TemplateRef })
    badgeCell: TemplateRef<any>;

    @ViewChild('actionButtonCell', { read: TemplateRef })
    actionButtonCell: TemplateRef<any>;

    @ViewChild('responsiveCardTemplate', { read: TemplateRef })
    responsiveCardTemplate: TemplateRef<any>;

    updateTableRows;
    actionButtons$ = new BehaviorSubject<ActionButton[]>(null);
    actionBtns: ActionButton[] = [];
    partyToAddToList: Party;
    myCoverageLegend: LegendItem[] = LEGEND;

    readonly CONTACT_TYPE = ContactType;
    readonly ICON_COVERAGE = ICON_COVERAGE.icon;
    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;
    readonly TABLE_DISPLAY_MODE = TableDisplayMode;
    readonly systemEntity = SystemEntity;

    private entityRouteService = inject(EntityRouteService);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private modalManagementService: ModalManagementService,
        private qbFilterHandlerService: QueryBuilderFilterHandlerService,
        private accessVisibilityService: AccessVisibilityService,
        private actionsSubject$: ActionsSubject,
        private featuresService: FeaturesService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
    ) {
        super(
            filterQueryFacadeService,
            filterQueryService,
            entityOperationsService,
            cd,
            store$,
            routeDataService,
            translationsService,
        );

        this.table.pagingType = PagingType.ServerPagination;

        this.filterQueryService.updateTableHeader$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
                this.updateHeader();
            });

        this.store$.select(listsSelectors.getLists).subscribe((listData) => {
            this.listsAsInfoCardData(listData);
        });
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.filterHandlerValuesChange$.subscribe(() => {
            this.updateHeader();
        });

        this.actionsSubject$
            .pipe(
                takeUntil(this.destroyed$),
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
                ),
                filter((action: any) =>
                    CONTACT_FORM_IDS.includes(action.formId),
                ),
            )
            .subscribe(() => {
                this.reloadTableData();
            });

        this.actionsSubject$
            .pipe(
                takeUntil(this.destroyed$),
                ofType(
                    dynamicFormsActions.bulkJobCompleted,
                    operationsActions.patchActionWithURLSuccess,
                ),
            )
            .subscribe(() => {
                this.reloadTableData();
            });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(
            this.filterQueryService.queryType,
            this.updateTableRows,
            this.infoCardCell,
            this.lifecycleStageCell,
            this.actionButtonCell,
            this.responsiveCardTemplate,
            this.badgeCell,
        );

        this.cd.detectChanges();
    }

    toggle(): void {
        this.filterQueryService.setLocationStorageFilterVisibilityToggle();
    }

    resetTable(): void {
        super.resetTable(this.table.headers);
    }

    onActionButtonClicked(actionButton: ActionButton): void {
        if (actionButton.mode === ActionButtonMode.FavouriteButton) {
            this.filterHandler.toggleIsFavourite(actionButton.isToggledOn);
        }
    }

    onToggleFavourite(
        isFavourite: boolean,
        id: string,
        contactType: string,
    ): void {
        this.store$.dispatch(
            favouritesActions.toggleFavourite({
                id: id,
                entity: <UserActionEntity>contactType,
                newValue: isFavourite,
            }),
        );
    }

    onActionButtonMenuItemSelected(
        navItem: NavItem,
        id: string,
        partyId: string,
        party: Party,
        entityType: SystemEntity | UserActionEntity,
    ): void {
        switch (navItem.label) {
            case CHAT_MENU_ITEM:
                navItem.id =
                    entityType === UserActionEntity.Lead ? partyId : id;
                entityType && this.openLinkInNewTab(entityType);
                break;
            case OPEN_NEW_TAB_MENU_ITEM:
                this.openLinkInNewTab(
                    entityType,
                    entityType === UserActionEntity.Lead ? party.id : id,
                );
                break;
            case ADD_TO_LIST_MENU_ITEM.label:
                this.openListModal(party);
                break;
            case VISIBILITY_MENU_ITEM.label:
                this.accessVisibilityService.open(
                    id,
                    entityType as SystemEntity,
                );
                break;
            default:
                break;
        }
    }

    openListModal(party?: Party): void {
        this.partyToAddToList = party;
        this.modalManagementService.openModalWithId('queryListsModal');
        this.store$.dispatch(
            listsActions.getListsByType({ listType: ListType.Standard }),
        );
    }

    onAddToList(list: List): void {
        this.store$.dispatch(
            listsActions.addPartiesToList({
                parties: [this.partyToAddToList],
                list: list,
            }),
        );

        this.modalManagementService.closeActiveModal();
        this.partyToAddToList = null;
    }

    listsAsInfoCardData(lists: List[]): void {
        const INFO_CARD_DATA: InfoCardData[] = [];

        lists?.map((list) => {
            INFO_CARD_DATA.push({
                id: list.id,
                primaryInfo: list.name,
                secondaryInfo: [{ label: list.description }],
                tertiaryInfo: [
                    { label: `${list.numberOfMembers || 0} members` },
                ],
                icon: ENTITY_ICON_MAP[SystemEntity.List],
                data: list,
            });
        });

        this.lists$.next(INFO_CARD_DATA);
    }

    openLinkInNewTab(entity: string, id?: string): void {
        new EntityRouteOpenNewTabPipe(this.entityRouteService).transform(
            SystemEntity[entity] || UserActionEntity[entity],
            this.router,
            this.route,
            this.location,
            id,
        );
    }

    updateHeader(): void {
        const ACTION_BTNS = [];

        from(this.actionBtns)
            .pipe(take(this.actionBtns.length))
            .subscribe((data) => {
                if (data.mode === ActionButtonMode.FavouriteButton) {
                    data.isToggledOn = this.qbFilterHandlerService.hasFavourite;
                    data.isDisabled = !this.filterQueryService.inBuilder
                        ? this.qbFilterHandlerService.hasFavourite
                        : false;
                }

                ACTION_BTNS.push(data);
            });

        this.actionButtons$.next(ACTION_BTNS);
    }

    private reloadTableData() {
        this.filterQueryFacadeService.resetResultsGetRecords(
            this.filterQueryService.queryType,
            this.filterQueryService.query,
        );
    }
}
