import { createSelector } from '@ngrx/store';
import * as partiesReducer from './parties.reducer';

import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

/**
 * Reducer state selector
 */
export const getState = (state: {
    parties: partiesReducer.State;
}): partiesReducer.State => state.parties;

/**
 * Reducer property selectors
 */

export const getKeyStatsForPartyIsLoadingList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.keyStatsForParty[props.id]?.isLoadingList,
);
export const getKeyStatsForPartyHasLoadingListError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.keyStatsForParty[props.id]?.hasLoadingListError,
);
export const getKeyStatsForParty = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.keyStatsForParty[props.id]?.list,
);

export const getTeamMembersForParty = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForParty[props.id]?.list,
);

export const getTeamMembersForPartyIsLoadingList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForParty[props.id]?.isLoadingList,
);

export const getTeamMembersForPartyHasLoadingListError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForParty[props.id]?.hasLoadingListError,
);

export const getTeamMembersForPartyRole = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForPartyRole[props.id]?.list,
);

export const getTeamMembersForPartyRoleIsLoadingList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForPartyRole[props.id]?.isLoadingList,
);

export const getTeamMembersForPartyRoleHasLoadingListError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.teamMembersForPartyRole[props.id]?.hasLoadingListError,
);

export const getIsLoadingSingle = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.parties[props.id]?.isLoadingSingle,
);
export const getHasLoadingSingleError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.parties[props.id]?.hasLoadingSingleError,
);
export const getSingle = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.parties[props.id]?.single,
);

export const getHeatMapForPartyIsLoadingSingle = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.heatMap[props.id]?.isLoadingSingle,
);
export const getHeatMapForPartyHasLoadingSingleError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.heatMap[props.id]?.hasLoadingSingleError,
);
export const getHeatMapForParty = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.heatMap[props.id]?.single,
);

export const getVisibilityForPartyIsLoadingList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.visibilityForParty[props.id]?.isLoadingList,
);
export const getVisibilityForPartyHasLoadingListError = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.visibilityForParty[props.id]?.hasLoadingListError,
);
export const getVisibilityForParty = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.visibilityForParty[props.id]?.list,
);

export const getTasksForPartyInfinityPaging = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.tasks[props.partyId]?.infinity?.paging,
);

export const getOrganisationConnectionsForPartyIsLoadingList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.organisationConnections[props.id]?.isLoadingList,
);
export const getOrganisationConnectionsForPartyHasLoadingListError =
    createSelector(
        getState,
        (state: partiesReducer.State, props: any) =>
            state.organisationConnections[props.id]?.hasLoadingListError,
    );
export const getOrganisationConnectionsList = createSelector(
    getState,
    (state: partiesReducer.State, props: any) =>
        state.organisationConnections[props.id]?.list,
);

export const getMembershipListsForEntityIsLoadingList = (
    entityId: string,
    entityType: SystemEntity,
) =>
    createSelector(
        getState,
        (state: partiesReducer.State) =>
            state.lists?.[entityType]?.[entityId]?.isLoadingList,
    );

export const getMembershipListsForEntityHasLoadingListError = (
    entityId: string,
    entityType: SystemEntity,
) =>
    createSelector(
        getState,
        (state: partiesReducer.State) =>
            state.lists?.[entityType]?.[entityId]?.hasLoadingListError,
    );

export const getMembershipListsForEntity = (
    entityId: string,
    entityType: SystemEntity,
) =>
    createSelector(
        getState,
        (state: partiesReducer.State) =>
            state.lists?.[entityType]?.[entityId]?.list,
    );
