import {
    FormDataResult,
    FormElementLayoutDefinition,
    FormElementStyle,
    FormFieldType,
    FormLayout,
    FormPendingChange,
    FormSectionLayoutDefinition,
    PendingChangeStatusType,
} from '@wdx/shared/utils';

export const INPUT_STRING_DATA = [
    FormFieldType.Text,
    FormFieldType.Password,
    FormFieldType.Integer,
    FormFieldType.Decimal,
    FormFieldType.MultilineText,
];

export const ARRAY_CONTROL_TYPES = [FormFieldType.Array];

export const INPUT_CONTROL_TYPES = [
    FormFieldType.Text,
    FormFieldType.Password,
    FormFieldType.Time,
    FormFieldType.Integer,
    FormFieldType.Decimal,
    FormFieldType.Date,
    FormFieldType.DateTime,
    FormFieldType.MultilineText,
    FormFieldType.UrlLookup,
    FormFieldType.TextDropdown,
];

export const SELECT_CONTROL_TYPES = [FormFieldType.Select];

export const CHECKBOX_CONTROL_TYPES = [
    FormElementStyle.Checkboxes,
    FormElementStyle.CheckTiles,
];

export const RADIO_BUTTONS_CONTROL_TYPES = [
    FormElementStyle.Radios,
    FormElementStyle.RadioTiles,
    FormElementStyle.ButtonGroup,
];

export const DATE_CONTROL_TYPES = [
    FormFieldType.DateRange,
    FormFieldType.DateTimeRange,
    FormFieldType.Date,
];

export const LOOKUP_CONTROL_TYPES = [
    FormFieldType.LookupSingle,
    FormFieldType.LookupMulti,
];

export const WRAPPED_CONTROL_TYPES = [
    ...LOOKUP_CONTROL_TYPES,
    FormFieldType.TagListSingle,
    FormFieldType.TagListMulti,
];

export const MULTI_SELECT_CONTROL_TYPES = [FormFieldType.Multiselect];

export const FILE_UPLOAD_CONTROL_TYPES = [
    FormFieldType.DocumentUpload,
    FormFieldType.ImageUpload,
];

export const PENDING_CHANGES_LOCK_FIELD = [
    PendingChangeStatusType.Accepted,
    PendingChangeStatusType.Submitted,
];

export interface IFormLayout extends FormLayout {
    sectionLayoutDefinitions?: IFormSectionLayoutDefinition[];
}

export interface IFormSectionLayoutDefinition
    extends FormSectionLayoutDefinition {
    elementLayoutDefinitions?: IFormElementLayoutDefinition[];
}

export interface IFormElementLayoutDefinition
    extends FormElementLayoutDefinition {
    pendingChange?: IFormPendingChange;
}

export interface IFormDataResult extends FormDataResult {
    pendingChanges?: IFormPendingChange[] | null;
}

export interface IFormPendingChange extends FormPendingChange {
    name?: string;
    subItems?: string[];
}

export interface IFormPendingChangeSubForm {
    [key: string]: {
        adding?: number;
        deleting?: number;
        updating?: number;
        label?: string;
    };
}
