import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueAsArray',
    standalone: true,
})
export class ValueAsArrayPipe implements PipeTransform {
    transform(value: any): any[] {
        return !value || Array.isArray(value) ? value : [value];
    }
}
