import { ClientLifecycleStage } from '@wdx/clmi/clmi-swagger-gen';
import { Highlight, Icon } from '@wdx/shared/utils';
import { TagData } from '../models/tag-data.model';
import { TypedKeyValueObject } from '../models/typed-key-value-object.model';
import {
    ICON_CLIENT,
    ICON_DANGER,
    ICON_ENGAGED,
    ICON_LEFT,
    ICON_ONBOARDING,
    ICON_PITCHING,
    ICON_SWITCHED,
    ICON_TARGET,
    ICON_UNWINDING,
} from './icons.constants';

export const LIFECYCLE_STAGE_ICON_MAP: TypedKeyValueObject<Icon> = {
    [ClientLifecycleStage.Client]: ICON_CLIENT.icon,
    // [ClientLifecycleStage.Disqualified]: ICON_DISQUALIFIED.icon,
    [ClientLifecycleStage.Engaged]: ICON_ENGAGED.icon,
    [ClientLifecycleStage.Left]: ICON_LEFT.icon,
    [ClientLifecycleStage.Onboarding]: ICON_ONBOARDING.icon,
    [ClientLifecycleStage.Pitching]: ICON_PITCHING.icon,
    [ClientLifecycleStage.Switched]: ICON_SWITCHED.icon,
    [ClientLifecycleStage.Target]: ICON_TARGET.icon,
    [ClientLifecycleStage.Unwinding]: ICON_UNWINDING.icon,
    [ClientLifecycleStage.PotentiallyWithoutContact]: ICON_DANGER.icon,
    [ClientLifecycleStage.WithoutContact]: ICON_DANGER.icon,
    [ClientLifecycleStage.Dormant]: ICON_DANGER.icon,
};

export const LIFECYCLE_STAGE_TAG_MAP: TypedKeyValueObject<TagData> = {
    [ClientLifecycleStage.Client]: {
        label: 'Client',
        highlight: Highlight.Success,
        shade: 200,
        icon: ICON_CLIENT.icon,
    },
    [ClientLifecycleStage.Engaged]: {
        label: 'Engaged',
        highlight: Highlight.Info,
        shade: 500,
        icon: ICON_ENGAGED.icon,
    },
    [ClientLifecycleStage.Left]: {
        label: 'Left',
        highlight: Highlight.Info,
        shade: 100,
        icon: ICON_LEFT.icon,
    },
    [ClientLifecycleStage.Onboarding]: {
        label: 'Onboarding',
        highlight: Highlight.Warning,
        shade: 700,
        icon: ICON_ONBOARDING.icon,
    },
    [ClientLifecycleStage.Pitching]: {
        label: 'Pitching',
        highlight: Highlight.Warning,
        shade: 400,
        icon: ICON_PITCHING.icon,
    },
    [ClientLifecycleStage.Target]: {
        label: 'Target',
        highlight: Highlight.Danger,
        shade: 100,
        icon: ICON_TARGET,
    },
    [ClientLifecycleStage.Unwinding]: {
        label: 'Unwinding',
        highlight: Highlight.Warning,
        shade: 200,
        icon: ICON_TARGET.icon,
    },
    [ClientLifecycleStage.Dormant]: {
        label: 'Dormant',
        highlight: Highlight.Danger,
        shade: 200,
        icon: ICON_DANGER.icon,
    },
    [ClientLifecycleStage.PotentiallyWithoutContact]: {
        label: 'Potentially Without Contact',
        highlight: Highlight.Warning,
        shade: 200,
        icon: ICON_DANGER.icon,
    },
    [ClientLifecycleStage.WithoutContact]: {
        label: 'Without Contact',
        highlight: Highlight.Warning,
        shade: 200,
        icon: ICON_DANGER.icon,
    },
};
