/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AppStatusType {
  Incomplete = 'Incomplete',
  PendingConfirmation = 'PendingConfirmation',
  NearingExpiration = 'NearingExpiration',
  Expired = 'Expired',
  Confirmed = 'Confirmed',
  Closed = 'Closed',
  Dormant = 'Dormant',
  Inactive = 'Inactive'
}
