import { Action, createReducer, on } from '@ngrx/store';
import {
    Document,
    RiskAssessmentAdviser,
    RiskAssessmentClient,
    RiskAssessmentSnapshot,
} from '@wdx/clmi/clmi-swagger-gen';
import {
    CrudStatus,
    CrudStatusRecord,
    GLOBAL_STATE_INDEX_ID,
    setCrudStatus,
} from '@wdx/shared/utils';
import * as oxfordRiskActions from './oxford-risk.actions';

export const OXFORD_RISK_FEATURE_KEY = 'oxfordRisk';

export interface State {
    riskAssessmentClients: Record<
        string,
        CrudStatusRecord<RiskAssessmentClient>
    >;
    riskAssessmentAdvisers: CrudStatusRecord<RiskAssessmentAdviser[]>;
    riskAssessments: Record<string, CrudStatusRecord<RiskAssessmentSnapshot[]>>;
    reports: Record<
        string,
        Record<string, Record<string, CrudStatusRecord<Document>>>
    >;
}

export const initialState: State = {
    riskAssessmentClients: {},
    riskAssessmentAdvisers: {
        ...setCrudStatus(CrudStatus.Initial),
        record: [],
    },
    riskAssessments: {},
    reports: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        oxfordRiskActions.createRiskAssessmentClient,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...(state.riskAssessmentClients[partyId]
                        ? {
                              ...state.riskAssessmentClients[partyId],
                              ...setCrudStatus(CrudStatus.Loading),
                          }
                        : setCrudStatus(CrudStatus.Created)),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.createRiskAssessmentClientSuccess,
        (state, { partyId, riskAssessmentClient }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Success),
                    record: riskAssessmentClient,
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.createRiskAssessmentClientFailure,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Error),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentClient,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...(state.riskAssessmentClients[partyId]
                        ? {
                              ...state.riskAssessmentClients[partyId],
                              ...setCrudStatus(CrudStatus.Loading),
                          }
                        : setCrudStatus(CrudStatus.Created)),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentClientSuccess,
        (state, { partyId, riskAssessmentClient }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Success),
                    record: riskAssessmentClient,
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentClientNotFound,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Success),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentClientFailure,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Error),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentAdvisers,
        (state): State => ({
            ...state,
            riskAssessmentAdvisers: {
                ...state.riskAssessmentAdvisers,
                ...setCrudStatus(CrudStatus.Loading),
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentAdvisersSuccess,
        (state, { riskAssessmentAdvisers }): State => ({
            ...state,
            riskAssessmentAdvisers: {
                ...state.riskAssessmentAdvisers,
                ...setCrudStatus(CrudStatus.Success),
                record: riskAssessmentAdvisers,
            },
        }),
    ),

    on(
        oxfordRiskActions.getRiskAssessmentAdvisersFailure,
        (state): State => ({
            ...state,
            riskAssessmentAdvisers: {
                ...state.riskAssessmentAdvisers,
                ...setCrudStatus(CrudStatus.Error),
            },
        }),
    ),

    on(
        oxfordRiskActions.getLatestAssessmentForParty,
        (state, { partyId }): State => ({
            ...state,
            riskAssessments: {
                ...state.riskAssessments,
                [partyId]: {
                    ...(state.riskAssessments[partyId]
                        ? {
                              ...state.riskAssessments[partyId],
                              ...setCrudStatus(CrudStatus.Loading),
                          }
                        : setCrudStatus(CrudStatus.Created)),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getLatestAssessmentForPartySuccess,
        (state, { partyId, riskAssessment }): State => ({
            ...state,
            riskAssessments: {
                ...state.riskAssessments,
                [partyId]: {
                    ...state.riskAssessments[partyId],
                    ...setCrudStatus(CrudStatus.Success),
                    record: riskAssessment,
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getLatestAssessmentForPartyFailure,
        (state, { partyId }): State => ({
            ...state,
            riskAssessments: {
                ...state.riskAssessments,
                [partyId]: {
                    ...state.riskAssessments[partyId],
                    ...setCrudStatus(CrudStatus.Error),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.getLatestReportForParty,
        (state, { partyId, snapshotId, reportType }): State => {
            const id = snapshotId || GLOBAL_STATE_INDEX_ID;
            const stateReports = state.reports;
            const stateReportsForParty = stateReports[partyId] || {};
            const stateReportsForPartyForSnapshot =
                stateReportsForParty[id] || {};
            const stateReportsForPartyForSnapshotForReportType =
                stateReportsForPartyForSnapshot[reportType];
            return {
                ...state,
                reports: {
                    ...stateReports,
                    [partyId]: {
                        ...stateReportsForParty,
                        [id]: {
                            ...stateReportsForPartyForSnapshot,
                            [reportType]: {
                                ...(stateReportsForPartyForSnapshotForReportType
                                    ? {
                                          ...stateReportsForPartyForSnapshotForReportType,
                                          ...setCrudStatus(CrudStatus.Loading),
                                      }
                                    : setCrudStatus(CrudStatus.Created)),
                            },
                        },
                    },
                },
            };
        },
    ),

    on(
        oxfordRiskActions.getLatestReportForPartySuccess,
        (state, { partyId, snapshotId, reportType, report }): State => {
            const id = snapshotId || GLOBAL_STATE_INDEX_ID;
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [partyId]: {
                        ...state.reports[partyId],
                        [id]: {
                            ...state.reports[partyId][id],
                            [reportType]: {
                                ...state.reports[partyId][id][reportType],
                                ...setCrudStatus(CrudStatus.Success),
                                record: report,
                            },
                        },
                    },
                },
            };
        },
    ),

    on(
        oxfordRiskActions.getLatestReportForPartyFailure,
        (state, { partyId, snapshotId, reportType }): State => {
            const id = snapshotId || GLOBAL_STATE_INDEX_ID;
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [partyId]: {
                        ...state.reports[partyId],
                        [id]: {
                            ...state.reports[partyId][id],
                            [reportType]: {
                                ...state.reports[partyId][id][reportType],
                                ...setCrudStatus(CrudStatus.Error),
                            },
                        },
                    },
                },
            };
        },
    ),

    on(
        oxfordRiskActions.setRiskAssessmentClientRiskLevelForParty,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...(state.riskAssessmentClients[partyId] || {}),
                    ...setCrudStatus(CrudStatus.Updating),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.setRiskAssessmentClientRiskLevelForPartySuccess,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Success),
                },
            },
        }),
    ),

    on(
        oxfordRiskActions.setRiskAssessmentClientRiskLevelForPartyFailure,
        (state, { partyId }): State => ({
            ...state,
            riskAssessmentClients: {
                ...state.riskAssessmentClients,
                [partyId]: {
                    ...state.riskAssessmentClients[partyId],
                    ...setCrudStatus(CrudStatus.Error),
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
