/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormActionType {
  SaveDraft = 'SaveDraft',
  Publish = 'Publish',
  Complete = 'Complete',
  Function = 'Function'
}
