import { createAction, props } from '@ngrx/store';
import { WorkItem } from '@wdx/clmi/clmi-swagger-gen';

export const getWorkItemsForProcess = createAction(
    '[Work Items] Get Work Items For Process',
    props<{
        processId: string;
    }>(),
);

export const getWorkItemsForProcessSuccess = createAction(
    '[Work Items] Get Work Items For Process Success',
    props<{
        processId: string;
        workItems: WorkItem[];
    }>(),
);

export const getWorkItemsForProcessFailure = createAction(
    '[Work Items] Get Work Items For Process Failure',
    props<{
        processId: string;
        error: Error;
    }>(),
);

export const getWorkItemsForCase = createAction(
    '[Work Items] Get Work Items For Case',
    props<{
        caseId: string;
    }>(),
);

export const getWorkItemsForCaseSuccess = createAction(
    '[Work Items] Get Work Items For Case Success',
    props<{
        caseId: string;
        workItems: WorkItem[];
    }>(),
);

export const getWorkItemsForCaseFailure = createAction(
    '[Work Items] Get Work Items For Case Failure',
    props<{
        caseId: string;
        error: Error;
    }>(),
);

export const getWorkItem = createAction(
    '[Work Items] Get Work Item',
    props<{
        workItemId: string;
    }>(),
);

export const getWorkItemSuccess = createAction(
    '[Work Items] Get Work Item Success',
    props<{
        workItemId: string;
        workItem: WorkItem;
    }>(),
);

export const getWorkItemFailure = createAction(
    '[Work Items] Get Work Item Failure',
    props<{
        workItemId: string;
        error: Error;
    }>(),
);
