import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Country,
    Currency,
    FormDefinition,
    FormSectionLayoutDefinition,
    Locale,
    SelectApiSource,
    Tag,
} from '@wdx/clmi/clmi-swagger-gen';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../../constants/state.constants';
import { ExtendedFieldDefinitionOption } from '../../../models/extended-field-definition-option.model';
import { Privilege } from '../../../models/privilege.model';
import * as rootReducer from '../../../state/_setup/reducers';
import * as countriesSelectors from '../../../state/countries/countries.selectors';
import * as currenciesSelectors from '../../../state/currencies/currencies.selectors';
import {
    DynamicFormsFacade,
    IFormDynamicData,
    dynamicFormsActions,
    formDefinitionToLayoutAndDefinition,
    compareOptionValue,
} from '@wdx/shared/infrastructure/form-framework';
import * as selectOptionsActions from '../../../state/select-options/select-options.actions';
import * as selectOptionsSelectors from '../../../state/select-options/select-options.selectors';
import * as tagsActions from '../../../state/tags/tags.actions';
import * as tagsSelectors from '../../../state/tags/tags.selectors';
import { ContextualDataContext } from '@wdx/clmi/api-services/models';
import { CrudStatus, TagCategory } from '@wdx/shared/utils';

// We need a short debounce time, this is due to forms often having muiltiple subscriptions to get data
// these all come in very close together and we don't want multiple API requests at the same time.
// The time is short so that we do get fresh data as the user interacts with CLMi - this is because they
// can make changes which alter the contextual select options result.
// Note: only used for contextual selects currently
const DEBOUNCE_TIME = 2000;

@Injectable({
    providedIn: 'root',
})
export class ReactiveFormDynamicDataService implements IFormDynamicData {
    private dynamicFormsFacade = inject(DynamicFormsFacade);
    private store$ = inject(Store<rootReducer.State>);

    forms = [];
    readonly PRIVILEGE = Privilege;
    private selectDebouncer: Record<
        string,
        {
            lastRequest: number;
        }
    > = {};

    compareOptionValue = compareOptionValue;

    getMeLocale(): Observable<Locale> {
        return this.store$.select(userSelectors.getMeLocaleSelector);
    }

    getCurrencies(): Observable<Currency[]> {
        return this.store$.select(currenciesSelectors.getList, {
            id: GLOBAL_STATE_INDEX_ID,
        });
    }

    getCountries(): Observable<Country[]> {
        return this.store$.select(countriesSelectors.getList, {
            id: GLOBAL_STATE_INDEX_ID,
        });
    }

    getTagsForCategory(tagCategory: TagCategory): Observable<Tag[]> {
        return this.store$
            .select(tagsSelectors.getList, { id: tagCategory })
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.store$.dispatch(
                            tagsActions.getForCategory({
                                category: { type: tagCategory },
                            }),
                        );
                    }
                }),
            );
    }

    getSelectApiSourceOptions(
        selectSource: SelectApiSource,
        selectQueryParams?: Record<string, string>,
    ): Observable<ExtendedFieldDefinitionOption[]> {
        const queryParamsString =
            selectQueryParams &&
            new HttpParams({
                fromObject: selectQueryParams,
            }).toString();

        const id =
            selectSource + (queryParamsString ? `?${queryParamsString}` : '');

        return this.store$
            .select(selectOptionsSelectors.selectOne({ id }))
            .pipe(
                tap((selectData) => {
                    if (!selectData || selectData.status < CrudStatus.Loading) {
                        this.store$.dispatch(
                            selectOptionsActions.getSelectOptions({
                                id,
                            }),
                        );
                    }
                }),
                filter((selectData) => selectData?.status > CrudStatus.Loading),
                map((selectFieldResults) =>
                    selectFieldResults.data.map((selectFieldResult) => ({
                        label: selectFieldResult.name,
                        value: selectFieldResult.id,
                        active: selectFieldResult.active !== false,
                        ...(selectFieldResult.icon && {
                            icon: selectFieldResult.icon,
                        }),
                        ...(selectFieldResult.level !== undefined && {
                            level: selectFieldResult.level,
                        }),
                    })),
                ),
            );
    }

    getContextSelectApiSourceOptions(
        source: SelectApiSource,
        lookups: any[],
        context?: ContextualDataContext[],
    ): Observable<ExtendedFieldDefinitionOption[]> {
        const selectSource =
            source +
            btoa(JSON.stringify(lookups)) +
            btoa(JSON.stringify(context));

        return this.store$
            .select(
                selectOptionsSelectors.selectOne({
                    id: selectSource,
                }),
            )
            .pipe(
                tap((selectData) => {
                    const lastRequest =
                        this.selectDebouncer[selectSource]?.lastRequest || 0;
                    const now = Date.now();
                    const debounce = now - lastRequest < DEBOUNCE_TIME;
                    this.selectDebouncer[selectSource] = { lastRequest: now };
                    const status = selectData?.status || CrudStatus.Initial;
                    if (debounce && status >= CrudStatus.Loading) {
                        return;
                    }
                    // update store to trigger refreshing data
                    if (!debounce && status > CrudStatus.Loading) {
                        this.store$.dispatch(
                            selectOptionsActions.resetContextualSelect({
                                id: selectSource,
                            }),
                        );
                    }
                    if (status < CrudStatus.Loading) {
                        this.store$.dispatch(
                            selectOptionsActions.getContextualSelect({
                                id: selectSource,
                                source,
                                lookups,
                                context,
                            }),
                        );
                    }
                }),
                filter((selectData) => selectData?.status > CrudStatus.Loading),
                map((selectFieldResults) =>
                    selectFieldResults.data.map((selectFieldResult) => ({
                        label: selectFieldResult.name,
                        value: selectFieldResult.id,
                        active: selectFieldResult.active !== false,
                        ...(selectFieldResult.icon && {
                            icon: selectFieldResult.icon,
                        }),
                        ...(selectFieldResult.level !== undefined && {
                            level: selectFieldResult.level,
                        }),
                    })),
                ),
            );
    }

    getFormLayoutAndDefinition(formId: string): Observable<{
        definition: FormDefinition;
        layoutAndDefinitions: FormSectionLayoutDefinition[];
    }> {
        return combineLatest([
            this.dynamicFormsFacade.getFormDefinition$(formId),
        ]).pipe(
            tap(([definition]) => {
                if (!definition && !this.forms.includes(formId)) {
                    this.store$.dispatch(
                        dynamicFormsActions.getFormDefinition({
                            formId: formId,
                        }),
                    );
                    this.forms.push(formId);
                }
            }),
            filter(([definition]) => Boolean(definition)),
            map(([definition]) =>
                formDefinitionToLayoutAndDefinition(definition),
            ),
        );
    }
}
