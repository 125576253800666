import { createAction, props } from '@ngrx/store';
import { MatchedDuplicate, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getDuplicates = createAction(
    '[Duplicates] Get Duplicates ',
    props<{
        reset: boolean;
        entity: SystemEntity;
    }>(),
);

export const getDuplicatesSuccess = createAction(
    '[Duplicates] Get Duplicates  Success',
    props<{
        duplicates: PaginatedApiResponse<MatchedDuplicate>;
        entity: SystemEntity;
    }>(),
);

export const getDuplicatesFailure = createAction(
    '[Duplicates] Get Duplicates  Failure',
    props<{
        error: Error;
        entity: SystemEntity;
    }>(),
);

export const runDuplicateCheck = createAction(
    '[Duplicates] Run Duplicate Check',
    props<{
        entity: SystemEntity;
    }>(),
);

export const runDuplicateCheckSuccess = createAction(
    '[Duplicates] Run Duplicate Check Success',
    props<{
        entity: SystemEntity;
    }>(),
);

export const runDuplicateCheckFailure = createAction(
    '[Duplicates] Run Duplicate Check Failure',
    props<{
        entity: SystemEntity;
        error: Error;
    }>(),
);

export const runDuplicateCheckRunning = createAction(
    '[Duplicates] Run Duplicate Check Running',
    props<{
        entity: SystemEntity;
    }>(),
);

export const markNotDuplicates = createAction(
    '[Duplicates] Mark Not Duplicates',
    props<{
        duplicateId: string;
    }>(),
);

export const markNotDuplicatesSuccess = createAction(
    '[Duplicates] Mark Not Duplicates Success',
    props<{
        duplicateId: string;
    }>(),
);

export const markNotDuplicatesFailure = createAction(
    '[Duplicates] Mark Not Duplicates Failure',
    props<{
        duplicateId: string;
        error: Error;
    }>(),
);

export const mergeDuplicates = createAction(
    '[Duplicates] Merge Duplicates',
    props<{
        duplicateId: string;
        targetId: string;
    }>(),
);

export const mergeDuplicatesSuccess = createAction(
    '[Duplicates] Merge Duplicates Success',
    props<{
        duplicateId: string;
        targetId: string;
    }>(),
);

export const mergeDuplicatesFailure = createAction(
    '[Duplicates] Merge Duplicates Failure',
    props<{
        duplicateId: string;
        targetId: string;
        error: Error;
    }>(),
);
