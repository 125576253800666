/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ReviewStatusType {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Inconclusive = 'Inconclusive',
  Warning = 'Warning',
  NotExecuted = 'NotExecuted'
}
