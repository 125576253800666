import { Injectable } from '@angular/core';
import { Event, KeyStatResult, Opportunity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class EventService extends BaseApiService {
    getEventById(eventId: string): Observable<Event> {
        return this.get<Event>(`event/${eventId}`);
    }

    getOpportunitiesById(eventId: string): Observable<Opportunity[]> {
        return this.get<Opportunity[]>(`event/${eventId}/opportunities`);
    }

    getKeyStats(eventId: string): Observable<KeyStatResult[]> {
        return this.get<KeyStatResult[]>(`event/${eventId}/keystats`);
    }
}
