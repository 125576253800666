import { Injectable } from '@angular/core';
import { FilterHandler } from '../../../../../../classes/filter-handler.class';
import {
    FilterOperator,
    Query,
    QueryExpression,
} from '@wdx/clmi/clmi-swagger-gen';
import { UPDATE_ITEM_CREATE_NEW_ARRAY } from '../../../../../../shared/helpers';
import {
    FILTER_QUERY_FAVOURITE,
    FILTER_HANDLER_FAVOURITE,
    FILTER_HANDLER_SEARCH,
    FILTER_QUERY_SEARCH,
} from '@wdx/clmi/api-services/models';

@Injectable()
export class QueryBuilderFilterHandlerService {
    hasFavourite = false;

    /**
     * This will update the filter query where the search has been clear. It keeps the search QueryExpression
     * but updates the values to ['']
     *
     * @param query | Query
     * @returns | QueryExpression[]
     */
    addEmptySearchValue(query: Query): QueryExpression[] {
        const QUERY = { ...query };
        const INDEX = this.findPropInExpressionGetItem(
            QUERY?.expressions,
            FILTER_QUERY_SEARCH,
        );

        if (INDEX >= 0) {
            QUERY.expressions = UPDATE_ITEM_CREATE_NEW_ARRAY<QueryExpression>(
                QUERY.expressions,
                INDEX,
                { values: [''] },
            );
        }

        return QUERY.expressions;
    }

    /**
     *
     * @param expressions | QueryExpression[]
     * @param findProp | string - The name of the property
     * @returns number - The index number of the property in the array
     */
    findPropInExpressionGetItem(
        expressions: QueryExpression[],
        findProp,
    ): number {
        return expressions?.findIndex((item) => item.field === findProp);
    }

    /**
     *
     * @param query | Query
     * @param key | string
     * @param queryData | { [key: string]: any }
     * @param updatedSearch | boolean
     * @returns | { query: Query; updatedSearch: boolean }
     */
    updateQueryData(
        query: Query,
        key: string,
        queryData,
        updatedSearch,
    ): { query: Query; updatedSearch: boolean } {
        if (key === 'sortDirection') {
            query.sortDirection = queryData[key];
        }

        if (key === 'sortBy') {
            query.sortBy = queryData[key];
        }

        if (key !== 'sortBy' && key !== 'sortDirection') {
            let field = key;
            let operator: FilterOperator;
            let values;

            if (key === FILTER_HANDLER_SEARCH) {
                field = FILTER_QUERY_SEARCH;
                operator = FilterOperator.Matches;
                updatedSearch = true;
            }

            if (key === FILTER_HANDLER_FAVOURITE) {
                field = FILTER_QUERY_FAVOURITE;
                operator = FilterOperator.Equals;
            }

            if (
                key !== FILTER_HANDLER_SEARCH &&
                key !== FILTER_HANDLER_FAVOURITE
            ) {
                operator = FilterOperator.Contains;
                values = queryData[key];
            }

            if (
                (key === FILTER_HANDLER_FAVOURITE &&
                    !this.hasFavourite &&
                    queryData[key]) ||
                key !== FILTER_HANDLER_FAVOURITE
            ) {
                query.expressions.push({
                    field,
                    operator,
                    values: values ? values : [queryData[key]],
                });
            }
        }

        return { query, updatedSearch };
    }

    /**
     * This would loop through the expressions and find the item where
     * the name matches findProp. The value at index 0 must be true
     *
     * @param expressions | QueryExpression[]
     * @param findProp  | { [key: string]: any }
     * @param filterHandler | FilterHandler
     * @returns boolean
     */
    findFavouriteInExpression(
        expressions: QueryExpression[],
        filterHandler: FilterHandler,
    ): boolean {
        const HAS_PROP = expressions?.find(
            (item) => item.field === FILTER_QUERY_FAVOURITE && item?.values[0],
        );

        this.hasFavourite = Boolean(HAS_PROP);
        filterHandler?.setIsFavourite(this.hasFavourite, false);

        return this.hasFavourite;
    }
}
