import { ActivityType } from '@wdx/clmi/clmi-swagger-gen';
import {
    EMAIL_FORM_ID,
    EXTERNAL_TASK_FORM_ID,
    GLIA_FORM_ID,
    MEETING_FORM_ID,
    NOTE_FORM_ID,
    PHONECALL_FORM_ID,
    SECURE_MESSAGE_FORM_ID,
    SMS_FORM_ID,
    TASK_FORM_ID,
    UNBLU_FORM_ID,
    WORK_ITEM_FORM_ID,
} from '@wdx/shared/utils';
import { ButtonType } from '../models/button-type.model';
import { Button } from '../models/button.model';
import { ICON_TASK } from './icons.constants';

export const ACTIVITY_FORM_ID_MAP = {
    [ActivityType.Appointment]: MEETING_FORM_ID,
    [ActivityType.Email]: EMAIL_FORM_ID,
    [ActivityType.ExternalTask]: EXTERNAL_TASK_FORM_ID,
    [ActivityType.Glia]: GLIA_FORM_ID,
    [ActivityType.Note]: NOTE_FORM_ID,
    [ActivityType.PhoneCall]: PHONECALL_FORM_ID,
    [ActivityType.Task]: TASK_FORM_ID,
    [ActivityType.Unblu]: UNBLU_FORM_ID,
    [ActivityType.WorkItem]: WORK_ITEM_FORM_ID,
    [ActivityType.SecureMessage]: SECURE_MESSAGE_FORM_ID,
    [ActivityType.Sms]: SMS_FORM_ID,
};

export const ACTIVITY_STATUS_OPEN = 'Open';
export const ACTIVITY_STATUS_CANCELLED = 'Cancelled';
export const ACTIVITY_STATUS_COMPLETED = 'Completed';

export const TASK_BUTTON: Button = {
    label: 'Task',
    cySelector: 'btn-task',
    icon: ICON_TASK.icon,
    type: ButtonType.icon,
    formSetup: {
        formId: TASK_FORM_ID,
        initialisationParams: {},
    },
    tooltip: 'Create a task',
};
