/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormFieldType {
  Text = 'Text',
  MultilineText = 'MultilineText',
  RichText = 'RichText',
  Json = 'Json',
  DateTime = 'DateTime',
  Date = 'Date',
  Time = 'Time',
  Duration = 'Duration',
  DateRange = 'DateRange',
  DateTimeRange = 'DateTimeRange',
  Select = 'Select',
  Multiselect = 'Multiselect',
  TextDropdown = 'TextDropdown',
  Boolean = 'Boolean',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Currency = 'Currency',
  LookupSingle = 'LookupSingle',
  LookupMulti = 'LookupMulti',
  DocumentLookup = 'DocumentLookup',
  ContactPointLookup = 'ContactPointLookup',
  UrlLookup = 'UrlLookup',
  Hierarchical = 'Hierarchical',
  ImageUpload = 'ImageUpload',
  DocumentUpload = 'DocumentUpload',
  Array = 'Array',
  Address = 'Address',
  PhoneNumber = 'PhoneNumber',
  EmailAddress = 'EmailAddress',
  TagListSingle = 'TagListSingle',
  TagListMulti = 'TagListMulti',
  Password = 'Password',
  Identifier = 'Identifier'
}
