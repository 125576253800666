import { inject, Injectable } from '@angular/core';
import {
    Comment,
    CommentCreate,
    CommentQueryResult,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { CommentUrlSegment } from './comment-url-segments';
import { BaseApiService } from '../base-api.service';
import { ApiPaginationService } from '@wdx/shared/infrastructure/api-service';

@Injectable()
export class CommentService extends BaseApiService {
    private apiPaginationService = inject(ApiPaginationService);

    getComments(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<CommentQueryResult> {
        return this.get<CommentQueryResult>(
            `${
                CommentUrlSegment.Comment
            }/${entityType.toLowerCase()}/${entityId}`,
            {
                headers: this.apiPaginationService.mapPaginationOptions({
                    pageSize: 200,
                }),
            },
        );
    }

    createComment(
        entityType: SystemEntity,
        entityId: string,
        comment: CommentCreate,
    ) {
        return this.post<Comment, CommentCreate>(
            `${
                CommentUrlSegment.Comment
            }/${entityType.toLowerCase()}/${entityId}`,
            comment,
        );
    }
}
