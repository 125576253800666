/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ClientLifecycleStage {
  Target = 'Target',
  Engaged = 'Engaged',
  Pitching = 'Pitching',
  Disqualified = 'Disqualified',
  Onboarding = 'Onboarding',
  Client = 'Client',
  PotentiallyWithoutContact = 'PotentiallyWithoutContact',
  WithoutContact = 'WithoutContact',
  Dormant = 'Dormant',
  Unwinding = 'Unwinding',
  Switched = 'Switched',
  PendingDeletion = 'PendingDeletion',
  Left = 'Left'
}
