import { createAction, props } from '@ngrx/store';
import {
    Document,
    RiskAssessmentAdviser,
    RiskAssessmentClient,
    RiskAssessmentSnapshot,
    RiskLevel,
} from '@wdx/clmi/clmi-swagger-gen';
import { OxfordRiskReportType } from '@wdx/shared/utils';

export const createRiskAssessmentClient = createAction(
    '[Oxford Risk] Create Risk Assessment Client',
    props<{
        partyId: string;
    }>(),
);
export const createRiskAssessmentClientSuccess = createAction(
    '[Oxford Risk] Create Risk Assessment Client Success',
    props<{
        partyId: string;
        riskAssessmentClient: RiskAssessmentClient;
    }>(),
);
export const createRiskAssessmentClientFailure = createAction(
    '[Oxford Risk] Create Risk Assessment Client Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);

export const getRiskAssessmentClient = createAction(
    '[Oxford Risk] Get Risk Assessment Client',
    props<{
        partyId: string;
    }>(),
);
export const getRiskAssessmentClientSuccess = createAction(
    '[Oxford Risk] Get Risk Assessment Client Success',
    props<{
        partyId: string;
        riskAssessmentClient: RiskAssessmentClient;
    }>(),
);
export const getRiskAssessmentClientNotFound = createAction(
    '[Oxford Risk] Get Risk Assessment Client Not Found',
    props<{
        partyId: string;
    }>(),
);
export const getRiskAssessmentClientFailure = createAction(
    '[Oxford Risk] Get Risk Assessment Client Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);

export const getRiskAssessmentAdvisers = createAction(
    '[Oxford Risk] Get Risk Assessment Advisers',
);
export const getRiskAssessmentAdvisersSuccess = createAction(
    '[Oxford Risk] Get Risk Assessment Advisers Success',
    props<{
        riskAssessmentAdvisers: RiskAssessmentAdviser[];
    }>(),
);
export const getRiskAssessmentAdvisersFailure = createAction(
    '[Oxford Risk] Get Risk Assessment Advisers Failure',
    props<{
        error: Error;
    }>(),
);

export const getLatestAssessmentForParty = createAction(
    '[Oxford Risk] Get Latest Assessment For Party',
    props<{
        partyId: string;
    }>(),
);
export const getLatestAssessmentForPartySuccess = createAction(
    '[Oxford Risk] Get Latest Assessment For Party Success',
    props<{
        partyId: string;
        riskAssessment: RiskAssessmentSnapshot[];
    }>(),
);
export const getLatestAssessmentForPartyFailure = createAction(
    '[Oxford Risk] Get Latest Assessment For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);

export const getLatestReportForParty = createAction(
    '[Oxford Risk] Get Latest Report For Party',
    props<{
        partyId: string;
        reportType: OxfordRiskReportType;
        snapshotId?: string;
    }>(),
);
export const getLatestReportForPartySuccess = createAction(
    '[Oxford Risk] Get Latest Report For Party Success',
    props<{
        partyId: string;
        reportType: OxfordRiskReportType;
        snapshotId?: string;
        report: Document;
    }>(),
);
export const getLatestReportForPartyFailure = createAction(
    '[Oxford Risk] Get Latest Report For Party Failure',
    props<{
        partyId: string;
        reportType: OxfordRiskReportType;
        snapshotId?: string;
        error: Error;
    }>(),
);

export const setRiskAssessmentClientRiskLevelForParty = createAction(
    '[Oxford Risk] Set Risk Assessment Client Risk Level For Party',
    props<{
        partyId: string;
        riskLevel: RiskLevel;
    }>(),
);
export const setRiskAssessmentClientRiskLevelForPartySuccess = createAction(
    '[Oxford Risk] Set Risk Assessment Client Risk Level For Party Success',
    props<{
        partyId: string;
        riskLevel: RiskLevel;
    }>(),
);
export const setRiskAssessmentClientRiskLevelForPartyFailure = createAction(
    '[Oxford Risk] Set Risk Assessment Client Risk Level For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>(),
);
