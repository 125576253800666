import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity } from '@wdx/clmi/clmi-swagger-gen';
import { Paging } from '@wdx/clmi/api-services/models';
import { BaseWidgetClass } from '../../../classes/base-widget.class';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { INFINITY_SCROLL_CONTENT } from '../../../constants/inifinity-scroll-content.contants';
import { ActionButton } from '../../../models/action-button.model';
import { CardType } from '../../../models/card-type.model';
import { ScrollMode } from '../../../models/scroll-mode.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'widget-activities-list',
    templateUrl: './widget-activities-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block' },
})
export class WidgetActivitiesListComponent
    extends BaseWidgetClass
    implements OnInit
{
    @Input() activities: Activity[];
    @Input() activitiesPerRow: number;
    @Input() activitiesPaging: Paging;
    @Input() allActivitiesModalTitle = 'All Activities';
    @Input() filterHandler: FilterHandler;
    @Input() activityCreateActionButton: ActionButton;
    @Input() showActivitiesListActionsButtons: boolean;
    @Input() totalRecordCount: number;
    @Input() scrollMode: ScrollMode;

    @Output() infinityScrollFired = new EventEmitter<INFINITY_SCROLL_CONTENT>();

    cardType: CardType = CardType.Activity;
    limit = 3;
    modalId: string;

    ngOnInit() {
        this.modalId = `all-${this.cardType}-cards`;
        this.spokeButton.modalId = this.modalId;
        this.spokeButton.isHidden =
            !this.activities?.length ||
            (this.limit > 0 && this.activities?.length <= this.limit);
    }

    onModalClosed() {
        this.filterHandler.reset();
    }

    onInfinityScrollFired() {
        this.infinityScrollFired.emit(INFINITY_SCROLL_CONTENT.Activities);
    }
}
