import { createAction, props } from '@ngrx/store';
import {
    ActivityComment,
    ActivityCommentCreate,
    User,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const create = createAction(
    '[Comments] Create',
    props<{
        activityId: string;
        commentCreateData: ActivityCommentCreate;
    }>(),
);

export const createSuccess = createAction(
    '[Comments] Create Success',
    props<{
        activityId: string;
        comment: ActivityComment;
        resolveIssueId?: string;
    }>(),
);

export const createFailure = createAction(
    '[Comments] Create Failure',
    props<{
        activityId: string;
        error: Error;
    }>(),
);

export const getPage = createAction(
    '[Comments] Get Page',
    props<{
        activityId: string;
        reset?: boolean;
        page?: number;
        pageSize: number;
    }>(),
);

export const getPageSuccess = createAction(
    '[Comments] Get Page Success',
    props<{
        activityId: string;
        comments: PaginatedApiResponse<ActivityComment>;
        reset: boolean;
    }>(),
);

export const getPageFailure = createAction(
    '[Comments] Get Page Failure',
    props<{
        activityId: string;
        error: Error;
    }>(),
);

export const getCommentsSilently = createAction(
    '[Comments] Get Page Silently',
    props<{
        activityId: string;
    }>(),
);

export const getCommentsSilentlySuccess = createAction(
    '[Comments] Get Page Silently Success',
    props<{
        activityId: string;
        comments: PaginatedApiResponse<ActivityComment>;
    }>(),
);

export const updateCommentData = createAction(
    '[Comments] update issue to resolved',
    props<{
        activityId: string;
        me: User;
    }>(),
);
