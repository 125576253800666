import { createAction, props } from '@ngrx/store';
import {
    Document,
    FileIndex,
    Regarding,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getDocumentsForClientProduct = createAction(
    '[Documents] Get Documents For Client Product',
    props<{
        clientProductId: string;
        reset: boolean;
    }>(),
);

export const getDocumentsForClientProductSuccess = createAction(
    '[Documents] Get Documents For Client Product Success',
    props<{
        clientProductDocuments: PaginatedApiResponse<Document>;
        clientProductId: string;
    }>(),
);

export const getDocumentsForClientProductFailure = createAction(
    '[Documents] Get Documents For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getDocumentsForEntityId = createAction(
    '[Documents" Get Documents For Entity Id',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);

export const getDocumentsForEntityIdSuccess = createAction(
    '[Documents" Get Documents For Entity Id Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        documents: Document[];
    }>(),
);

export const getDocumentsForEntityIdFailure = createAction(
    '[Documents" Get Documents For Entity Id Failure',
    props<{
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>(),
);

export const deleteDocument = createAction(
    '[Documents] Delete Document',
    props<{
        documentId: string;
    }>(),
);

export const deleteDocumentSuccess = createAction(
    '[Documents] Delete Document Success',
    props<{
        documentId: string;
    }>(),
);

export const deleteDocumentFailure = createAction(
    '[Documents] Delete Document Failure',
    props<{
        documentId: string;
        error: Error;
    }>(),
);

export const dissociateDocument = createAction(
    '[Documents] Dissociate',
    props<{
        id: string;
        regarding: Regarding[];
    }>(),
);

export const dissociateDocumentSuccess = createAction(
    '[Documents] Dissociate Success',
    props<{
        id: string;
    }>(),
);

export const dissociateDocumentFailure = createAction(
    '[Documents] Dissociate Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);

export const uploadDocument = createAction(
    '[Documents] Upload Document',
    props<{
        id: string;
        entityId: string;
        entityType: SystemEntity;
        file: File;
    }>(),
);

export const uploadDocumentComplete = createAction(
    '[Documents] Upload Document Complete',
    props<{
        id: string;
        entityId: string;
        fileIndex?: FileIndex;
    }>(),
);

export const uploadDocumentFailure = createAction(
    '[Documents] Upload Document Failure',
    props<{
        id: string;
        entityId: string;
        error: string;
    }>(),
);
