import { Action, createReducer, on } from '@ngrx/store';
import {
    AccessVisibility,
    ActivityTask,
    PartyRole,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as partyRoleActions from './party-role.actions';

export interface State {
    visibility?: CrudStateObject<AccessVisibility>;
    tasks: CrudStateObject<ActivityTask>;
    partyRoles: CrudStateObject<PartyRole>;
}

export const initialState: State = {
    visibility: {},
    tasks: {},
    partyRoles: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        partyRoleActions.getForId,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...state.partyRoles,
                [props.partyRoleId]: {
                    ...(state.partyRoles[props.partyRoleId] ||
                        ({} as CrudState<PartyRole>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...state.partyRoles,
                [props.partyRoleId]: {
                    ...state.partyRoles[props.partyRoleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.partyRole,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...state.partyRoles,
                [props.partyRoleId]: {
                    ...state.partyRoles[props.partyRoleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: {} as PartyRole,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getVisibilityForId,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...(state.visibility[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getVisibilityForIdSuccess,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getVisibilityForIdFailure,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
    on(
        partyRoleActions.getAllTasksForPartyRole,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyRoleId]: {
                    ...(state.tasks[props.partyRoleId] ||
                        ({} as CrudState<ActivityTask>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: state.tasks[props.partyRoleId]?.infinity,
                },
            },
        }),
    ),

    on(
        partyRoleActions.getAllTasksForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyRoleId]: {
                    ...state.tasks[props.partyRoleId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.tasks.paging,
                        combinedList: [
                            ...(state.tasks[props.partyRoleId].infinity
                                ?.combinedList || []),
                            ...props.tasks.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        partyRoleActions.getAllTasksForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyRoleId]: {
                    ...state.tasks[props.partyRoleId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
