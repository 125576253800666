import { Action, createReducer, on } from '@ngrx/store';
import { PartyRoleRelationship } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as professionalConnectionsActions from './professional-connections.actions';

export interface State {
    professionalConnections: CrudStateObject<PartyRoleRelationship>;
}

export const initialState: State = {
    professionalConnections: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        professionalConnectionsActions.getForParty,
        (state, props): State => ({
            ...state,
            professionalConnections: {
                ...state.professionalConnections,
                [props.partyId]: {
                    ...(state.professionalConnections[props.partyId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        professionalConnectionsActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            professionalConnections: {
                ...state.professionalConnections,
                [props.partyId]: {
                    ...state.professionalConnections[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.relationships,
                },
            },
        }),
    ),

    on(
        professionalConnectionsActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            professionalConnections: {
                ...state.professionalConnections,
                [props.partyId]: {
                    ...state.professionalConnections[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
