import { createAction, props } from '@ngrx/store';
import {
    DocumentRequirementType,
    StatusType,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getAll = createAction('[Document Requirement Type] Get All');

export const getAllSuccess = createAction(
    '[Document Requirement Type] Get All Success',
    props<{
        documentRequirementTypes: DocumentRequirementType[];
    }>(),
);

export const getAllFailure = createAction(
    '[Document Requirement Type] Get All Failure',
    props<{
        error: Error;
    }>(),
);

export const searchDocumentRequirementTypes = createAction(
    '[Document Requirement Type] Search Requirement Types',
    props<{
        reset?: boolean;
        filter: any;
    }>(),
);

export const searchDocumentRequirementTypesSuccess = createAction(
    '[Document Requirement Type] Search Requirement Types Success',
    props<{
        documentRequirementTypes: PaginatedApiResponse<DocumentRequirementType>;
    }>(),
);

export const searchDocumentRequirementTypesFailure = createAction(
    '[Document Requirement Type] Search Requirement Types Failure',
    props<{ error: Error }>(),
);

export const toggleStatus = createAction(
    '[Document Requirement Type] Toggle Status',
    props<{
        id: string;
        newValue?: boolean;
    }>(),
);

export const activateDocumentRequirementType = createAction(
    '[Document Requirement Type] Activate Requirement Types',
    props<{
        id: string;
        status?: StatusType;
    }>(),
);

export const activateDocumentRequirementTypeSuccess = createAction(
    '[Document Requirement Type] Activate Requirement Types Success',
    props<{
        id: string;
        status?: StatusType;
    }>(),
);

export const activateDocumentRequirementTypeFailure = createAction(
    '[Document Requirement Type] Activate Requirement Types Failure',
    props<{
        error: Error;
    }>(),
);

export const inactivateDocumentRequirementType = createAction(
    '[Document Requirement Type] Inactivate Requirement Types',
    props<{
        id: string;
        status?: StatusType.Inactive;
    }>(),
);

export const inactivateDocumentRequirementTypeSuccess = createAction(
    '[Document Requirement Type] Inactivate Requirement Types Success',
    props<{
        id: string;
        status?: StatusType.Inactive;
    }>(),
);

export const inactivateDocumentRequirementTypeFailure = createAction(
    '[Document Requirement Type] Inactivate Requirement Types Failure',
    props<{
        error: Error;
    }>(),
);

export const deleteDocumentRequirementType = createAction(
    '[Document Requirement Type] Delete Requirement Types',
    props<{
        id: string;
    }>(),
);

export const deleteDocumentRequirementTypeSuccess = createAction(
    '[Document Requirement Type] Delete Requirement Types Success',
    props<{
        id: string;
    }>(),
);

export const deleteDocumentRequirementTypeFailure = createAction(
    '[Document Requirement Type] Delete Requirement Types Failure',
    props<{
        id: string;
        error: Error;
    }>(),
);
