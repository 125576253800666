import { Action, createReducer, on } from '@ngrx/store';
import { Notification, NotificationStatus } from '@wdx/clmi/clmi-swagger-gen';
import {
    CrudStatus,
    CrudStatusRecord,
    PaginatedApiResponse,
    setCrudStatus,
} from '@wdx/shared/utils';
import * as notificationsActions from './notifications.actions';

export interface State {
    notifications?: CrudStatusRecord<PaginatedApiResponse<Notification>>;
    paginatedViewResponse?: CrudStatusRecord<
        PaginatedApiResponse<Notification>
    >;
    unreadNotifications?: number;
}

export const initialState: State = {
    notifications: {
        ...setCrudStatus(CrudStatus.Initial),
    },
    paginatedViewResponse: {
        ...setCrudStatus(CrudStatus.Initial),
    },
    unreadNotifications: 0,
};

const reducerSetup = createReducer(
    initialState,

    on(
        notificationsActions.getPage,
        (state): State => ({
            ...state,
            notifications: {
                ...(state.notifications || {}),
                ...setCrudStatus(CrudStatus.Loading),
            },
        }),
    ),

    on(
        notificationsActions.getPageSuccess,
        (state, props): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Success),
                record: {
                    paging: props.notifications.paging,
                    results: [
                        ...(props.reset
                            ? []
                            : state.notifications?.record?.results || []),
                        ...props.notifications.results,
                    ],
                },
            },
        }),
    ),

    on(
        notificationsActions.getPageFailure,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Error),
            },
        }),
    ),

    on(
        notificationsActions.setUnreadNotifications,
        (state, props): State => ({
            ...state,
            unreadNotifications: props.unreadNotifications,
        }),
    ),

    on(
        notificationsActions.getForView,
        (state): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                ...setCrudStatus(CrudStatus.Loading),
            },
        }),
    ),

    on(
        notificationsActions.getForViewSuccess,
        (state, props): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                ...setCrudStatus(CrudStatus.Success),
                record: {
                    ...(state.paginatedViewResponse.record ?? {}),
                    paging: props.payload.paging,
                    results: props.payload.results,
                },
            },
        }),
    ),

    on(
        notificationsActions.getForViewFailure,
        (state): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                ...setCrudStatus(CrudStatus.Error),
            },
        }),
    ),

    on(notificationsActions.markAsRead, (state, { notifications }): State => {
        const notificationsResults = markNotificationsAsRead(
            notifications,
            state.notifications.record?.results,
        );
        const paginatedViewResponseResults = markNotificationsAsRead(
            notifications,
            state.paginatedViewResponse?.record?.results,
        );
        const uniqueIdsMarkedAsRead = [
            ...new Set([
                ...notificationsResults.idsMarkedAsRead,
                ...paginatedViewResponseResults.idsMarkedAsRead,
            ]),
        ];
        const unreadNotifications = Math.max(
            state.unreadNotifications - uniqueIdsMarkedAsRead.length,
            0,
        );
        return {
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Updating),
                record: {
                    ...(state.notifications?.record ?? {}),
                    results: notificationsResults.notifications,
                    paging: state.notifications?.record?.paging,
                },
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Updating),
                record: {
                    ...(state.paginatedViewResponse?.record || {}),
                    results: paginatedViewResponseResults.notifications,
                    paging: state.paginatedViewResponse?.record?.paging,
                },
            },
            unreadNotifications,
        };
    }),

    on(
        notificationsActions.markAsReadSuccess,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Success),
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Success),
            },
        }),
    ),

    on(
        notificationsActions.markAsReadFailure,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Error),
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Error),
            },
        }),
    ),

    on(
        notificationsActions.markAllAsRead,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Updating),
                record: {
                    ...(state.notifications?.record ?? {}),
                    results:
                        markNotificationsAsRead(
                            state.notifications?.record?.results,
                            state.notifications?.record?.results,
                        )?.notifications || [],
                    paging: state.notifications?.record?.paging,
                },
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Updating),
                record: {
                    ...(state.paginatedViewResponse?.record ?? {}),
                    results:
                        markNotificationsAsRead(
                            state.paginatedViewResponse?.record?.results,
                            state.paginatedViewResponse?.record?.results,
                        )?.notifications || [],
                    paging: state.paginatedViewResponse?.record?.paging,
                },
            },
            unreadNotifications: 0,
        }),
    ),

    on(
        notificationsActions.markAllAsReadSuccess,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Success),
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Success),
            },
        }),
    ),

    on(
        notificationsActions.markAllAsReadFailure,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                ...setCrudStatus(CrudStatus.Error),
            },
            paginatedViewResponse: {
                ...state.paginatedViewResponse,
                ...setCrudStatus(CrudStatus.Error),
            },
        }),
    ),
);

export function markNotificationsAsRead(
    markAsRead: Notification[],
    state: Notification[],
): { notifications: Notification[]; idsMarkedAsRead: string[] } {
    const ids =
        markAsRead?.length && markAsRead.map((notification) => notification.id);
    const notifications: Notification[] = [...(state || [])];
    const idsMarkedAsRead: string[] = [];
    if (ids?.length && notifications?.length) {
        ids.forEach((id) => {
            const index = notifications.findIndex(
                (stateNotif) => stateNotif.id === id,
            );
            if (index > -1) {
                const notification = notifications[index];
                if (notification.status !== NotificationStatus.Read) {
                    notifications[index] = {
                        ...notification,
                        status: NotificationStatus.Read,
                    };
                    if (!idsMarkedAsRead.includes(id)) {
                        idsMarkedAsRead.push(id);
                    }
                }
            }
        });
    }
    return { notifications, idsMarkedAsRead };
}

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
