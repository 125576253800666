import { Component, inject, Input, OnInit, Type } from '@angular/core';
import {
    WdxAuditInfo,
    WdxAuditTimeline,
    WdxAuditType,
    WdxAuditValue,
} from '@wdx/shared/components/wdx-audit';
import {
    CrudStatus,
    DEFAULT_INPUT_DEBOUNCE_DELAY,
    FieldDefinition,
    FormDataHistory,
    FormDataResult,
    FormDefinition,
    FormElementLayoutDefinition,
    FormElementType,
    FormFieldType,
    FormSectionLayoutDefinition,
    PartyStub,
    SummaryLevel,
    TranslationsService,
    WdxDateFormat,
    WdxDateTimeService,
    WdxDestroyClass,
    WdxThemeColor,
} from '@wdx/shared/utils';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    defaultIfEmpty,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { FormHistoryInstanceFacade } from '../../../+state/form-history-instance/form-history-instance.facade';
import { FormHistoryService } from '../../../+state/form-history/form-history.service';
import {
    IFormDynamicData,
    IWrappedFormComponentProvider,
} from '../../../interfaces';
import { FormSummaryService } from '../../../services';

@Component({
    selector: 'wdx-ff-form-history',
    templateUrl: './form-framework-form-history.component.html',
    providers: [FormHistoryService],
})
export class FormFrameworkFormHistoryComponent
    extends WdxDestroyClass
    implements OnInit
{
    @Input() title!: string;
    @Input() formId!: string;
    @Input() entityId!: string;

    formHistory$!: Observable<FormDataHistory[]>;

    before$!: Observable<FormDataResult | null>;
    beforeId$ = new BehaviorSubject<string | undefined>(undefined);
    beforeStatus$!: Observable<CrudStatus | null>;

    after$!: Observable<FormDataResult | null>;
    afterId$ = new BehaviorSubject<string | undefined>(undefined);
    afterStatus$!: Observable<CrudStatus | null>;

    formDefinition$!: Observable<{
        definition: FormDefinition;
        layoutAndDefinitions: FormSectionLayoutDefinition[];
    }>;

    lastUpdated$!: Observable<string>;
    timeline$!: Observable<WdxAuditTimeline[]>;
    info$!: Observable<WdxAuditInfo[]>;
    status$!: Observable<CrudStatus>;
    avatar$!: Observable<{
        component: Type<any>;
        inputs?: Record<string, unknown>;
    }>;

    private dynamicDataService = inject(IFormDynamicData);
    private formHistoryService = inject(FormHistoryService);
    private formHistoryInstanceFacade = inject(FormHistoryInstanceFacade);
    private formSummaryService = inject(FormSummaryService);
    private dateTimeService = inject(WdxDateTimeService);
    private translationsService = inject(TranslationsService);
    private componentProvider = inject(IWrappedFormComponentProvider);

    ngOnInit(): void {
        this.setFormDefinition();
        this.setFormHistory();
        this.setLastUpdated();
        this.setBefore();
        this.setAfter();
        this.setTimeline();
        this.setInfo();
        this.setStatus();
        this.setAvatar();
    }

    private setFormDefinition(): void {
        this.formDefinition$ = this.dynamicDataService
            .getFormLayoutAndDefinition(this.formId)
            .pipe(takeUntil(this.destroyed$));
    }

    private setFormHistory(): void {
        this.formHistory$ = this.formHistoryService
            .getHistory(this.formId, this.entityId)
            .pipe(
                tap((formHistory) => {
                    if (formHistory?.length) {
                        const afterId = formHistory[0].id;
                        if (afterId) {
                            this.afterId$.next(afterId);
                        }
                    }
                }),
                shareReplay(1),
                takeUntil(this.destroyed$),
            );
    }

    private setLastUpdated(): void {
        this.lastUpdated$ = this.formHistory$.pipe(
            filter((formHistory) => Boolean(formHistory?.length)),
            map((formHistory) =>
                formHistory?.length
                    ? `Last update: ${this.dateTimeService.convertDateToViewFriendlyFormat(
                          formHistory[0].updatedOn || '',
                          {
                              format: WdxDateFormat.AbsoluteDateTime,
                          },
                      )}`
                    : '',
            ),
            takeUntil(this.destroyed$),
        );
    }

    private setBefore(): void {
        this.before$ = this.beforeId$.pipe(
            filter(Boolean),
            switchMap((instanceId) => {
                return this.formHistoryInstanceFacade
                    .formHistoryInstance$(
                        this.formId,
                        this.entityId,
                        instanceId,
                    )
                    .pipe(
                        switchMap((before) => {
                            if (!before) {
                                this.formHistoryInstanceFacade.loadFormHistoryInstance(
                                    this.formId,
                                    this.entityId,
                                    instanceId,
                                );
                            }
                            return this.formHistoryInstanceFacade.formHistoryInstance$(
                                this.formId,
                                this.entityId,
                                instanceId,
                            );
                        }),
                    );
            }),
            takeUntil(this.destroyed$),
        );
        this.beforeStatus$ = this.beforeId$.pipe(
            filter((instanceId) => Boolean(instanceId)),
            switchMap((instanceId) =>
                this.formHistoryInstanceFacade.status$(
                    this.formId,
                    this.entityId,
                    instanceId || '',
                ),
            ),
            startWith(CrudStatus.Initial),
        );
    }

    private setAfter(): void {
        this.after$ = combineLatest([this.formHistory$, this.afterId$]).pipe(
            filter(([formHistory, instanceId]) =>
                Boolean(formHistory?.length && instanceId),
            ),
            switchMap(([formHistory, instanceId]) => {
                // set before as the interface preceding the after
                // (to be improved with explicit setting of before / after by the user)
                const afterHistoryIndex = formHistory.findIndex(
                    (history) => history.id === instanceId,
                );

                // if the selected 'after' value is the last in the array, then it was
                // chronologically the first entry, so set the 'before' value to be the
                // same index
                // otherwise, the 'before' value is the value after the 'after' value
                const beforeHistoryIndex =
                    afterHistoryIndex < formHistory.length - 1
                        ? afterHistoryIndex + 1
                        : afterHistoryIndex;
                const beforeInstanceId =
                    beforeHistoryIndex !== undefined &&
                    formHistory[beforeHistoryIndex].id;
                if (beforeInstanceId) {
                    this.beforeId$.next(beforeInstanceId);
                }

                return instanceId
                    ? this.formHistoryInstanceFacade
                          .formHistoryInstance$(
                              this.formId,
                              this.entityId,
                              instanceId,
                          )
                          .pipe(
                              switchMap((after) => {
                                  if (!after) {
                                      this.formHistoryInstanceFacade.loadFormHistoryInstance(
                                          this.formId,
                                          this.entityId,
                                          instanceId,
                                      );
                                  }
                                  return this.formHistoryInstanceFacade.formHistoryInstance$(
                                      this.formId,
                                      this.entityId,
                                      instanceId,
                                  );
                              }),
                          )
                    : of(null);
            }),
            takeUntil(this.destroyed$),
        );
        this.afterStatus$ = this.afterId$.pipe(
            filter((instanceId) => Boolean(instanceId)),
            switchMap((instanceId) =>
                this.formHistoryInstanceFacade.status$(
                    this.formId,
                    this.entityId,
                    instanceId || '',
                ),
            ),
            startWith(CrudStatus.Initial),
        );
    }

    private setAvatar(): void {
        this.avatar$ = this.after$.pipe(
            filter((after) => Boolean(after?.lastUpdatedByParty)),
            switchMap((after) =>
                this.componentProvider.getAvatarComponent$(
                    after?.lastUpdatedByParty as PartyStub,
                ),
            ),
            shareReplay(1),
            takeUntil(this.destroyed$),
        );
    }

    private setTimeline(): void {
        this.timeline$ = this.formHistory$.pipe(
            map((formHistory) => {
                if (!formHistory?.length) {
                    return [];
                }
                return formHistory.map(
                    (history) =>
                        ({
                            ...(history.updatedOn
                                ? {
                                      x: new Date(history.updatedOn).getTime(),
                                      date: this.dateTimeService.convertDateToViewFriendlyFormat(
                                          history.updatedOn,
                                          {
                                              format: WdxDateFormat.AbsoluteDateTime,
                                          },
                                      ),
                                  }
                                : {
                                      x: 0,
                                      date: '',
                                  }),
                            x: history.updatedOn
                                ? new Date(history.updatedOn).getTime()
                                : 0,
                            name: history.id,
                            label: history.updatedBy,
                        }) as WdxAuditTimeline,
                );
            }),
            takeUntil(this.destroyed$),
        );
    }

    /**
     * Combine the before and after form histories with the form definition
     * to create a WdxAuditInfo array that accurately reflects the base form
     * through it's layout and schema
     */
    private setInfo(): void {
        this.info$ = combineLatest([
            this.before$,
            this.after$,
            this.formDefinition$,
        ]).pipe(
            debounceTime(DEFAULT_INPUT_DEBOUNCE_DELAY),
            // Combine the before, after, and formDefinition observables
            switchMap(([before, after, { definition, layoutAndDefinitions }]) =>
                // Get the form details for after first (as before is set following after's assignment)
                this.formValues$(after, definition, layoutAndDefinitions).pipe(
                    // Join all of the after value observables together and then map them to a WdxAuditInfo array
                    switchMap((afterFormValues$) =>
                        forkJoin(afterFormValues$).pipe(
                            defaultIfEmpty({}),
                            map((afterFormValues: Record<string, any>) =>
                                this.mapValuesToInfoLayout(afterFormValues),
                            ),
                            switchMap((afterFormValues) =>
                                // Do the same as above for before
                                this.formValues$(
                                    before,
                                    definition,
                                    layoutAndDefinitions,
                                ).pipe(
                                    switchMap((beforeFormValues$) =>
                                        forkJoin(beforeFormValues$).pipe(
                                            defaultIfEmpty({}),
                                            map(
                                                (
                                                    beforeFormValues: Record<
                                                        string,
                                                        any
                                                    >,
                                                ) =>
                                                    this.mapValuesToInfoLayout(
                                                        beforeFormValues,
                                                    ),
                                            ),
                                            map((beforeFormValues) =>
                                                this.mapInfo(
                                                    definition,
                                                    beforeFormValues,
                                                    afterFormValues,
                                                    after?.lastUpdatedOn,
                                                ),
                                            ),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
            // If the info array only has one child, then raise it up to the top level to reflect
            // the regular form behaviour
            map((info) =>
                info?.length === 1 &&
                info[0].children &&
                info[0].type === WdxAuditType.Section
                    ? info[0].children
                    : info,
            ),
            takeUntil(this.destroyed$),
        );
    }

    /**
     * Returns an observable of a Record that maps the result from formDataToValues
     */
    private formValues$(
        formDataResult: FormDataResult | null,
        definition: FormDefinition,
        layoutAndDefinitions: FormSectionLayoutDefinition[],
    ): Observable<Record<string, Observable<any>>> {
        return this.formSummaryService
            .getInitialisationFunctions(this.formId, definition, formDataResult)
            .pipe(
                map(({ formData, formFunctionResults }) =>
                    this.formDataToValues(
                        this.formSummaryService.getLayoutAndDefinitionsWithConditions(
                            definition,
                            layoutAndDefinitions,
                            formFunctionResults,
                            formData,
                            {},
                        ),
                        definition.schema as FieldDefinition[],
                        formData?.data,
                    ),
                ),
            );
    }

    /**
     * Maps form values from the formData to the field names, based on those that appear in the layout,
     * and are not hidden by either the layout or the form schema
     */
    private formDataToValues(
        layoutDefinitions: {
            layoutAndDefinitionsWithConditions: any[];
            subFormData: any;
        },
        schema: FieldDefinition[],
        formData: Record<string, any>,
    ): Record<string, Observable<any>> {
        const layout = layoutDefinitions.layoutAndDefinitionsWithConditions;
        let values = {};
        const recordStringValuesForElement = (
            element: FormElementLayoutDefinition,
            section: FormSectionLayoutDefinition,
            schema: FieldDefinition[],
            form: Record<string, any>,
            parentData?: Record<string, any>,
            parentIndex?: number,
            isSubForm?: boolean,
            prefix?: string,
        ) => {
            const name = element.name;
            let elementData = undefined;
            if (form) {
                if (form[name] !== undefined) {
                    elementData = form[name];
                }
            }
            const schemaData = schema?.find(
                (schemaValue: FieldDefinition) => schemaValue.name === name,
            ) as FieldDefinition;
            const isHidden =
                (element && element.isHidden) ||
                (elementData && elementData.isHidden);
            const key = `${prefix ? prefix + '.' : ''}${section.name}${
                parentIndex !== undefined && isSubForm
                    ? '{' + parentIndex + '}'
                    : ''
            }.${name}`;
            const setValues = (value: any, suffix?: string) => {
                values = {
                    ...values,
                    [`${key}${suffix ?? ''}`]: this.formSummaryService
                        .getStringValue(
                            value,
                            schemaData,
                            {
                                entityId: this.entityId,
                                formData: form,
                            },
                            parentData,
                            schema,
                            true,
                        )
                        .pipe(take(1)),
                };
            };
            if (
                schemaData?.fieldType === FormFieldType.Array &&
                schemaData?.childSchema &&
                elementData !== undefined
            ) {
                // Record subform
                (Array.isArray(elementData)
                    ? elementData
                    : [elementData]
                ).forEach((subForm: Record<string, any>, i) =>
                    recordStringValuesForElement(
                        element,
                        section,
                        schemaData.childSchema as FieldDefinition[],
                        subForm,
                        schemaData,
                        i,
                        false,
                    ),
                );
            } else if (elementData !== undefined && schemaData && !isHidden) {
                if (Array.isArray(elementData)) {
                    elementData.forEach((data, i) => setValues(data, `[${i}]`));
                } else {
                    setValues(elementData);
                }
            }
            if (element.sectionLayoutDefinitions?.length) {
                // Record sections
                element.sectionLayoutDefinitions.forEach(
                    (sectionLayoutDefinition) =>
                        !sectionLayoutDefinition.isHidden &&
                        sectionLayoutDefinition?.elementLayoutDefinitions?.forEach(
                            (element) =>
                                recordStringValuesForElement(
                                    element,
                                    sectionLayoutDefinition,
                                    schema,
                                    form,
                                    schema,
                                    parentIndex,
                                    true,
                                    key,
                                ),
                        ),
                );
            }
        };
        layout.forEach(
            (section: FormSectionLayoutDefinition) =>
                !section.isHidden &&
                section.elementLayoutDefinitions?.forEach((element) =>
                    recordStringValuesForElement(
                        element,
                        section,
                        schema,
                        formData,
                    ),
                ),
        );
        return values;
    }

    /**
     * Map values keyed by a string following '[section].[field].[section].[field]...' into
     * a nested object that the key strings represent
     */
    private mapValuesToInfoLayout(
        values: Record<string, any>,
    ): Record<string, any> {
        const valuesInLayout = {};
        const setDeep = (
            obj: Record<string, any>,
            path: string[],
            value: string,
            isArray: boolean,
        ) => {
            const topLevel = path.length - 1;
            path.reduce((a, b, level) => {
                const subFormKey = b.indexOf('{');
                const isSubForm = subFormKey > -1;
                const k = b.slice(0, subFormKey > -1 ? subFormKey : undefined);

                if (isSubForm) {
                    const subFormIndex = Number(
                        b.substring(subFormKey + 1, b.indexOf('}')),
                    );
                    if (typeof a[k] === 'undefined') {
                        a[k] = [];
                    }
                    if (typeof a[k][subFormIndex] === 'undefined') {
                        for (let i = 0; i <= Number(subFormIndex); i++) {
                            if (typeof a[k][i] === 'undefined') {
                                a[k][i] = {};
                            }
                        }
                    }
                    if (level === topLevel) {
                        if (isArray) {
                            if (typeof a[k][subFormIndex] === 'undefined') {
                                a[k][subFormIndex] = [];
                            }
                            a[k][subFormIndex] = [...a[k][subFormIndex], value];
                        } else {
                            a[k][subFormIndex] = value;
                        }
                        return value;
                    }
                    return a[k][subFormIndex];
                } else if (typeof a[k] === 'undefined' && level !== topLevel) {
                    a[k] = {};
                    return a[k];
                }

                if (level === topLevel) {
                    if (isArray) {
                        if (typeof a[k] === 'undefined') {
                            a[k] = [];
                        }
                        a[k] = [...a[k], value];
                    } else {
                        a[k] = value;
                    }
                    return value;
                }
                return a[k];
            }, obj);
        };
        Object.keys(values).forEach((key) => {
            const value = values[key];
            const arrKey = key.indexOf('[');
            const splitKey = key
                .slice(0, arrKey > -1 ? arrKey : undefined)
                .split('.');
            setDeep(valuesInLayout, splitKey, value, arrKey > -1);
        });
        return valuesInLayout;
    }

    /**
     * Combines Records for before and after values to a WdxAuditInfo array, following
     * the rules set out by the form layout
     */
    private mapInfo(
        definition: FormDefinition,
        beforeValues: Record<string, any>,
        afterValues: Record<string, any>,
        lastUpdatedOn: string | null | undefined,
    ): WdxAuditInfo[] {
        const info: WdxAuditInfo[] = [];
        const layout = definition.layout;
        const schema = definition.schema;

        const mapSection = (
            formSection: FormSectionLayoutDefinition,
            formDefinition: FieldDefinition[],
            before: Record<string, any>,
            after: Record<string, any>,
        ) => {
            const formSectionName = formSection.name;
            const beforeSection = before && before[formSectionName];
            const afterSection = after && after[formSectionName];
            const children: WdxAuditInfo[] = [];
            if (
                formSection.elementLayoutDefinitions?.length &&
                !formSection.isHidden
            ) {
                formSection.elementLayoutDefinitions?.forEach((formElement) => {
                    const formElementName = formElement.name;
                    const formElementSchema = formDefinition?.find(
                        (definition) => definition.name === formElementName,
                    );
                    const beforeValue =
                        beforeSection && beforeSection[formElementName];
                    const afterValue =
                        afterSection && afterSection[formElementName];
                    if (formElement.elementType === FormElementType.Array) {
                        const subForms: WdxAuditInfo[] = [];
                        if (formElement.sectionLayoutDefinitions?.length) {
                            formElement.sectionLayoutDefinitions.forEach(
                                (subFormSection) => {
                                    const subBefore =
                                        beforeValue &&
                                        beforeValue[subFormSection.name];
                                    const subAfter =
                                        afterValue &&
                                        afterValue[subFormSection.name];
                                    if (subBefore?.length || subAfter?.length) {
                                        const lenBefore =
                                            subBefore?.length ?? 0;
                                        const lenAfter = subAfter?.length ?? 0;
                                        const maxLen = Math.max(
                                            lenBefore,
                                            lenAfter,
                                        );
                                        for (let i = 0; i < maxLen; i++) {
                                            const subChildren: WdxAuditInfo[] =
                                                [];
                                            const subBeforeValues =
                                                subBefore?.length &&
                                                subBefore[i];
                                            const subAfterValues =
                                                subAfter?.length && subAfter[i];
                                            const subFormInfo: {
                                                beforeValue: WdxAuditValue;
                                                updateValue: WdxAuditValue;
                                            } = {
                                                beforeValue:
                                                    {} as WdxAuditValue,
                                                updateValue:
                                                    {} as WdxAuditValue,
                                            };
                                            subFormSection.elementLayoutDefinitions?.forEach(
                                                (subElement) => {
                                                    if (!subElement.isHidden) {
                                                        const subElementName =
                                                            subElement.name;
                                                        const subBeforeValue =
                                                            subBeforeValues &&
                                                            subBeforeValues[
                                                                subElementName
                                                            ];
                                                        const subAfterValue =
                                                            subAfterValues &&
                                                            subAfterValues[
                                                                subElementName
                                                            ];
                                                        const childSchemaDefinition =
                                                            formElementSchema?.childSchema?.find(
                                                                (child) =>
                                                                    child.name ===
                                                                    subElementName,
                                                            );
                                                        if (
                                                            childSchemaDefinition?.summaryLevel
                                                        ) {
                                                            const summaryBefore =
                                                                subBeforeValue?.label;
                                                            const summaryAfter =
                                                                subAfterValue?.label;
                                                            const primaryBadge =
                                                                {
                                                                    label: 'Primary',
                                                                    themeColor:
                                                                        'primary' as WdxThemeColor,
                                                                };
                                                            switch (
                                                                childSchemaDefinition.summaryLevel
                                                            ) {
                                                                case SummaryLevel.Title:
                                                                    subFormInfo.beforeValue.label =
                                                                        summaryBefore;
                                                                    subFormInfo.updateValue.label =
                                                                        summaryAfter;
                                                                    break;
                                                                case SummaryLevel.SubTitle1:
                                                                    subFormInfo.beforeValue.subtitle =
                                                                        summaryBefore;
                                                                    subFormInfo.updateValue.subtitle =
                                                                        summaryAfter;
                                                                    break;
                                                                case SummaryLevel.IsPrimary:
                                                                    subFormInfo.beforeValue.badge =
                                                                        subBeforeValue?.value ===
                                                                        true
                                                                            ? primaryBadge
                                                                            : undefined;
                                                                    subFormInfo.updateValue.badge =
                                                                        subAfterValue?.value ===
                                                                        true
                                                                            ? primaryBadge
                                                                            : undefined;
                                                                    break;
                                                            }
                                                        }
                                                        if (
                                                            subElement
                                                                .sectionLayoutDefinitions
                                                                ?.length
                                                        ) {
                                                            subElement.sectionLayoutDefinitions.forEach(
                                                                (subLayout) => {
                                                                    mapSection(
                                                                        subLayout,
                                                                        formElementSchema?.childSchema ??
                                                                            formDefinition,
                                                                        subBeforeValue,
                                                                        subAfterValue,
                                                                    );
                                                                },
                                                            );
                                                        } else if (
                                                            subBeforeValue?.label ||
                                                            subAfterValue?.label
                                                        ) {
                                                            subChildren.push(
                                                                this.formElementToAuditInfo(
                                                                    subBeforeValue,
                                                                    subAfterValue,
                                                                    lastUpdatedOn,
                                                                    subElement.label,
                                                                ),
                                                            );
                                                        }
                                                    }
                                                },
                                            );
                                            if (subChildren?.length) {
                                                subForms.push({
                                                    label: this.translationsService.translateTokenisedString(
                                                        formElement.label ??
                                                            subFormSection?.label ??
                                                            '',
                                                    ),
                                                    type: WdxAuditType.SubForm,
                                                    children: subChildren,
                                                    ...subFormInfo,
                                                });
                                            }
                                        }
                                    }
                                },
                            );
                        }
                        children.push({
                            label: this.translationsService.translateTokenisedString(
                                formElement.label ?? '',
                            ),
                            type: WdxAuditType.Array,
                            children: subForms,
                        });
                    } else if (
                        Array.isArray(beforeValue) ||
                        Array.isArray(afterValue)
                    ) {
                        const valueArrayChildren: WdxAuditInfo[] = [];
                        const maxLength = Math.max(
                            beforeValue?.length || 0,
                            afterValue?.length || 0,
                        );
                        for (let i = 0; i < maxLength; i++) {
                            valueArrayChildren.push(
                                this.formElementToAuditInfo(
                                    beforeValue?.[i] || null,
                                    afterValue?.[i] || null,
                                    lastUpdatedOn,
                                ),
                            );
                        }
                        children.push({
                            label: this.translationsService.translateTokenisedString(
                                formElement.label ?? '',
                            ),
                            type: WdxAuditType.Array,
                            children: valueArrayChildren,
                        });
                    } else if (
                        beforeValue?.label !== undefined ||
                        afterValue?.label !== undefined
                    ) {
                        children.push(
                            this.formElementToAuditInfo(
                                beforeValue,
                                afterValue,
                                lastUpdatedOn,
                                formElement.label,
                            ),
                        );
                    }
                }, []);
                if (children.length) {
                    info.push({
                        label: this.translationsService.translateTokenisedString(
                            formSection?.label ?? '',
                        ),
                        type: WdxAuditType.Section,
                        dateOfChange: lastUpdatedOn,
                        children,
                    });
                }
            }
        };

        layout?.sectionLayoutDefinitions?.forEach((formSection) => {
            if (schema) {
                mapSection(formSection, schema, beforeValues, afterValues);
            }
        });

        return info;
    }

    /**
     * Compares a before and after value, and returns a WdxAuditInfo object that accurately
     * represents the relationship between them
     */
    private formElementToAuditInfo(
        beforeValue: WdxAuditValue | WdxAuditValue[] | null,
        afterValue: WdxAuditValue | WdxAuditValue[] | null,
        lastUpdatedOn?: string | null | undefined,
        label: string | null | undefined = '',
    ): WdxAuditInfo {
        let type = WdxAuditType.Single;
        const children = [];
        const isArrayBefore = Array.isArray(beforeValue);
        const isArrayAfter = Array.isArray(afterValue);
        if (isArrayBefore || isArrayAfter) {
            type = WdxAuditType.Array;
            const arrLength = Math.max(
                isArrayBefore ? beforeValue.length : 0,
                isArrayAfter ? afterValue.length : 0,
            );
            const sortedBefore = isArrayBefore
                ? this.sortByLabel(beforeValue)
                : [];
            const sortedAfter = isArrayAfter
                ? this.sortByLabel(afterValue)
                : [];
            for (let i = 0; i < arrLength; i++) {
                const before =
                    isArrayBefore && i < sortedBefore.length
                        ? sortedBefore[i]
                        : null;
                const after =
                    isArrayAfter && i < sortedAfter.length
                        ? sortedAfter[i]
                        : null;
                children.push(
                    this.formElementToAuditInfo(before, after, lastUpdatedOn),
                );
            }
        }
        const parsedValueBefore = beforeValue
            ? this.setValue(beforeValue)
            : undefined;
        const parsedValueAfter = afterValue
            ? this.setValue(afterValue)
            : undefined;
        const hasChanged = this.hasChanged(parsedValueBefore, parsedValueAfter);
        return {
            label: this.translationsService.translateTokenisedString(
                label as string,
            ),
            type,
            beforeValue: parsedValueBefore,
            updateValue: parsedValueAfter,
            hasChanged,
            dateOfChange:
                hasChanged && lastUpdatedOn ? lastUpdatedOn : undefined,
            children: children?.length ? children : undefined,
        };
    }

    /**
     * Combine the statuses for formHistory$, before$, and after$ and output the lowest enum value
     */
    private setStatus(): void {
        this.status$ = combineLatest([
            this.formHistory$,
            this.beforeStatus$,
            this.afterStatus$,
        ]).pipe(
            map(([formHistory, beforeStatus, afterStatus]) => {
                if (formHistory && !formHistory.length) {
                    return CrudStatus.Success;
                }
                const historyStatus = formHistory
                    ? CrudStatus.Success
                    : CrudStatus.Loading;
                return Math.min(
                    Number(historyStatus ?? 0),
                    Number(beforeStatus ?? 0),
                    Number(afterStatus ?? 0),
                );
            }),
            takeUntil(this.destroyed$),
        );
    }

    /**
     * Transforms an array of audit values to a single representative audit value
     */
    private setValue(value: WdxAuditValue | WdxAuditValue[]): WdxAuditValue {
        if (Array.isArray(value)) {
            return this.sortByLabel(value).reduce(
                (combinedValue, { label }) => ({
                    label: `${
                        combinedValue.label ? combinedValue.label + ', ' : ''
                    }${label}`,
                }),
                { label: '' },
            );
        }
        return value;
    }

    /**
     * Sorts an array of audit values by their label
     */
    private sortByLabel(values: WdxAuditValue[]): WdxAuditValue[] {
        return values.sort((a, b) =>
            a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1,
        );
    }

    /**
     * Equates values that are undefined with those that have a falsy label and then compares the
     * entire before and after WdxAuditValue objects to detect changes
     */
    private hasChanged(
        beforeValue: WdxAuditValue | undefined,
        afterValue: WdxAuditValue | undefined,
    ): boolean {
        const before =
            beforeValue?.label && beforeValue.label !== ''
                ? beforeValue
                : undefined;
        const after =
            afterValue?.label && afterValue.label !== ''
                ? afterValue
                : undefined;
        return JSON.stringify(before) !== JSON.stringify(after);
    }

    onDateChange(timelineItem: WdxAuditTimeline): void {
        const id = timelineItem.name;
        this.afterId$.next(id);
    }
}
