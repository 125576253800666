import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { FeatureFlag } from '@wdx/clmi/api-services/services';

@Component({
    selector: 'clmi-related-records',
    templateUrl: './related-records.component.html',
    styleUrls: ['./related-records.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedRecordsComponent {
    @Input() entityId: string;
    @Input() entityType: SystemEntity;
    @Input() showChildCases: boolean;
    @Input() showRelatedCases: boolean;
    @Input() showSuggestedCases: boolean;
    @Input() showOpportunities: boolean;
    @Input() showArticles: boolean;
    @Input() showActivities: boolean;
    @Input() cardsPerRow: number;
    @Input() isInactiveRecord = false;

    readonly FeatureFlag = FeatureFlag;

    get showCases(): boolean {
        return (
            this.showChildCases ||
            this.showRelatedCases ||
            this.showSuggestedCases
        );
    }
}
