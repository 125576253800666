import { SlaStatusType } from '@wdx/clmi/clmi-swagger-gen';
import {
    TRANSLATION_CASE_COMPLETED,
    TRANSLATION_CASE_COMPLETED_OUTSIDE_SLA,
    TRANSLATION_CASE_COMPLETED_WITHIN_SLA,
    TRANSLATION_CASE_NO_SLA,
    TRANSLATION_CASE_SLA_DUE,
    TRANSLATION_CASE_SLA_OVERDUE,
} from '@wdx/shared/utils';

export const CASEWORK_COMPLETED_SLA_STATUS_MAP = {
    [SlaStatusType.None]: TRANSLATION_CASE_COMPLETED,
    [SlaStatusType.Within]: TRANSLATION_CASE_COMPLETED_WITHIN_SLA,
    [SlaStatusType.Breached]: TRANSLATION_CASE_COMPLETED_OUTSIDE_SLA,
};

export const CASEWORK_ACTIVE_SLA_STATUS_MAP = {
    [SlaStatusType.None]: TRANSLATION_CASE_NO_SLA,
    [SlaStatusType.Within]: TRANSLATION_CASE_SLA_DUE,
    [SlaStatusType.Approaching]: TRANSLATION_CASE_NO_SLA,
    [SlaStatusType.Breached]: TRANSLATION_CASE_SLA_OVERDUE,
};
