import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { Currency } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class CurrenciesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getCurrencies(): Observable<Currency[]> {
        return this.http.get<Currency[]>(
            `${this.config.getConfiguration().API_BASE}/currency`,
        );
    }
}
