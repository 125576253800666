import { Action, createReducer, on } from '@ngrx/store';
import { InvestmentAccount } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as investmentAccountsActions from './investment-accounts.actions';

export interface State {
    investmentAccounts: CrudStateObject<InvestmentAccount>;
}

export const initialState: State = {
    investmentAccounts: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        investmentAccountsActions.getForId,
        (state, props): State => ({
            ...state,
            investmentAccounts: {
                ...state.investmentAccounts,
                [props.investmentAccountId]: {
                    ...(state.investmentAccounts[props.investmentAccountId] ||
                        ({} as CrudState<InvestmentAccount>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        investmentAccountsActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            investmentAccounts: {
                ...state.investmentAccounts,
                [props.investmentAccountId]: {
                    ...state.investmentAccounts[props.investmentAccountId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.investmentAccount,
                },
            },
        }),
    ),

    on(
        investmentAccountsActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            investmentAccounts: {
                ...state.investmentAccounts,
                [props.investmentAccountId]: {
                    ...state.investmentAccounts[props.investmentAccountId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
