import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    NgbAccordionModule,
    NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WdxAlertModule } from '@wdx/shared/components/wdx-alert';
import { WdxAuditComponent } from '@wdx/shared/components/wdx-audit';
import { WdxAvatarModule } from '@wdx/shared/components/wdx-avatar';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxSplitButtonComponent } from '@wdx/shared/components/wdx-split-button';
import {
    DisplayDatePipe,
    SpreadPipe,
    TranslatePipe,
    TranslateTokenisedStringPipe,
    WdxComponentRendererDirective,
    WdxMarkdownToHtmlPipe,
    WdxReplacePipe,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';
import { AddressesEffects } from './+state/addresses/addresses.effects';
import * as fromAddresses from './+state/addresses/addresses.reducer';
import { DynamicFormsFacade } from './+state/dynamic-forms';
import { DynamicFormsEffects } from './+state/dynamic-forms/dynamic-forms.effects';
import * as fromDynamicForms from './+state/dynamic-forms/dynamic-forms.reducer';
import { FormHistoryInstanceEffects } from './+state/form-history-instance/form-history-instance.effects';
import * as fromFormHistoryInstance from './+state/form-history-instance/form-history-instance.reducer';

import { ArrayIncludesPipe } from '@wdx/shared/utils';
import {
    FormAccordionHeaderComponent,
    FormControlsModule,
    FormFrameworkFormArrayComponent,
    FormFrameworkFormComponent,
    FormFrameworkFormContainerComponent,
    FormLockSummaryComponent,
    FormSummaryInfoCardsComponent,
    FormValidationSummaryComponent,
    FormVersionInfoComponent,
} from './components';
import { FormFrameworkFormHistoryComponent } from './components/containers/form-framework-form-history/form-framework-form-history.component';
import { AddressFormatService } from './components/form-controls/form-address-control/services/address-format.service';
import { PendingChangesComponent } from './components/pending-changes/pending-changes.component';
import {
    FormSubFormContentComponent,
    FormSubFormHeaderComponent,
    FormSubformControlDirective,
    FormSubformInfoComponent,
} from './components/sub-form';
import { FormActionsPipe } from './pipes/form-actions/form-actions.pipe';
import {
    FormContextualDataService,
    FormLockService,
    FormSummaryLayoutService,
    FormSummaryService,
} from './services';

@NgModule({
    declarations: [
        PendingChangesComponent,
        FormFrameworkFormComponent,
        FormFrameworkFormContainerComponent,
        FormFrameworkFormHistoryComponent,
        FormLockSummaryComponent,
        FormSummaryInfoCardsComponent,
        FormVersionInfoComponent,
        FormFrameworkFormArrayComponent,
        FormAccordionHeaderComponent,
        FormSubformInfoComponent,
        FormSubFormContentComponent,
        FormSubFormHeaderComponent,
        FormSubformControlDirective,
    ],
    imports: [
        CommonModule,
        PushPipe,
        ReactiveFormsModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxAlertModule,
        WdxButtonModule,
        NgbAccordionModule,
        StoreModule.forFeature(
            fromDynamicForms.FEATURE_KEY,
            fromDynamicForms.reducer,
        ),
        StoreModule.forFeature(
            fromAddresses.FEATURE_KEY,
            fromAddresses.reducer,
        ),
        StoreModule.forFeature(
            fromFormHistoryInstance.FORM_HISTORY_INSTANCE_FEATURE_KEY,
            fromFormHistoryInstance.formHistoryInstanceReducer,
        ),
        EffectsModule.forFeature([
            DynamicFormsEffects,
            AddressesEffects,
            FormHistoryInstanceEffects,
        ]),
        TranslatePipe,
        WdxReplacePipe,
        FormControlsModule,
        WdxIconContainerModule,
        WdxAvatarModule,
        SpreadPipe,
        WdxBadgeModule,
        WdxInfoCardModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
        FormValidationSummaryComponent,
        WdxIsLoadingModule,
        WdxSpinnerModule,
        WdxSplitButtonComponent,
        DisplayDatePipe,
        TranslateTokenisedStringPipe,
        NgbPopoverModule,
        WdxAuditComponent,
        WdxComponentRendererDirective,
        FormActionsPipe,
        ArrayIncludesPipe,
    ],
    providers: [
        AddressFormatService,
        DynamicFormsFacade,
        FormSummaryLayoutService,
        FormSummaryService,
        FormContextualDataService,
        FormLockService,
    ],
    exports: [
        PendingChangesComponent,
        FormFrameworkFormComponent,
        FormFrameworkFormContainerComponent,
        FormFrameworkFormHistoryComponent,
        FormLockSummaryComponent,
        FormSummaryInfoCardsComponent,
        FormVersionInfoComponent,
        FormFrameworkFormArrayComponent,
        FormAccordionHeaderComponent,
        FormSubformInfoComponent,
        FormSubFormContentComponent,
        FormSubFormHeaderComponent,
    ],
})
export class WdxFormFrameworkModule {}
