import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as processesActions from './processes.actions';
import { ProcessesService } from './processes.service';

@Injectable()
export class ProcessesEffects {
    private actions$ = inject(Actions);
    private processesService = inject(ProcessesService);

    getProcessesForCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processesActions.getProcessesForCase),
            mergeMap((action) =>
                this.processesService
                    .getProcessesForSystemEntity(
                        SystemEntity.Case,
                        action.caseId,
                    )
                    .pipe(
                        map((processes) =>
                            processesActions.getProcessesForCaseSuccess({
                                caseId: action.caseId,
                                processes,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                processesActions.getProcessesForCaseFailure({
                                    caseId: action.caseId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processesActions.getProcess),
            switchMap((action) =>
                this.processesService.getProcess(action.processId).pipe(
                    map((process) =>
                        processesActions.getProcessSuccess({ process }),
                    ),
                    catchError((error) =>
                        of(
                            processesActions.getProcessFailure({
                                processId: action.processId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    createProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processesActions.createProcess),
            switchMap((action) =>
                this.processesService.createProcess(action.process).pipe(
                    map((process) =>
                        processesActions.createProcessSuccess({ process }),
                    ),
                    catchError((error) =>
                        of(
                            processesActions.createProcessFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
