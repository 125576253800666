import {
    Column,
    ValueFormatterParams,
    ValueGetterParams,
    ICellRendererParams,
    _,
} from '@ag-grid-community/core';
import {
    CubeResult,
    WdxDateFormat,
    WdxDateTimeService,
} from '@wdx/shared/utils';

/**
 * For use in AG Grid Colummns where a column can display both standard row cells
 * and also be used for grouping.
 * IMPORTANT: when getting group data ensure `__group_row__: true` is added to each group row
 * @param groupName name of group in cube response
 * @param groupProp
 * @returns
 */
export const DYNAMIC_GROUP_BY_VALUE_GETTER =
    (groupName: string, groupProp: string | null = 'name') =>
    (params: ValueGetterParams<CubeResult & { __group_row__?: boolean }>) => {
        if (params.data?.__group_row__) {
            if (!params.column.isRowGroupActive()) {
                return;
            }
            return params.data?.groups?.[groupName]
                ? `${
                      groupProp
                          ? params.data?.groups?.[groupName]?.[groupProp] || ''
                          : params.data?.groups?.[groupName] || ''
                  } (${params.data?.rollupValue})`
                : ` (${params.data?.rollupValue})`;
        }
        return AG_GRID_VALUE_GETTER(params);
    };

/**
 * @deprecated This function has been deprecated use DATE_CELL_VALUE_FORMATTER instead
 */
export const DATE_STRING_VALUE_GETTER = (params: ValueGetterParams) => {
    const field = params.column.getColDef().field;
    const dateObj =
        field && params.data?.[field]
            ? new Date(params.data[field])
            : undefined;

    if (!dateObj) {
        return;
    }
    return dateObj.getTime() ? dateObj : undefined;
};

export const DATE_CELL_VALUE_GETTER =
    (
        dateTimeService: WdxDateTimeService,
        format = WdxDateFormat.AbsoluteDate,
    ) =>
    (params: ValueGetterParams) => {
        const value = AG_GRID_VALUE_GETTER(params);
        return value
            ? dateTimeService.convertDateToViewFriendlyFormat(value, { format })
            : '';
    };

export const AG_GRID_VALUE_GETTER = (
    params: ValueFormatterParams | ValueGetterParams | ICellRendererParams,
    fieldName?: string,
) => {
    const column = params.column as Column;
    const field = fieldName || column?.getColDef()?.field || column?.getColId();
    return field
        ? _.getValueUsingField(
              params.data,
              field,
              column.isFieldContainsDots() || Boolean(fieldName?.includes('.')),
          ) || ''
        : '';
};
