import { Directive } from '@angular/core';
import { Action } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { filter, map, skipWhile, take, takeUntil } from 'rxjs/operators';

import * as globalSelectors from '../state/global/global.selectors';
import * as recentRecordsActions from '../state/recent-records/recent-records.actions';
import { BaseDiDependantClass } from './base-di-dependant.class';

@Directive()
export class BaseComponentClass extends BaseDiDependantClass {
    appIsInitialised$: Observable<boolean>;

    cachedModalQueryParams: any;

    fireOnAppInitialised(method: () => any) {
        if (this.hasMissingDependencies('fireOnAppInitialised', 'store$')) {
            return;
        }

        this.appIsInitialised$ = this.store$.select(
            globalSelectors.getAppIsInitialised,
        );

        this.appIsInitialised$
            .pipe(
                skipWhile((value) => !value),
                take(1),
            )
            .subscribe(method);
    }

    listenTo(...listenedActions: any[]): Observable<Action> {
        if (this.hasMissingDependencies('listenTo', 'actions$')) {
            return;
        }

        return this.actions$.pipe(
            takeUntil(this.destroyed$),
            filter((action: Action) =>
                listenedActions.some(
                    (listenedAction) => listenedAction.type === action.type,
                ),
            ),
        );
    }

    sendRecentView(entityType: SystemEntity, recordId: string) {
        if (this.hasMissingDependencies('sendRecentView', 'store$')) {
            return;
        }

        this.store$.dispatch(
            recentRecordsActions.updateRecentlyViewedRecord({
                entityType: entityType,
                recordId: recordId,
            }),
        );
    }
}
