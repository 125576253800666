/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ImportStatusType {
  Created = 'Created',
  Processing = 'Processing',
  Completed = 'Completed',
  Errored = 'Errored'
}
