import { createSelector } from '@ngrx/store';
import { DmsProvider } from '@wdx/clmi/clmi-swagger-gen';
import * as documentsReducer from './documents.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    documents: documentsReducer.State;
}): documentsReducer.State => state.documents;

export const getClientProductDocumentsIsLoadingPage = createSelector(
    getState,
    (state: documentsReducer.State, props: any) =>
        state.clientProductDocuments[props.id]?.isLoadingPage,
);

export const getClientProductDocumentsHasLoadingPageError = createSelector(
    getState,
    (state: documentsReducer.State, props: any) =>
        state.clientProductDocuments[props.id]?.hasLoadingPageError,
);

export const getClientProductDocumentsInfinityPaging = createSelector(
    getState,
    (state: documentsReducer.State, props: any) =>
        state.clientProductDocuments[props.id]?.infinity?.paging,
);

export const getClientProductDocumentsInfinityCombinedList = createSelector(
    getState,
    (state: documentsReducer.State, props: any) =>
        state.clientProductDocuments[props.id]?.infinity?.combinedList,
);

export const getDocumentsForEntityIdIsLoadingList = ({
    entityType,
    entityId,
}) =>
    createSelector(
        getState,
        (state: documentsReducer.State) =>
            state.documentsForEntity[entityType] &&
            state.documentsForEntity[entityType][entityId]?.isLoadingList,
    );

export const getDocumentsForEntityIdHasLoadingListError = ({
    entityType,
    entityId,
}) =>
    createSelector(
        getState,
        (state: documentsReducer.State) =>
            state.documentsForEntity[entityType] &&
            state.documentsForEntity[entityType][entityId]?.hasLoadingListError,
    );

export const getDocumentsForEntityId = ({ entityType, entityId }) =>
    createSelector(
        getState,
        (state: documentsReducer.State) =>
            state.documentsForEntity[entityType] &&
            state.documentsForEntity[entityType][entityId]?.list,
    );

export const getIsLoadingPage = createSelector(
    getState,
    (
        state: documentsReducer.State,
        props: { dMSProvider: DmsProvider; id: string; type: string },
    ) => state[props.type]?.[props.id]?.[props.dMSProvider]?.isLoadingPage,
);

export const getHasLoadingPageError = createSelector(
    getState,
    (
        state: documentsReducer.State,
        props: { dMSProvider: DmsProvider; id: string; type: string },
    ) =>
        state[props.type]?.[props.id]?.[props.dMSProvider]?.hasLoadingPageError,
);

export const getInfinityPaging = createSelector(
    getState,
    (
        state: documentsReducer.State,
        props: { dMSProvider: DmsProvider; id: string; type: string },
    ) => state[props.type]?.[props.id]?.[props.dMSProvider]?.infinity?.paging,
);

export const getInfinityCombinedList = createSelector(
    getState,
    (
        state: documentsReducer.State,
        props: { dMSProvider: DmsProvider; id: string; type: string },
    ) =>
        state[props.type]?.[props.id]?.[props.dMSProvider]?.infinity
            ?.combinedList,
);

export const getIsDeleting = createSelector(
    getState,
    (state: documentsReducer.State) =>
        Object.keys(state.documents).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.documents[id].isDeleting,
            }),
            {},
        ),
);

export const getIsDeletingError = createSelector(
    getState,
    (state: documentsReducer.State) =>
        Object.keys(state.documents).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.documents[id].hasDeletingError,
            }),
            {},
        ),
);
