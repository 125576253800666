import { createAction, props } from '@ngrx/store';
import {
    CommentCreate,
    SystemEntity,
    Comment,
} from '@wdx/clmi/clmi-swagger-gen';

export const loadChat = createAction(
    '[Chat] Load Chat',
    props<{ entityId: string; entityType: SystemEntity }>(),
);

export const loadChatSuccess = createAction(
    '[Chat] Load Chat Success',
    props<{
        entityId: string;
        entityType: SystemEntity;
        comments: Comment[];
    }>(),
);

export const loadChatFailure = createAction(
    '[Chat] Load Chat Failure',
    props<{ entityId: string; entityType: SystemEntity; error: any }>(),
);

export const createComment = createAction(
    '[Chat] Create Comment',
    props<{
        entityId: string;
        entityType: SystemEntity;
        commentCreate: CommentCreate;
    }>(),
);

export const createCommentSuccess = createAction(
    '[Chat] Create Comment Success',
    props<{
        entityId: string;
        entityType: SystemEntity;
        comment: Comment;
        resolvesId?: string | null;
    }>(),
);

export const createCommentFailure = createAction(
    '[Chat] Create Comment Failure',
    props<{ entityId: string; entityType: SystemEntity; error: any }>(),
);
