/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum TabType {
  Opportunities = 'Opportunities',
  Cases = 'Cases',
  Feed = 'Feed',
  Tasks = 'Tasks',
  KycStatus = 'KycStatus',
  Inbox = 'Inbox',
  Products = 'Products',
  TeamMembers = 'TeamMembers',
  Parties = 'Parties',
  Segmentation = 'Segmentation',
  FamilyFriends = 'FamilyFriends',
  ProcessionalConnections = 'ProcessionalConnections',
  Employees = 'Employees',
  Notes = 'Notes',
  Profile = 'Profile',
  Portfolio360 = 'Portfolio360',
  NetworkGraph = 'NetworkGraph',
  CampaignEngagements = 'CampaignEngagements',
  DocumentChecklist = 'DocumentChecklist',
  ExternalUser = 'ExternalUser',
  ClientProducts = 'ClientProducts',
  ClientInsights = 'ClientInsights',
  PendingChanges = 'PendingChanges',
  Edit = 'Edit',
  ExternalTasks = 'ExternalTasks',
  List = 'List',
  Appendix = 'Appendix'
}
