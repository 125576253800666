import { ActivityType } from '@wdx/clmi/clmi-swagger-gen';
import {
    UTILS_ICON_CALENDAR_ALT,
    UTILS_ICON_CIRCLE_EXCLAMATION,
    UTILS_ICON_COMMENTS,
    UTILS_ICON_ENVELOPE,
    UTILS_ICON_ENVELOPE_CIRCLE_CHECK,
    UTILS_ICON_PHONE,
    UTILS_ICON_QUESTION,
    UTILS_ICON_SMS,
    UTILS_ICON_STICKY_NOTE,
    UTILS_ICON_TASKS,
    UTILS_ICON_WATCH,
} from '@wdx/shared/utils';

export const ACTIVITY_ICON_MAP: Record<ActivityType, string> = {
    [ActivityType.Appointment]: UTILS_ICON_CALENDAR_ALT,
    [ActivityType.Email]: UTILS_ICON_ENVELOPE,
    [ActivityType.ExternalTask]: UTILS_ICON_TASKS,
    [ActivityType.Glia]: UTILS_ICON_COMMENTS,
    [ActivityType.Note]: UTILS_ICON_STICKY_NOTE,
    [ActivityType.PhoneCall]: UTILS_ICON_PHONE,
    [ActivityType.Push]: UTILS_ICON_CIRCLE_EXCLAMATION,
    [ActivityType.Qwil]: UTILS_ICON_COMMENTS,
    [ActivityType.SecureMessage]: UTILS_ICON_ENVELOPE_CIRCLE_CHECK,
    [ActivityType.Sms]: UTILS_ICON_SMS,
    [ActivityType.Task]: UTILS_ICON_TASKS,
    [ActivityType.TimeBooking]: UTILS_ICON_WATCH,
    [ActivityType.Unblu]: UTILS_ICON_COMMENTS,
    [ActivityType.Unknown]: UTILS_ICON_QUESTION,
    [ActivityType.WorkItem]: UTILS_ICON_TASKS,
    [ActivityType.Letter]: '',
};
