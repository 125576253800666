import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartyRoleAccess } from '@wdx/clmi/clmi-swagger-gen';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class AccessService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForParty(partyId: string): Observable<PartyRoleAccess[]> {
        return this.http.get<PartyRoleAccess[]>(
            `${this.config.getConfiguration().API_BASE}/party/${partyId}/access`,
        );
    }
}
