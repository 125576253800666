import { Component, inject, Input } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    DYNAMIC_FORM,
    FormInitialisationMode,
    KeyValueObject,
    UserAction,
} from '@wdx/shared/utils';
import { BaseComponentClass } from '../../../classes/base-component.class';
import { FeatureSvg } from '../../../models/svg.model';
import { ModalManagementService } from '../../../services/modal-management.service';
import {
    FormFrameworkEvent,
    FormFrameworkEventType,
} from '@wdx/shared/infrastructure/form-framework';
import { UserActionHttpService } from '@wdx/shared/infrastructure/api-service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-dynamic-form-modal',
    templateUrl: './organism-dynamic-form-modal.component.html',
    styleUrls: ['./organism-dynamic-form-modal.component.scss'],
})
export class OrganismDynamicFormModalComponent extends BaseComponentClass {
    @Input() modalId: string;

    private userActionHttpService = inject(UserActionHttpService);

    isLoading = true;
    formTitle: string;
    formTemplateId: string;
    formTemplateEntityId: string;
    formTemplateEntityType: SystemEntity;
    formId: string;
    entityId: string;
    appId: string;
    initialisationParams: KeyValueObject;
    initialisationMode: FormInitialisationMode;
    queryParams: KeyValueObject;
    emitInsteadOfPost: boolean;
    emitData: any;
    initialFormValue: KeyValueObject;
    customEndpoint: string;
    readonlyMode = false;
    auditMode = false;

    readonly FEATURE_FLAG = FeatureFlag;
    readonly FEATURE_SVG = FeatureSvg;

    constructor(modalManagementService: ModalManagementService) {
        super();

        this.patchInjectedItems({ modalManagementService });

        this.modalManagementService.latestInstanceParams$.subscribe(
            (params) => {
                if (
                    params?.modalId === DYNAMIC_FORM &&
                    !params?.modalParams?.formId
                ) {
                    this.setError();
                    return;
                }
                if (
                    params?.modalParams?.formId &&
                    params?.modalId === this.modalId
                ) {
                    this.isLoading = true;
                    this.formTemplateId = params.modalParams.formTemplateId;
                    this.formTemplateEntityId =
                        params.modalParams.formTemplateEntityId;
                    this.formTemplateEntityType =
                        params.modalParams.formTemplateEntityType;
                    this.formId = params.modalParams.formId;
                    this.customEndpoint = params.modalParams.customEndpoint;
                    this.entityId = params.modalParams.entityId;
                    this.appId = params.modalParams.appId;
                    this.initialisationParams =
                        params.modalParams.initialisationParams;
                    this.queryParams = params.modalParams.queryParams;
                    this.emitInsteadOfPost =
                        params.modalParams.emitInsteadOfPost;
                    this.emitData = params.modalParams.emitData;
                    this.initialFormValue = params.modalParams.initialFormValue;
                    this.initialisationMode =
                        params.modalParams.initialisationMode;
                    this.readonlyMode = params.modalParams?.readonlyMode;
                    this.auditMode = params.modalParams?.auditMode;
                }
            },
        );
    }

    setError() {
        this.formTitle = 'Error';
        this.formId = null;
        this.entityId = null;
        this.isLoading = false;
    }

    onFormTitleReceived(formTitle: string): void {
        this.formTitle = formTitle;
        this.isLoading = false;
    }

    formEvent(event: FormFrameworkEvent) {
        if (
            event.type === FormFrameworkEventType.FormLoaded &&
            event.action.entityId &&
            event.action.entityType
        ) {
            this.sendUserAction(
                UserAction.View,
                event.action.entityType,
                event.action.entityId,
            );
        }
        if (
            event.type === FormFrameworkEventType.AuditUserAction &&
            event.action.userAction
        ) {
            this.sendUserAction(
                event.action.userAction.action,
                event.action.userAction.entity,
                event.action.userAction.id,
            );
        }
    }

    private sendUserAction(
        action: UserAction,
        entity: SystemEntity,
        id: string,
    ) {
        this.userActionHttpService.sendAction(action, entity, id);
    }
}
