import { Injectable } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { AccessVisibilityComponent } from '../../access-visibility.component';

@Injectable({
    providedIn: 'root',
})
export class AccessVisibilityService {
    private component: AccessVisibilityComponent;

    setComponent(component: AccessVisibilityComponent): void {
        this.component = component;
    }

    open(id: string, entityType: SystemEntity): void {
        this.component.open(id, entityType);
    }

    close(): void {
        this.component.close();
    }
}
