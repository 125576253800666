import { Action, createReducer, on } from '@ngrx/store';
import { EntityPermissionType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStateObject } from '@wdx/shared/utils';
import {
    systemEntityActionPermission,
    systemEntityActionPermissionFailure,
    systemEntityActionPermissionSuccess,
} from './system-entity.actions';

export interface State {
    permissions?: {
        [systemEntity: string]: CrudStateObject<EntityPermissionType>;
    };
}

export const initialSystemEntityState: State = {
    permissions: {},
};

const reducerSetup = createReducer(
    initialSystemEntityState,

    on(
        systemEntityActionPermission,
        (state, props): State => ({
            ...state,
            permissions: {
                ...state.permissions,
                [props.systemEntity]: {
                    ...(state.permissions?.[props.systemEntity] ??
                        ({} as CrudStateObject<EntityPermissionType>)),
                    [props.entityId]: {
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            },
        }),
    ),

    on(
        systemEntityActionPermissionSuccess,
        (state, props): State => ({
            ...state,
            permissions: {
                ...state.permissions,
                [props.systemEntity]: {
                    ...(state.permissions?.[props.systemEntity] ??
                        ({} as CrudStateObject<EntityPermissionType>)),
                    [props.entityId]: {
                        list: props.permission,
                        isLoadingList: false,
                        hasLoadingListError: false,
                    },
                },
            },
        }),
    ),

    on(
        systemEntityActionPermissionFailure,
        (state, props): State => ({
            ...state,
            permissions: {
                ...state.permissions,
                [props.systemEntity]: {
                    ...(state.permissions?.[props.systemEntity] ??
                        ({} as CrudStateObject<EntityPermissionType>)),
                    [props.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
