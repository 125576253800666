import { Action, createReducer, on } from '@ngrx/store';
import { AdminRole, RoleType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as adminRolesActions from './admin-roles.actions';

export interface State {
    adminRoles?: CrudStateObject<AdminRole>;
    roles?: CrudStateObject<RoleType>;
}

export const initialState: State = {
    adminRoles: {},
    roles: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        adminRolesActions.searchRoles,
        (state, props): State => ({
            ...state,
            adminRoles: {
                ...state.adminRoles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.adminRoles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<AdminRole>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    filteredList: props.reset
                        ? []
                        : state.adminRoles[GLOBAL_STATE_INDEX_ID]?.filteredList,
                },
            },
        }),
    ),

    on(
        adminRolesActions.searchRolesSuccess,
        (state, props): State => ({
            ...state,
            adminRoles: {
                ...state.adminRoles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.adminRoles[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    filteredList: [
                        ...(state.adminRoles[GLOBAL_STATE_INDEX_ID]
                            .filteredList || []),
                        ...props.roles,
                    ],
                },
            },
        }),
    ),

    on(
        adminRolesActions.searchRolesFailure,
        (state): State => ({
            ...state,
            adminRoles: {
                ...state.adminRoles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.adminRoles[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
