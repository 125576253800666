import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatusDateOptions } from '../models/activity-status.model';
import {
    Activity,
    ActivityStatusType,
    ActivityType,
} from '@wdx/clmi/clmi-swagger-gen';
import { DisplayDatePipe } from '@wdx/shared/utils';

@Pipe({ name: 'activityStatusDate' })
export class ActivityStatusDatePipe implements PipeTransform {
    transform(activity: Activity, opts?: ActivityStatusDateOptions): string {
        const defaultOptions = {
            labelPrefix: true,
            includeTime: false,
        };
        const options = {
            ...defaultOptions,
            ...opts,
        };

        return [
            ...(options.labelPrefix ? [this.getLabelPrefix(activity)] : []),
            new DisplayDatePipe().transform(this.getDate(activity), {
                todayAsString: true,
                includeTime: options.includeTime,
            }),
        ].join(' ');
    }

    getDate(activity: Activity) {
        if (
            activity.activityType !== ActivityType.Task &&
            activity.activityType !== ActivityType.WorkItem
        ) {
            return activity.due || activity.dateCreated;
        }

        if (activity.status === ActivityStatusType.Complete) {
            return activity.completed || activity.dateCreated;
        }

        return activity.due || activity.dateCreated;
    }

    getLabelPrefix(activity: Activity) {
        switch (activity.activityType) {
            case ActivityType.Email:
                return 'Received';
            case ActivityType.Glia:
            case ActivityType.Qwil:
            case ActivityType.Sms:
            case ActivityType.SecureMessage:
                return '';
            case ActivityType.WorkItem:
            case ActivityType.Task:
                return activity.status === ActivityStatusType.Complete
                    ? ''
                    : 'Due';
            default:
                return activity.status === ActivityStatusType.Complete
                    ? ''
                    : 'Scheduled';
        }
    }
}
