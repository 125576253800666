import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product, ProductType } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ProductTypesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getProductTypes(availableOnly?: boolean): Observable<ProductType[]> {
        const AVAILABLE_ONLY =
            typeof availableOnly === 'boolean'
                ? `?availableOnly=${availableOnly}`
                : '';
        return this.http.get<ProductType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/producttype${AVAILABLE_ONLY}`,
        );
    }

    getProducts(code: string): Observable<Product[]> {
        return this.http.get<Product[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/product/type/${code}?includeUsageCount=true`,
        );
    }

    disableProduct(id: string, isDisabled: boolean): Observable<Product> {
        return this.http.patch<Product>(
            `${
                this.config.getConfiguration().API_BASE
            }/product/${id}/disabled/${isDisabled}`,
            {},
        );
    }
}
