import { createAction, props } from '@ngrx/store';
import {
    Activity,
    Article,
    Case,
    Opportunity,
} from '@wdx/clmi/clmi-swagger-gen';
import { SystemEntityActionProps } from '../../models/system-entity-action-props.model';

export const getRelatedActivitiesForEntity = createAction(
    '[Related Records] Get Related Activities For Entity',
    props<SystemEntityActionProps>(),
);

export const getRelatedActivitiesForEntitySuccess = createAction(
    '[Related Records] Get Related Activities For Entity Success',
    props<
        SystemEntityActionProps & {
            activities: Activity[];
        }
    >(),
);

export const getRelatedActivitiesForEntityFailure = createAction(
    '[Related Records] Get Related Activities For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getRelatedArticlesForEntity = createAction(
    '[Related Records] Get Related Articles For Entity',
    props<SystemEntityActionProps>(),
);

export const getRelatedArticlesForEntitySuccess = createAction(
    '[Related Records] Get Related Articles For Entity Success',
    props<
        SystemEntityActionProps & {
            articles: Article[];
        }
    >(),
);

export const getRelatedArticlesForEntityFailure = createAction(
    '[Related Records] Get Related Articles For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getRelatedCasesForEntity = createAction(
    '[Related Records] Get Related Cases For Entity',
    props<SystemEntityActionProps>(),
);

export const getRelatedCasesForEntitySuccess = createAction(
    '[Related Records] Get Related Cases For Entity Success',
    props<
        SystemEntityActionProps & {
            cases: Case[];
        }
    >(),
);

export const getRelatedCasesForEntityFailure = createAction(
    '[Related Records] Get Related Cases For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getChildCasesForEntity = createAction(
    '[Related Records] Get Child Cases For Entity',
    props<SystemEntityActionProps>(),
);

export const getChildCasesForEntitySuccess = createAction(
    '[Related Records] Get Child Cases For Entity Success',
    props<
        SystemEntityActionProps & {
            cases: Case[];
        }
    >(),
);

export const getChildCasesForEntityFailure = createAction(
    '[Related Records] Get Child Cases For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getRelatedOpportunitiesForEntity = createAction(
    '[Related Records] Get Related Opportunities For Entity',
    props<SystemEntityActionProps>(),
);

export const getRelatedOpportunitiesForEntitySuccess = createAction(
    '[Related Records] Get Related Opportunities For Entity Success',
    props<
        SystemEntityActionProps & {
            opportunities: Opportunity[];
        }
    >(),
);

export const getRelatedOpportunitiesForEntityFailure = createAction(
    '[Related Records] Get Related Opportunities For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getSuggestedRelatedArticlesForEntity = createAction(
    '[Related Records] Get Suggested Related Articles For Entity',
    props<SystemEntityActionProps>(),
);

export const getSuggestedRelatedArticlesForEntitySuccess = createAction(
    '[Related Records] Get Suggested Related Articles For Entity Success',
    props<
        SystemEntityActionProps & {
            articles: Article[];
        }
    >(),
);

export const getSuggestedRelatedArticlesForEntityFailure = createAction(
    '[Related Records] Get Suggested Related Articles For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getSuggestedRelatedCasesForEntity = createAction(
    '[Related Records] Get Suggested Related Cases For Entity',
    props<SystemEntityActionProps>(),
);

export const getSuggestedRelatedCasesForEntitySuccess = createAction(
    '[Related Records] Get Suggested Related Cases For Entity Success',
    props<
        SystemEntityActionProps & {
            cases: Case[];
        }
    >(),
);

export const getSuggestedRelatedCasesForEntityFailure = createAction(
    '[Related Records] Get Suggested Related Cases For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const getSuggestedRelatedOpportunitiesForEntity = createAction(
    '[Related Records] Get Related Suggested Opportunities For Entity',
    props<SystemEntityActionProps>(),
);

export const getSuggestedRelatedOpportunitiesForEntitySuccess = createAction(
    '[Related Records] Get Related Suggested Opportunities For Entity Success',
    props<
        SystemEntityActionProps & {
            opportunities: Opportunity[];
        }
    >(),
);

export const getSuggestedRelatedOpportunitiesForEntityFailure = createAction(
    '[Related Records] Get Related Suggested Opportunities For Entity Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >(),
);

export const createLinkToArticleForEntity = createAction(
    '[Related Records] Create Link to Article For Entity',
    props<
        SystemEntityActionProps & {
            articleId: string;
        }
    >(),
);

export const createLinkToArticleForEntitySuccess = createAction(
    '[Related Records] Create Link to Article For Entity Success',
    props<
        SystemEntityActionProps & {
            articleId: string;
        }
    >(),
);

export const createLinkToArticleForEntityFailure = createAction(
    '[Related Records] Create Link to Article For Entity Failure',
    props<
        SystemEntityActionProps & {
            articleId: string;
            error: Error;
        }
    >(),
);

export const createLinkToCaseForEntity = createAction(
    '[Related Records] Create Link to Case For Entity',
    props<
        SystemEntityActionProps & {
            caseId: string;
        }
    >(),
);

export const createLinkToCaseForEntitySuccess = createAction(
    '[Related Records] Create Link to Case For Entity Success',
    props<
        SystemEntityActionProps & {
            caseId: string;
        }
    >(),
);

export const createLinkToCaseForEntityFailure = createAction(
    '[Related Records] Create Link to Case For Entity Failure',
    props<
        SystemEntityActionProps & {
            caseId: string;
            error: Error;
        }
    >(),
);

export const createLinkToOpportunityForEntity = createAction(
    '[Related Records] Create Link to Opportunity For Entity',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
        }
    >(),
);

export const createLinkToOpportunityForEntitySuccess = createAction(
    '[Related Records] Create Link to Opportunity For Entity Success',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
        }
    >(),
);

export const createLinkToOpportunityForEntityFailure = createAction(
    '[Related Records] Create Link to Opportunity For Entity Failure',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
            error: Error;
        }
    >(),
);

export const deleteLinkToArticleForEntity = createAction(
    '[Related Records] Delete Link to Article For Entity',
    props<
        SystemEntityActionProps & {
            articleId: string;
        }
    >(),
);

export const deleteLinkToArticleForEntitySuccess = createAction(
    '[Related Records] Delete Link to Article For Entity Success',
    props<
        SystemEntityActionProps & {
            articleId: string;
        }
    >(),
);

export const deleteLinkToArticleForEntityFailure = createAction(
    '[Related Records] Delete Link to Article For Entity Failure',
    props<
        SystemEntityActionProps & {
            articleId: string;
            error: Error;
        }
    >(),
);

export const deleteLinkToCaseForEntity = createAction(
    '[Related Records] Delete Link to Case For Entity',
    props<
        SystemEntityActionProps & {
            caseId: string;
        }
    >(),
);

export const deleteLinkToCaseForEntitySuccess = createAction(
    '[Related Records] Delete Link to Case For Entity Success',
    props<
        SystemEntityActionProps & {
            caseId: string;
        }
    >(),
);

export const deleteLinkToCaseForEntityFailure = createAction(
    '[Related Records] Delete Link to Case For Entity Failure',
    props<
        SystemEntityActionProps & {
            caseId: string;
            error: Error;
        }
    >(),
);

export const deleteLinkToOpportunityForEntity = createAction(
    '[Related Records] Delete Link to Opportunity For Entity',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
        }
    >(),
);

export const deleteLinkToOpportunityForEntitySuccess = createAction(
    '[Related Records] Delete Link to Opportunity For Entity Success',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
        }
    >(),
);

export const deleteLinkToOpportunityForEntityFailure = createAction(
    '[Related Records] Delete Link to Opportunity For Entity Failure',
    props<
        SystemEntityActionProps & {
            opportunityId: string;
            error: Error;
        }
    >(),
);
