import { createAction, props } from '@ngrx/store';
import { ProcessType } from '@wdx/clmi/clmi-swagger-gen';

export const getProcessTypes = createAction(
    '[Process Types] Get Process Types',
);

export const getProcessTypesSuccess = createAction(
    '[Process Types] Get Process Types Success',
    props<{
        processTypes: ProcessType[];
    }>(),
);

export const getProcessTypesFailure = createAction(
    '[Process Types] Get Process Types Failure',
    props<{
        error: Error;
    }>(),
);

export const getProcessType = createAction(
    '[Process Types] Get Process Type',
    props<{
        processTypeId: string;
    }>(),
);

export const getProcessTypeSuccess = createAction(
    '[Process Types] Get Process Type Success',
    props<{
        processType: ProcessType;
    }>(),
);

export const getProcessTypeFailure = createAction(
    '[Process Types] Get Process Type Failure',
    props<{
        processTypeId: string;
        error: Error;
    }>(),
);

export const updateProcessType = createAction(
    '[Process Types] Update Process Type',
    props<{
        processType: ProcessType;
    }>(),
);

export const updateProcessTypeSuccess = createAction(
    '[Process Types] Update Process Type Success',
    props<{
        processType: ProcessType;
    }>(),
);

export const updateProcessTypeFailure = createAction(
    '[Process Types] Update Process Type Failure',
    props<{
        processType: ProcessType;
        error: Error;
    }>(),
);
