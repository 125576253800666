import { createAction, props } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/clmi/clmi-swagger-gen';

export const getAll = createAction('[Organisation Client Types] Get All');

export const getAllSuccess = createAction(
    '[Organisation Client Types] Get All Success',
    props<{
        organisationClientTypes: SelectFieldResult[];
    }>(),
);

export const getAllFailure = createAction(
    '[Organisation Client Types] Get All Failure',
    props<{
        error: Error;
    }>(),
);
