import { Injectable } from '@angular/core';
import {
    Document,
    RiskAssessmentAdviser,
    RiskAssessmentClient,
    RiskAssessmentSnapshot,
    RiskLevel,
} from '@wdx/clmi/clmi-swagger-gen';
import { OxfordRiskReportType } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { OxfordRiskSegment } from './oxford-risk-url-segment';

@Injectable({ providedIn: 'root' })
export class OxfordRiskApiService extends BaseApiService {
    createRiskAssessmentClient(
        partyId: string,
    ): Observable<RiskAssessmentClient> {
        return this.post<object, RiskAssessmentClient>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Person}/${partyId}`,
            {},
        );
    }

    getRiskAssessmentClient(partyId: string): Observable<RiskAssessmentClient> {
        return this.get<RiskAssessmentClient>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Person}/${partyId}`,
        );
    }

    getRiskAssessmentAdvisers(): Observable<RiskAssessmentAdviser[]> {
        return this.get<RiskAssessmentAdviser[]>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Advisers}`,
        );
    }

    getLatestAssessmentForParty(
        partyId: string,
    ): Observable<RiskAssessmentSnapshot[]> {
        return this.get<RiskAssessmentSnapshot[]>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Person}/${partyId}/${OxfordRiskSegment.Snapshots}`,
        );
    }

    getLatestReportForParty(
        partyId: string,
        reportType: OxfordRiskReportType,
        snapshotId?: string,
    ): Observable<Document> {
        return this.patch<Document>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Person}/${partyId}/${OxfordRiskSegment.DownloadOxfordRiskPdf}`,
            {},
            {
                params: {
                    reportType,
                    ...(snapshotId ? { snapshotId } : {}),
                },
            },
        );
    }

    setRiskAssessmentClientRiskLevelForParty(
        partyId: string,
        riskLevel: RiskLevel,
    ): Observable<RiskLevel> {
        return this.put<RiskLevel>(
            `${OxfordRiskSegment.RiskAssessment}/${OxfordRiskSegment.Person}/${partyId}`,
            { riskLevel },
        );
    }
}
