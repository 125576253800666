import { createSelector } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { NgRxService } from '../../services/ngrx.service';
import * as processesReducer from './processes.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    processes: processesReducer.State;
}): processesReducer.State => state.processes;

/**
 * Reducer property selectors
 */
export const getProcessesForCaseIsLoading = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[NgRxService.chunkId(SystemEntity.Case, props)]
            ?.isLoadingList,
);
export const getProcessesForCaseHasError = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[NgRxService.chunkId(SystemEntity.Case, props)]
            ?.hasLoadingListError,
);
export const getProcessesForCase = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[NgRxService.chunkId(SystemEntity.Case, props)]?.list,
);

export const getProcessesIsLoadingSingle = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[props.id]?.isLoadingSingle,
);
export const getProcessesHasLoadingSingleError = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[props.id]?.hasLoadingSingleError,
);
export const getProcess = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[props.id]?.single,
);

export const getProcessIsCreating = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[props.id]?.isCreating,
);
export const getProcessHasCreatingError = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        state.processes[props.id]?.hasCreatingError,
);

/**
 * Derived selectors
 */
export const getIsLoadingListForIds = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        props.ids.some((id) => state.processes[id]?.isLoadingList),
);

export const getHasLoadingListErrorForIds = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        props.ids.some((id) => state.processes[id]?.hasLoadingListError),
);

export const getListForIds = createSelector(
    getState,
    (state: processesReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.processes[id]?.list || [],
            }),
            {},
        ),
);
