import { Action, createReducer, on } from '@ngrx/store';
import { Reminder } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as nudgesActions from './nudges.actions';

export type State = CrudState<Reminder>;

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        nudgesActions.create,
        (state): State => ({
            ...state,
            isCreating: true,
            hasCreatingError: false,
        }),
    ),

    on(
        nudgesActions.createSuccess,
        (state): State => ({
            ...state,
            isCreating: false,
        }),
    ),

    on(
        nudgesActions.createFailure,
        (state): State => ({
            ...state,
            isCreating: false,
            hasCreatingError: true,
        }),
    ),

    on(
        nudgesActions.deleteNudge,
        (state): State => ({
            ...state,
            isDeleting: true,
            hasDeletingError: false,
        }),
    ),

    on(
        nudgesActions.deleteSuccess,
        (state): State => ({
            ...state,
            isDeleting: false,
        }),
    ),

    on(
        nudgesActions.deleteFailure,
        (state): State => ({
            ...state,
            isDeleting: false,
            hasDeletingError: true,
        }),
    ),

    on(
        nudgesActions.dismissNudge,
        (state): State => ({
            ...state,
            isDismissing: true,
            hasDismissingError: false,
        }),
    ),

    on(
        nudgesActions.dismissSuccess,
        (state): State => ({
            ...state,
            isDismissing: false,
        }),
    ),

    on(
        nudgesActions.dismissFailure,
        (state): State => ({
            ...state,
            isDismissing: false,
            hasDismissingError: true,
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
