import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    ICON_COMMENT,
    ICON_COMMENT_ISSUE,
    ICON_COMMENT_PLUS,
} from '../../../../constants/icons.constants';

import { ActivityType, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { WdxSize } from '@wdx/shared/utils';
import { CommentsFacade } from '../shared/services/comments.facade';
import { CommentsService } from '../shared/services/comments.service';

@Component({
    selector: 'clmi-comment-button',
    templateUrl: './comment-button.component.html',
    styleUrls: ['./comment-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentButtonComponent implements OnInit {
    @Input() count = 0;
    @Input() entityId: string;
    @Input() hasIssue: boolean;
    @Input() modalTitle: string;
    @Input() activityType: ActivityType;
    @Input() entityType: SystemEntity;
    @Input() preventClickEvent = false;
    @Input() size: WdxSize = 'md';
    @Input() cySelector: string;
    @Input() disabled = false;

    @Output() clmiOnClick = new EventEmitter();

    readonly ICON_COMMENT = ICON_COMMENT.icon;
    readonly ICON_COMMENT_PLUS = ICON_COMMENT_PLUS.icon;
    readonly ICON_COMMENT_ISSUE = ICON_COMMENT_ISSUE.icon;

    constructor(
        private commentsService: CommentsService,
        private commentsFacade: CommentsFacade,
    ) {}

    ngOnInit(): void {
        this.commentsFacade.activityType = this.activityType;
    }

    onClick(event: MouseEvent): void {
        event.stopPropagation();

        if (!this.preventClickEvent) {
            this.commentsFacade.id = this.entityId;
            this.commentsFacade.activityType = this.activityType;
            this.commentsService.open(
                this.entityId,
                this.modalTitle,
                this.entityType,
            );
        }

        this.clmiOnClick.emit();
    }
}
