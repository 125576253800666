import { Directive, Input, OnInit } from '@angular/core';
import { View } from '@wdx/clmi/clmi-swagger-gen';
import { BaseWidgetContainerClass } from './base-widget-container.class';

@Directive()
export class BaseWidgetClass
    extends BaseWidgetContainerClass
    implements OnInit
{
    @Input() views: View[];

    onViewSelected(view: View) {
        this.viewSelected.emit(view);
    }
}
