/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CaseStatusType {
  Pending = 'Pending',
  Active = 'Active',
  Complete = 'Complete',
  Cancelled = 'Cancelled'
}
