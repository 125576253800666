import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetClass } from '../../../classes/base-widget.class';
import { INFINITY_SCROLL_CONTENT } from '../../../constants/inifinity-scroll-content.contants';
import { Case } from '@wdx/clmi/clmi-swagger-gen';
import { CardType } from '../../../models/card-type.model';
import { ScrollMode } from '../../../models/scroll-mode.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'widget-cases-list',
    templateUrl: './widget-cases-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block' },
})
export class WidgetCasesListComponent
    extends BaseWidgetClass
    implements OnInit
{
    @Input() cases: Case[];
    @Input() casesPerRow: number;
    @Input() showCaseListActionsButtons: boolean;
    @Input() totalRecordCount: number;
    @Input() showLegend = true;
    @Input() showFilters = true;
    @Input() showSearchBar = true;
    @Input() showFavourite = true;
    @Input() scrollMode: ScrollMode;
    @Input() linkToEntityType: string;
    @Input() linkToEntityId: string;

    @Output() infinityScrollFired = new EventEmitter<INFINITY_SCROLL_CONTENT>();

    cardType: CardType = CardType.Case;
    limit = 3;
    modalId: string;

    ngOnInit() {
        this.modalId = `all-${this.cardType}-cards`;
        if (this.spokeButton) {
            this.setDefaultModal(this.modalId);
            this.spokeButton.isHidden =
                !this.cases?.length ||
                (this.limit > 0 && this.cases?.length <= this.limit);
        }
    }

    onInfinityScrollFired() {
        this.infinityScrollFired.emit(INFINITY_SCROLL_CONTENT.Cases);
    }
}
