import { AppStatusType } from '@wdx/clmi/clmi-swagger-gen';

import { Severity } from '@wdx/shared/utils';
import {
    ICON_DANGER,
    ICON_INFO,
    ICON_SUCCESS,
    ICON_WARNING,
} from './icons.constants';

export const APP_STATUS_SEVERITY_MAP = {
    [AppStatusType.Incomplete]: Severity.Danger,
    [AppStatusType.PendingConfirmation]: Severity.Info,
    [AppStatusType.NearingExpiration]: Severity.Warning,
    [AppStatusType.Expired]: Severity.Danger,
    [AppStatusType.Confirmed]: Severity.Success,
    [AppStatusType.Inactive]: Severity.Info,
};

export const APP_SEVERITY_ICON_MAP = {
    [Severity.Success]: ICON_SUCCESS,
    [Severity.Warning]: ICON_WARNING,
    [Severity.Danger]: ICON_DANGER,
    [Severity.None]: ICON_INFO,
    [Severity.Info]: ICON_INFO,
};

export const APP_CHANGE_OF_CIRCUMSTANCE_LABEL = 'Change Summary';
export const APP_CHANGE_OF_CIRCUMSTANCE = 'appChangeOfCircumstance';
