import { createAction, props } from '@ngrx/store';
import { PartyAvatarInfo, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

export const getForEntityId = createAction(
    '[Avatar Info] Get For Entity ID',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);

export const getForEntityIdSuccess = createAction(
    '[Avatar Info] Get For Entity ID Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        avatarInfo: PartyAvatarInfo;
    }>(),
);

export const getForEntityIdFailure = createAction(
    '[Avatar Info] Get For Entity ID Failure',
    props<{
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>(),
);
