import { Injectable } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { CommentsModalComponent } from '../../../../../shared/features/comments/comments-modal/comments-modal.component';

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    private component: CommentsModalComponent;

    setComponent(component: CommentsModalComponent): void {
        this.component = component;
    }

    open(
        activityId: string,
        modalTitle?: string,
        entityType?: SystemEntity,
    ): void {
        this.component.modalTitle = modalTitle;
        this.component.entityId = activityId;
        this.component.entityType = entityType;
        this.component.open();
    }

    close(): void {
        this.component.close();
    }
}
