import { Action, createReducer, on } from '@ngrx/store';
import { Person } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { FAVOURITES_INDEX_ID } from '../../constants/state.constants';
import * as peopleActions from './people.actions';

export interface State {
    people: CrudStateObject<Person>;
}

export const initialState: State = {
    people: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        peopleActions.getForParty,
        (state, props): State => ({
            ...state,
            people: {
                ...state.people,
                [props.partyId]: {
                    ...(state.people[props.partyId] ||
                        ({} as CrudState<Person>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        peopleActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            people: {
                ...state.people,
                [props.partyId]: {
                    ...state.people[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.person,
                },
            },
        }),
    ),

    on(
        peopleActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            people: {
                ...state.people,
                [props.partyId]: {
                    ...state.people[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        peopleActions.getFavourites,
        (state): State => ({
            ...state,
            people: {
                ...state.people,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.people[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Person>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        peopleActions.getFavouritesSuccess,
        (state, props): State => ({
            ...state,
            people: {
                ...state.people,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.people[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Person>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.favourites,
                },
            },
        }),
    ),

    on(
        peopleActions.getFavouritesFailure,
        (state): State => ({
            ...state,
            people: {
                ...state.people,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.people[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Person>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
