import { Pipe, PipeTransform } from '@angular/core';
import { TextToNumberPipe } from '../text-to-number/text-to-number.pipe';

@Pipe({ name: 'textToHsl', standalone: true })
export class TextToHslPipe implements PipeTransform {
    transform(
        value: string | undefined | null,
        params?: { saturation?: number; luminance?: number },
    ): string | undefined {
        if (!value) {
            return;
        }

        const hue = new TextToNumberPipe().transform(value, 360);

        return `hsl(${hue},${params?.saturation || 100}%,${
            params?.luminance ? params.luminance + '%' : 'var(--bs-luminance)'
        })`;
    }
}
