/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ActionType {
  Patch = 'Patch',
  Form = 'Form',
  NewForm = 'NewForm',
  OpenUrl = 'OpenUrl'
}
