<div
    class="form-action-buttons px-4 py-2 active-component d-flex align-items-center"
>
    <div class="ms-auto d-flex align-items-center">
        @if (showQuickCreateButton && createActionButton) {
            <molecule-action-button
                class="me-2"
                data-cy="btn-create-new"
                btnClass="btn-outline-primary"
                [actionButton]="createActionButton"
            >
                <span class="action-button-standard">
                    {{ CREATE_NEW | translate }}
                </span>
            </molecule-action-button>
        }
        @if (lookupMode === LOOKUP_MODE.Standard) {
            <button
                class="btn btn-primary flex-shrink-0"
                data-cy="btn-confirm"
                (click)="onConfirmClicked()"
            >
                {{ CONFIRM | translate }}
            </button>
        }
    </div>
</div>

@if (multipleLookupEnabled) {
    <div class="px-2 pt-3 pb-1">
        <ng-select
            bindLabel="type"
            [searchable]="false"
            [clearable]="false"
            [items]="formInputData.lookupSources"
            data-cy="dropdown-SourceType"
            [(ngModel)]="activeLookupSourceDefinition"
            (change)="onLookupSourceTypeChange($event)"
        >
            <ng-template ng-label-tmp let-item="item">
                <span class="fw-bold"
                    >Search
                    {{
                        item?.type | translateEnum: ENUM_TRANSLATION_TYPE
                    }}</span
                >
                (or select dropdown to change)
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item?.type | translateEnum: ENUM_TRANSLATION_TYPE }}
            </ng-template>
        </ng-select>
    </div>
}

<div
    id="info-card-search"
    class="bg-muted offcanvas-body flex-shrink-0 d-flex flex-grow-0"
>
    <molecule-input-search
        class="flex-grow-1"
        cySelector="info-card-lookupSearch"
        [formInputData]="{ id: 'lookupSearch' }"
        [placeholder]="placeholder"
        [autoFocus]="true"
        (valueChanged)="
            onSearch($event, formInputData?.minimumSearchCharacters)
        "
    ></molecule-input-search>

    @if (lookupMode === LOOKUP_MODE.DocumentTemplate) {
        <atom-icon-button
            class="ms-2"
            size="sm"
            icon="download"
            [disabled]="!value"
            (click)="onGenerateAndDownloadDocument()"
        ></atom-icon-button>
        <atom-icon-button
            class="ms-2"
            size="sm"
            icon="code"
            [disabled]="!value"
            (click)="onPreviewAvailableDocumentData()"
        ></atom-icon-button>
    }
</div>

@if (bannerInfo) {
    <div
        class="flex-shrink-0 flex-grow-0 bg-muted offcanvas-body d-flex align-items-center pt-0"
    >
        <wdx-icon class="me-1" size="md" [icon]="ICON_INFO"></wdx-icon>
        <span>
            {{ bannerInfo }}
        </span>
    </div>
}
<ng-content></ng-content>

<div class="flex-grow-1 overflow-hidden" data-cy="search-result">
    <atom-async-data [isLoading]="isLoading" [hasError]="hasError">
        <div class="list-group list-group-flush h-100 overflow-auto">
            @for (
                infoCardData of clientSideSearch
                    | fn: parsedInfoCards : infoCards : searchText;
                track infoCardData.id
            ) {
                <label
                    role="button"
                    class="list-group-item list-group-item-action d-flex align-items-center p-0"
                    data-cy="lbl-info-card-search"
                    [class.active]="
                        mode === MODE.Select
                            ? value === infoCardData.data
                            : (arrayCache
                              | arrayIncludes: infoCardData.data : 'id')
                    "
                    [for]="infoCardData.id"
                >
                    <ng-container [ngSwitch]="mode">
                        <input
                            *ngSwitchCase="MODE.Select"
                            class="form-check-input flex-shrink-0 ms-2"
                            type="radio"
                            [id]="infoCardData.id"
                            [name]="inputName"
                            [value]="infoCardData.data"
                            [ngModel]="value"
                            (ngModelChange)="onChange($event)"
                        />
                        <input
                            *ngSwitchCase="MODE.MultiSelect"
                            class="form-check-input flex-shrink-0 ms-2"
                            type="checkbox"
                            [id]="infoCardData.id"
                            [name]="inputName"
                            [ngModel]="
                                arrayCache?.length &&
                                (arrayCache
                                    | arrayIncludes: infoCardData.data : 'id')
                            "
                            (ngModelChange)="onToggle(infoCardData.data)"
                        />
                    </ng-container>
                    <molecule-info-card
                        class="text-truncate ms-2 pt-2 pb-2 w-100"
                        [primaryInfo]="
                            infoCardData.primaryInfo | translateTokenisedString
                        "
                        [routerLinkData]="infoCardData.routerLinkData"
                        [secondaryInfo]="infoCardData.secondaryInfo"
                        [tertiaryInfo]="infoCardData.tertiaryInfo"
                        [avatars]="infoCardData.avatars"
                        [icon]="infoCardData.icon"
                        [dynamicFormSetup]="infoCardData.formSetup"
                    ></molecule-info-card>
                </label>
            }
        </div>
    </atom-async-data>
</div>
