import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { View } from '@wdx/clmi/clmi-swagger-gen';
import { switchMap, take } from 'rxjs/operators';
import { FilterViewFacadeService } from '../../../../shared/features/form-filters';
import { RouteDataService } from '../../../../shared/services';
import { FilterQueryFacadeService } from '@wdx/clmi/api-services/state';

@Injectable()
export class FilterResolver implements Resolve<any> {
    constructor(
        private filterViewFacadeService: FilterViewFacadeService,
        private filterQueryFacadeService: FilterQueryFacadeService,
        private routeDataService: RouteDataService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<View> {
        this.routeDataService.resolveFromRoute(route);

        return new Promise((resolve) => {
            this.filterViewFacadeService
                .getViewsForEntityType$(
                    this.routeDataService.viewType,
                    this.routeDataService.contextual,
                )
                .pipe(
                    take(1),
                    switchMap((views) =>
                        this.filterViewFacadeService.getSelectedView$(
                            views,
                            route.data,
                            route?.queryParams?.selectView,
                        ),
                    ),
                )
                .subscribe((view) => {
                    this.routeDataService.setView(view);
                    this.filterQueryFacadeService.resetResultsGetRecords(
                        this.routeDataService.queryType,
                        view?.filter,
                        this.routeDataService.paginationOptions,
                    );
                    resolve(view);
                });
        });
    }
}
