import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupFieldResult, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { REMOVE_NAME } from '@wdx/shared/utils';

@Injectable()
export class LookupsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getLookup(
        lookupSource: string,
        searchText?: string,
        entityId?: string,
        entityType?: SystemEntity,
    ): Observable<LookupFieldResult[]> {
        return this.http.get<LookupFieldResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/lookup/${lookupSource.toLowerCase()}`,
            {
                params: {
                    search: searchText,
                    ...(entityId ? { entityId } : {}),
                    ...(entityType ? { entityType } : {}),
                },
            },
        );
    }

    getContextualLookup(
        lookupSource: string,
        lookups: any[],
        search?: string,
    ): Observable<LookupFieldResult[]> {
        const DATA_CLEAN = REMOVE_NAME(lookups);

        return this.http.post<LookupFieldResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/lookup/${lookupSource.toLowerCase()}`,
            {
                search,
                lookups: DATA_CLEAN,
            },
        );
    }
}
