import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PowerBiEmbedReport, PowerBiReport } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../services/config.service';

const POWER_BI_ENDPOINT = 'system/powerbi/poc';

@Injectable({ providedIn: 'root' })
export class PowerBiRequestsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    /**
     * @deprecated The api this refers to returns a 500. May need to be updated if
     * PowerBI is reimplemented in the future.
     */
    getPowerBiList(): Observable<PowerBiReport[]> {
        return this.http.get<PowerBiReport[]>(
            `${this.config.getConfiguration().API_BASE}/${POWER_BI_ENDPOINT}`,
        );
    }

    getDashboardConfig(
        powerBiDashboardId: string,
    ): Observable<PowerBiEmbedReport> {
        return this.http.get<PowerBiEmbedReport>(
            `${
                this.config.getConfiguration().API_BASE
            }/${POWER_BI_ENDPOINT}/${powerBiDashboardId}`,
        );
    }
}
