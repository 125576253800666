import { Action, createReducer, on } from '@ngrx/store';

import {
    AccessVisibility,
    ActivityTask,
    HeatMap,
    KeyStatResult,
    List,
    Party,
    PartyRoleRelationship,
    PartyRoleTeamMember,
    PartyTeamMember,
} from '@wdx/clmi/clmi-swagger-gen';

import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { KeyValueObject } from '@wdx/shared/utils';
import * as partiesActions from './parties.actions';

export interface State {
    parties?: CrudStateObject<Party>;
    keyStatsForParty?: CrudStateObject<KeyStatResult>;
    heatMap?: CrudStateObject<HeatMap>;
    teamMembersForParty?: CrudStateObject<PartyTeamMember>;
    teamMembersForPartyRole?: CrudStateObject<PartyRoleTeamMember>;
    visibilityForParty?: CrudStateObject<AccessVisibility>;
    tasks?: CrudStateObject<ActivityTask>;
    organisationConnections?: CrudStateObject<PartyRoleRelationship>;
    lists?: KeyValueObject<CrudStateObject<List>>;
}

export const initialState: State = {
    parties: {},
    keyStatsForParty: {},
    heatMap: {},
    teamMembersForParty: {},
    teamMembersForPartyRole: {},
    visibilityForParty: {},
    tasks: {},
    organisationConnections: {},
    lists: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        partiesActions.getForId,
        (state, props): State => ({
            ...state,
            parties: {
                ...state.parties,
                [props.partyId]: {
                    ...(state.parties[props.partyId] ||
                        ({} as CrudState<Party>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            parties: {
                ...state.parties,
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.party,
                },
            },
        }),
    ),

    on(
        partiesActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            parties: {
                ...state.parties,
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getKeyStatsForParty,
        (state, props): State => ({
            ...state,
            keyStatsForParty: {
                ...state.keyStatsForParty,
                [props.partyId]: {
                    ...(state.keyStatsForParty[props.partyId] ||
                        ({} as CrudState<KeyStatResult>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getKeyStatsForPartySuccess,
        (state, props): State => ({
            ...state,
            keyStatsForParty: {
                ...state.keyStatsForParty,
                [props.partyId]: {
                    ...state.keyStatsForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.keyStatsForParty,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForParty,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                ...state.teamMembersForParty,
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForPartySuccess,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                ...state.teamMembersForParty,
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.teamMembersForParty,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForPartyFailure,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                ...state.teamMembersForParty,
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForPartyRole,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                ...state.teamMembersForPartyRole,
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                ...state.teamMembersForPartyRole,
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.teamMembersForPartyRole,
                },
            },
        }),
    ),

    on(
        partiesActions.getTeamMembersForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                ...state.teamMembersForPartyRole,
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getKeyStatsForPartyFailure,
        (state, props): State => ({
            ...state,
            keyStatsForParty: {
                ...state.keyStatsForParty,
                [props.partyId]: {
                    ...state.keyStatsForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getHeatMapForParty,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.partyId]: {
                    ...(state.heatMap[props.partyId] ||
                        ({} as CrudState<HeatMap>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getHeatMapForPartySuccess,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.partyId]: {
                    ...state.heatMap[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.heatMap,
                },
            },
        }),
    ),

    on(
        partiesActions.getHeatMapForPartyFailure,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.partyId]: {
                    ...state.heatMap[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.activateParty,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.activatePartySuccess,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.activatePartyFailure,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.inactivateParty,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.inactivatePartySuccess,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.inactivatePartyFailure,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.resendInvite,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.resendInviteSuccess,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.resendInviteFailure,
        (state, props): State => ({
            ...state,
            parties: {
                [props.partyId]: {
                    ...state.parties[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForParty,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForPartySuccess,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForPartyFailure,
        (state, props): State => ({
            ...state,
            teamMembersForParty: {
                [props.partyId]: {
                    ...state.teamMembersForParty[props.partyId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForPartyRole,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.setPrimaryTeamMemberForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            teamMembersForPartyRole: {
                [props.partyRoleId]: {
                    ...state.teamMembersForPartyRole[props.partyRoleId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getVisibilityForParty,
        (state, props): State => ({
            ...state,
            visibilityForParty: {
                ...state.visibilityForParty,
                [props.partyId]: {
                    ...(state.visibilityForParty[props.partyId] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getVisibilityForPartySuccess,
        (state, props): State => ({
            ...state,
            visibilityForParty: {
                ...state.visibilityForParty,
                [props.partyId]: {
                    ...state.visibilityForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        }),
    ),

    on(
        partiesActions.getVisibilityForPartyFailure,
        (state, props): State => ({
            ...state,
            visibilityForParty: {
                ...state.visibilityForParty,
                [props.partyId]: {
                    ...state.visibilityForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
    on(
        partiesActions.getAllTasksForParty,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyId]: {
                    ...(state.tasks[props.partyId] ||
                        ({} as CrudState<ActivityTask>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: state.tasks[props.partyId]?.infinity,
                },
            },
        }),
    ),

    on(
        partiesActions.getAllTasksForPartySuccess,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyId]: {
                    ...state.tasks[props.partyId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.tasks.paging,
                        combinedList: [
                            ...(state.tasks[props.partyId].infinity
                                ?.combinedList || []),
                            ...props.tasks.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        partiesActions.getAllTasksForPartyFailure,
        (state, props): State => ({
            ...state,
            tasks: {
                ...state.tasks,
                [props.partyId]: {
                    ...state.tasks[props.partyId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getForPartyOrganisationConnections,
        (state, props): State => ({
            ...state,
            organisationConnections: {
                ...state.organisationConnections,
                [props.partyId]: {
                    ...(state.organisationConnections[props.partyId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        partiesActions.getOrganisationConnectionsForPartySuccess,
        (state, props): State => ({
            ...state,
            organisationConnections: {
                ...state.organisationConnections,
                [props.partyId]: {
                    ...state.organisationConnections[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.relationships,
                },
            },
        }),
    ),

    on(
        partiesActions.getOrganisationConnectionsForPartyFailure,
        (state, props): State => ({
            ...state,
            organisationConnections: {
                ...state.organisationConnections,
                [props.partyId]: {
                    ...state.organisationConnections[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        partiesActions.getMembershipListsForEntity,
        (state, props): State => ({
            ...state,
            lists: {
                ...state.lists,
                [props.entityType]: {
                    ...(state.lists[props.entityType] || {}),
                    [props.entityId]: {
                        ...(state.lists[props.entityType]?.[props.entityId] ||
                            {}),
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            },
        }),
    ),

    on(
        partiesActions.getMembershipListsForEntitySuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...state.lists,
                [props.entityType]: {
                    ...state.lists[props.entityType],
                    [props.entityId]: {
                        ...state.lists[props.entityType][props.entityId],
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.lists,
                    },
                },
            },
        }),
    ),

    on(
        partiesActions.getMembershipListsForEntityFailure,
        (state, props): State => ({
            ...state,
            lists: {
                ...state.lists,
                [props.entityType]: {
                    ...state.lists[props.entityType],
                    [props.entityId]: {
                        ...state.lists[props.entityType][props.entityId],
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
