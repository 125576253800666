/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ActivityStatusType {
  Draft = 'Draft',
  Active = 'Active',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  NotRequired = 'NotRequired'
}
