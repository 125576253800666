import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Document,
    Envelope,
    Signatory,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { EnvelopeUrlSegment } from './envelope.static';

@Injectable({ providedIn: 'root' })
export class EnvelopeService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    /**
     * Get all envelopes
     */
    getAll(): Observable<PaginatedApiResponse<Envelope>> {
        return this.get<PaginatedApiResponse<Envelope>>(
            `${EnvelopeUrlSegment.Envelope}`,
        );
    }

    /**
     * Get all envelopes for a given entity and entity id
     */
    getForEntityId(
        entityId: string,
        entity: SystemEntity,
        status?: string,
    ): Observable<Envelope[]> {
        const STATUS = status ? `?status=${status}` : '';

        return this.get<Envelope[]>(
            `${String(entity).toLowerCase()}/${entityId}/${
                EnvelopeUrlSegment.Envelopes
            }${STATUS}`,
        );
    }

    /**
     * Get a single envelope object from an envelope id
     */
    getForId(id: string): Observable<Envelope> {
        return this.get<Envelope>(`${EnvelopeUrlSegment.Envelope}/${id}`);
    }

    /**
     * Send an envelope
     */
    sendForId(id: string): Observable<Envelope> {
        return this.post(
            `${EnvelopeUrlSegment.Envelope}/${id}/${EnvelopeUrlSegment.Send}`,
            {},
        );
    }

    /**
     * Resend an envelope
     */
    resendForId(id: string): Observable<Envelope> {
        return this.post(
            `${EnvelopeUrlSegment.Envelope}/${id}/${EnvelopeUrlSegment.Resend}`,
            {},
        );
    }

    /**
     * Delete an envelope
     */
    deleteForId(id: string): Observable<Response> {
        return this.delete(`${EnvelopeUrlSegment.Envelope}/${id}`);
    }

    /**
     * Get all documents associated with an envelope id
     */
    getDocumentsForId(id: string): Observable<Document[]> {
        return this.get<Document[]>(
            `${EnvelopeUrlSegment.Envelope}/${id}/${EnvelopeUrlSegment.Document}`,
        );
    }

    /**
     * Get all signatories associated with an envelope id
     */
    getSignatoriesForId(id: string): Observable<Signatory[]> {
        return this.get<Signatory[]>(
            `${EnvelopeUrlSegment.Envelope}/${id}/${EnvelopeUrlSegment.Signatory}`,
        );
    }

    /**
     * Add a document to an envelope
     */
    addDocumentForId(envelopeId: string, documentId: string): Observable<any> {
        return this.put(
            `${EnvelopeUrlSegment.Envelope}/${envelopeId}/${EnvelopeUrlSegment.Document}/${documentId}`,
            {},
        );
    }

    /**
     * Add a signatory to an envelope
     */
    addSignatoryForId(envelopeId: string, partyId: string): Observable<any> {
        return this.put(
            `${EnvelopeUrlSegment.Envelope}/${envelopeId}/${EnvelopeUrlSegment.Signatory}`,
            { envelopeId, partyId },
        );
    }

    /**
     * Delete a document from an envelope
     */
    deleteDocumentForId(
        envelopeId: string,
        documentId: string,
    ): Observable<void> {
        return this.delete(
            `${EnvelopeUrlSegment.Envelope}/${envelopeId}/${EnvelopeUrlSegment.Document}/${documentId}`,
        );
    }
}
