import { Action, createReducer, on } from '@ngrx/store';
import { Article, QueueItem } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { systemEntityStateId } from '../_helpers/system-entity-action-id-compiler';
import * as queuesActions from './queues.actions';

export interface State {
    queues: CrudStateObject<QueueItem>;
    assignees: CrudStateObject<any>;
}

export const initialState: State = {
    queues: {},
    assignees: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        queuesActions.assign,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [systemEntityStateId(props)]: {
                    ...(state.assignees[systemEntityStateId(props)] ||
                        ({} as CrudState<Article>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        queuesActions.assignSuccess,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [systemEntityStateId(props)]: {
                    ...state.assignees[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        queuesActions.assignFailure,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [systemEntityStateId(props)]: {
                    ...state.assignees[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
