import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { PartyRoleRelationship } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class ConnectionsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForPartyRole(partyRoleId: string): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyRole/${partyRoleId}/connections`,
        );
    }
}
