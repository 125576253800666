/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ContactPointType {
  Unknown = 'Unknown',
  Postal = 'Postal',
  Email = 'Email',
  Phone = 'Phone',
  Social = 'Social',
  ElectronicBanking = 'ElectronicBanking'
}
