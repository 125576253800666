import { Pipe, PipeTransform } from '@angular/core';
import { IdentityMap } from '@wdx/clmi/clmi-swagger-gen';

@Pipe({ name: 'identitiesToLockedByList' })
export class IdentitiesToLockedByListPipe implements PipeTransform {
    transform(identities: IdentityMap[]): string[] {
        if (!identities?.length) {
            return;
        }

        return identities
            .filter((identity) => identity.isLocked)
            .map((identity) => identity.source.name);
    }
}
