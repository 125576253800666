import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

/**
 * ===========
 * Query types
 * ===========
 */

export enum ActivityQueryType {
    Inbox = 'Inbox',
}

export enum UserQueryType {
    UserLogin = 'UserLogin',
}

export type QueryType = SystemEntity | ActivityQueryType | UserQueryType;
