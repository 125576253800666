import { Injectable, inject } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';

import {
    FieldDefinition,
    FormContext,
    FormDefinition,
    FormElementLayoutDefinition,
    FormElementType,
    FormFieldType,
    FormInitialisationMode,
    WdxDestroyClass,
} from '@wdx/shared/utils';

import {
    IExtendedFieldDefinition,
    ILayoutAndSchemaFieldDefinition,
    ISubFormLayoutFormData,
    ISubFormPendingChange,
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../models';
import {
    FormConditionsService,
    FormProcessConditionDataService,
} from '../form-conditions';
import { FormControlService } from '../form-control/form-control.service';
import { FormFieldsDataService } from '../form-fields-data';
import { FormPendingChangesService } from '../form-pending-changes/form-pending-changes.service';
import { FormStaticService } from '../form-static/form-static.class';
import { FormValidationService } from '../form-validation';
import {
    CREATE_FORM_GROUP,
    CREATE_FORM_GROUP_HIDE_END,
    FORM_GROUP,
    MANUAL_FORM_GROUP,
} from './form-builder.static';
import * as R from 'ramda';

@Injectable()
export class FormBuilderService extends WdxDestroyClass {
    firstLevel: { [key: string]: any } = {};

    private fb = inject(UntypedFormBuilder);
    private formValidationService = inject(FormValidationService);
    private formStaticService = inject(FormStaticService);
    private formProcessService = inject(FormProcessConditionDataService);
    private formConditionsService = inject(FormConditionsService);
    private formControlService = inject(FormControlService);
    private formPendingChangesService = inject(FormPendingChangesService);
    private formFieldsDataService = inject(FormFieldsDataService);

    buildForm(extraContext?: Record<string, any>): void {
        this.formStaticService.form = this.fb.group(
            this.getFormControlsFromDefinition(
                this.formStaticService.formDefinition,
            ),
        );

        if (extraContext) {
            this.formStaticService.formData.context = R.mergeDeepRight(
                this.formStaticService.formData.context || {},
                extraContext,
            ) as FormContext;
        }

        this.formStaticService?.formLayout?.messageDefinitions?.map(
            (message) => {
                this.formFieldsDataService.messageDefinitionToObject[
                    message.name as string
                ] = message;
            },
        );
    }

    getFormControlsFromDefinition(
        formDefinition: FormDefinition,
    ): Record<string, any> {
        return {
            ...formDefinition.schema?.reduce(
                (accumulator, formFieldDefinition) => {
                    if (formFieldDefinition.fieldType === 'Array') {
                        const ARR_FORM_GROUP = this.loopFormSchemaData(
                            formFieldDefinition,
                            false,
                            formFieldDefinition.name as string,
                        ) as ISubFormLayoutFormData;

                        if (
                            !this.firstLevel[formFieldDefinition.name as string]
                        ) {
                            this.firstLevel[
                                formFieldDefinition.name as string
                            ] = {} as UntypedFormGroup;
                        }

                        this.firstLevel[formFieldDefinition.name as string] =
                            ARR_FORM_GROUP;

                        return {
                            ...accumulator,
                            [formFieldDefinition.name as string]:
                                ARR_FORM_GROUP.formArray,
                        };
                    }

                    if (
                        MANUAL_FORM_GROUP.includes(
                            formFieldDefinition.fieldType as FormFieldType,
                        )
                    ) {
                        this.formFieldsDataService.addFieldsToNullCheck(
                            this.formStaticService.fieldsToNullCheck,
                            formFieldDefinition.name as string,
                        );
                        const ADDRESS_DATA =
                            this.formStaticService?.formData?.data?.[
                                formFieldDefinition.name as string
                            ];

                        return {
                            ...accumulator,
                            [formFieldDefinition.name as string]: this.fb.group(
                                ADDRESS_DATA ?? {},
                            ),
                        };
                    }

                    return {
                        ...accumulator,
                        [formFieldDefinition.name as string]:
                            this.setFormControlByType(
                                false,
                                formFieldDefinition,
                                formDefinition?.layout?.sectionLayoutDefinitions?.[0]?.elementLayoutDefinitions?.find(
                                    (eld) =>
                                        eld.name === formFieldDefinition.name,
                                ) as FormElementLayoutDefinition,
                                {},
                            ),
                    };
                },
                {},
            ),
        };
    }

    setFormData(
        skipData: boolean,
        formFieldDefinition: FieldDefinition,
        isArray = false,
        index = 0,
        name?: string,
        childName?: string,
        childIndex?: number,
    ): any {
        if (
            skipData ||
            typeof this.formStaticService?.formData === 'undefined' ||
            typeof this.formStaticService?.formData?.data === 'undefined' ||
            (typeof this.formStaticService?.formData?.data[
                formFieldDefinition?.name as string
            ] === 'undefined' &&
                !isArray) ||
            (typeof this.formStaticService?.formData?.data[name as string] ===
                'undefined' &&
                isArray)
        ) {
            return null;
        }

        if (!isArray) {
            return this.setInitialValue(formFieldDefinition);
        }

        return this.setInitialArrayValue(
            formFieldDefinition,
            name as string,
            index,
            childName as string,
            childIndex as number,
            isArray,
        );
    }

    setInitialValue(formFieldDefinition: FieldDefinition): any {
        const Initial =
            this.formStaticService.formData.data[
                formFieldDefinition.name as string
            ];

        return this.getDefaultValue(Initial, formFieldDefinition);
    }

    setInitialArrayValue(
        formFieldDefinition: FieldDefinition,
        name: string,
        index: number,
        childName: string,
        childIndex: number,
        isArray: boolean,
    ): any {
        let initialValue;

        if (childName && isArray) {
            const Initial =
                this.formStaticService.formData.data[name][index][childName][
                    childIndex
                ][formFieldDefinition.name as string];

            initialValue = this.getDefaultValue(Initial, formFieldDefinition);
        } else {
            const Initial =
                this.formStaticService.formData.data[name][index][
                    formFieldDefinition.name as string
                ];

            initialValue = this.getDefaultValue(Initial, formFieldDefinition);
        }

        return initialValue;
    }

    getDefaultValue(Initial: any, formFieldDefinition: FieldDefinition): any {
        return this.useDefaultValue(Initial, formFieldDefinition)
            ? formFieldDefinition.defaultValue
            : Initial;
    }

    useDefaultValue(
        InitialValue: any,
        formFieldDefinition: FieldDefinition,
    ): boolean {
        return (
            InitialValue === null &&
            this.formStaticService.initialisationMode ===
                FormInitialisationMode.Add &&
            // eslint-disable-next-line no-prototype-builtins
            formFieldDefinition.hasOwnProperty('defaultValue')
        );
    }

    setFormControlByType(
        skipData: boolean,
        formFieldDefinition: FieldDefinition,
        layout: FormElementLayoutDefinition,
        config: {
            isArray?: boolean;
            index?: number;
            name?: string;
            childName?: string;
            childIndex?: number;
        },
    ): UntypedFormGroup | UntypedFormControl {
        const { isArray, index, name, childName, childIndex } = config;
        const DATA = this.setFormData(
            skipData,
            formFieldDefinition,
            isArray,
            index,
            name,
            childName,
            childIndex,
        );

        if (
            FORM_GROUP.includes(formFieldDefinition.fieldType as FormFieldType)
        ) {
            return new UntypedFormGroup(
                {
                    ...this.createFormGroupForComponent(
                        layout?.elementStyle?.includes('StartOnly')
                            ? (
                                  CREATE_FORM_GROUP_HIDE_END as Record<
                                      string,
                                      any
                                  >
                              )[formFieldDefinition.fieldType as string]
                            : (CREATE_FORM_GROUP as Record<string, any>)[
                                  formFieldDefinition.fieldType as string
                              ],
                        DATA,
                    ),
                },
                this.formValidationService.getValidators(
                    formFieldDefinition,
                    layout,
                ),
            );
        }

        return new UntypedFormControl(
            { value: DATA, disabled: false },
            {
                validators:
                    this.formValidationService.getValidators(
                        formFieldDefinition,
                    ),
                updateOn: formFieldDefinition.inputMaskRegEx
                    ? 'blur'
                    : 'change',
            },
        );
    }

    createFormGroupForComponent(
        arrayData: any[],
        content: { [key: string]: string | number | boolean },
    ): { string: UntypedFormControl } {
        return arrayData.reduce((accumulator, current) => {
            return {
                ...accumulator,
                [current.formControlName]: new UntypedFormControl({
                    value:
                        content == null
                            ? content
                            : typeof content[current.formControlName] !==
                                'undefined'
                              ? content[current.formControlName]
                              : content,
                    disabled: false,
                }),
            };
        }, {});
    }

    findDefinition(name: string, parent?: string): FieldDefinition[] {
        let i = 0;
        const MAX = this.formStaticService.formDefinition.schema
            ?.length as number;
        const SCHEMA = this.formStaticService.formDefinition
            .schema as FieldDefinition[];
        let res: any[] = [];

        while (i < MAX) {
            if (SCHEMA[i].name === name && !parent) {
                res = SCHEMA[i].childSchema as FieldDefinition[];
                i = MAX;
            }

            if (parent && SCHEMA[i].name === parent) {
                SCHEMA[i].childSchema?.forEach((childDefinition) => {
                    if (childDefinition.name === name) {
                        res = childDefinition.childSchema as FieldDefinition[];
                        i = MAX;
                    }
                });
            }

            i++;
        }

        return res;
    }

    addToArray(
        elementLayoutDefinition: ILayoutAndSchemaFieldDefinition,
        parentName?: FormElementLayoutDefinition,
        parentIndex?: number,
    ): void {
        let formArray = this.formProcessService.getFormArray(
            this.formStaticService.form,
            parentName
                ? [parentName.name as string]
                : [elementLayoutDefinition.name as string],
        ) as UntypedFormArray;

        const CHILD_SCHEMA_DEFINITION = this.findDefinition(
            elementLayoutDefinition.name as string,
            parentName?.name,
        );

        const layout: any[] = [];
        const allFields: any[] = [];

        elementLayoutDefinition?.sectionLayoutDefinitions?.[0]?.elementLayoutDefinitions?.map(
            (elementLayout) => {
                const elementSchema = CHILD_SCHEMA_DEFINITION.find(
                    (elementSchema) =>
                        elementSchema.name === elementLayout.name,
                );

                const eleLayout = {
                    ...elementLayout,
                    isRequired: elementLayout.isHidden
                        ? false
                        : elementLayout.isRequired,
                };

                if (elementSchema) {
                    const DATA = {
                        ...elementSchema,
                        ...eleLayout,
                        ...((elementSchema as any).options && {
                            originalOptions: (elementSchema as any).options,
                        }),
                        children: [],
                    };

                    layout.push(DATA);
                    allFields.push(DATA);
                } else {
                    allFields.push({
                        ...eleLayout,
                        children: [],
                    });
                }
            },
        );

        const NEW_FORM_GROUP: UntypedFormGroup =
            typeof parentIndex !== 'number'
                ? this.createFormGroup(
                      CHILD_SCHEMA_DEFINITION,
                      true,
                      elementLayoutDefinition.name as string,
                      0,
                  )
                : this.createFormGroup(
                      CHILD_SCHEMA_DEFINITION,
                      true,
                      elementLayoutDefinition.name as string,
                      0,
                      parentName?.name,
                      0,
                  );

        if (typeof parentIndex === 'number') {
            formArray = formArray
                .at(parentIndex)
                .get(
                    elementLayoutDefinition.name as string,
                ) as UntypedFormArray;
        }

        allFields.forEach((FIELD) => {
            const FORM_CONTROL = NEW_FORM_GROUP.get(
                FIELD.name,
            ) as UntypedFormControl;

            if (FIELD.defaultValue) {
                this.formProcessService.updateFormControlValue(
                    FORM_CONTROL,
                    FIELD.defaultValue,
                    true,
                );
            }
            this.formProcessService.updateValidation(FORM_CONTROL, FIELD);
        });

        this.firstLevel[elementLayoutDefinition.name as string].layout.push(
            elementLayoutDefinition.childSchema,
        );

        elementLayoutDefinition.children?.[0].layoutAndDefinition?.push(
            layout as unknown as ReactiveFormLayoutAndDefinition,
        );

        formArray.push(NEW_FORM_GROUP);

        const TRIGGERS =
            this.formStaticService.formDefinition?.conditions?.triggers;

        TRIGGERS?.map((trigger) => {
            const NAME = this.formControlService.stringToFormControlObject(
                trigger.field as string,
            );
            const FORM_CONTROLS_DATA =
                this.formControlService.getFormControlData(
                    this.formStaticService.form,
                    trigger.field as string,
                );
            const FORM_CONTROL_DATA = FORM_CONTROLS_DATA[formArray.length - 1];

            trigger.is?.forEach((is) => {
                is.then?.updateFields?.forEach((updateField) => {
                    const NAME =
                        this.formControlService.stringToFormControlObject(
                            updateField.name as string,
                        );
                    if (
                        NAME.Level1HasSubForm &&
                        NAME.Level1ControlName === elementLayoutDefinition.name
                    ) {
                        for (const FORM_CONTROL_DATA of FORM_CONTROLS_DATA) {
                            this.formConditionsService.updateIsTrigger(
                                trigger,
                                FORM_CONTROL_DATA.formControl.value,
                                FORM_CONTROL_DATA,
                            );
                        }
                    }
                });
            });

            if (
                elementLayoutDefinition.name === NAME.Level1ControlName &&
                FORM_CONTROL_DATA
            ) {
                this.formConditionsService.setFormControlListener(
                    trigger,
                    FORM_CONTROL_DATA,
                );
            }
        });
    }

    removeFromArray(
        element: ReactiveFormLayoutAndDefinition,
        index: number,
        parentName?: ReactiveFormLayoutAndDefinition,
        parentIndex?: number,
    ): void {
        let formArray = this.formProcessService.getFormArray(
            this.formStaticService.form,
            parentName ? [parentName.name as string] : [element.name as string],
        ) as UntypedFormArray;

        if (typeof parentIndex === 'number') {
            formArray = formArray
                .at(parentIndex)
                .get(element.name as string) as UntypedFormArray;
        }

        const layout = this.firstLevel[element.name as string]?.layout;
        const layoutAndDefinition = element.children?.[0].layoutAndDefinition;

        this.firstLevel[element.name as string].layout = [
            ...layout.slice(0, index),
            ...layout.slice(index + 1),
        ];

        (element.children as ReactiveFormElement[])[0].layoutAndDefinition = [
            ...(layoutAndDefinition?.slice(0, index) || []),
            ...(layoutAndDefinition?.slice(index + 1) || []),
        ];
        formArray.removeAt(index);
    }

    loopFormSchemaData(
        data: IExtendedFieldDefinition,
        skipData: boolean,
        name?: string,
        parentName?: string,
        parentIndex?: number,
    ): ISubFormLayoutFormData {
        const CREATED_FORM_GROUP = this.createFormArrayGroup(
            data.childSchema as IExtendedFieldDefinition[],
            skipData,
            name,
            parentName,
            parentIndex,
        );

        return {
            formArray: this.fb.array(
                CREATED_FORM_GROUP.formGroup,
                this.formValidationService.getValidators(data),
            ),
            layout: CREATED_FORM_GROUP.formGroupLayout.map((fgl) => ({
                elementType: FormElementType.Field,
                ...fgl,
            })),
            pendingChanges: CREATED_FORM_GROUP.pendingChanges,
        };
    }

    createFormArrayGroup(
        data: IExtendedFieldDefinition[],
        skipData: boolean,
        name?: string,
        parentName?: string,
        parentIndex?: number,
    ): {
        formGroup: UntypedFormGroup[];
        formGroupLayout: FieldDefinition[];
        pendingChanges: { [key: number]: ISubFormPendingChange };
    } {
        const FORM_ARRAY_DATA = {
            formGroup: [] as UntypedFormGroup[],
            formGroupLayout: [] as IExtendedFieldDefinition[][],
            pendingChanges: {} as any,
        };

        const DATA = [...data];

        // This is checks if we have form data to populate the form with and creates the amount of groups needed.
        if (
            !skipData &&
            (this.formStaticService?.formData?.data?.[name as string] ||
                this.formStaticService?.formData?.data?.[
                    parentName as string
                ]?.[0]?.[name as string])
        ) {
            if (!parentName) {
                const ARR =
                    this.formStaticService.formData.data[name as string];
                ARR.map((formDataResult: any, index: number) => {
                    FORM_ARRAY_DATA.formGroup.push(
                        this.createFormGroup(
                            data,
                            false,
                            name,
                            index,
                            parentName,
                            index,
                        ),
                    );

                    this.formPendingChangesService.addDataToSubFormPendingChanges(
                        name as string,
                        index,
                        formDataResult,
                    );

                    FORM_ARRAY_DATA.pendingChanges[index] = formDataResult;

                    FORM_ARRAY_DATA.formGroupLayout.push(DATA);
                });
            }

            if (parentName) {
                const ARR =
                    this.formStaticService.formData.data[parentName][
                        parentIndex as number
                    ];
                if (ARR && ARR[name as string]) {
                    ARR[name as string].map((_: any, indexChild: number) => {
                        FORM_ARRAY_DATA.formGroup.push(
                            this.createFormGroup(
                                data,
                                false,
                                name,
                                indexChild,
                                parentName,
                                parentIndex,
                            ),
                        );

                        FORM_ARRAY_DATA.formGroupLayout.push(data);
                    });
                }
            }

            return FORM_ARRAY_DATA as {
                formGroup: UntypedFormGroup[];
                formGroupLayout: FieldDefinition[];
                pendingChanges: { [key: number]: ISubFormPendingChange };
            };
        }

        return FORM_ARRAY_DATA as {
            formGroup: UntypedFormGroup[];
            formGroupLayout: FieldDefinition[];
            pendingChanges: { [key: number]: ISubFormPendingChange };
        };
    }

    createFormGroup(
        data: FieldDefinition[],
        skipData: boolean,
        name?: string,
        index?: number,
        parentName?: string,
        parentIndex?: number,
    ): UntypedFormGroup {
        return this.fb.group({
            ...data.reduce((accumulator, formFieldDefinition) => {
                if (formFieldDefinition.fieldType === FormFieldType.Array) {
                    const ARR_FORM_GROUP = this.loopFormSchemaData(
                        formFieldDefinition,
                        skipData,
                        formFieldDefinition.name as string,
                        name,
                        index,
                    );

                    return {
                        ...accumulator,
                        [formFieldDefinition.name as string]:
                            ARR_FORM_GROUP.formArray,
                    };
                }

                if (
                    MANUAL_FORM_GROUP.includes(
                        formFieldDefinition.fieldType as FormFieldType,
                    )
                ) {
                    this.formFieldsDataService.addFieldsToNullCheck(
                        this.formStaticService.fieldsToNullCheck,
                        formFieldDefinition.name as string,
                    );
                    return {
                        ...accumulator,
                        [formFieldDefinition.name as string]: this.fb.group({}),
                    };
                }

                return {
                    ...accumulator,
                    [formFieldDefinition.name as string]: parentName
                        ? this.setFormControlByType(
                              skipData,
                              formFieldDefinition,
                              undefined as any,
                              {
                                  isArray: true,
                                  index: parentIndex,
                                  name: parentName,
                                  childName: name,
                                  childIndex: index,
                              },
                          )
                        : this.setFormControlByType(
                              skipData,
                              formFieldDefinition,
                              undefined as any,
                              {
                                  isArray: true,
                                  index,
                                  name,
                              },
                          ),
                };
            }, {}),
        });
    }
}
