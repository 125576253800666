/**
 * The required preset WDX Date format
 * - Absolute dates are used where a full timestamp is appropriate.
 * - Relative dates are used in where the users focus will be on the date relative to the current time.
 */
export enum WdxDateFormat {
    AbsoluteDate,
    AbsoluteDateTime,
    RelativeDate,
    RelativeDateTime,
    TimeOnly,
}

/**
 * This is the WdxDateOptions options interface
 *
 * @prop locale - This needs to be the isoCode e.g 'en-US', 'en-UK'
 * @prop format - WdxDateFormat
 */
export interface WdxDateOptions {
    locale?: string | null;
    format?: WdxDateFormat;
}
