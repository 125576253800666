<ng-container
    *ngIf="
        crudStatus === undefined || crudStatus > CrudStatus.Initial;
        else loadingTmpl
    "
>
    <highcharts-chart
        *ngIf="chartOptions"
        #timelineChart
        class="audit__timeline flex-shrink-0"
        [style]="'--highcharts-color-0: ' + BASE_COLOR"
        [ngStyle]="pointStyles"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
    ></highcharts-chart>

    <div class="flex-1 overflow-auto position-relative">
        <ng-container
            *ngIf="
                !info?.length &&
                    !timeline?.length &&
                    crudStatus !== undefined &&
                    crudStatus > CrudStatus.Loading;
                else auditContentTmpl
            "
        >
            <wdx-feature-svg
                class="absolute-center"
                [label]="FORM_NO_HISTORY | translate"
            ></wdx-feature-svg>
        </ng-container>
        <ng-template #auditContentTmpl>
            <wdx-audit-row class="audit__header-row" [header]="true">
                <ng-container wdxAuditRowLabel>Details</ng-container>
                <ng-container wdxAuditRowBefore>Before Save</ng-container>
                <ng-container wdxAuditRowAfter>After Save</ng-container>
                <ng-container wdxAuditRowDate>Date Saved</ng-container>
                <ng-container wdxAuditRowUser>User</ng-container>
            </wdx-audit-row>

            <div
                [wdxIsLoading]="crudStatus === CrudStatus.Loading"
                [loadingOverlay]="true"
            >
                <ng-container
                    *ngTemplateOutlet="
                        rowTmpl;
                        context: { data: info, level: 0 }
                    "
                ></ng-container>

                <ng-template #rowTmpl let-data="data" let-level="level">
                    <ng-container *ngFor="let dataRow of data; let i = index">
                        <ng-container
                            *ngIf="dataRow.children; else noChildrenTmpl"
                        >
                            <ng-container *ngIf="20 | randomString as id">
                                <!-- child header -->
                                <div
                                    [attr.data-cy]="
                                        'wdx-audit-row-' + level + '-' + i
                                    "
                                    (click)="
                                        dataRow.children?.length &&
                                            toggleCollapseChild(id)
                                    "
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            tableInfoTmpl;
                                            context: {
                                                data: dataRow,
                                                open:
                                                    !collapsedStates[id]
                                                    | boolean: level !== 0,
                                                hasChanged:
                                                    dataRow | childHasChanged,
                                            }
                                        "
                                    ></ng-container>
                                </div>
                                <!-- children -->
                                <ng-container
                                    *ngIf="
                                        !collapsedStates[id]
                                            | boolean: level !== 0
                                    "
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            rowTmpl;
                                            context: {
                                                data: dataRow.children,
                                                level: level + 1,
                                            }
                                        "
                                    ></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-template #noChildrenTmpl>
                            <!-- no children -->
                            <ng-container
                                *ngTemplateOutlet="
                                    tableInfoTmpl;
                                    context: {
                                        data: dataRow,
                                    }
                                "
                            ></ng-container>
                        </ng-template>
                    </ng-container>
                </ng-template>

                <ng-template
                    #tableInfoTmpl
                    let-data="data"
                    let-open="open"
                    let-hasChanged="hasChanged"
                >
                    <wdx-audit-row
                        data-cy="wdx-audit-row"
                        [dataChanged]="hasChanged || data.hasChanged"
                    >
                        <ng-container wdxAuditRowLabel>
                            <div class="d-flex align-items-center">
                                <span
                                    *ngIf="data.type !== AuditType.SubForm"
                                    [class.fw-bold]="
                                        data.type === AuditType.Section ||
                                        data.type === AuditType.Array
                                    "
                                >
                                    {{ data.label }}
                                    <ng-container
                                        *ngIf="
                                            data.children &&
                                            data.type === AuditType.Array
                                        "
                                    >
                                        <span>
                                            ({{ data.children.length }})
                                        </span>
                                    </ng-container>
                                </span>

                                <wdx-icon
                                    *ngIf="
                                        data.type === AuditType.Section ||
                                        (data.type === AuditType.Array &&
                                            data.children?.length) ||
                                        data.type === AuditType.SubForm
                                    "
                                    class="ms-2"
                                    [icon]="
                                        open ? ICON_ARROW_UP : ICON_ARROW_DOWN
                                    "
                                ></wdx-icon>
                            </div>
                        </ng-container>

                        <ng-container wdxAuditRowBefore>
                            <wdx-audit-value
                                [label]="
                                    data.beforeValue?.label?.name !== undefined
                                        ? data.beforeValue?.label?.name
                                        : data.beforeValue?.label
                                "
                                [subtitle]="data.beforeValue?.subtitle"
                                [badge]="data.beforeValue?.badge"
                            ></wdx-audit-value>
                        </ng-container>

                        <ng-container wdxAuditRowAfter>
                            <wdx-audit-value
                                [label]="
                                    data.updateValue?.label?.name !== undefined
                                        ? data.updateValue?.label?.name
                                        : data.updateValue?.label
                                "
                                [subtitle]="data.updateValue?.subtitle"
                                [badge]="data.updateValue?.badge"
                            ></wdx-audit-value>
                        </ng-container>

                        <ng-container wdxAuditRowDate>
                            @if (hasChanged || data.hasChanged) {
                                {{
                                    data?.dateOfChange
                                        | dateTime
                                            : {
                                                  format: WDX_DATE_FORMAT,
                                              }
                                }}
                            }
                        </ng-container>

                        <ng-container wdxAuditRowUser>
                            @if (hasChanged || data.hasChanged) {
                                <ng-container
                                    *ngTemplateOutlet="avatarTmpl"
                                ></ng-container>
                                <wdx-avatar
                                    *ngIf="data?.user"
                                    [avatar]="data.user"
                                    size="md"
                                ></wdx-avatar>
                            }
                        </ng-container>
                    </wdx-audit-row>
                </ng-template>
            </div>
        </ng-template>
    </div>
</ng-container>

<ng-template #loadingTmpl>
    <div class="flex-1 position-relative">
        <div [wdxIsLoading]="true"></div>
    </div>
</ng-template>
