import { createAction, props } from '@ngrx/store';
import { EntityOperations, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

export const getOperationsForEntity = createAction(
    '[Operations] Get Operations For Entity',
    props<{
        entityType: SystemEntity;
    }>(),
);

export const getOperationsForEntitySuccess = createAction(
    '[Operations] Get Operations For Entity Success',
    props<{
        entityType: SystemEntity;
        operations: EntityOperations;
    }>(),
);

export const getOperationsForEntityFailure = createAction(
    '[Operations] Get Operations For Entity Failure',
    props<{
        entityType: SystemEntity;
        error: Error;
    }>(),
);

export const getOperationsForId = createAction(
    '[Operations] Get Operations For Id',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>(),
);

export const getOperationsForIdSuccess = createAction(
    '[Operations] Get Operations For Id Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        operations: EntityOperations;
    }>(),
);

export const getOperationsForIdFailure = createAction(
    '[Operations] Get Operations For Id Failure',
    props<{
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>(),
);

export const patchActionWithURL = createAction(
    '[Operations] Patch Action With URL',
    props<{
        entityType: SystemEntity;
        entityId: string;
        url: string;
    }>(),
);

export const patchActionWithURLSuccess = createAction(
    '[Operations] Patch Action With URL Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        url: string;
    }>(),
);

export const patchWithURLFailure = createAction(
    '[Operations] Patch Action With URL Failure',
    props<{
        error: Error;
    }>(),
);
