import { Action, createReducer, on } from '@ngrx/store';
import { ViewTile } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as viewTileActions from './viewtile.actions';

export interface State {
    viewTiles?: CrudState<ViewTile>;
    viewTile?: CrudStateObject<ViewTile>;
    rollups?: CrudStateObject<any>;
}

export const initialState: State = {
    viewTiles: {},
    viewTile: {},
    rollups: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        viewTileActions.getAll,
        (state): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        }),
    ),
    on(
        viewTileActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.viewTiles,
            },
            viewTile: {
                ...(state.viewTile || ({} as CrudStateObject<ViewTile>)),
                ...listToCrudStateObject(props.viewTiles, false, false),
            },
        }),
    ),
    on(
        viewTileActions.getAllFailure,
        (state): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        }),
    ),

    on(
        viewTileActions.getForId,
        (state, props): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: true,
                hasUpdatingError: false,
            },
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),
    on(
        viewTileActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: false,
                hasUpdatingError: false,
                list: updateViewTileInList(
                    state.viewTiles?.list,
                    props.viewTile,
                ),
            },
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.viewTile,
                },
            },
        }),
    ),
    on(
        viewTileActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: false,
                hasUpdatingError: true,
            },
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        viewTileActions.createViewTile,
        (state): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isCreating: true,
                hasCreatingError: false,
            },
        }),
    ),
    on(
        viewTileActions.createViewTileSuccess,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.viewTile?.id]: {
                    ...(state.viewTile[props.viewTile?.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.viewTile,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                list: [...(state.viewTiles?.list || []), props.viewTile],
                isCreating: false,
                hasCreatingError: false,
            },
        }),
    ),
    on(
        viewTileActions.createViewTileFailure,
        (state): State => ({
            ...state,
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isCreating: false,
                hasCreatingError: true,
            },
        }),
    ),

    on(
        viewTileActions.updateViewTile,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: true,
                hasUpdatingError: false,
            },
        }),
    ),
    on(
        viewTileActions.updateViewTileSuccess,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.viewTile?.id]: {
                    ...(state.viewTile[props.viewTile?.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    isUpdating: false,
                    hasUpdatingError: false,
                    single: props.viewTile,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: false,
                hasUpdatingError: false,
                list: updateViewTileInList(
                    state.viewTiles?.list,
                    props.viewTile,
                ),
            },
        }),
    ),
    on(
        viewTileActions.updateViewTileFailure,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isUpdating: false,
                hasUpdatingError: true,
            },
        }),
    ),

    on(
        viewTileActions.deleteViewTile,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isDeleting: true,
                hasDeletingError: false,
            },
        }),
    ),
    on(
        viewTileActions.deleteViewTileSuccess,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    isDeleting: false,
                    hasDeletingError: false,
                    single: undefined,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isDeleting: false,
                hasDeletingError: false,
                list: deleteViewTileInList(state.viewTiles?.list, props.id),
            },
        }),
    ),
    on(
        viewTileActions.deleteViewTileFailure,
        (state, props): State => ({
            ...state,
            viewTile: {
                ...state.viewTile,
                [props.id]: {
                    ...(state.viewTile[props.id] ||
                        ({} as CrudState<ViewTile>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
            viewTiles: {
                ...(state.viewTiles || ({} as CrudState<ViewTile>)),
                isDeleting: false,
                hasDeletingError: true,
            },
        }),
    ),

    on(
        viewTileActions.getRollup,
        (state, props): State => ({
            ...state,
            rollups: {
                ...(state.rollups || ({} as CrudStateObject<any>)),
                [props.id]: {
                    ...(state.rollups[props.id] || ({} as CrudState<any>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),
    on(
        viewTileActions.getRollupSuccess,
        (state, props): State => ({
            ...state,
            rollups: {
                ...(state.rollups || ({} as CrudStateObject<any>)),
                [props.id]: {
                    ...(state.rollups[props.id] || ({} as CrudState<any>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.results,
                },
            },
        }),
    ),
    on(
        viewTileActions.getRollupFailure,
        (state, props): State => ({
            ...state,
            rollups: {
                ...(state.rollups || ({} as CrudStateObject<any>)),
                [props.id]: {
                    ...(state.rollups[props.id] || ({} as CrudState<any>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),
);

function listToCrudStateObject(
    viewTiles: ViewTile[],
    isLoadingSingle: boolean,
    hasLoadingSingleError: boolean,
): CrudStateObject<ViewTile> {
    return viewTiles.reduce((accumulator, viewTile) => {
        return {
            ...accumulator,
            [viewTile.id]: {
                single: viewTile,
                isLoadingSingle,
                hasLoadingSingleError,
            },
        } as CrudStateObject<ViewTile>;
    }, {} as CrudStateObject<ViewTile>);
}

function updateViewTileInList(
    viewTiles: ViewTile[],
    updateWith: ViewTile,
): ViewTile[] {
    const index = viewTiles.findIndex(
        (viewTile) => viewTile.id === updateWith.id,
    );
    if (index > -1) {
        const tiles = [...viewTiles];
        tiles[index] = updateWith;
        return tiles;
    }
    return [...viewTiles, updateWith];
}

function deleteViewTileInList(viewTiles: ViewTile[], id: string): ViewTile[] {
    const tiles = [...viewTiles];
    const index = tiles.findIndex((viewTile) => viewTile.id === id);
    if (index > -1) {
        tiles.splice(index, 1);
    }
    return tiles;
}

export const testFns = {
    listToCrudStateObject,
    updateViewTileInList,
    deleteViewTileInList,
};

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
