import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

export const openDuplicatesModal = createAction(
    '[Duplication Check] Open Duplication Check Modal',
    props<{
        formId?: string;
        entityId?: string;
        entityType?: SystemEntity;
        formData?: any;
        payload: any[];
    }>(),
);
