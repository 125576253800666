import { Action, createReducer, on } from '@ngrx/store';
import { GoalType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as goalTypesActions from './goal-types.actions';

export type State = CrudState<GoalType>;

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        goalTypesActions.getAll,
        (state): State => ({
            ...state,
            isLoadingSingle: true,
            hasLoadingSingleError: false,
        }),
    ),

    on(
        goalTypesActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            isLoadingSingle: false,
            list: props.goalTypes,
        }),
    ),

    on(
        goalTypesActions.getAllFailure,
        (state): State => ({
            ...state,
            isLoadingSingle: false,
            hasLoadingSingleError: true,
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
