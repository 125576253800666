import { Pipe, PipeTransform } from '@angular/core';
import { SimpleTag } from '@wdx/shared/swagger-gen';

@Pipe({
    name: 'convertToTags',
    standalone: true,
})
export class ConvertToTagsPipe implements PipeTransform {
    transform(values: string[], tags: SimpleTag[]): SimpleTag[] {
        if (!values?.length) {
            return [];
        }
        const valuesAsTags = values
            .map((value) => {
                const tag = tags.find((tag) => tag.tag === value);
                if (tag) {
                    return tag;
                }
                return undefined;
            })
            .filter((tag) => tag !== undefined);
        return valuesAsTags;
    }
}
