import { Action, createReducer, on } from '@ngrx/store';
import { AmlReport } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as amlActions from './aml.actions';

export interface State {
    aml?: CrudStateObject<AmlReport>;
}

export const initialState: State = {
    aml: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        amlActions.getAllForId,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        amlActions.getAllForIdSuccess,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.reports,
                },
            },
        }),
    ),

    on(
        amlActions.getAllForIdFailure,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        amlActions.getLatestForId,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        amlActions.getLatestForIdSuccess,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.report,
                },
            },
        }),
    ),

    on(
        amlActions.getLatestForIdFailure,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        amlActions.patchNewCheckForId,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(
        amlActions.patchNewCheckForIdSuccess,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isCreating: false,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(
        amlActions.patchNewCheckForIdFailure,
        (state, props): State => ({
            ...state,
            aml: {
                ...state.aml,
                [props.id]: {
                    ...(state.aml[props.id] || ({} as CrudState<AmlReport>)),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
