/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum KeyStatValueType {
  Int = 'Int',
  DateTime = 'DateTime',
  Currency = 'Currency',
  Text = 'Text',
  Percentage = 'Percentage',
  Decimal = 'Decimal'
}
