import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClientProduct, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { FilterQueryFacadeService } from '@wdx/clmi/api-services/state';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { TranslationsService } from '@wdx/shared/utils';
import { PRODUCT_STATUS_THEME_COLOR_MAP } from '../../../../features/client-products/constants/client-products.static';
import { TableRow } from '../../../../models/table-row.model';
import { RouteDataService } from '../../../../shared/services';
import { EntityOperationsService } from '../../../../shared/services/entity-operations';
import * as rootReducer from '../../../../state/_setup/reducers';
import { AccessVisibilityService } from '../../access-visibility/shared/services/access-visibility.service';
import { FilterQueryService } from '../../form-filters/shared';
import { FilterTableComponentClass } from '../filter-table-component.class';
import { CLIENT_PRODUCTS_TABLE_HEADERS } from './client-products.static';

@Component({
    selector: 'clmi-filter-table-client-products',
    templateUrl: '../filter-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableClientProductsComponent
    extends FilterTableComponentClass
    implements AfterViewInit, OnInit
{
    @ViewChild('badgeCell', { read: TemplateRef })
    badgeCell: TemplateRef<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected accessVisibilityService: AccessVisibilityService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
        protected entityRouteService: EntityRouteService,
    ) {
        super(
            router,
            route,
            location,
            accessVisibilityService,
            store$,
            filterQueryService,
            filterQueryFacadeService,
            entityOperationsService,
            cd,
            routeDataService,
            translationsService,
            entityRouteService,
        );

        this.headers = CLIENT_PRODUCTS_TABLE_HEADERS;
        this.table.headers = this.headers;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(
            this.filterQueryService.queryType,
            this.getClientProductAsTableRow,
            this.badgeCell,
            this.entityRouteService,
        );

        this.cd.detectChanges();
    }

    resetTable(): void {
        super.resetTable(CLIENT_PRODUCTS_TABLE_HEADERS);
    }

    getClientProductAsTableRow = (
        clientProduct: ClientProduct,
        statusBadgeTemplate: TemplateRef<any>,
        entityRouteService: EntityRouteService,
    ) => {
        const routerLink = entityRouteService.routeForEntity(
            SystemEntity.ClientProduct,
            clientProduct.id,
        );
        return (
            clientProduct &&
            ({
                routerLink,
                values: [
                    {
                        value: clientProduct.name,
                        routerLink,
                    },
                    {
                        value: clientProduct?.client?.name,
                        routerLink: entityRouteService.routeForEntity(
                            SystemEntity.Client,
                            clientProduct?.client?.id,
                        ),
                    },
                    {
                        value: clientProduct.code,
                    },
                    {
                        value: clientProduct.type,
                    },
                    {
                        value: clientProduct.managingIntermediary?.name,
                    },
                    {
                        templateRef: statusBadgeTemplate,
                        data: {
                            label: clientProduct.status,
                            themeColor:
                                PRODUCT_STATUS_THEME_COLOR_MAP[
                                    clientProduct.status
                                ] || 'none',
                        },
                    },
                ],
            } as TableRow)
        );
    };
}
