import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Activity,
    Article,
    Case,
    Opportunity,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class RelatedRecordsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getRelatedActivitiesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Activity[]> {
        return this.http.get<Activity[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/activity`,
        );
    }

    getRelatedArticlesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/article`,
        );
    }

    getRelatedCasesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/case`,
        );
    }

    getChildCasesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/children`,
        );
    }

    getRelatedOpportunitiesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Opportunity[]> {
        return this.http.get<Opportunity[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/opportunity`,
        );
    }

    getSuggestedRelatedArticlesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/suggestedarticle`,
        );
    }

    getSuggestedRelatedCasesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/suggestedcase`,
        );
    }

    getSuggestedRelatedOpportunitiesForEntity(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<Opportunity[]> {
        return this.http.get<Opportunity[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/suggestedopportunity`,
        );
    }

    createLinkToArticleForEntity(
        entityType: SystemEntity,
        entityId: string,
        articleId: string,
    ): Observable<any> {
        return entityType === SystemEntity.Case
            ? this.createLinkToArticleForCase(entityType, entityId, articleId)
            : this.createLinktoArticle(entityType, entityId, articleId);
    }

    createLinktoArticle(
        entityType: SystemEntity,
        entityId: string,
        articleId: string,
    ) {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/article/${articleId}`,
            {},
        );
    }

    createLinkToArticleForCase(
        entityType: SystemEntity,
        entityId: string,
        articleId: string,
    ) {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/article/${articleId}`,
            {},
        );
    }

    createLinkToCaseForEntity(
        entityType: SystemEntity,
        entityId: string,
        caseId: string,
    ): Observable<any> {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/case/${caseId}`,
            {},
        );
    }

    createLinkToOpportunityForEntity(
        entityType: SystemEntity,
        entityId: string,
        opportunityId: string,
    ): Observable<any> {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/opportunity/${opportunityId}`,
            {},
        );
    }

    deleteLinkToArticleForEntity(
        entityType: SystemEntity,
        entityId: string,
        articleId: string,
    ): Observable<any> {
        return this.http.delete<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/article/${articleId}`,
            {},
        );
    }

    deleteLinkToCaseForEntity(
        entityType: SystemEntity,
        entityId: string,
        caseId: string,
    ): Observable<any> {
        return this.http.delete<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/case/${caseId}`,
            {},
        );
    }

    deleteLinkToOpportunityForEntity(
        entityType: SystemEntity,
        entityId: string,
        opportunityId: string,
    ): Observable<any> {
        return this.http.delete<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/opportunity/${opportunityId}`,
            {},
        );
    }
}
