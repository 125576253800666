import { Pipe, PipeTransform } from '@angular/core';
import { CompletedStatusType } from '@wdx/clmi/clmi-swagger-gen';

@Pipe({ name: 'prioritisedCompletedStatus' })
export class PrioritisedCompletedStatusPipe implements PipeTransform {
    transform(values: CompletedStatusType[]): CompletedStatusType {
        const priorityOrder = [
            CompletedStatusType.HasIssue,
            CompletedStatusType.Overdue,
            CompletedStatusType.DueSoon,
            CompletedStatusType.NoDeadline,
            CompletedStatusType.DueLater,
            CompletedStatusType.CompletedOverdue,
            CompletedStatusType.CompletedOnTime,
            CompletedStatusType.Cancelled,
            CompletedStatusType.Inactive,
        ];

        const prioritisedStatusesThatExistInValues = priorityOrder.filter(
            (status) => values?.some((value) => value === status),
        );

        return prioritisedStatusesThatExistInValues[0];
    }
}
