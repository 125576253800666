import { Action, createReducer, on } from '@ngrx/store';
import { ProcessType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as processTypesActions from './process-types.actions';

export interface State {
    processTypes: CrudStateObject<ProcessType>;
}

export const initialState: State = {
    processTypes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        processTypesActions.getProcessTypes,
        (state): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.processTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<ProcessType>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        processTypesActions.getProcessTypesSuccess,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.processTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.processTypes,
                },
            },
        }),
    ),

    on(
        processTypesActions.getProcessTypesFailure,
        (state): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.processTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        processTypesActions.getProcessType,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processTypeId]: {
                    ...(state.processTypes[props.processTypeId] ||
                        ({} as CrudState<ProcessType>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        processTypesActions.getProcessTypeSuccess,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processType.code]: {
                    ...state.processTypes[props.processType.code],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.processType,
                },
            },
        }),
    ),

    on(
        processTypesActions.getProcessTypeFailure,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processTypeId]: {
                    ...state.processTypes[props.processTypeId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        processTypesActions.updateProcessType,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processType.code]: {
                    ...(state.processTypes[props.processType.code] ||
                        ({} as CrudState<ProcessType>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        processTypesActions.updateProcessTypeSuccess,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processType.code]: {
                    ...state.processTypes[props.processType.code],
                    isUpdating: false,
                    hasUpdatingError: false,
                    single: props.processType,
                },
            },
        }),
    ),

    on(
        processTypesActions.updateProcessTypeFailure,
        (state, props): State => ({
            ...state,
            processTypes: {
                ...state.processTypes,
                [props.processType.code]: {
                    ...state.processTypes[props.processType.code],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
