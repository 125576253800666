import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueAsArrayToString',
    standalone: true,
})
export class ValueAsArrayToStringPipe implements PipeTransform {
    transform(valueAsArray: any[]): string {
        return valueAsArray?.join(', ');
    }
}
