import { createAction, props } from '@ngrx/store';
import { Country } from '@wdx/clmi/clmi-swagger-gen';

export const getCountries = createAction('[Countries] Get Countries');

export const getCountriesSuccess = createAction(
    '[Countries] Get Countries Success',
    props<{
        countries: Country[];
    }>(),
);

export const getCountriesFailure = createAction(
    '[Countries] Get Countries Failure',
    props<{
        error: Error;
    }>(),
);
