import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WebHook } from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../_setup/reducers';
import * as webHookActions from './web-hooks.actions';
import * as webhooksSelectors from './web-hooks.selectors';
import { WebhooksService } from './webhooks.service';

@Injectable()
export class WebHooksEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private webHooksService = inject(WebhooksService);

    loadWebHooks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(webHookActions.LoadWebHooks),
            withLatestFrom(
                this.store$.select(
                    webhooksSelectors.getWebHooksInfinityPaging,
                    {
                        id: GLOBAL_STATE_INDEX_ID,
                    },
                ),
            ),
            switchMap(([action, paging]) =>
                this.webHooksService
                    .loadAllWebHooks({
                        pageNumber: action.reset ? 1 : (paging?.page || 0) + 1,
                    })
                    .pipe(
                        map((webHooks: PaginatedApiResponse<WebHook>) =>
                            webHookActions.LoadWebHooksSuccess({
                                webHooks,
                            }),
                        ),
                        catchError((error: Error) =>
                            of(
                                webHookActions.LoadWebHooksFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
