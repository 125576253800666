import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import {
    Case,
    EntityPermissionType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import {
    TRANSLATION_ENTITY_CASE_PLURAL,
    TRANSLATION_GUIDANCE_TEXT_RELATED_CASES,
    TRANSLATION_NO_RECENT_RECORDS,
    TRANSLATION_RELATED_CASES_GUIDANCE_TEXT,
    TRANSLATION_RELATED_CASES_OPEN,
    TRANSLATION_SUGGESTED_CASES,
    TRANSLATION_WIDGET_TITLE_CHILD_CASES,
    TranslationsService,
} from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ICON_ADD } from '../../../../constants/icons.constants';
import { AccordionPanel } from '../../../../models/accordion-panel.model';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { ScrollMode } from '../../../../models/scroll-mode.model';
import { SystemEntityActionProps } from '../../../../models/system-entity-action-props.model';
import * as assignToActions from '../../../../state/assign/assign.actions';
import * as caseTypesActions from '../../../../state/case-types/case-types.actions';
import * as caseTypesSelectors from '../../../../state/case-types/case-types.selectors';
import * as relatedRecordsActions from '../../../../state/related-records/related-records.actions';
import { RelatedRecordsFacadeService } from '../related-records-facade.service';

@Component({
    selector: 'clmi-related-cases',
    templateUrl: './related-cases.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedCasesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() entityId: string;
    @Input() entityType: SystemEntity;
    @Input() showChildCases: boolean;
    @Input() showRelatedCases: boolean;
    @Input() showSuggestedCases: boolean;
    @Input() cardsPerRow: number;
    @Input() isInactiveRecord: boolean;

    @ViewChild('relatedCasesContentTemplate')
    relatedCasesContentTemplate: TemplateRef<any>;

    @ViewChild('suggestedCasesContentTemplate')
    suggestedCasesContentTemplate: TemplateRef<any>;

    childCases$: Observable<Case[]>;
    childCasesIsLoading$: Observable<boolean>;
    childCasesHasError$: Observable<boolean>;

    relatedCases$: Observable<Case[]>;
    relatedCasesIsLoading$: Observable<boolean>;
    relatedCasesHasError$: Observable<boolean>;
    relatedCasesAccordionPanels$: Observable<AccordionPanel[]>;

    suggestedCases$: Observable<Case[]>;
    suggestedCasesIsLoading$: Observable<boolean>;
    suggestedCasesHasError$: Observable<boolean>;

    suggestedCasesAccordionPanels$: Observable<AccordionPanel[]>;

    createCaseActionButton$: Observable<ActionButton>;

    destroyed$ = new Subject<boolean>();

    readonly SCROLL_MODE = ScrollMode;
    readonly NO_DATA_MESSAGE = TRANSLATION_NO_RECENT_RECORDS;
    readonly ENTITY_CASE_PLURAL = TRANSLATION_ENTITY_CASE_PLURAL;
    readonly CHILD_CASES = TRANSLATION_WIDGET_TITLE_CHILD_CASES;

    private translationsService = inject(TranslationsService);

    constructor(
        private store$: Store,
        private relatedRecordsFacadeService: RelatedRecordsFacadeService,
        private actionsSubject$: ActionsSubject,
    ) {}

    ngOnInit(): void {
        this.actionsSubject$
            .pipe(
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe((action: any) => {
                if (action.response?.entityType === SystemEntity.Case) {
                    const props = {
                        entityId: this.entityId,
                        entityType: this.entityType,
                    };

                    if (this.showChildCases) {
                        this.relatedRecordsFacadeService.loadChildCases(props);
                    }

                    if (this.showRelatedCases) {
                        this.relatedRecordsFacadeService.loadRelatedCases(
                            props,
                        );
                    }
                }
            });

        this.actionsSubject$
            .pipe(
                ofType(
                    assignToActions.assignToSuccess,
                    relatedRecordsActions.createLinkToCaseForEntitySuccess,
                    relatedRecordsActions.deleteLinkToCaseForEntitySuccess,
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                const props = {
                    entityId: this.entityId,
                    entityType: this.entityType,
                };

                if (this.showChildCases) {
                    this.relatedRecordsFacadeService.loadChildCases(props);
                }

                if (this.showRelatedCases) {
                    this.relatedRecordsFacadeService.loadRelatedCases(props);
                }

                if (this.showSuggestedCases) {
                    this.relatedRecordsFacadeService.loadSuggestedCases(props);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const entityId = this.entityId ?? changes.entityId.currentValue;
        const entityType = this.entityType ?? changes.entityType.currentValue;

        if (entityId && entityType) {
            const props = {
                entityId,
                entityType,
            };

            this.setCreateCaseActionButton(props);

            if (this.showChildCases) {
                this.setChildCases(props);
            }

            if (this.showRelatedCases) {
                this.setRelatedCases(props);
            }

            if (this.showSuggestedCases) {
                this.setSuggestedCases(props);
            }
        }
    }

    setCreateCaseActionButton(props: SystemEntityActionProps): void {
        this.createCaseActionButton$ = this.store$
            .select(caseTypesSelectors.getList)
            .pipe(
                tap((caseTypes) => {
                    if (!caseTypes) {
                        this.store$.dispatch(
                            caseTypesActions.getAll({ isActive: true }),
                        );
                    }
                }),
                filter((caseTypes) => Boolean(caseTypes)),
                map((caseTypes: any) => {
                    return {
                        mode: ActionButtonMode.DropdownButtonIcon,
                        cySelector: 'action-add-case',
                        icon: ICON_ADD.icon,
                        operationsSetup: props,
                        ...(!this.isInactiveRecord
                            ? { permission: EntityPermissionType.Edit }
                            : {}),
                        dropdownMenu: caseTypes?.map((caseType) => ({
                            label: caseType.name,
                            value: caseType.formName,
                            formSetup: {
                                formId: caseType.formName,
                                initialisationParams: {
                                    version: caseType.formVersion,
                                    [props.entityType === SystemEntity.Activity
                                        ? 'activityId'
                                        : 'caseId']: props.entityId,
                                },
                            },
                        })),
                    };
                }),
            );
    }

    setChildCases(props: SystemEntityActionProps): void {
        this.childCases$ =
            this.relatedRecordsFacadeService.getChildCases$(props);

        this.childCasesIsLoading$ =
            this.relatedRecordsFacadeService.getChildCasesIsLoading$(props);

        this.childCasesHasError$ =
            this.relatedRecordsFacadeService.getChildCasesHasError$(props);
    }

    setRelatedCases(props: SystemEntityActionProps): void {
        this.relatedCases$ = this.relatedRecordsFacadeService
            .getRelatedCases$(props)
            .pipe(
                map((relatedCases) =>
                    relatedCases?.filter(
                        (relatedCase) =>
                            relatedCase.originatingCase?.id !== props.entityId,
                    ),
                ),
            );

        this.relatedCasesIsLoading$ =
            this.relatedRecordsFacadeService.getRelatedCasesIsLoading$(props);

        this.relatedCasesHasError$ =
            this.relatedRecordsFacadeService.getRelatedCasesHasError$(props);

        this.relatedCasesAccordionPanels$ = this.relatedCases$.pipe(
            map((relatedCases) => {
                return [
                    {
                        label: `${this.translationsService.getTranslationByKey(
                            TRANSLATION_RELATED_CASES_OPEN,
                        )} (${relatedCases?.length ? relatedCases.length : 0})`,
                        template: this.relatedCasesContentTemplate,
                        templateData: relatedCases,
                        guidanceText:
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_GUIDANCE_TEXT_RELATED_CASES,
                            ),
                    },
                ];
            }),
        );
    }

    setSuggestedCases(props: SystemEntityActionProps): void {
        this.suggestedCases$ =
            this.relatedRecordsFacadeService.getSuggestedCases$(props);

        this.suggestedCasesIsLoading$ =
            this.relatedRecordsFacadeService.getSuggestedCasesIsLoading$(props);

        this.suggestedCasesHasError$ =
            this.relatedRecordsFacadeService.getSuggestedCasesHasError$(props);

        this.suggestedCasesAccordionPanels$ = this.suggestedCases$.pipe(
            debounceTime(0),
            map((suggestedCases) => {
                return [
                    {
                        label: `${this.translationsService.getTranslationByKey(
                            TRANSLATION_SUGGESTED_CASES,
                        )} (${
                            suggestedCases?.length ? suggestedCases.length : 0
                        })`,
                        template: this.suggestedCasesContentTemplate,
                        guidanceText:
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_RELATED_CASES_GUIDANCE_TEXT,
                            ),
                    },
                ];
            }),
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
