import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dashboard, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { DashboardUrlSegment } from './dashboard-url-segments';
import { QueryType } from '@wdx/clmi/api-services/models';

@Injectable({ providedIn: 'root' })
export class DashboardApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getDashboards(): Observable<Dashboard[]> {
        return this.get(`${DashboardUrlSegment.Dashboard}`);
    }

    getDashboardsConfiguration(): Observable<Record<string, any>> {
        return this.get(
            `${DashboardUrlSegment.Dashboard}/${DashboardUrlSegment.Configuration}`,
        );
    }

    createDashboard(queryType: QueryType, body: any): Observable<Dashboard> {
        return this.post(`${DashboardUrlSegment.Dashboard}/${queryType}`, body);
    }

    updateDashboard(
        queryType: QueryType,
        dashboardId: string,
        body: any,
    ): Observable<Dashboard> {
        return this.put(
            `${DashboardUrlSegment.Dashboard}/${queryType}/${dashboardId}`,
            body,
        );
    }

    deleteDashboard(
        dashboardId: string,
        entityType: SystemEntity,
    ): Observable<Dashboard> {
        return this.delete(
            `${DashboardUrlSegment.Dashboard}/${entityType}/${dashboardId}`,
        );
    }

    shareDashboard(
        dashboardId: string,
        contactId: string,
    ): Observable<Dashboard> {
        return this.patch(
            `${DashboardUrlSegment.Dashboard}/${dashboardId}/${DashboardUrlSegment.Share}/${contactId}`,
            null,
        );
    }

    unshareDashboard(
        dashboardId: string,
        contactId: string,
    ): Observable<Dashboard> {
        return this.delete(
            `${DashboardUrlSegment.Dashboard}/${dashboardId}/${DashboardUrlSegment.Share}/${contactId}`,
        );
    }
}
