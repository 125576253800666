import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { ImportCategory } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class ImportEntitiesService extends BaseApiService {
    getImportEntities(): Observable<ImportCategory[]> {
        return this.get<ImportCategory[]>(`import/type`);
    }
}
