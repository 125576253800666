import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { delay, takeUntil } from 'rxjs/operators';
import { MoleculeInputLookupComponent } from '../../../../../../components/molecules/molecule-input-lookup/containers/molecule-input-lookup/molecule-input-lookup.component';
import { ModalManagementService } from '../../../../../../services/modal-management.service';

@Injectable()
export class FilterLookupService extends WdxDestroyClass {
    dynamicViewContainer: ViewContainerRef;
    lookupComponentRef: ComponentRef<MoleculeInputLookupComponent>;

    constructor(private modalManagementService: ModalManagementService) {
        super();
    }

    config(
        inputs?: {
            isMulti?: boolean;
            showReadout?: boolean;
            lookupMode?: boolean;
        },
        form?: {
            initialValue?: any;
            formInputData?: FormInputData;
        },
    ) {
        this.lookupComponentRef = this.dynamicViewContainer.createComponent(
            MoleculeInputLookupComponent,
        );

        Object.keys(inputs).forEach((data) => {
            this.lookupComponentRef.instance[data] = inputs[data];
        });

        Object.keys(form).forEach((data) => {
            this.lookupComponentRef.instance[data] = form[data];
        });

        this.valueChange();
        this.closed();
    }

    openLookUp(): void {
        this.lookupComponentRef.instance.modalRegistered
            .pipe(delay(0), takeUntil(this.destroyed$))
            .subscribe((modalId) => {
                this.modalManagementService.openModalWithId(modalId);
            });
    }

    removeView(): void {
        this.dynamicViewContainer.remove();
    }

    private closed(): void {
        this.lookupComponentRef.instance.closed
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.removeView();
            });
    }

    private valueChange(): void {
        /**
         * Added a delay here so if a component is listening to valueChanged
         * then this one runs last
         */
        this.lookupComponentRef.instance.valueChanged
            .pipe(delay(0), takeUntil(this.destroyed$))
            .subscribe(() => {
                this.removeView();
            });
    }
}
