import { Action, createReducer, on } from '@ngrx/store';
import {
    Activity,
    Article,
    Case,
    Opportunity,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { systemEntityStateId } from '../_helpers/system-entity-action-id-compiler';
import * as relatedRecordsActions from './related-records.actions';

export interface State {
    activities?: CrudStateObject<Activity>;
    articles?: CrudStateObject<Article>;
    cases?: CrudStateObject<Case>;
    childCases?: CrudStateObject<Case>;
    opportunities?: CrudStateObject<Opportunity>;
    suggestedArticles?: CrudStateObject<Article>;
    suggestedCases?: CrudStateObject<Case>;
    suggestedOpportunities?: CrudStateObject<Opportunity>;
}

export const initialState: State = {
    activities: {},
    articles: {},
    cases: {},
    childCases: {},
    opportunities: {},
    suggestedArticles: {},
    suggestedCases: {},
    suggestedOpportunities: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        relatedRecordsActions.getRelatedActivitiesForEntity,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [systemEntityStateId(props)]: {
                    ...(state.activities[systemEntityStateId(props)] ||
                        ({} as CrudState<Activity>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedActivitiesForEntitySuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [systemEntityStateId(props)]: {
                    ...state.activities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.activities,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedActivitiesForEntityFailure,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [systemEntityStateId(props)]: {
                    ...state.activities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedArticlesForEntity,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...(state.articles[systemEntityStateId(props)] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedArticlesForEntitySuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.articles,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedArticlesForEntityFailure,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedCasesForEntity,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...(state.cases[systemEntityStateId(props)] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedCasesForEntitySuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedCasesForEntityFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getChildCasesForEntity,
        (state, props): State => ({
            ...state,
            childCases: {
                ...state.childCases,
                [systemEntityStateId(props)]: {
                    ...(state.childCases[systemEntityStateId(props)] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getChildCasesForEntitySuccess,
        (state, props): State => ({
            ...state,
            childCases: {
                ...state.childCases,
                [systemEntityStateId(props)]: {
                    ...state.childCases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getChildCasesForEntityFailure,
        (state, props): State => ({
            ...state,
            childCases: {
                ...state.childCases,
                [systemEntityStateId(props)]: {
                    ...state.childCases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedOpportunitiesForEntity,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...(state.opportunities[systemEntityStateId(props)] ||
                        ({} as CrudState<Opportunity>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedOpportunitiesForEntitySuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.opportunities,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getRelatedOpportunitiesForEntityFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedArticlesForEntity,
        (state, props): State => ({
            ...state,
            suggestedArticles: {
                ...state.suggestedArticles,
                [systemEntityStateId(props)]: {
                    ...(state.suggestedArticles[systemEntityStateId(props)] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedArticlesForEntitySuccess,
        (state, props): State => ({
            ...state,
            suggestedArticles: {
                ...state.suggestedArticles,
                [systemEntityStateId(props)]: {
                    ...state.suggestedArticles[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.articles,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedArticlesForEntityFailure,
        (state, props): State => ({
            ...state,
            suggestedArticles: {
                ...state.suggestedArticles,
                [systemEntityStateId(props)]: {
                    ...state.suggestedArticles[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedCasesForEntity,
        (state, props): State => ({
            ...state,
            suggestedCases: {
                ...state.suggestedCases,
                [systemEntityStateId(props)]: {
                    ...(state.cases[systemEntityStateId(props)] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedCasesForEntitySuccess,
        (state, props): State => ({
            ...state,
            suggestedCases: {
                ...state.suggestedCases,
                [systemEntityStateId(props)]: {
                    ...state.suggestedCases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases || [],
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedCasesForEntityFailure,
        (state, props): State => ({
            ...state,
            suggestedCases: {
                ...state.suggestedCases,
                [systemEntityStateId(props)]: {
                    ...state.suggestedCases[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntity,
        (state, props): State => ({
            ...state,
            suggestedOpportunities: {
                ...state.suggestedOpportunities,
                [systemEntityStateId(props)]: {
                    ...(state.suggestedOpportunities[
                        systemEntityStateId(props)
                    ] || ({} as CrudState<Opportunity>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntitySuccess,
        (state, props): State => ({
            ...state,
            suggestedOpportunities: {
                ...state.suggestedOpportunities,
                [systemEntityStateId(props)]: {
                    ...state.suggestedOpportunities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.opportunities,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntityFailure,
        (state, props): State => ({
            ...state,
            suggestedOpportunities: {
                ...state.suggestedOpportunities,
                [systemEntityStateId(props)]: {
                    ...state.suggestedOpportunities[systemEntityStateId(props)],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToArticleForEntity,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...(state.articles[systemEntityStateId(props)] ||
                        ({} as CrudState<Article>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToArticleForEntitySuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToArticleForEntityFailure,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToCaseForEntity,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...(state.cases[systemEntityStateId(props)] ||
                        ({} as CrudState<Case>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToCaseForEntitySuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToCaseForEntityFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToOpportunityForEntity,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...(state.opportunities[systemEntityStateId(props)] ||
                        ({} as CrudState<Opportunity>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToOpportunityForEntitySuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.createLinkToOpportunityForEntityFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToArticleForEntity,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...(state.articles[systemEntityStateId(props)] ||
                        ({} as CrudState<Article>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToArticleForEntitySuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToArticleForEntityFailure,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [systemEntityStateId(props)]: {
                    ...state.articles[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToCaseForEntity,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...(state.cases[systemEntityStateId(props)] ||
                        ({} as CrudState<Case>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToCaseForEntitySuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToCaseForEntityFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [systemEntityStateId(props)]: {
                    ...state.cases[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToOpportunityForEntity,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...(state.opportunities[systemEntityStateId(props)] ||
                        ({} as CrudState<Opportunity>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToOpportunityForEntitySuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        relatedRecordsActions.deleteLinkToOpportunityForEntityFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [systemEntityStateId(props)]: {
                    ...state.opportunities[systemEntityStateId(props)],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
