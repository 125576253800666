import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminRole, SortDirection } from '@wdx/clmi/clmi-swagger-gen';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminRolesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    searchAdminRolesClient(filter: any): Observable<AdminRole[]> {
        const getRoles$ = this.http.get<AdminRole[]>(
            `${this.config.getConfiguration().API_BASE}/admin/role?type=user`,
        );

        return getRoles$.pipe(
            map((roles) => {
                let searches = roles.filter(
                    (m) =>
                        m.name
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.code
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.description
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()),
                );

                switch (filter.sortBy) {
                    case 'name':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.name,
                                b.name,
                            ),
                        );
                        break;
                    case 'code':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.code,
                                b.code,
                            ),
                        );
                        break;
                    case 'description':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.description,
                                b.description,
                            ),
                        );
                        break;
                    default:
                        break;
                }

                return searches;
            }),
        );
    }

    getSearchSort(sortDirection, a, b) {
        return sortDirection === SortDirection.Ascending
            ? a > b
                ? 1
                : -1
            : a > b
              ? -1
              : 1;
    }
}
