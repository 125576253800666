import { ClientLifecycleStage } from '@wdx/clmi/clmi-swagger-gen';
import { Avatar } from '@wdx/clmi/utils/models';
import { Icon, WdxThemeColor } from '@wdx/shared/utils';
import { RoutingLink } from './routing-link.model';

export interface ITile {
    value: string;
    icon: Icon | string;
    data: any;
    routingLink?: RoutingLink;
}

export interface ITileMini {
    value?: string;
    icon?: Icon | string;
    label: string;
}

export interface ITileAvatar {
    value: string;
    avatar: Avatar;
    date: Date;
    routingLink?: RoutingLink;
}

export interface ITileValue {
    value?: number;
    isPrimary?: boolean;
    appreciation?: number;
    label: string;
}

export interface ITileInfo {
    value?: string | number;
    icon?: Icon | string;
    label: string;
    // severity?: Severity;
    lifecycleStage?: ClientLifecycleStage;
    themeColor?: WdxThemeColor;
}

export enum TileType {
    Avatar,
    Default,
    Info,
    Mini,
    Value,
}
