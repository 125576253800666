import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    Activity,
    ArticleLinkedRecord,
    Case,
} from '@wdx/clmi/clmi-swagger-gen';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as articlesActions from './articles.actions';
import * as articlesSelectors from './articles.selectors';
import { ArticlesService } from './articles.service';

@Injectable()
export class ArticlesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private articlesService = inject(ArticlesService);

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.create),
            switchMap((action) =>
                this.articlesService.create(action.articleCreateData).pipe(
                    map((article) =>
                        articlesActions.createSuccess({ article }),
                    ),
                    catchError((error) =>
                        of(articlesActions.createFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.update),
            switchMap((action) =>
                this.articlesService.update(action.articleUpdateData).pipe(
                    map((article) =>
                        articlesActions.updateSuccess({ article }),
                    ),
                    catchError((error) =>
                        of(articlesActions.updateFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    parse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.parse),
            switchMap((action) =>
                this.articlesService.parse(action.articleParseData).pipe(
                    map((article) => articlesActions.parseSuccess({ article })),
                    catchError((error) =>
                        of(articlesActions.parseFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    getFavourites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getFavourites),
            switchMap(() =>
                this.articlesService.getFavourites().pipe(
                    map((articles) =>
                        articlesActions.getFavouritesSuccess({
                            favourites: articles,
                        }),
                    ),
                    catchError((error) =>
                        of(articlesActions.getFavouritesFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getForId),
            switchMap((action) =>
                this.articlesService.getForId(action.articleId).pipe(
                    map((article) =>
                        articlesActions.getForIdSuccess({
                            articleId: action.articleId,
                            article,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getForIdFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getTargettedForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getTargettedForId),
            switchMap((action) =>
                this.articlesService.getTargettedForId(action.articleId).pipe(
                    map((people) =>
                        articlesActions.getTargettedForIdSuccess({
                            articleId: action.articleId,
                            people,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getTargettedForIdFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getHistoryForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getHistoryForId),
            withLatestFrom(
                this.store$.select(
                    articlesSelectors.getHistoryForIdInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID },
                ),
            ),
            switchMap(([action, paging]) =>
                this.articlesService
                    .getHistoryForId(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                            sortDescending: true,
                        },
                        action.articleId,
                    )
                    .pipe(
                        map((history) =>
                            articlesActions.getHistoryForIdSuccess({
                                history,
                                articleId: action.articleId,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                articlesActions.getHistoryForIdFailure({
                                    articleId: action.articleId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    linkImages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.linkImages),
            mergeMap((action) =>
                this.articlesService
                    .linkImages(action.articleId, action.fileIndexes)
                    .pipe(
                        mergeMap(() => [
                            articlesActions.linkImagesSuccess(),
                            articlesActions.getForId({
                                articleId: action.articleId,
                            }),
                        ]),
                        catchError((error) =>
                            of(articlesActions.linkImagesFailure({ error })),
                        ),
                    ),
            ),
        ),
    );

    linkedRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.linkedRecords),
            switchMap((action) =>
                this.articlesService.linkedRecords(action.articleId).pipe(
                    map((records: ArticleLinkedRecord[]) =>
                        articlesActions.linkedRecordsSuccess({
                            articleId: action.articleId,
                            records,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.linkedRecordsFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    linkedActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.linkedActivities),
            switchMap((action) =>
                this.articlesService.linkedActivities(action.articleId).pipe(
                    map((activities: Activity[]) =>
                        articlesActions.linkedActivitiesSuccess({
                            articleId: action.articleId,
                            activities,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.linkedActivitiesFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    linkedCases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.linkedCases),
            switchMap((action) =>
                this.articlesService.linkedCases(action.articleId).pipe(
                    map((cases: Case[]) =>
                        articlesActions.linkedCasesSuccess({
                            articleId: action.articleId,
                            cases,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.linkedCasesFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    favourited$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.favourited),
            withLatestFrom(
                this.store$.select(
                    articlesSelectors.getFavouritedInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID },
                ),
            ),
            switchMap(([action, paging]) =>
                this.articlesService
                    .favourited(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                            sortDescending: true,
                        },
                        action.articleId,
                    )
                    .pipe(
                        map((favourited) =>
                            articlesActions.favouritedSuccess({
                                favourited,
                                articleId: action.articleId,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                articlesActions.favouritedFailure({
                                    articleId: action.articleId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getViewedForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getViewedForId),
            switchMap((action) =>
                this.articlesService.getViewedForId(action.articleId).pipe(
                    map((people) =>
                        articlesActions.getViewedForIdSuccess({
                            articleId: action.articleId,
                            people,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getViewedForIdFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getRecentArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getRecentArticles),
            switchMap(() =>
                this.articlesService.getRecentArticles().pipe(
                    map((articles) =>
                        articlesActions.getRecentArticlesSuccess({ articles }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getRecentArticlesFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getFeedClientsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getFeedClientsForId),
            switchMap((action) =>
                this.articlesService.getFeedClientsForId(action.articleId).pipe(
                    map((clients) =>
                        articlesActions.getFeedClientsForIdSuccess({
                            articleId: action.articleId,
                            clients,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getFeedClientsForIdFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getCommentsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.getCommentsForId),
            switchMap((action) =>
                this.articlesService.getCommentsForId(action.articleId).pipe(
                    map((comments) =>
                        articlesActions.getCommentsForIdSuccess({
                            articleId: action.articleId,
                            comments,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            articlesActions.getCommentsForIdFailure({
                                articleId: action.articleId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    linkFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.linkFiles),
            mergeMap((action) =>
                this.articlesService
                    .linkFiles(action.articleId, action.fileIndexes)
                    .pipe(
                        mergeMap(() => [
                            articlesActions.linkFilesSuccess(),
                            articlesActions.getForId({
                                articleId: action.articleId,
                            }),
                        ]),
                        catchError((error) =>
                            of(articlesActions.linkFilesFailure({ error })),
                        ),
                    ),
            ),
        ),
    );

    setStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(articlesActions.setStatus),
            mergeMap((action) =>
                this.articlesService
                    .setStatus(action.articleId, action.status)
                    .pipe(
                        mergeMap(() => [
                            articlesActions.setStatusSuccess(),
                            articlesActions.getForId({
                                articleId: action.articleId,
                            }),
                        ]),
                        catchError((error) =>
                            of(articlesActions.setStatusFailure({ error })),
                        ),
                    ),
            ),
        ),
    );
}
