import { createAction, props } from '@ngrx/store';
import { FeatureStatus, RoleType } from '@wdx/clmi/clmi-swagger-gen';

export const getAll = createAction('[Roles] Get All');

export const getAllSuccess = createAction(
    '[Roles] Get All Success',
    props<{
        roles: RoleType[];
    }>(),
);

export const getAllFailure = createAction(
    '[Roles] Get All Failure',
    props<{
        error: Error;
    }>(),
);

export const getByRoleType = createAction(
    '[Roles] Get By RoleType Type',
    props<{
        roleType: string;
    }>(),
);

export const getByRoleTypeSuccess = createAction(
    '[Roles] Get By RoleType Type Success',
    props<{
        roleType: string;
        role: RoleType;
    }>(),
);

export const getByRoleTypeFailure = createAction(
    '[Roles] Get By RoleType Type Failure',
    props<{
        roleType: string;
        error: Error;
    }>(),
);

export const getForClientProduct = createAction(
    '[Roles] Get For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getForClientProductSuccess = createAction(
    '[Roles] Get For Client Product Success',
    props<{
        roles: RoleType[];
        clientProductId: string;
    }>(),
);

export const getForClientProductFailure = createAction(
    '[Roles] Get For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const enableRoleStatus = createAction(
    '[Roles] Enable RoleType Status',
    props<{
        code: string;
        status?: FeatureStatus.Enabled;
    }>(),
);

export const enableRoleStatusSuccess = createAction(
    '[Roles] Enable RoleType Status Success',
    props<{
        code: string;
        status?: FeatureStatus.Enabled;
    }>(),
);

export const enableRoleStatusFailure = createAction(
    '[Roles] Enable RoleType Status Failure',
    props<{
        error: Error;
    }>(),
);

export const availableRoleStatus = createAction(
    '[Roles] Available RoleType Status',
    props<{
        code: string;
        status?: FeatureStatus.Available;
    }>(),
);

export const availableRoleStatusSuccess = createAction(
    '[Roles] Available RoleType Status Success',
    props<{
        code: string;
        status?: FeatureStatus.Available;
    }>(),
);

export const availableRoleStatusFailure = createAction(
    '[Roles] Available RoleType Status Failure',
    props<{
        error: Error;
    }>(),
);
