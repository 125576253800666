import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { SimpleTag } from '@wdx/clmi/clmi-swagger-gen';
import { BaseInputClass } from '../../../classes/base-input-class';
import { InfoCardSearchMode } from '../../../models/info-card-search-mode.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../services/modal-management.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-tags',
    templateUrl: './molecule-input-tags.component.html',
})
export class MoleculeInputTagsComponent extends BaseInputClass {
    @Input() isMulti: boolean;

    @Input() tagsIsLoading: boolean;
    @Input() tagsHasError: boolean;
    @Input() tags: SimpleTag[];
    @Output() deleteTag = new EventEmitter<any>();

    searchText = '';

    modalId: string;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;

    constructor(
        elementRef: ElementRef,
        modalManagementService: ModalManagementService,
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            modalManagementService,
        });

        this.modalId = new RandomStringPipe().transform();
    }

    onChange(value: SimpleTag[]): void {
        if (!value?.length) {
            super.onChange([]);
        }
        super.onChange(value.map((tag) => tag.tag));
    }

    onSearch(searchText: string): void {
        this.searchText = searchText;
    }

    onConfirmClicked() {
        this.modalManagementService.closeActiveModal();
    }

    deselectTag(tag: string): void {
        this.deleteTag.emit(tag);
    }
}
