import { Pipe, PipeTransform } from '@angular/core';
import { ActivityType } from '@wdx/clmi/clmi-swagger-gen';
import { MEETING_FORM_ID } from '@wdx/shared/utils';

@Pipe({ name: 'activityTypeToFormName' })
export class ActivityTypeToFormNamePipe implements PipeTransform {
    transform(value: ActivityType): string {
        if (!value) {
            return '';
        }

        switch (value) {
            case ActivityType.Appointment:
                return MEETING_FORM_ID;
            default:
                return `${value}Form`;
        }
    }
}
