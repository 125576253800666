import { Pipe, PipeTransform } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { KeyValueObject } from '@wdx/shared/utils';
import { ENTITY_QUERY_PARAM_MAP } from '../constants/query-params.constants';

@Pipe({ name: 'entityQueryParam' })
export class EntityQueryParamPipe implements PipeTransform {
    transform(entityType: SystemEntity): KeyValueObject {
        return ENTITY_QUERY_PARAM_MAP[entityType];
    }
}
