/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FormDataStatus {
  Draft = 'Draft',
  Published = 'Published',
  HistoricDraft = 'HistoricDraft',
  HistoricPublished = 'HistoricPublished',
  Confirmed = 'Confirmed'
}
