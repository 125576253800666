import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetClass } from '../../../../../classes/base-widget.class';
import { FilterHandler } from '../../../../../classes/filter-handler.class';
import { INFINITY_SCROLL_CONTENT } from '../../../../../constants/inifinity-scroll-content.contants';
import { Article, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { ListStyle } from '../../../../../models/list.model';
import { ToggleEvent } from '../../../../../models/toggle-event.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'widget-articles-list',
    templateUrl: './widget-articles-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block' },
})
export class WidgetArticlesListComponent
    extends BaseWidgetClass
    implements OnInit
{
    @Input() articleListItems: Article[];
    @Input() articlesPerRow: number;
    @Input() filterHandler: FilterHandler;
    @Input() showArticlesListActionsButtons: boolean;
    @Input() totalRecordCount: number;
    @Input() showArticleImage = true;
    @Input() showLinkButton = false;
    @Input() isSuggestedCardList: boolean;
    @Input() responsiveScroll: boolean;
    @Input() linkToEntityId: string;
    @Input() linkToEntityType: SystemEntity;

    @Output() likeToggled = new EventEmitter<ToggleEvent>();
    @Output() linkToggled = new EventEmitter<ToggleEvent>();
    @Output() infinityScrollFired = new EventEmitter<INFINITY_SCROLL_CONTENT>();

    readonly LIST_STYLE = ListStyle;

    limit = 6;
    modalId = 'all-article-cards';

    ngOnInit(): void {
        this.spokeButton.modalId = this.modalId;
        this.spokeButton.isHidden =
            !this.articleListItems?.length ||
            (this.limit > 0 && this.articleListItems?.length <= this.limit);
    }

    onModalClosed(): void {
        this.filterHandler.reset();
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit(INFINITY_SCROLL_CONTENT.Articles);
    }
}
