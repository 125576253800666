import { Action, createReducer, on } from '@ngrx/store';
import { Client } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as relatedClientsActions from './related-clients.actions';

export interface State {
    relatedClients: CrudStateObject<Client>;
}

export const initialState: State = {
    relatedClients: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        relatedClientsActions.getForParty,
        (state, props): State => ({
            ...state,
            relatedClients: {
                ...state.relatedClients,
                [props.partyId]: {
                    ...(state.relatedClients[props.partyId] ||
                        ({} as CrudState<Client>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        relatedClientsActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            relatedClients: {
                ...state.relatedClients,
                [props.partyId]: {
                    ...state.relatedClients[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.relatedClients,
                },
            },
        }),
    ),

    on(
        relatedClientsActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            relatedClients: {
                ...state.relatedClients,
                [props.partyId]: {
                    ...state.relatedClients[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
