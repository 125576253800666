import { createAction, props } from '@ngrx/store';
import { FileIndex } from '@wdx/clmi/clmi-swagger-gen';

export const uploadFile = createAction(
    '[File Upload Profile Avatar] Upload File',
    props<{
        id: string;
        file: File;
    }>(),
);

export const uploadFileCancel = createAction(
    '[File Upload Profile Avatar] Upload File Cancel',
    props<{
        id: string;
    }>(),
);

export const uploadFileReset = createAction(
    '[File Upload Profile Avatar] Upload File Reset',
    props<{
        id: string;
    }>(),
);

export const uploadFileStarted = createAction(
    '[File Upload Profile Avatar] Upload File Started',
    props<{
        id: string;
    }>(),
);

export const uploadFileProgress = createAction(
    '[File Upload Profile Avatar] Upload File Progress',
    props<{
        id: string;
        progress: number;
    }>(),
);

export const uploadFileDownloading = createAction(
    '[File Upload Profile Avatar] Upload File Downloading',
    props<{
        id: string;
    }>(),
);

export const uploadFileComplete = createAction(
    '[File Upload Profile Avatar] Upload File Complete',
    props<{
        id: string;
        fileIndex: FileIndex;
    }>(),
);

export const uploadFileCompleteSuccess = createAction(
    '[File Upload Profile Avatar] Upload File Complete Success',
    props<{
        id: string;
        fileIndex: FileIndex;
    }>(),
);

export const uploadFileFailure = createAction(
    '[File Upload Profile Avatar] Upload File Failure',
    props<{
        id: string;
        error: string;
    }>(),
);
