import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconStyle } from './wdx-icon.type';

@Component({
    selector: 'wdx-icon',
    templateUrl: './wdx-icon.component.html',
    styleUrls: ['./wdx-icon.component.scss'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIconComponent {
    @Input() icon?: string | null;
    @Input() iconStyle: IconStyle = 'fal';
    @Input() iconColour?: boolean;
    @Input() rotate?: number | null;
    @Input() offsetTop?: number | null;
    @Input() offsetLeft?: number | null;
    @Input() scale?: number | null;
    @Input() fixedWidth?: boolean;
    @Input() severity?: 'success' | 'warning' | 'danger' | 'info' | null;
}
