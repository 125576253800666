import { Injectable } from '@angular/core';
import { WdxCurrencyOptions } from './number.model';

@Injectable({ providedIn: 'root' })
export class WdxNumberService {
    /**
     * Formats a standard number value
     * @param value The number to format
     * @param locale - The locale to use for number formatting (if undefined will use browser default)
     * @returns
     */
    formatNumber(
        value: number,
        locale?: string | null,
        options?: any,
    ): string | null {
        if (typeof value === 'undefined') {
            return null;
        }

        return new Intl.NumberFormat(locale || undefined, {
            style: 'decimal',
            ...(options && options),
        }).format(value);
    }

    /**
     * Formats a number value to a decimal format
     * @param value The number to format
     * @param locale - The locale to use for number formatting
     * @returns
     */
    formatDecimal(value: number, locale: string): string | null {
        if (typeof value === 'undefined') {
            return null;
        }

        return new Intl.NumberFormat(locale, {
            style: 'decimal',
            minimumFractionDigits: 2,
        }).format(value);
    }

    /**
     * Formats a number value to a currency format eg £100,000.00 GBP
     * @param value
     * @param options - the available options (see IWdxCurrencyOptions)
     * @returns
     */
    formatCurrency(
        value: number,
        options: WdxCurrencyOptions,
        displayCurrencyCode = true,
    ): string | null {
        if (typeof value === 'undefined') {
            return null;
        }

        const numberParts = new Intl.NumberFormat(
            options.locale as string,
            {
                style: 'currency',
                currency: options.currencyCode,
                notation: options.notation || 'standard',
                currencyDisplay: 'narrowSymbol',
            } as any,
        ).formatToParts(value);

        const number = this.separators(numberParts, options);

        const symbol = numberParts.find(
            (p: any) => p.type === 'currency',
        )?.value;

        return [
            symbol,
            symbol === options.currencyCode ? ' ' : '',
            number,
            symbol !== options.currencyCode && displayCurrencyCode
                ? ' ' + options.currencyCode
                : '',
        ]
            .join('')
            .trim();
    }

    private separators(
        numberParts: { type: string; value: string }[],
        options: WdxCurrencyOptions,
    ): string {
        return numberParts
            .filter(({ type }) => !['currency', 'literal'].includes(type))
            .map(({ type, value }) => {
                if (type === 'group') {
                    return options.groupSeparator || value;
                }
                if (type === 'decimal') {
                    return options.decimalSeparator || value;
                }
                return value;
            })
            .join('');
    }
}
