/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ActivityPartyType {
  To = 'To',
  From = 'From',
  Cc = 'Cc',
  Bcc = 'Bcc',
  Organiser = 'Organiser',
  Required = 'Required',
  Optional = 'Optional',
  Assignee = 'Assignee',
  AssigneeTeam = 'AssigneeTeam',
  Related = 'Related',
  Creator = 'Creator',
  Visitor = 'Visitor',
  Operator = 'Operator',
  Resource = 'Resource',
  Sender = 'Sender'
}
