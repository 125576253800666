import { createAction, props } from '@ngrx/store';
import { CaseType, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';

export const getAll = createAction(
    '[Case Types] Get All',
    props<{
        isActive: boolean;
        entityType?: SystemEntity;
    }>(),
);

export const getAllSuccess = createAction(
    '[Case Types] Get All Success',
    props<{
        caseTypes: CaseType[];
    }>(),
);

export const getAllFailure = createAction(
    '[Case Types] Get All Failure',
    props<{
        error: Error;
    }>(),
);
