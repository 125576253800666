/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SlaStatusType {
  None = 'None',
  Within = 'Within',
  Approaching = 'Approaching',
  Breached = 'Breached'
}
