import { Action, createReducer, on } from '@ngrx/store';
import {
    Client,
    HeatMap,
    KeyStatResult,
    PartyRoleRelationship,
    ProductType,
    RelationshipStatus,
    RelationshipTypeSimple,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { FAVOURITES_INDEX_ID } from '../../constants/state.constants';
import * as clientsActions from './clients.actions';

export interface State {
    clients?: CrudStateObject<Client>;
    accountHoldersForClient?: CrudStateObject<PartyRoleRelationship>;
    otherPartiesForClient?: CrudStateObject<PartyRoleRelationship>;
    clientParties?: CrudStateObject<PartyRoleRelationship>;
    applicableRolesForClient?: CrudStateObject<RelationshipTypeSimple>;
    keyStatsForClient?: CrudStateObject<KeyStatResult>;
    heatMap?: CrudStateObject<HeatMap>;
    productTypes?: CrudStateObject<ProductType>;
}

export const initialState: State = {
    clients: {},
    accountHoldersForClient: {},
    otherPartiesForClient: {},
    clientParties: {},
    applicableRolesForClient: {},
    keyStatsForClient: {},
    heatMap: {},
    productTypes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        clientsActions.getClient,
        (state, props): State => ({
            ...state,
            clients: {
                ...state.clients,
                [props.clientId]: {
                    ...(state.clients[props.clientId] ||
                        ({} as CrudState<Client>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getClientSuccess,
        (state, props): State => ({
            ...state,
            clients: {
                ...state.clients,
                [props.client.id]: {
                    ...state.clients[props.client.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.client,
                },
            },
        }),
    ),

    on(
        clientsActions.getClientFailure,
        (state, props): State => ({
            ...state,
            clients: {
                ...state.clients,
                [props.clientId]: {
                    ...state.clients[props.clientId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getFavourites,
        (state): State => ({
            ...state,
            clients: {
                ...state.clients,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.clients[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Client>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getFavouritesSuccess,
        (state, props): State => ({
            ...state,
            clients: {
                ...state.clients,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.clients[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Client>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.favourites,
                },
            },
        }),
    ),

    on(
        clientsActions.getFavouritesFailure,
        (state): State => ({
            ...state,
            clients: {
                ...state.clients,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.clients[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Client>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getAccountHoldersForClient,
        (state, props): State => ({
            ...state,
            accountHoldersForClient: {
                ...state.accountHoldersForClient,
                [props.clientId]: {
                    ...(state.accountHoldersForClient[props.clientId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getAccountHoldersForClientSuccess,
        (state, props): State => ({
            ...state,
            accountHoldersForClient: {
                ...state.accountHoldersForClient,
                [props.clientId]: {
                    ...state.accountHoldersForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.accountHoldersForClient,
                },
            },
        }),
    ),

    on(
        clientsActions.getAccountHoldersForClientFailure,
        (state, props): State => ({
            ...state,
            accountHoldersForClient: {
                ...state.accountHoldersForClient,
                [props.clientId]: {
                    ...state.accountHoldersForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getOtherPartiesForClient,
        (state, props): State => ({
            ...state,
            otherPartiesForClient: {
                ...state.otherPartiesForClient,
                [props.clientId]: {
                    ...(state.otherPartiesForClient[props.clientId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getOtherPartiesForClientSuccess,
        (state, props): State => ({
            ...state,
            otherPartiesForClient: {
                ...state.otherPartiesForClient,
                [props.clientId]: {
                    ...state.otherPartiesForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.otherPartiesForClient,
                },
            },
        }),
    ),

    on(
        clientsActions.getOtherPartiesForClientFailure,
        (state, props): State => ({
            ...state,
            otherPartiesForClient: {
                ...state.otherPartiesForClient,
                [props.clientId]: {
                    ...state.otherPartiesForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getApplicableRolesForClient,
        (state, props): State => ({
            ...state,
            applicableRolesForClient: {
                ...state.applicableRolesForClient,
                [props.clientId]: {
                    ...(state.applicableRolesForClient[props.clientId] ||
                        ({} as CrudState<RelationshipTypeSimple>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getApplicableRolesForClientSuccess,
        (state, props): State => ({
            ...state,
            applicableRolesForClient: {
                ...state.applicableRolesForClient,
                [props.clientId]: {
                    ...state.applicableRolesForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.applicableRoles,
                },
            },
        }),
    ),

    on(
        clientsActions.getApplicableRolesForClientFailure,
        (state, props): State => ({
            ...state,
            applicableRolesForClient: {
                ...state.applicableRolesForClient,
                [props.clientId]: {
                    ...state.applicableRolesForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getPartiesForClient,
        (state, props): State => ({
            ...state,
            clientParties: {
                ...state.clientParties,
                [props.clientId]: {
                    ...(state.clientParties[props.clientId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getPartiesForClientSuccess,
        (state, props): State => ({
            ...state,
            clientParties: {
                ...state.clientParties,
                [props.clientId]: {
                    ...state.clientParties[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientParties,
                },
            },
        }),
    ),

    on(
        clientsActions.getPartiesForClientFailure,
        (state, props): State => ({
            ...state,
            clientParties: {
                ...state.clientParties,
                [props.clientId]: {
                    ...state.clientParties[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getKeyStatsForClient,
        (state, props): State => ({
            ...state,
            keyStatsForClient: {
                ...state.keyStatsForClient,
                [props.clientId]: {
                    ...(state.keyStatsForClient[props.clientId] ||
                        ({} as CrudState<KeyStatResult>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getKeyStatsForClientSuccess,
        (state, props): State => ({
            ...state,
            keyStatsForClient: {
                ...state.keyStatsForClient,
                [props.clientId]: {
                    ...state.keyStatsForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.keyStatsForClient,
                },
            },
        }),
    ),

    on(
        clientsActions.getKeyStatsForClientFailure,
        (state, props): State => ({
            ...state,
            keyStatsForClient: {
                ...state.keyStatsForClient,
                [props.clientId]: {
                    ...state.keyStatsForClient[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getHeatMapForClient,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.clientId]: {
                    ...(state.heatMap[props.clientId] ||
                        ({} as CrudState<HeatMap>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getHeatMapForClientSuccess,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.clientId]: {
                    ...state.heatMap[props.clientId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.heatMap,
                },
            },
        }),
    ),

    on(
        clientsActions.getHeatMapForClientFailure,
        (state, props): State => ({
            ...state,
            heatMap: {
                ...state.heatMap,
                [props.clientId]: {
                    ...state.heatMap[props.clientId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.deleteRelationshipForId,
        (state, props): State => ({
            ...state,
            clientParties: {
                ...(state.clientParties ||
                    ({} as CrudStateObject<PartyRoleRelationship>)),
                [props.clientId]: {
                    ...(state.clientParties[props.clientId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(clientsActions.deleteRelationshipForIdSuccess, (state, props): State => {
        let list = [...state.clientParties[props.clientId].list];

        if (props.role.relationship.status !== RelationshipStatus.Active) {
            list = list?.filter(
                (partyRoleRelationship) =>
                    partyRoleRelationship.relationship.id !==
                    props.relationshipId,
            );
        }

        if (props.role.relationship.status === RelationshipStatus.Active) {
            list = list?.map((partyRoleRelationship) => {
                const PARTY_ROLE_RELATIONSHIP = {
                    ...partyRoleRelationship,
                };

                if (
                    partyRoleRelationship.relationship.id ===
                    props.relationshipId
                ) {
                    PARTY_ROLE_RELATIONSHIP.relationship = {
                        ...PARTY_ROLE_RELATIONSHIP.relationship,
                        status: RelationshipStatus.Inactive,
                    };
                }

                return PARTY_ROLE_RELATIONSHIP;
            });
        }

        return {
            ...state,
            clientParties: {
                ...state.clientParties,
                [props.clientId]: {
                    ...state.clientParties[props.clientId],
                    isDeleting: false,
                    hasDeletingError: false,
                    list,
                },
            },
        };
    }),

    on(
        clientsActions.deleteRelationshipForIdFailure,
        (state, props): State => ({
            ...state,
            clientParties: {
                ...state.clientParties,
                [props.clientId]: {
                    ...state.clientParties[props.clientId],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),

    on(
        clientsActions.getClientProductType,
        (state, props): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [props.clientId]: {
                    ...(state.productTypes[props.clientId] ||
                        ({} as CrudState<ProductType>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        clientsActions.getClientProductTypeSuccess,
        (state, props): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [props.clientId]: {
                    ...(state.productTypes[props.clientId] ||
                        ({} as CrudState<ProductType>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.productTypes,
                },
            },
        }),
    ),

    on(
        clientsActions.getClientProductTypeFailure,
        (state, props): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [props.clientId]: {
                    ...(state.productTypes[props.clientId] ||
                        ({} as CrudState<ProductType>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
