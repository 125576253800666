<molecule-input-wrapper
    [formInputData]="formInputData"
    [validationErrorMessage]="validationErrorMessage"
    [hideGuidanceText]="hideGuidanceText"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [value]="value | valueAsArray | valueAsArrayToString"
>
    <div class="action-buttons">
        <atom-add-button
            type="button"
            [attr.data-cy]="'btn-add-tag-' + formInputData?.name"
            [modalId]="modalId"
            [disabled]="formInputData.isDisabled"
            >Add</atom-add-button
        >
    </div>
    @if (!value?.length) {
        <atom-input-none-selected
            [modalId]="modalId"
            [disabled]="formInputData.isDisabled"
        ></atom-input-none-selected>
    } @else {
        <div class="info-card-container">
            @for (tag of value | valueAsArray; track tag) {
                <wdx-info-card [flush]="true">
                    <div wdxInfoCardLabel class="ms-2">{{ tag }}</div>
                    <div wdxInfoCardTrailing>
                        <atom-close
                            size="sm"
                            (click)="deselectTag(tag)"
                            [attr.data-cy]="'btn-close-tag-' + tag"
                        ></atom-close>
                    </div>
                </wdx-info-card>
            }
        </div>
    }
</molecule-input-wrapper>

<atom-modal-wrapper [modalId]="modalId" [modalTemplate]="tagsModal">
    <ng-template #tagsModal>
        <organism-modal
            [modalTitle]="formInputData.label | translateTokenisedString"
        >
            <molecule-info-card-search
                [formInputData]="formInputData"
                [mode]="
                    isMulti
                        ? INFO_CARD_SEARCH_MODE.MultiSelect
                        : INFO_CARD_SEARCH_MODE.Select
                "
                [infoCards]="
                    tags | filteredTags: searchText | tagsAsInfoCardData
                "
                [isLoading]="tagsIsLoading"
                [hasError]="tagsHasError"
                [initialValue]="value | convertToTags: tags"
                (search)="onSearch($event)"
                (confirm)="onConfirmClicked()"
                (valueChanged)="onChange($event)"
            ></molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
