import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    DateRestrictionType,
    FieldDefinition,
    FormElementLayoutDefinition,
    FormElementStyle,
    FormElementType,
    FormFieldType,
} from '@wdx/shared/utils';
import {
    ARRAY_FIELD_TYPES_FOR_VALIDATORS,
    CURRENCY_FIELD_TYPES,
    NUMERIC_FIELD_TYPES,
    STRING_FIELD_TYPES,
} from '../../constants';
import {
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
    ValidationSummary,
    ValidationSummarySection,
} from '../../models';
import { BespokeValidators } from '../../validators';

@Injectable()
export class FormValidationService {
    getValidators(
        formFieldDefinition: FieldDefinition,
        layout?: FormElementLayoutDefinition,
    ): ValidatorFn[] {
        const requiredValidator = formFieldDefinition.isRequired
            ? [Validators.required]
            : [];

        const minValidator = this.getMinValidator(
            formFieldDefinition.fieldType as FormFieldType,
            formFieldDefinition?.min,
        );
        const maxValidator = this.getMaxValidator(
            formFieldDefinition.fieldType as FormFieldType,
            formFieldDefinition?.max,
        );

        const futureDateValidator =
            formFieldDefinition.dateRestrictionType ===
            DateRestrictionType.FutureOnly
                ? [
                      BespokeValidators.dateMustBeAfter(
                          new Date(),
                          formFieldDefinition.fieldType as FormFieldType,
                      ),
                  ]
                : [];

        const historicDateValidator =
            formFieldDefinition.dateRestrictionType ===
            DateRestrictionType.HistoricOnly
                ? [
                      BespokeValidators.dateMustBeBefore(
                          new Date(),
                          formFieldDefinition.fieldType as FormFieldType,
                      ),
                  ]
                : [];

        const dateRangeValidator =
            [FormFieldType.DateRange, FormFieldType.DateTimeRange].includes(
                formFieldDefinition.fieldType as FormFieldType,
            ) &&
            layout &&
            !layout.isHidden &&
            layout?.elementStyle != FormElementStyle.StartOnly
                ? [BespokeValidators.datesMustBeValidRange()]
                : [];

        const regexValidator = formFieldDefinition.inputMaskRegEx
            ? [BespokeValidators.regex(formFieldDefinition.inputMaskRegEx)]
            : [];

        return [
            ...requiredValidator,
            ...minValidator,
            ...maxValidator,
            ...futureDateValidator,
            ...historicDateValidator,
            ...dateRangeValidator,
            ...regexValidator,
        ];
    }

    getMaxValidator(
        fieldType: FormFieldType | FormElementType,
        amount?: number | null,
    ) {
        return typeof amount === 'number' && amount > 0
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.maxLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.max(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(
                      fieldType as FormFieldType,
                  )
                      ? [BespokeValidators.maxArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [BespokeValidators.maxCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getMinValidator(
        fieldType: FormFieldType | FormElementType,
        amount?: number | null,
    ) {
        return typeof amount === 'number' && amount > 0
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.minLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [Validators.min(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(
                      fieldType as FormFieldType,
                  )
                      ? [BespokeValidators.minArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType as FormFieldType)
                      ? [BespokeValidators.minCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getArrayErrors(
        sectionLayoutDefinitions: ReactiveFormElement[],
        formGroups: UntypedFormGroup[],
    ): ValidationSummarySection[] {
        return formGroups.reduce((previous, current) => {
            const sectionErrors = this.getValidationSummary(
                sectionLayoutDefinitions,
                current.controls,
            );
            return [
                ...previous,
                ...(sectionErrors.sections?.length > 0
                    ? this.getValidationSummary(
                          sectionLayoutDefinitions,
                          current.controls,
                      ).sections
                    : ([null] as any)),
            ];
        }, [] as ValidationSummarySection[]);
    }

    getValidationSummary(
        sectionLayoutDefinitions: ReactiveFormElement[],
        controls: Record<string, AbstractControl>,
    ): ValidationSummary {
        return sectionLayoutDefinitions.reduce(
            (previous, current, currentIndex) => {
                const elementLayoutDefinitions =
                    current.layoutAndDefinition as ReactiveFormLayoutAndDefinition[];
                const definitionErrors = this.getDefinitionErrors(
                    elementLayoutDefinitions,
                    controls,
                );

                const subsections = elementLayoutDefinitions.reduce(
                    (previousChild, currentChild) => {
                        if (currentChild.fieldType === FormFieldType.Array) {
                            return [
                                ...previousChild,
                                ...(controls[currentChild.name as string]
                                    ?.invalid
                                    ? this.getSubSectionErrors(
                                          currentChild,
                                          controls[currentChild.name as string],
                                      )
                                    : ([] as any)),
                            ];
                        }
                        return [];
                    },
                    [] as ReactiveFormElement[],
                );

                return {
                    sections: [
                        ...previous.sections,
                        ...[
                            {
                                name: current.section?.name,
                                label: current.section?.label,
                                errors: definitionErrors?.map((error) => ({
                                    ...error,
                                    sectionIndex: currentIndex,
                                })),
                                subsections: subsections,
                            },
                        ],
                    ],
                } as ValidationSummary;
            },
            {
                sections: [],
            } as ValidationSummary,
        );
    }

    getCompletionSummary(formGroup: UntypedFormGroup): {
        expected: number;
        completed: number;
    } {
        let expected = 0;
        let completed = 0;

        Object.keys(formGroup.controls).forEach((key) => {
            const CONTROL_DEFINITION = formGroup.get(key);

            if (CONTROL_DEFINITION instanceof FormArray) {
                const CONTROLS = formGroup.get(key) as FormArray;

                CONTROLS.controls.forEach((formGroup) => {
                    const DATA = this.getCompletionSummary(
                        formGroup as UntypedFormGroup,
                    );

                    expected = expected + DATA.expected;
                    completed = completed + DATA.completed;
                });
            }

            if (CONTROL_DEFINITION instanceof FormGroup) {
                const FormGroup = formGroup.get(key) as FormGroup;

                const DATA = this.getCompletionSummary(
                    FormGroup as UntypedFormGroup,
                );

                expected = expected + DATA.expected;
                completed = completed + DATA.completed;
            }

            if (CONTROL_DEFINITION instanceof FormControl) {
                const CONTROL = formGroup.get(key) as FormControl;

                const isRequired = CONTROL.hasValidator(Validators.required);
                const isValid = CONTROL.valid;

                if (isRequired) {
                    if (isValid) {
                        completed = completed + 1;
                    }
                    expected = expected + 1;
                }
            }
        });

        return { expected, completed };
    }

    getDefinitionErrors(
        elementLayoutDefinitions: ReactiveFormLayoutAndDefinition[],
        controls: Record<string, AbstractControl>,
    ) {
        let eleLayoutDefinitions = elementLayoutDefinitions;

        if (Array.isArray(eleLayoutDefinitions[0])) {
            eleLayoutDefinitions = eleLayoutDefinitions[0];
        }

        return eleLayoutDefinitions.reduce((previous, current) => {
            const CONTROL = controls[current.name as string];

            return [
                ...previous,
                ...(CONTROL?.invalid ||
                (typeof CONTROL?.value === 'undefined' && current.isRequired)
                    ? [
                          {
                              fieldLabel: current.label,
                              name: current.name,
                              elementType: FormElementType.Field,
                          },
                      ]
                    : []),
            ];
        }, [] as ReactiveFormLayoutAndDefinition[]);
    }

    getSubSectionErrors(SubSection: any, formGroup: any) {
        if (formGroup.controls.length) {
            return this.getArrayErrors(
                SubSection?.children,
                formGroup.controls,
            );
        }

        return [];
    }

    scrollToError(fieldDefinitionElement: any): void {
        fieldDefinitionElement?.scrollIntoView({
            behavior: 'smooth',
        });
        const errorElement = fieldDefinitionElement?.querySelector(
            'input,select,textarea',
        );
        if (errorElement) {
            setTimeout(() => errorElement.focus(), 1);
        }
    }

    isNullOrEmpty(value: any) {
        return value === null || value?.length === 0;
    }
}
