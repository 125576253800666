<wdx-form-field
    *ngIf="showReadout"
    [label]="formInputData?.label | translateTokenisedString"
    [isRequired]="formInputData?.isRequired"
    [isSensitive]="formInputData?.isSensitive"
    [guidanceText]="formInputData?.guidanceText | translateTokenisedString"
    [formInputData]="formInputData"
    [validationErrorMessage]="validationErrorMessage"
    [formDisplayMode]="formDisplayMode"
    [value]="value | fn: valueAsString"
>
    <wdx-input-group [prefix]="true">
        <button
            wdxInputText
            class="lookup-button"
            [class.is-invalid]="validationErrorMessage"
            [attr.data-cy]="
                'action-' + (formInputData.name || formInputData.id)
            "
            [modalId]="!formInputData.isDisabled && modalId"
            [disabled]="formInputData.isDisabled"
            (click)="onOpen()"
        >
            <div
                *ngIf="
                    (!isMulti && value) || (isMulti && value?.length);
                    else placeholderTmpl
                "
                class="results-container"
            >
                <ng-container
                    *ngFor="
                        let infoCardData of value
                            | fn: valueAsArray
                            | fn: lookupResultsAsInfoCardData;
                        let i = index;
                        let last = last
                    "
                >
                    <molecule-info-card
                        class="result-card text-truncate"
                        size="sm"
                        [avatars]="infoCardData.avatars"
                        [primaryInfo]="infoCardData.primaryInfo"
                    >
                        <button
                            *ngIf="!formInputData.isDisabled"
                            class="custom-actions"
                            wdxIconButton
                            [wdxButton]="WDX_BUTTON_STYLE.None"
                            [wdxButtonSize]="WDX_BUTTON_SIZE.Sm"
                            (click)="
                                isMulti
                                    ? deleteSingle($event, infoCardData.data)
                                    : clearField($event)
                            "
                        >
                            <wdx-icon [icon]="ICON_DELETE"></wdx-icon>
                        </button>
                    </molecule-info-card>
                    <div *ngIf="!last" class="divider-end divider--short"></div>
                </ng-container>
            </div>
            <ng-template #placeholderTmpl>
                <span *ngIf="placeholder" class="placeholder">
                    {{ placeholder }}
                </span>
            </ng-template>
        </button>
        <wdx-icon prefix [icon]="ICON_SEARCH"></wdx-icon>
    </wdx-input-group>
</wdx-form-field>

<atom-modal-wrapper [modalId]="modalId" [modalTemplate]="lookupModal">
    <ng-template #lookupModal>
        <organism-modal
            [modalTitle]="formInputData.label | translateTokenisedString"
            (modalClosed)="onClose()"
        >
            <molecule-info-card-search
                [formInputData]="formInputData"
                [mode]="
                    isMulti
                        ? INFO_CARD_SEARCH_MODE.MultiSelect
                        : INFO_CARD_SEARCH_MODE.Select
                "
                [lookupMode]="lookupMode"
                [infoCards]="lookupResults | fn: lookupResultsAsInfoCardData"
                [isLoading]="lookupIsLoading"
                [hasError]="lookupHasError"
                [initialValue]="value"
                [bannerInfo]="bannerInfo"
                [placeholder]="placeholder"
                (confirm)="onConfirmClicked()"
                (search)="onSearch($event)"
                (generateDocument)="onGenerateAndDownloadDocument($event)"
                (previewAvailableDocumentData)="
                    onPreviewAvailableDocumentData($event)
                "
                (valueChanged)="onChange($event)"
            >
                <div
                    class="flex-shrink-0 flex-grow-0 bg-muted offcanvas-body d-flex align-items-center"
                    *ngIf="
                        showNoResultsMessage &&
                        lookupResults?.length === 0 &&
                        keyword
                    "
                >
                    <p>
                        The user does not exist or does not have access to view
                        this record.
                    </p>
                </div>
            </molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
