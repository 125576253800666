import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { fileDownloadActions } from '@wdx/shared/infrastructure/file-io';
import {
    InterpolationFragment,
    InterpolationFragmentType,
} from '../../../models/notification.model';
import * as rootReducer from '../../../state/_setup/reducers';

interface TokenParts {
    type: string;
    id: string;
    name: string;
    token: string;
}

@Component({
    selector: 'clmi-interpolation-display',
    templateUrl: './interpolation-display.component.html',
})
export class InterpolationDisplayComponent implements OnInit {
    readonly NOTIFICATION_FRAGMENT_TYPE = InterpolationFragmentType;

    fragments: Array<InterpolationFragment>;

    @Input() entityId!: string;
    @Input() text!: string;

    @Output() linkSelected = new EventEmitter<void>();

    private entityRouteService = inject(EntityRouteService);
    private store$ = inject(Store<rootReducer.State>);

    ngOnInit(): void {
        // TODO: remove all code supporting the old format: #name:id:type#
        // and just support new format: [type|id|name]
        // when API just returns new format
        if (/#[^:#]+:[^:#]+:[^:#]+#/.test(this.text)) {
            this.fragments = this.oldParseInterpolation(this.text);
        } else {
            this.fragments = this.parseInterpolation(this.text);
        }
    }

    onDownload(fragment: InterpolationFragment) {
        this.store$.dispatch(
            fileDownloadActions.getFileDownload({
                fileIndex: fragment.fileIndex,
                fileName: fragment.value,
            }),
        );
    }

    private oldParseInterpolation(value: string): Array<InterpolationFragment> {
        return (
            value
                // split entire string (e.g. 'abc#I:II:III#def#1:2:3#...) by the pattern #a:b:c#
                ?.split(/#(.*?)#/gi)
                // remove empty ones
                .filter((fragment) => fragment)
                .map((fragment: string): InterpolationFragment => {
                    // further split fragments to a and b and c
                    const [token, name, id, type] = this.execRegExp(
                        fragment,
                    ) || [fragment];
                    const parts: TokenParts = { token, name, id, type };

                    return this.convertTokenPartsToFragment(parts);
                })
        );
    }

    private parseInterpolation(value: string): Array<InterpolationFragment> {
        return value
            ?.split(/\[(.*?)\]/)
            .filter((fragment) => fragment)
            .map((fragment: string): InterpolationFragment => {
                // further split fragments to a and b and c
                const [token, type, id, name] =
                    // eslint-disable-next-line no-useless-escape
                    /(?<type>[^\[\|]+)\|(?<id>[^\[\|]+)\|(?<name>[^\]\|]+)/.exec(
                        fragment,
                    ) || [fragment];
                const parts: TokenParts = {
                    token,
                    name: decodeURIComponent(name || ''),
                    id,
                    type,
                };

                return this.convertTokenPartsToFragment(parts);
            });
    }

    private execRegExp(value: string): RegExpExecArray {
        return new RegExp(
            '(?<title>[^#:]+):(?<id>[^#:]+):(?<type>[^#:]+)',
        ).exec(value);
    }

    onLinkSelected(): void {
        this.linkSelected.emit();
    }

    convertTokenPartsToFragment(parts: TokenParts): InterpolationFragment {
        if ([parts.name, parts.id, parts.type].every((item) => !item)) {
            return {
                type: InterpolationFragmentType.Text,
                value: parts.token,
            };
        }

        // if this is a download notification
        if (parts.name === 'fileindex') {
            return {
                type: InterpolationFragmentType.FileDownload,
                value: parts.id,
                fileIndex: parts.type,
            };
        }

        // if this is a link notification
        if ([parts.name, parts.id, parts.type].every(Boolean)) {
            const entityType = parts.type as SystemEntity;
            const entityId =
                entityType === SystemEntity.WorkItem ? this.entityId : parts.id;
            const value = parts.name;

            let subEntityType = null;
            let subEntityId = null;

            if (entityType === SystemEntity.WorkItem) {
                subEntityType = parts.type;
                subEntityId = parts.id;
            }

            const routeWithParams =
                this.entityRouteService.routeWithParamsForEntity(
                    entityType,
                    entityId,
                    null,
                    subEntityType,
                    subEntityId,
                );

            return {
                type: InterpolationFragmentType.Link,
                value,
                entityType,
                entityId,
                subEntityType,
                subEntityId,
                ...routeWithParams,
            };
        }
    }
}
