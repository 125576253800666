import { FilterOperator } from '@wdx/clmi/clmi-swagger-gen';

export const DATE_TO_INT = [
    FilterOperator.LastXDays,
    FilterOperator.LastXHours,
    FilterOperator.NextXDays,
    FilterOperator.NextXHours,
    FilterOperator.OlderThanXDays,
];

export const INPUT_NUMBER = 'number';
