import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import {
    EntityAction,
    EntityOperations,
    EntityPermissionType,
    SystemEntity,
} from '@wdx/clmi/clmi-swagger-gen';
import {
    operationsActions,
    operationsSelectors,
} from '@wdx/clmi/api-services/state';
import { Observable } from 'rxjs';
import * as rootReducer from '../../../state/_setup/reducers';

@Injectable({
    providedIn: 'root',
})
export class EntityOperationsService {
    entityType: SystemEntity;

    operations$: Observable<EntityOperations>;
    actions$: Observable<EntityAction[]>;
    permissions$: Observable<EntityPermissionType[]>;

    operationsIsLoading$: Observable<boolean>;
    operationsHasError$: Observable<boolean>;

    constructor(private store$: Store<rootReducer.State>) {}

    /**
     * Set the operations selectors for the input entity, dispatch the  getOperationsForEntity action, and then return the selectors in an object.
     * @param entityType | SystemEntity
     * @returns | { operations$: Observable<EntityOperations>, actions$: Observable<EntityAction[], permissions$: Observable<EntityPermissionType[]> }
     */
    getOperations(entityType: SystemEntity): {
        operations$: Observable<EntityOperations>;
        actions$: Observable<EntityAction[]>;
        permissions$: Observable<EntityPermissionType[]>;
    } {
        const selectors = this.select(entityType);
        this.dispatch();
        return selectors;
    }

    /**
     * Set the operations selectors and return them in an object.
     * @param entityType | SystemEntity
     * @returns | { operations$: Observable<EntityOperations>, actions$: Observable<EntityAction[], permissions$: Observable<EntityPermissionType[]> }
     */
    select(entityType: SystemEntity): {
        operations$: Observable<EntityOperations>;
        actions$: Observable<EntityAction[]>;
        permissions$: Observable<EntityPermissionType[]>;
    } {
        this.entityType = entityType;
        this.operations$ = this.store$.select(
            operationsSelectors.getOperationsForEntity,
            { entityType },
        );
        this.actions$ = this.store$.select(
            operationsSelectors.getActionsForEntity,
            { entityType },
        );
        this.permissions$ = this.store$.select(
            operationsSelectors.getPermissionsForEntity,
            { entityType },
        );
        return {
            operations$: this.operations$,
            actions$: this.actions$,
            permissions$: this.permissions$,
        };
    }

    /**
     * Dispatch the getOperationsForEntity action with the stored entityType.
     * This has only been implemented on the backend for ExternalUsers, Organisation, and Person, but will be expanded in future.
     */
    dispatch(): void {
        switch (this.entityType) {
            case SystemEntity.ExternalUser:
            case SystemEntity.Organisation:
            case SystemEntity.Person:
                this.store$.dispatch(
                    operationsActions.getOperationsForEntity({
                        entityType: this.entityType,
                    }),
                );
        }
    }
}
