import { Pipe, PipeTransform } from '@angular/core';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';
import {
    FormAction,
    FormActionType,
    TRANSLATION_CANCEL_BTN,
    TranslationsService,
} from '@wdx/shared/utils';
import { FormActionButton } from '../../models/form-action-button.model';

@Pipe({ name: 'formActions', standalone: true })
export class FormActionsPipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(
        actions?: FormAction[] | null,
        hideCancel?: boolean,
    ): FormActionButton[] {
        if (!actions || (!actions?.length && hideCancel)) {
            return [];
        }
        const functions: FormActionButton[] = this.mapActionButtons(
            actions,
            FormActionType.Function,
            {
                buttonStyle: WdxButtonStyle.OutlinePrimary,
                dataCy: 'btn-function',
            },
        );
        const saveAsDraft: FormActionButton[] = this.mapActionButtons(
            actions,
            FormActionType.SaveDraft,
            {
                buttonStyle: WdxButtonStyle.OutlinePrimary,
                dataCy: 'btn-save-draft',
            },
        );
        const save: FormActionButton[] = this.mapActionButtons(
            actions,
            FormActionType.Publish,
            {
                buttonStyle: WdxButtonStyle.Primary,
                dataCy: 'btn-save',
                dropdown: saveAsDraft?.length
                    ? [
                          {
                              ...saveAsDraft[0],
                              label: saveAsDraft[0].label || '',
                          },
                      ]
                    : undefined,
            },
        );
        const saveAndComplete: FormActionButton[] = this.mapActionButtons(
            actions,
            FormActionType.Complete,
            {
                buttonStyle: WdxButtonStyle.Primary,
                dataCy: 'btn-save-complete',
            },
        );
        const cancel: FormActionButton = {
            type: 'Cancel',
            label: this.translationsService.getTranslationByKey(
                TRANSLATION_CANCEL_BTN,
            ),
            buttonStyle: WdxButtonStyle.OutlinePrimary,
            dataCy: 'btn-cancel',
        };

        return [
            ...(hideCancel ? [] : [cancel]),
            ...functions,
            ...(save?.length ? [] : saveAsDraft),
            ...saveAndComplete,
            ...save,
        ];
    }

    private mapActionButtons(
        actions: FormAction[],
        type: FormActionType,
        buttonConfig: Partial<FormActionButton>,
    ): FormActionButton[] {
        const typeActions = this.actionsForType(actions, type);
        const defaultConfig = {
            buttonStyle: WdxButtonStyle.OutlinePrimary,
            ...buttonConfig,
        };
        return typeActions.map((action) => ({
            ...defaultConfig,
            type,
            action,
            label: action.name?.value || '',
            dataCy: `${buttonConfig.dataCy}-${action.name?.key}`,
        }));
    }

    private actionsForType(
        actions: FormAction[],
        type: FormActionType,
    ): FormAction[] {
        return actions.reduce((subActions: FormAction[], action) => {
            if (action.type === type) {
                return [...subActions, action];
            }
            return subActions;
        }, []);
    }
}
