import { Action, createReducer, on } from '@ngrx/store';
import { EntityOperations } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { CrudStatus, OperationsSetup } from '@wdx/shared/utils';
import * as operationsActions from './operations.actions';

export const GLOBAL_STATE_INDEX_ID = 'GLOBAL_STATE_INDEX_ID';

export interface State {
    operations: {
        [entityType: string]: CrudStateObject<EntityOperations>;
    };
    latestRouteSetups?: CrudState<OperationsSetup>;
    status: CrudStatus;
    isPatching: boolean;
}

export const initialState: State = {
    operations: {},
    latestRouteSetups: {},
    status: CrudStatus.Initial,
    isPatching: false,
};

const reducerSetup = createReducer(
    initialState,

    on(
        operationsActions.getOperationsForEntity,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
            status: CrudStatus.Loading,
        }),
    ),

    on(
        operationsActions.getOperationsForEntitySuccess,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        single: props.operations,
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                    },
                },
            },
            status: CrudStatus.Success,
        }),
    ),

    on(
        operationsActions.getOperationsForEntityFailure,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
            status: CrudStatus.Error,
        }),
    ),

    on(
        operationsActions.getOperationsForId,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
            status: CrudStatus.Loading,
        }),
    ),

    on(
        operationsActions.getOperationsForIdSuccess,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        single: props.operations,
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                    },
                },
            },
            status: CrudStatus.Success,
        }),
    ),

    on(
        operationsActions.getOperationsForIdFailure,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
            status: CrudStatus.Error,
        }),
    ),

    on(
        operationsActions.patchActionWithURL,
        (state): State => ({
            ...state,
            status: CrudStatus.Loading,
            isPatching: true,
        }),
    ),

    on(operationsActions.patchActionWithURLSuccess, (state, props): State => {
        const OPERATIONS_FOR_TYPE = {
            ...state.operations[props.entityType],
        };
        delete OPERATIONS_FOR_TYPE[props.entityId];

        return {
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...OPERATIONS_FOR_TYPE,
                },
            },
            status: CrudStatus.Success,
            isPatching: false,
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
