import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { RecentRecord } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class RecentRecordsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getUserRecentRecords(): Observable<RecentRecord[]> {
        return this.http.get<RecentRecord[]>(
            `${this.config.getConfiguration().API_BASE}/user/me/recents`,
        );
    }

    updateRecentlyViewedRecord(
        entityType: string,
        recordId: string,
    ): Observable<RecentRecord> {
        // eslint-disable-next-line max-len
        return this.http.patch<RecentRecord>(
            `${
                this.config.getConfiguration().API_BASE
            }/useraction/view/entity/${entityType}/${recordId}`,
            {},
        );
    }
}
