import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { SuggestedParty } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class SuggestedPartiesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForParty(partyId: string): Observable<SuggestedParty[]> {
        return this.http.get<SuggestedParty[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/suggestedparties`,
        );
    }
}
