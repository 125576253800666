import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsvFile } from '@wdx/clmi/clmi-swagger-gen';
import { Batch } from '../../models/batch.model';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class BatchListService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getImportFile(id: string): Observable<CsvFile> {
        return this.http.get<CsvFile>(
            `${this.config.getConfiguration().API_BASE}/Import/${id}`,
        );
    }

    deleteBatch(id: string): Observable<Batch> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/messagebatch/${id}`,
        );
    }
}
