import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormFieldType } from '@wdx/shared/utils';
import {
    FormFrameworkEvent,
    ReactiveFormLayoutAndDefinition,
} from '../../models';

@Component({
    selector: 'wdx-ff-controls-container',
    templateUrl: './form-controls-container.component.html',
})
export class FormControlsContainerComponent {
    @Input() entityId!: string;
    @Input() formElement!: ReactiveFormLayoutAndDefinition;
    @Input() formContext!: any;

    @Output() formEvent = new EventEmitter<FormFrameworkEvent>();

    @HostBinding('class') class = 'd-block position-relative mb-3';

    constructor(public controlContainer: ControlContainer) {}

    readonly FORM_FIELD_TYPE = FormFieldType;
}
