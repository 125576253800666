import { Action, createReducer, on } from '@ngrx/store';
import { ActivityNote } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as notesActions from './notes.actions';

export interface State {
    notes: CrudStateObject<ActivityNote>;
}

export const initialState: State = {
    notes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        notesActions.deleteNote,
        (state, props): State => ({
            ...state,
            notes: {
                ...state.notes,
                [props.noteId]: {
                    ...(state.notes[props.noteId] ||
                        ({} as CrudState<ActivityNote>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        notesActions.deleteSuccess,
        (state, props): State => ({
            ...state,
            notes: {
                ...state.notes,
                [props.noteId]: {
                    ...state.notes[props.noteId],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        }),
    ),

    on(
        notesActions.deleteFailure,
        (state, props): State => ({
            ...state,
            notes: {
                ...state.notes,
                [props.noteId]: {
                    ...state.notes[props.noteId],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
