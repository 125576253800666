import { Action, createReducer, on } from '@ngrx/store';
import { Product, ProductType, StatusType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as productTypesActions from './product-types.actions';

export interface State {
    productTypes: CrudStateObject<ProductType>;
    products: CrudStateObject<Product>;
}

export const initialState: State = {
    productTypes: null,
    products: null,
};

const reducerSetup = createReducer(
    initialState,

    on(
        productTypesActions.getProductTypes,
        (state): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        productTypesActions.getProductTypesSuccess,
        (state, props): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.productTypes,
                    filteredList: props.productTypes,
                },
            },
        }),
    ),

    on(
        productTypesActions.getProductTypesFailure,
        (state): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.productTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        productTypesActions.filterProductTypes,
        (state, props): State => ({
            ...state,
            productTypes: {
                ...state.productTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.productTypes[GLOBAL_STATE_INDEX_ID],
                    filteredList:
                        props.searchText.length < 2
                            ? state.productTypes[GLOBAL_STATE_INDEX_ID].list
                            : state.productTypes[GLOBAL_STATE_INDEX_ID].list
                                  .filter(
                                      (productType) =>
                                          productType.name
                                              .toLowerCase()
                                              .indexOf(
                                                  props.searchText.toLowerCase(),
                                              ) > -1,
                                  )
                                  .slice(0, 10),
                },
            },
        }),
    ),

    on(
        productTypesActions.getProducts,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        productTypesActions.getProductsSuccess,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    list: props.products,
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        productTypesActions.getProductsFailure,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        productTypesActions.updateProductSuccess,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    ...state.products[props.code],
                    list: (state.products[props.code].list as Product[]).map(
                        (product) => ({
                            ...product,
                            ...(product.id === props.id && props.product),
                        }),
                    ),
                },
            },
        }),
    ),

    on(
        productTypesActions.createProductSuccess,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    ...state.products[props.code],
                    list: [
                        ...state.products[props.code].list,
                        props.product,
                    ].sort((a, b) => (a.name > b.name ? 1 : -1)),
                },
            },
        }),
    ),

    on(
        productTypesActions.disableProduct,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    ...state.products[props.code],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        productTypesActions.disableProductSuccess,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    ...state.products[props.code],
                    list: state.products[props.code]?.list.map((item) => {
                        if (item.id === props.id) {
                            return {
                                ...item,
                                status: props.isDisabled
                                    ? StatusType.Inactive
                                    : StatusType.Active,
                            };
                        }
                        return item;
                    }),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        productTypesActions.disableProductFailure,
        (state, props): State => ({
            ...state,
            products: {
                ...state.products,
                [props.code]: {
                    ...state.products[props.code],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
