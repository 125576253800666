import { Action, createReducer, on } from '@ngrx/store';
import {
    AccessVisibility,
    Opportunity,
    OpportunityTeamMember,
    PipelineSummary,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as opportunitiesActions from './opportunities.actions';

export interface State {
    opportunities?: CrudStateObject<Opportunity>;
    pipelineSummary?: CrudState<PipelineSummary>;
    activeKanbanStage?: string;
    activeMoveToStage?: string;
    visibility?: CrudStateObject<AccessVisibility>;
    contextual?: {
        [key: string]: {
            [key: string]: {
                list: Opportunity[];
                isLoadingSingle: boolean;
                hasLoadingSingleError: boolean;
            };
        };
    };
}

export const initialState: State = {
    pipelineSummary: {},
    opportunities: {},
    activeKanbanStage: null,
    activeMoveToStage: null,
    visibility: {},
    contextual: {},
};

const reducerSetup = createReducer(
    initialState,

    on(opportunitiesActions.getPipelineSummary, (state): State => {
        return {
            ...state,
            pipelineSummary: {
                isLoadingSingle: true,
                hasLoadingSingleError: false,
            },
        };
    }),

    on(
        opportunitiesActions.getPipelineSummarySuccess,
        (state, props): State => {
            return {
                ...state,
                pipelineSummary: {
                    single: props.pipelineSummary,
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            };
        },
    ),

    on(opportunitiesActions.getPipelineSummaryFailure, (state): State => {
        return {
            ...state,
            pipelineSummary: {
                isLoadingSingle: false,
                hasLoadingSingleError: true,
            },
        };
    }),

    on(opportunitiesActions.setActiveKanbanStage, (state, props): State => {
        return {
            ...state,
            activeKanbanStage: props.stageCode,
        };
    }),

    on(opportunitiesActions.moveToStage, (state, props): State => {
        return {
            ...state,
            activeMoveToStage: props.toStage,
        };
    }),

    on(opportunitiesActions.moveToStageSuccess, (state): State => {
        return {
            ...state,
            activeMoveToStage: null,
        };
    }),

    on(
        opportunitiesActions.getForId,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...(state.opportunities[props.opportunityId] ||
                        ({} as CrudState<Opportunity>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...state.opportunities[props.opportunityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.opportunity,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...state.opportunities[props.opportunityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.resetOpportunities,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.id]: null,
            },
        }),
    ),

    on(
        opportunitiesActions.resetOpportunities,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.id]: null,
            },
        }),
    ),

    on(
        opportunitiesActions.deleteTeamMember,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...(state.opportunities[props.opportunityId] ||
                        ({} as CrudState<OpportunityTeamMember>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.deleteTeamMemberSuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...state.opportunities[props.opportunityId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.deleteTeamMemberFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...state.opportunities[props.opportunityId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getAllMembers,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...(state.opportunities[props.opportunityId] ||
                        ({} as CrudState<OpportunityTeamMember>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getAllMembersSuccess,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...(state.opportunities[props.opportunityId] ||
                        ({} as CrudState<OpportunityTeamMember>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.teamMembers,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getAllMembersFailure,
        (state, props): State => ({
            ...state,
            opportunities: {
                ...state.opportunities,
                [props.opportunityId]: {
                    ...(state.opportunities[props.opportunityId] ||
                        ({} as CrudState<OpportunityTeamMember>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getVisibilityForId,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...(state.visibility[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getVisibilityForIdSuccess,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getVisibilityForIdFailure,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getContextualOpportunity,
        (state, props): State => ({
            ...state,
            contextual: {
                ...state.contextual,
                [props.contextualType]: {
                    ...state.contextual[props.contextualType],
                    [props.contextualId]: {
                        list: [],
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getContextualOpportunitySuccess,
        (state, props): State => ({
            ...state,
            contextual: {
                ...state.contextual,
                [props.contextualType]: {
                    ...state.contextual[props.contextualType],
                    [props.contextualId]: {
                        list: [...props.opportunities],
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                    },
                },
            },
        }),
    ),

    on(
        opportunitiesActions.getContextualOpportunityFailure,
        (state, props): State => ({
            ...state,
            contextual: {
                ...state.contextual,
                [props.contextualType]: {
                    ...state.contextual[props.contextualType],
                    [props.contextualId]: {
                        list: [],
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
