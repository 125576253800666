import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { InvestmentAccount } from '@wdx/clmi/clmi-swagger-gen';

@Injectable()
export class InvestmentAccountsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getForId(investmentAccountId: string): Observable<InvestmentAccount> {
        return this.http.get<InvestmentAccount>(
            `${
                this.config.getConfiguration().API_BASE
            }/investmentaccount/${investmentAccountId}`,
        );
    }
}
