import { Action, createReducer, on } from '@ngrx/store';
import {
    Activity,
    ActivityChat,
    Article,
    ArticleLinkedRecord,
    Case,
    Client,
    HistoryEntry,
    PartyStub,
    Person,
} from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import {
    FAVOURITES_INDEX_ID,
    GLOBAL_STATE_INDEX_ID,
} from '../../constants/state.constants';
import * as articlesActions from './articles.actions';

export interface State {
    articles?: CrudStateObject<Article>;
    targetted?: CrudStateObject<Person>;
    history?: CrudStateObject<HistoryEntry>;
    viewed?: CrudStateObject<Person>;
    recent?: CrudStateObject<Article>;
    feedClients?: CrudStateObject<Client>;
    comments?: CrudStateObject<ActivityChat>;
    linkedRecords?: CrudStateObject<ArticleLinkedRecord>;
    linkedActivities?: CrudStateObject<Activity>;
    linkedCases?: CrudStateObject<Case>;
    favourited?: CrudStateObject<PartyStub>;
}

export const initialState: State = {
    articles: {},
    targetted: {},
    history: {},
    viewed: {},
    recent: {},
    feedClients: {},
    comments: {},
    linkedRecords: {},
    linkedActivities: {},
    linkedCases: {},
    favourited: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        articlesActions.getFavourites,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.articles[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getFavouritesSuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.articles[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.favourites,
                },
            },
        }),
    ),

    on(
        articlesActions.getFavouritesFailure,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.articles[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.create,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.createSuccess,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isCreating: false,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.createFailure,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.update,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.updateSuccess,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.updateFailure,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.articles[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.parse,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                parsed: {
                    ...(state.articles.parsed || ({} as CrudState<Article>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.parseSuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                parsed: {
                    ...(state.articles.parsed || ({} as CrudState<Article>)),
                    isCreating: false,
                    hasCreatingError: false,
                    single: props.article,
                },
            },
        }),
    ),

    on(
        articlesActions.parseFailure,
        (state): State => ({
            ...state,
            articles: {
                ...state.articles,
                parsed: {
                    ...(state.articles.parsed || ({} as CrudState<Article>)),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getForId,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [props.articleId]: {
                    ...(state.articles[props.articleId] ||
                        ({} as CrudState<Article>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [props.articleId]: {
                    ...state.articles[props.articleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.article,
                },
            },
        }),
    ),

    on(
        articlesActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            articles: {
                ...state.articles,
                [props.articleId]: {
                    ...state.articles[props.articleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getHistoryForId,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.articleId]: {
                    ...(state.history[props.articleId] ||
                        ({} as CrudState<HistoryEntry>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.history[props.articleId]?.infinity,
                },
            },
        }),
    ),

    on(
        articlesActions.getHistoryForIdSuccess,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.articleId]: {
                    ...state.history[props.articleId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.history.paging,
                        combinedList: [
                            ...(state.history[props.articleId].infinity
                                ?.combinedList || []),
                            ...props.history.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        articlesActions.getHistoryForIdFailure,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.articleId]: {
                    ...state.history[props.articleId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedRecords,
        (state, props): State => ({
            ...state,
            linkedRecords: {
                ...state.linkedRecords,
                [props.articleId]: {
                    ...(state.linkedRecords[props.articleId] ||
                        ({} as CrudState<ArticleLinkedRecord>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedRecordsSuccess,
        (state, props): State => ({
            ...state,
            linkedRecords: {
                ...state.linkedRecords,
                [props.articleId]: {
                    ...(state.linkedRecords[props.articleId] ||
                        ({} as CrudState<ArticleLinkedRecord>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.records,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedRecordsFailure,
        (state, props): State => ({
            ...state,
            linkedRecords: {
                ...state.linkedRecords,
                [props.articleId]: {
                    ...(state.linkedRecords[props.articleId] ||
                        ({} as CrudState<ArticleLinkedRecord>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedActivities,
        (state, props): State => ({
            ...state,
            linkedActivities: {
                ...state.linkedActivities,
                [props.articleId]: {
                    ...(state.linkedActivities[props.articleId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedActivitiesSuccess,
        (state, props): State => ({
            ...state,
            linkedActivities: {
                ...state.linkedActivities,
                [props.articleId]: {
                    ...(state.linkedActivities[props.articleId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.activities,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedActivitiesFailure,
        (state, props): State => ({
            ...state,
            linkedActivities: {
                ...state.linkedActivities,
                [props.articleId]: {
                    ...(state.linkedActivities[props.articleId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedCases,
        (state, props): State => ({
            ...state,
            linkedCases: {
                ...state.linkedCases,
                [props.articleId]: {
                    ...(state.linkedCases[props.articleId] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedCasesSuccess,
        (state, props): State => ({
            ...state,
            linkedCases: {
                ...state.linkedCases,
                [props.articleId]: {
                    ...(state.linkedCases[props.articleId] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases,
                },
            },
        }),
    ),

    on(
        articlesActions.linkedCasesFailure,
        (state, props): State => ({
            ...state,
            linkedCases: {
                ...state.linkedCases,
                [props.articleId]: {
                    ...(state.linkedCases[props.articleId] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.favourited,
        (state, props): State => ({
            ...state,
            favourited: {
                ...state.favourited,
                [props.articleId]: {
                    ...(state.favourited[props.articleId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.favourited[props.articleId]?.infinity,
                },
            },
        }),
    ),

    on(
        articlesActions.favouritedSuccess,
        (state, props): State => ({
            ...state,
            favourited: {
                ...state.favourited,
                [props.articleId]: {
                    ...state.favourited[props.articleId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.favourited.paging,
                        combinedList: [
                            ...(state.favourited[props.articleId].infinity
                                ?.combinedList || []),
                            ...props.favourited.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        articlesActions.favouritedFailure,
        (state, props): State => ({
            ...state,
            favourited: {
                ...state.favourited,
                [props.articleId]: {
                    ...state.favourited[props.articleId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getTargettedForId,
        (state, props): State => ({
            ...state,
            targetted: {
                ...state.targetted,
                [props.articleId]: {
                    ...(state.targetted[props.articleId] ||
                        ({} as CrudState<Person>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getTargettedForIdSuccess,
        (state, props): State => ({
            ...state,
            targetted: {
                ...state.targetted,
                [props.articleId]: {
                    ...state.targetted[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.people,
                },
            },
        }),
    ),

    on(
        articlesActions.getTargettedForIdFailure,
        (state, props): State => ({
            ...state,
            targetted: {
                ...state.targetted,
                [props.articleId]: {
                    ...state.targetted[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getViewedForId,
        (state, props): State => ({
            ...state,
            viewed: {
                ...state.viewed,
                [props.articleId]: {
                    ...(state.viewed[props.articleId] ||
                        ({} as CrudState<Person>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getViewedForIdSuccess,
        (state, props): State => ({
            ...state,
            viewed: {
                ...state.viewed,
                [props.articleId]: {
                    ...state.viewed[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.people,
                },
            },
        }),
    ),

    on(
        articlesActions.getViewedForIdFailure,
        (state, props): State => ({
            ...state,
            viewed: {
                ...state.viewed,
                [props.articleId]: {
                    ...state.viewed[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getRecentArticles,
        (state): State => ({
            ...state,
            recent: {
                ...state.recent,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recent[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getRecentArticlesSuccess,
        (state, props): State => ({
            ...state,
            recent: {
                ...state.recent,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recent[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.articles,
                },
            },
        }),
    ),

    on(
        articlesActions.getRecentArticlesFailure,
        (state): State => ({
            ...state,
            recent: {
                ...state.recent,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.recent[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Article>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getFeedClientsForId,
        (state, props): State => ({
            ...state,
            feedClients: {
                ...state.feedClients,
                [props.articleId]: {
                    ...(state.feedClients[props.articleId] ||
                        ({} as CrudState<Client>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getFeedClientsForIdSuccess,
        (state, props): State => ({
            ...state,
            feedClients: {
                ...state.feedClients,
                [props.articleId]: {
                    ...state.feedClients[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clients,
                },
            },
        }),
    ),

    on(
        articlesActions.getFeedClientsForIdFailure,
        (state, props): State => ({
            ...state,
            feedClients: {
                ...state.feedClients,
                [props.articleId]: {
                    ...state.feedClients[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        articlesActions.getCommentsForId,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.articleId]: {
                    ...(state.comments[props.articleId] ||
                        ({} as CrudState<ActivityChat>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        articlesActions.getCommentsForIdSuccess,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.articleId]: {
                    ...state.comments[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.comments,
                },
            },
        }),
    ),

    on(
        articlesActions.getCommentsForIdFailure,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.articleId]: {
                    ...state.comments[props.articleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
