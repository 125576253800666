import { createAction, props } from '@ngrx/store';

import { KeyValuePoint } from '../../models/investment-data.model';
// eslint-disable-next-line max-len
import {
    CashMovement,
    CashSummary,
    Performance,
    PerformancePeriod,
    SecurityPosition,
    SecurityTransaction,
    StandingOrder,
    ValuationHistory,
    ValuationLevel,
    ValuationSummary,
} from '@wdx/clmi/clmi-swagger-gen';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getValuationHistoryForClientProduct = createAction(
    '[Investment Data] Get Valuation History For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getValuationHistoryForClientProductSuccess = createAction(
    '[Investment Data] Get Valuation History For Client Product Success',
    props<{
        clientProductId: string;
        valuationHistory: ValuationHistory;
    }>(),
);

export const getValuationHistoryForClientProductFailure = createAction(
    '[Investment Data] Get Valuation History For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getPerformanceForClientProduct = createAction(
    '[Investment Data] Get Performance For Client Product',
    props<{
        clientProductId: string;
        period: PerformancePeriod;
    }>(),
);

export const getPerformanceForClientProductSuccess = createAction(
    '[Investment Data] Get Performance For Client Product Success',
    props<{
        clientProductId: string;
        performance: Performance;
    }>(),
);

export const getPerformanceForClientProductFailure = createAction(
    '[Investment Data] Get Performance For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getMovements = createAction(
    '[Investment Data] Get Movements',
    props<{
        valuationLevel: ValuationLevel;
        valuationEntityId: string;
        reset: boolean;
    }>(),
);

export const getMovementsSuccess = createAction(
    '[Investment Data] Get Movements Success',
    props<{
        movements: PaginatedApiResponse<CashMovement>;
        valuationEntityId: string;
    }>(),
);

export const getMovementsFailure = createAction(
    '[Investment Data] Get Movements Failure',
    props<{
        valuationEntityId: string;
        error: Error;
    }>(),
);

export const getAssetAllocationByCountryForClientProduct = createAction(
    '[Investment Data] Get Asset Allocation By Country For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getAssetAllocationByCountryForClientProductSuccess = createAction(
    '[Investment Data] Get Asset Allocation By Country For Client Product Success',
    props<{
        clientProductId: string;
        allocation: KeyValuePoint[];
    }>(),
);

export const getAssetAllocationByCountryForClientProductFailure = createAction(
    '[Investment Data] Get Asset Allocation By Country For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getAssetAllocationByGeographicForClientProduct = createAction(
    '[Investment Data] Get Asset Allocation By Geographic For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getAssetAllocationByGeographicForClientProductSuccess =
    createAction(
        '[Investment Data] Get Asset Allocation By Geographic For Client Product Success',
        props<{
            clientProductId: string;
            allocation: KeyValuePoint[];
        }>(),
    );

export const getAssetAllocationByGeographicForClientProductFailure =
    createAction(
        '[Investment Data] Get Asset Allocation By Geographic For Client Product Failure',
        props<{
            clientProductId: string;
            error: Error;
        }>(),
    );

export const getAssetAllocationBySectorForClientProduct = createAction(
    '[Investment Data] Get Asset Allocation By Sector For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getAssetAllocationBySectorForClientProductSuccess = createAction(
    '[Investment Data] Get Asset Allocation By Sector For Client Product Success',
    props<{
        clientProductId: string;
        allocation: KeyValuePoint[];
    }>(),
);

export const getAssetAllocationBySectorForClientProductFailure = createAction(
    '[Investment Data] Get Asset Allocation By Sector For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getAssetAllocationByAssetClassForClientProduct = createAction(
    '[Investment Data] Get Asset Allocation By Asset Class For Client Product',
    props<{
        clientProductId: string;
    }>(),
);

export const getAssetAllocationByAssetClassForClientProductSuccess =
    createAction(
        '[Investment Data] Get Asset Allocation By Asset Class For Client Product Success',
        props<{
            clientProductId: string;
            allocation: KeyValuePoint[];
        }>(),
    );

export const getAssetAllocationByAssetClassForClientProductFailure =
    createAction(
        '[Investment Data] Get Asset Allocation By Asset Class For Client Product Failure',
        props<{
            clientProductId: string;
            error: Error;
        }>(),
    );

export const getCashSummary = createAction(
    '[Investment Data] Get Cash Summary',
    props<{
        valuationLevel: ValuationLevel;
        valuationEntityId: string;
    }>(),
);

export const getCashSummarySuccess = createAction(
    '[Investment Data] Get Cash Summary Success',
    props<{
        cashSummaries: CashSummary;
        valuationEntityId: string;
    }>(),
);

export const getCashSummaryFailure = createAction(
    '[Investment Data] Get Cash Summary Failure',
    props<{
        valuationEntityId: string;
        error: Error;
    }>(),
);

export const getPosition = createAction(
    '[Investment Data] Get Position',
    props<{
        clientProductId: string;
    }>(),
);

export const getPositionSuccess = createAction(
    '[Investment Data] Get Position Success',
    props<{
        cashSummaries: CashSummary;
        clientProductId: string;
    }>(),
);

export const getPositionFailure = createAction(
    '[Investment Data] Get Position Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);

export const getTopMovers = createAction(
    '[Investment Data] Get Top Movers',
    props<{
        clientProductId: string;
    }>(),
);

export const getTopMoversSuccess = createAction(
    '[Investment Data] Get Top Movers Success',
    props<{
        movements: SecurityPosition[];
        clientProductId: string;
    }>(),
);

export const getTopMoversFailure = createAction(
    '[Investment Data] Get Top Movers Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>(),
);
