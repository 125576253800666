/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PartyType {
  Unknown = 'Unknown',
  Person = 'Person',
  Organisation = 'Organisation',
  User = 'User',
  Team = 'Team',
  Construct = 'Construct'
}
