/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CompletedStatusType {
  Inactive = 'Inactive',
  Cancelled = 'Cancelled',
  CompletedOnTime = 'CompletedOnTime',
  CompletedOverdue = 'CompletedOverdue',
  DueSoon = 'DueSoon',
  Overdue = 'Overdue',
  DueLater = 'DueLater',
  NoDeadline = 'NoDeadline',
  HasIssue = 'HasIssue'
}
