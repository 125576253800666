import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Opportunity } from '@wdx/clmi/clmi-swagger-gen';
import { Paging } from '@wdx/clmi/api-services/models';
import { BaseWidgetClass } from '../../../classes/base-widget.class';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { INFINITY_SCROLL_CONTENT } from '../../../constants/inifinity-scroll-content.contants';
import { AssignToEvent } from '../../../models/assign.model';
import { CardType } from '../../../models/card-type.model';
import { ScrollMode } from '../../../models/scroll-mode.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'widget-opportunities-list',
    templateUrl: './widget-opportunities-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block' },
})
export class WidgetOpportunitiesListComponent
    extends BaseWidgetClass
    implements OnInit
{
    @Input() opportunities: Opportunity[];
    @Input() opportunitiesPaging: Paging;
    @Input() opportunitiesPerRow: number;
    @Input() filterHandler: FilterHandler;
    @Input() showOpportunitiesListActionsButtons: boolean;
    @Input() showDeleteLinkMenuItem: boolean;
    @Input() totalRecordCount: number;
    @Input() scrollMode: ScrollMode;
    @Input() partyId: string;
    @Input() partyRoleId: string;
    @Input() linkToEntityType: string;
    @Input() linkToEntityId: string;
    @Output() infinityScrollFired = new EventEmitter<INFINITY_SCROLL_CONTENT>();
    @Output() deleteOpportunityLink = new EventEmitter<string>();
    @Output() assignTo = new EventEmitter<AssignToEvent>();

    cardType: CardType = CardType.Opportunity;
    limit = 3;
    modalId: string;

    ngOnInit() {
        this.modalId = `all-${this.cardType}-cards`;
        if (this.spokeButton) {
            this.setDefaultModal(this.modalId);
            this.spokeButton.isHidden =
                !this.opportunities?.length ||
                (this.limit > 0 && this.opportunities?.length <= this.limit);
        }
    }

    onModalClosed() {
        if (this.filterHandler) {
            this.filterHandler.reset();
        }
    }

    onInfinityScrollFired() {
        this.infinityScrollFired.emit(INFINITY_SCROLL_CONTENT.Opportunities);
    }

    onAssignTo(assignToEvent: AssignToEvent) {
        this.assignTo.emit(assignToEvent);
    }
}
