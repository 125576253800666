import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ActionType,
    EntityOperations,
    EntityPermissionType,
} from '@wdx/clmi/clmi-swagger-gen';
import { userSelectors } from '@wdx/clmi/api-services/services';
import {
    OperationsFacade,
    operationsActions,
    operationsSelectors,
} from '@wdx/clmi/api-services/state';
import {
    WdxDialogComponent,
    WdxDialogService,
} from '@wdx/shared/components/wdx-dialog';
import { FileUploadPackage } from '@wdx/shared/infrastructure/file-io';
import {
    EntityAction,
    FeaturesService,
    NavItem,
    TranslationsService,
    WdxSize,
} from '@wdx/shared/utils';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponentClass } from '../../../../classes/base-component.class';
import { FilterHandler } from '../../../../classes/filter-handler.class';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { DropdownButtonStyle } from '../../../../models/dropdown-button-style.model';
import { Privilege } from '../../../../models/privilege.model';
import { RandomStringPipe } from '../../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../../services/modal-management.service';
import * as rootReducer from '../../../../state/_setup/reducers';
import { MoleculeActionButtonService } from '../shared/services/molecule-action-button.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-action-button',
    templateUrl: './molecule-action-button.component.html',
})
export class MoleculeActionButtonComponent
    extends BaseComponentClass
    implements OnInit, OnChanges
{
    private _actionButton: ActionButton;
    @Input() set actionButton(value: ActionButton) {
        this.operations$ = undefined;
        this._actionButton = value;
    }
    public get actionButton() {
        return this._actionButton;
    }
    @Input() btnClass = 'btn-outline-primary';
    @Input() size: WdxSize = 'sm';
    @Input() parentContainer = 'body';
    @Input() hideDropdownChevron: boolean;
    @Input() dropdownButtonStyle: DropdownButtonStyle =
        DropdownButtonStyle.Label;
    @Input() dropdownPlacement = ['bottom-right', 'top-right'];
    @Input() dropdownMenuSize = 'xs';
    @Input() dropdownAutoClose: boolean | 'outside' | 'inside';
    @Input() isDisabled = false;
    @Input() filterHandler: FilterHandler;
    @Input() highlightSelectedMenuItem: boolean;
    @Input() cySelector?: string;

    @Output() selectMenuItem = new EventEmitter<NavItem>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter<boolean>();
    @Output() fileSelected = new EventEmitter<FileUploadPackage>();
    @Output() dropdownMenuToggled = new EventEmitter<boolean>();

    dropdownTemplateId: string;
    dropdownMenu$ = new BehaviorSubject<NavItem[]>(null);

    operations$: Observable<EntityOperations>;
    isLoading$ = of(false);
    isVisible = true;
    resetButtonsFlag = false;

    private moleculeActionButtonService = inject(MoleculeActionButtonService);
    private operationsFacade = inject(OperationsFacade);
    private wdxDialogService = inject(WdxDialogService);
    private translationsService = inject(TranslationsService);

    constructor(
        private changeDetector: ChangeDetectorRef,
        private featuresService: FeaturesService,
        store$: Store<rootReducer.State>,
        modalManagementService: ModalManagementService,
    ) {
        super();
        this.patchInjectedItems({
            store$,
            modalManagementService,
        });
    }

    ngOnChanges() {
        if (this.actionButton?.dropdownMenu) {
            this.setDropdownMenu();
        }

        if (this.actionButton?.permission) {
            this.setPermissionVisibility();
        }

        if (this.actionButton?.privileges) {
            this.setPrivilegeVisibility();
        }

        if (this.actionButton?.feature) {
            this.isVisible = this.featuresService.hasFeature(
                this.actionButton?.feature,
            );
        }
    }

    ngOnInit(): void {
        this?.filterHandler?.resetValues
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetButtonsFlag = false;

                if (
                    this.actionButton.mode === ActionButtonMode.FavouriteButton
                ) {
                    this.changeDetector.detectChanges();
                    this.resetButtonsFlag = true;
                }
            });
    }

    setDropdownMenu() {
        this.dropdownTemplateId = new RandomStringPipe().transform();
        this.dropdownMenu$.next(this.actionButton.dropdownMenu);
    }

    setPermissionVisibility() {
        if (this.actionButton?.operationsSetup) {
            this.isVisible = false;
            this.getOperations();
            this.operations$
                .pipe(
                    takeUntil(this.destroyed$),
                    filter((operations) => Boolean(operations?.permissions)),
                )
                .subscribe(
                    (operations) =>
                        (this.isVisible = operations.permissions.includes(
                            this.actionButton?.permission,
                        )),
                );
        }
    }

    setPrivilegeVisibility() {
        this.isVisible = false;
        this.store$
            .select(userSelectors.getPrivilegesSelector)
            .pipe(take(1))
            .subscribe(
                (privileges) =>
                    (this.isVisible =
                        privileges?.includes(Privilege.Administrator) ||
                        privileges?.some((privilege: Privilege) =>
                            this.actionButton?.privileges?.includes(privilege),
                        )),
            );
    }

    getOperations() {
        if (this.operations$) {
            return;
        }
        const { operationsSetup } = this.actionButton;
        if (
            operationsSetup &&
            operationsSetup?.entityType &&
            operationsSetup?.entityId
        ) {
            this.isLoading$ = this.store$.select(
                operationsSelectors.operationsForIdIsLoadingSingle,
                operationsSetup,
            );
            this.operations$ = this.store$
                .select(operationsSelectors.getOperationsForId, operationsSetup)
                .pipe(
                    takeUntil(this.destroyed$),
                    tap((operations) => {
                        if (!operations) {
                            this.store$.dispatch(
                                operationsActions.getOperationsForId(
                                    operationsSetup,
                                ),
                            );
                        }
                    }),
                );
        } else if (operationsSetup && operationsSetup?.entityType) {
            this.isLoading$ = this.store$.select(
                operationsSelectors.operationsForEntityIsLoadingSingle,
                operationsSetup,
            );
            this.operations$ = this.operationsFacade.getOperationsForEntity$(
                operationsSetup.entityType,
            );
        } else {
            this.isLoading$ = of(false);
            this.operations$ = of({
                permissions: [EntityPermissionType.Edit],
                actions: [],
            });
        }
    }

    onFileSelected(uploadEvent: any): void {
        const fileUploadPackage = {
            id: new RandomStringPipe().transform(),
            file: uploadEvent.target.files[0],
        };
        this.fileSelected.emit(fileUploadPackage);
    }

    onActionButtonClicked(toggle?: boolean) {
        if (this.actionButton.modalId) {
            this.modalManagementService.openModalWithId(
                this.actionButton.modalId,
            );
        }
        if (this.actionButton.callback) {
            this.actionButton.callback();
        }
        this.onClick.emit(toggle);
    }

    onMenuItemSelected(navItem: NavItem) {
        this.selectMenuItem.emit(navItem);
    }

    onDropdownToggled(isOpen: boolean) {
        this.dropdownMenuToggled.emit(isOpen);
        if (isOpen) {
            this.getOperations();
            combineLatest([
                this.store$.select(userSelectors.getPrivilegesSelector),
                this.operations$,
            ])
                .pipe(takeUntil(this.destroyed$))
                .subscribe(([privileges, operations]) => {
                    const updatedMenu = this.actionButton?.dropdownMenu?.map(
                        (menu) => ({
                            ...menu,
                            hidden: (() => {
                                if (menu.hidden) {
                                    return true;
                                }

                                if (menu.feature) {
                                    return !this.featuresService.hasFeature(
                                        menu.feature,
                                    );
                                }

                                if (
                                    menu.privilege &&
                                    !privileges.includes(
                                        Privilege.Administrator,
                                    )
                                ) {
                                    return !privileges?.includes(
                                        menu.privilege,
                                    );
                                }
                                if (menu.permission) {
                                    if (
                                        menu.notPermission &&
                                        operations?.permissions?.includes(
                                            menu.notPermission,
                                        )
                                    ) {
                                        return true;
                                    }
                                    return !operations?.permissions?.includes(
                                        menu.permission,
                                    );
                                }
                                return false;
                            })(),
                        }),
                    );
                    let actionMenu;
                    if (
                        !this.actionButton?.ignoreActions &&
                        operations?.actions?.length
                    ) {
                        actionMenu = operations.actions.map((action) => {
                            const navItem: NavItem = {
                                id: action.displayName?.key,
                                label: this.translationsService.translateTokenisedString(
                                    action.displayName?.value ||
                                        action.displayName?.key,
                                ),
                                icon: action.icon,
                            };
                            if (action.type === ActionType.Form) {
                                return {
                                    ...navItem,
                                    formSetup: {
                                        formId: action.formName,
                                        entityId:
                                            action.formDataEntityId ||
                                            this.actionButton.operationsSetup
                                                ?.entityId,
                                    },
                                };
                            }
                            if (action.type === ActionType.NewForm) {
                                return {
                                    ...navItem,
                                    formSetup: {
                                        formId: action.formName || action.url,
                                        initialisationParams: {
                                            ...(action.formInitialisationParameters ||
                                                {}),
                                            entityId:
                                                this.actionButton
                                                    .operationsSetup?.entityId,
                                        },
                                    },
                                };
                            }
                            if (action.type === ActionType.Patch) {
                                return {
                                    ...navItem,
                                    callback: () => {
                                        if (action?.url) {
                                            if (action?.requiresConfirmation) {
                                                const actionModal =
                                                    this.wdxDialogService.open(
                                                        WdxDialogComponent,
                                                    );
                                                actionModal.componentInstance.title =
                                                    this.translationsService.translateTokenisedString(
                                                        `${action.displayName.key}_CONFIRMATION_TITLE`,
                                                    );

                                                actionModal.componentInstance.dialogBody =
                                                    this.translationsService.translateTokenisedString(
                                                        `${action.displayName.key}_CONFIRMATION_BODY`,
                                                    );

                                                actionModal.componentInstance.wdxOnClick.subscribe(
                                                    () => {
                                                        this.dispatchOperationAction(
                                                            action,
                                                        );
                                                    },
                                                );
                                            } else {
                                                this.dispatchOperationAction(
                                                    action,
                                                );
                                            }
                                        }
                                    },

                                    data: action,
                                };
                            }
                        });

                        // ---------------------------------------------------------
                        // Adding ability to override action item
                        // To be removed once the actions endpoint has been updated
                        // to do this automatically
                        // ---------------------------------------------------------
                        actionMenu.forEach((actionItem) => {
                            const matchIndex = updatedMenu?.findIndex(
                                (updatedItem) =>
                                    updatedItem.id === actionItem.id,
                            );
                            if (matchIndex > -1) {
                                actionItem.hidden = true;
                                updatedMenu[matchIndex].hidden = false;
                            }
                        });
                        // ---------------------------------------------------------
                    }
                    this.dropdownMenu$.next([
                        ...(updatedMenu || []),
                        ...(actionMenu || []),
                    ]);
                });
        }
    }

    private dispatchOperationAction(action: EntityAction): void {
        this.moleculeActionButtonService.operationActionCallback(
            this.actionButton.operationsSetup,
            action,
            this.actionButton.actionCallbacks?.find(
                (cb) =>
                    cb.type === action.type &&
                    (!cb.url || cb.url === action.url),
            )?.callback,
        );
    }
}
