import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { DashboardApiService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { DashboardsFacadeService } from './dashboards-facade.service';
import * as dashboardsActions from './dashboards.actions';

@Injectable()
export class DashboardsEffects {
    private actions$ = inject(Actions);
    private dashboardsFacadeService = inject(DashboardsFacadeService);
    private dashboardApiService = inject(DashboardApiService);

    getDashboards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.getDashboards),
            mergeMap(() =>
                this.dashboardApiService.getDashboards().pipe(
                    map((dashboards) => {
                        return dashboardsActions.getDashboardsSuccess({
                            dashboards,
                        });
                    }),
                    catchError((error: Error) =>
                        of(dashboardsActions.getDashboardsFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    getDashboardsConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.getDashboardsConfiguration),
            switchMap(() =>
                this.dashboardApiService.getDashboardsConfiguration().pipe(
                    map((configuration) =>
                        dashboardsActions.getDashboardsConfigurationSuccess({
                            configuration,
                        }),
                    ),
                    catchError((error: Error) =>
                        of(
                            dashboardsActions.getDashboardsConfigurationFailure(
                                {
                                    error,
                                },
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    createDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.createDashboard),
            mergeMap((action) =>
                this.dashboardApiService
                    .createDashboard(SystemEntity.User, action.body)
                    .pipe(
                        map((dashboard) => {
                            this.dashboardsFacadeService.dashboardUpdated$.next(
                                dashboard,
                            );
                            return dashboardsActions.createDashboardSuccess({
                                dashboard: dashboard,
                            });
                        }),
                        catchError((error: Error) =>
                            of(
                                dashboardsActions.createDashboardFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.updateDashboard),
            mergeMap((action) =>
                this.dashboardApiService
                    .updateDashboard(
                        SystemEntity.User,
                        action.dashboardId,
                        action.body,
                    )
                    .pipe(
                        map((dashboard) => {
                            this.dashboardsFacadeService.dashboardUpdated$.next(
                                dashboard,
                            );
                            return dashboardsActions.updateDashboardSuccess({
                                dashboard: dashboard,
                            });
                        }),
                        catchError((error: Error) =>
                            of(
                                dashboardsActions.updateDashboardFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deleteDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.deleteDashboard),
            mergeMap((action) =>
                this.dashboardApiService
                    .deleteDashboard(action.dashboardId, action.entityType)
                    .pipe(
                        map(() => {
                            return dashboardsActions.deleteDashboardSuccess({
                                dashboardId: action.dashboardId,
                                entityType: action.entityType,
                            });
                        }),
                        catchError((error: Error) =>
                            of(
                                dashboardsActions.deleteDashboardFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    shareDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.shareDashboard),
            mergeMap((action) =>
                this.dashboardApiService
                    .shareDashboard(action.dashboardId, action.contactId)
                    .pipe(
                        map(() => {
                            return dashboardsActions.shareDashboardSuccess({
                                dashboardId: action.dashboardId,
                                contactId: action.contactId,
                            });
                        }),
                        catchError((error: Error) =>
                            of(
                                dashboardsActions.shareDashboardFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    unshareDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardsActions.unshareDashboard),
            mergeMap(({ dashboardId, contactId, meIsOwner }) =>
                this.dashboardApiService
                    .unshareDashboard(dashboardId, contactId)
                    .pipe(
                        map(() => {
                            return dashboardsActions.unshareDashboardSuccess({
                                dashboardId,
                                contactId,
                                meIsOwner,
                            });
                        }),
                        catchError((error: Error) =>
                            of(
                                dashboardsActions.unshareDashboardFailure({
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
