import { Portfolio } from '@wdx/clmi/clmi-swagger-gen';
import { createAction, props } from '@ngrx/store';

const ACTION_PREFIX = '[Portfolio]';

export const getPortfolio = createAction(
    `${ACTION_PREFIX} Get Portfolio`,
    props<{
        portfolioId: string;
    }>(),
);

export const getPortfolioSuccess = createAction(
    `${ACTION_PREFIX} Get Portfolio Success`,
    props<{
        portfolio: Portfolio;
        portfolioId: string;
    }>(),
);

export const getPortfolioError = createAction(
    `${ACTION_PREFIX} Get Portfolio Error`,
    props<{
        error: Error;
        portfolioId: string;
    }>(),
);
