import { Action, createReducer, on } from '@ngrx/store';
import { SystemEntity, WorkItem } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { NgRxService } from '../../services/ngrx.service';
import * as workItemsActions from './work-items.actions';

export interface State {
    workItems?: CrudStateObject<WorkItem>;
}

export const initialState: State = {
    workItems: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        workItemsActions.getWorkItemsForProcess,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Process, props)]: {
                    ...(state.workItems[
                        NgRxService.chunkId(SystemEntity.Process, props)
                    ] || ({} as CrudState<WorkItem>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemsForProcessSuccess,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Process, props)]: {
                    ...state.workItems[
                        NgRxService.chunkId(SystemEntity.Process, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.workItems,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemsForProcessFailure,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Process, props)]: {
                    ...state.workItems[
                        NgRxService.chunkId(SystemEntity.Process, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemsForCase,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...(state.workItems[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ] || ({} as CrudState<WorkItem>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemsForCaseSuccess,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...state.workItems[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.workItems,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemsForCaseFailure,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...state.workItems[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItem,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [props.workItemId]: {
                    ...(state.workItems[props.workItemId] ||
                        ({} as CrudState<WorkItem>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemSuccess,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [props.workItem.id]: {
                    ...state.workItems[props.workItem.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.workItem,
                },
            },
        }),
    ),

    on(
        workItemsActions.getWorkItemFailure,
        (state, props): State => ({
            ...state,
            workItems: {
                ...state.workItems,
                [props.workItemId]: {
                    ...state.workItems[props.workItemId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
