import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DocumentType } from '@wdx/clmi/clmi-swagger-gen';
import { CrudState } from '@wdx/clmi/api-services/models';
import { CrudStateObject, CrudStatus } from '@wdx/shared/utils';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as documentTypesActions from './document-types.actions';

export interface ActiveDocumentTypeState extends EntityState<DocumentType> {
    status: CrudStatus;
}

export interface State {
    activeDocumentTypeState: ActiveDocumentTypeState;
    documentTypes: CrudStateObject<DocumentType>;
    query?: CrudState<DocumentType>;
}

export function selectId(documentType: DocumentType): string {
    return documentType.id;
}

export const adapter: EntityAdapter<DocumentType> =
    createEntityAdapter<DocumentType>({
        selectId,
    });

export const initialState: State = {
    activeDocumentTypeState: adapter.getInitialState({
        status: CrudStatus.Initial,
    }),
    documentTypes: {},
    query: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentTypesActions.getAll,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentType>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    page: props?.reset
                        ? null
                        : state.documentTypes[GLOBAL_STATE_INDEX_ID]?.page,
                    infinity: props?.reset
                        ? null
                        : state.documentTypes[GLOBAL_STATE_INDEX_ID]?.infinity,
                },
            },
        }),
    ),

    on(
        documentTypesActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    page: {
                        paging: props.documentTypes.paging,
                        results: props.documentTypes.results,
                    },
                    infinity: {
                        paging: props.documentTypes.paging,
                        combinedList: props.documentTypes.results,
                    },
                },
            },
        }),
    ),

    on(
        documentTypesActions.getAllFailure,
        (state): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),

    on(
        documentTypesActions.updateDocumentTypes,
        (state): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentType>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        }),
    ),

    on(
        documentTypesActions.updateDocumentTypesSuccess,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    page: {
                        paging: props.payload.paging,
                        results: props.payload.results,
                    },
                    infinity: {
                        paging: props.payload.paging,
                        combinedList: [
                            ...(state.documentTypes[GLOBAL_STATE_INDEX_ID]
                                .infinity?.combinedList || []),
                            ...props.payload.results,
                        ],
                    },
                },
            },
        }),
    ),

    on(
        documentTypesActions.updateDocumentTypesFailure,
        (state): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        }),
    ),

    on(
        documentTypesActions.queryDocumentTypes,
        (state): State => ({
            ...state,
            query: {
                ...(state.query || ({} as CrudState<DocumentType>)),
                isLoadingPage: true,
                hasLoadingPageError: false,
            },
        }),
    ),

    on(
        documentTypesActions.queryDocumentTypesSuccess,
        (state, props): State => ({
            ...state,
            query: {
                ...state.query,
                isLoadingPage: false,
                hasLoadingPageError: false,
                page: {
                    paging: props.documentTypes.paging,
                    results: props.documentTypes.results,
                },
                infinity: {
                    paging: props.documentTypes.paging,
                    combinedList: props.documentTypes.results,
                },
            },
        }),
    ),

    on(
        documentTypesActions.queryDocumentTypesFailure,
        (state): State => ({
            ...state,
            query: {
                ...state.query,
                isLoadingPage: false,
                hasLoadingPageError: true,
            },
        }),
    ),

    on(
        documentTypesActions.clearQuery,
        (state): State => ({
            ...state,
            query: {
                isLoadingPage: false,
                hasLoadingPageError: false,
                page: null,
                infinity: null,
            },
        }),
    ),

    on(
        documentTypesActions.getDocumentTypeById,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [props.documentTypeCode]: {
                    ...(state.documentTypes[props.documentTypeCode] ||
                        ({} as CrudStateObject<DocumentType>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        }),
    ),

    on(
        documentTypesActions.getDocumentTypeByIdSuccess,
        (state, props): State => {
            return {
                ...state,
                documentTypes: {
                    ...state.documentTypes,
                    [props.documentTypeCode]: {
                        ...state.documentTypes[props.documentTypeCode],
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.document,
                    },
                },
            };
        },
    ),

    on(
        documentTypesActions.getDocumentTypeByIdFailure,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [props.documentTypeCode]: {
                    ...state.documentTypes[props.documentTypeCode],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        }),
    ),

    on(
        documentTypesActions.disableDocumentType,
        (state): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        documentTypesActions.disableDocumentTypeSuccess,
        (state, props): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentType>)),
                    page: {
                        ...state.documentTypes[GLOBAL_STATE_INDEX_ID]?.page,
                        results: state.documentTypes[
                            GLOBAL_STATE_INDEX_ID
                        ]?.page?.results?.map((item: DocumentType) =>
                            item.code === props.code
                                ? props.updatedDocType
                                : item,
                        ),
                    },
                    infinity: {
                        ...state.documentTypes[GLOBAL_STATE_INDEX_ID]?.infinity,
                        combinedList: state.documentTypes[
                            GLOBAL_STATE_INDEX_ID
                        ]?.infinity?.combinedList.map((item: DocumentType) =>
                            item.code === props.code
                                ? props.updatedDocType
                                : item,
                        ),
                    },
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        }),
    ),

    on(
        documentTypesActions.disableDocumentTypeFailure,
        (state): State => ({
            ...state,
            documentTypes: {
                ...state.documentTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTypes[GLOBAL_STATE_INDEX_ID],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        }),
    ),

    on(
        documentTypesActions.getAllActiveDocumentTypes,
        (state): State => ({
            ...state,
            activeDocumentTypeState: adapter.removeAll({
                ...state.activeDocumentTypeState,
                status: CrudStatus.Loading,
            }),
        }),
    ),

    on(
        documentTypesActions.getAllActiveDocumentTypesSuccess,
        (state, { activeDocumentTypes }): State => ({
            ...state,
            activeDocumentTypeState: adapter.addMany(activeDocumentTypes, {
                ...state.activeDocumentTypeState,
                status: CrudStatus.Success,
            }),
        }),
    ),

    on(
        documentTypesActions.getAllActiveDocumentTypesFailure,
        (state): State => ({
            ...state,
            activeDocumentTypeState: adapter.removeAll({
                ...state.activeDocumentTypeState,
                status: CrudStatus.Error,
            }),
        }),
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}

// get the selectors
const { selectAll } = adapter.getSelectors();

export const selectAllActiveDocumentTypes = selectAll;
