import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayIncludes',
    standalone: true,
})
export class ArrayIncludesPipe implements PipeTransform {
    transform(array?: any[] | null, value?: any, property?: string): boolean {
        if (!array) {
            return false;
        }
        if (property) {
            // Check if property exists on value and array items
            const valueProperty = this.getNestedValue(value, property);
            if (valueProperty === undefined) {
                return false;
            }

            return array.some((item) => {
                const itemProperty = this.getNestedValue(item, property);
                return itemProperty === valueProperty;
            });
        }
        return array.includes(value);
    }

    private getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }
}
