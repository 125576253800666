import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CommentCreate, SystemEntity } from '@wdx/clmi/clmi-swagger-gen';
import { CrudStatus } from '@wdx/shared/utils';
import { Observable, filter, switchMap } from 'rxjs';
import * as chatActions from './chat.actions';
import { Chat } from './chat.reducer';
import * as chatSelectors from './chat.selectors';
import { selectChat } from './chat.selectors';

@Injectable()
export class ChatFacade {
    constructor(private store$: Store) {}

    getChat$(entityType: SystemEntity, entityId: string): Observable<Chat> {
        return this.store$.pipe(
            select(selectChat({ entityId, entityType })),
            switchMap((chat) => {
                if (!chat) {
                    this.store$.dispatch(
                        chatActions.loadChat({
                            entityId,
                            entityType,
                        }),
                    );
                }
                return this.store$.pipe(
                    select(
                        chatSelectors.selectChat({
                            entityId,
                            entityType,
                        }),
                    ),
                    filter(Boolean),
                );
            }),
        );
    }

    allChatsOfTypeUpdated$(entityType: SystemEntity): Observable<Chat[]> {
        return this.store$.pipe(
            select(chatSelectors.selectChatsOfType({ entityType })),
            filter(
                (chats) =>
                    !!chats.length &&
                    chats.every((chat) => chat.status === CrudStatus.Success),
            ),
        );
    }

    createComment(
        entityType: SystemEntity,
        entityId: string,
        commentCreate: CommentCreate,
    ) {
        this.store$.dispatch(
            chatActions.createComment({
                entityId,
                entityType,
                commentCreate,
            }),
        );
    }
}
