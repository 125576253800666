/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FilterOperator {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  In = 'In',
  NotIn = 'NotIn',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  MoreThan = 'MoreThan',
  MoreThanOrEqualTo = 'MoreThanOrEqualTo',
  Contains = 'Contains',
  NotContains = 'NotContains',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  LastXDays = 'LastXDays',
  NextXDays = 'NextXDays',
  OlderThanXDays = 'OlderThanXDays',
  LastXHours = 'LastXHours',
  NextXHours = 'NextXHours',
  ThisYear = 'ThisYear',
  ThisMonth = 'ThisMonth',
  ThisWeek = 'ThisWeek',
  Matches = 'Matches',
  ContainsAny = 'ContainsAny',
  NotContainsAny = 'NotContainsAny',
  ContainsAll = 'ContainsAll',
  NotContainsAll = 'NotContainsAll',
  ContainsData = 'ContainsData',
  DoesNotContainData = 'DoesNotContainData'
}
