import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiConfigService } from '../api-config.service';
import {
    IdentityCheck,
    IdentityCheckReview,
    KeyStatResult,
} from '@wdx/clmi/clmi-swagger-gen';
import { BaseApiService } from '../base-api.service';
import { IdentityVerificationUrlSegment } from './identity-verification-url-segment';

@Injectable()
export class IdentityVerificationService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getKeystats(identityCheckId: string): Observable<KeyStatResult[]> {
        return this.get<KeyStatResult[]>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${identityCheckId}/${IdentityVerificationUrlSegment.keystats}`,
        );
    }

    getCheckDetail(partyId: string): Observable<IdentityCheck> {
        return this.get<IdentityCheck>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${IdentityVerificationUrlSegment.party}/${partyId}/${IdentityVerificationUrlSegment.latest}`,
        );
    }

    getIdVerificationCheckDetail(
        idVerificationCheckId: string,
    ): Observable<IdentityCheck> {
        return this.get<IdentityCheck>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${idVerificationCheckId}`,
        );
    }

    getCheckReviews(
        identityCheckId: string,
    ): Observable<IdentityCheckReview[]> {
        return this.get<IdentityCheckReview[]>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${identityCheckId}/${IdentityVerificationUrlSegment.review}`,
        );
    }

    getCheckReviewsAdditional(
        identityCheckId: string,
    ): Observable<IdentityCheckReview[]> {
        return this.get<IdentityCheckReview[]>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${identityCheckId}/${IdentityVerificationUrlSegment.review}/${IdentityVerificationUrlSegment.additional}`,
        );
    }

    getCheckedHistory(partyId: string): Observable<IdentityCheck[]> {
        return this.get<IdentityCheck[]>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${IdentityVerificationUrlSegment.party}/${partyId}/${IdentityVerificationUrlSegment.history}`,
        );
    }

    refreshHeader(identityCheckId: string): Observable<IdentityCheck> {
        return this.patch<IdentityCheck>(
            `${IdentityVerificationUrlSegment.IdentityCheck}/${identityCheckId}`,
            {},
        );
    }

    rejectIdentityCheckReview(
        identityCheckReviewId: string,
    ): Observable<IdentityCheckReview> {
        return this.patch<IdentityCheckReview>(
            `${IdentityVerificationUrlSegment.identityCheckReview}/${identityCheckReviewId}/${IdentityVerificationUrlSegment.reject}`,
            {},
        );
    }

    approveIdentityCheckReview(
        identityCheckReviewId: string,
    ): Observable<IdentityCheckReview> {
        return this.patch<IdentityCheckReview>(
            `${IdentityVerificationUrlSegment.identityCheckReview}/${identityCheckReviewId}/${IdentityVerificationUrlSegment.approve}`,
            {},
        );
    }
}
